import React from "react"
import {observer} from "mobx-react"
import TextField from "@material-ui/core/TextField"
import classnames from "classnames"

import XsIconButton from "../xsButton/xsIconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import MenuItem from "@material-ui/core/MenuItem"
import "./xsInput.less"
import {getFilters} from "../../helpers/api"
import AutocompleteModal from "./autocompleModal"
import {getFieldIdentifier} from "../../helpers/actions"
import ReactTooltip from "react-tooltip"
import renderHTML from "react-render-html"
// import {toLatin} from "../../helpers/functions"
// import { injectIntl } from "react-intl"

// @injectIntl
@observer
export default class XsAutocomplete extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			modalIsOpen: false,
			isValid: false,
			mouseHover: {count: null, id: null},
			isLoadingData: false
		}
		this.inputText = ""
		this.innerValue = ""
		this.apiCallType = isNotEmpty(this.props.apiCallType) ? this.props.apiCallType : ""
		this.acceptResponse = true
		this.currentReq
		this.originalRules = this.props.field.get("rules")
		this.isLoading = false
		this.endDotsValue = null
		this.previouslySearchedString = ""
	}

	handleValidation = (field) => {
		field.validate().then(({isValid}) => {
			if (this.state.isValid !== isValid) {
				this.setState({
					isValid: isValid
				})
			}
		})
	}

	handleFreeTextValidator() {
		if (this.props.freeText) {
			if (
				isSafe(this.props.freeTextField) &&
				isSafe(this.props.freeTextField.value) &&
				this.props.freeTextField.value.length
			) {
				this.props.field.set("rules", "")
			} else {
				this.props.field.set("rules", this.originalRules)
			}
		}
	}

	focus = () => {
		if (isSafe(this.textFieldInputReference)) {
			this.textFieldInputReference.focus()
			//EMA-8919 Scroll na zaciatok stringu v inpute pri focuse
			this.textFieldInputReference.setSelectionRange(0, 0)
			this.textFieldInputReference.scrollLeft = 0
		}
	}

	handleClick = () => {
		this.setState({modalIsOpen: true})
	}

	handleChoose(option, fromHandleDBLoad = false, forcedRefresh = false) {
		const {postAction, inputRenderer, saveValue, firstLoad} = this.props
		if (this.props.autoFocus) {
			this.textFieldInputReference.focus()
		}
		this.textFieldInputReference.value = inputRenderer(option)
		let saveValueString = saveValue(option)
		if (isNotEmpty(option.name_ext)) {
			this.inputText = option.name_ext
		}
		if (isSafe(saveValueString)) {
			saveValueString = "" + saveValueString
			this.props.field.set(saveValueString)
			this.innerValue = saveValueString
			this.handleOnChange(this.props.field)
			this.handleValidation(this.props.field)
			this.setState({data: []})
			this.previouslySearchedString = ""
			if (isSafe(postAction) && typeof postAction === "function") {
				postAction(option, fromHandleDBLoad, forcedRefresh, this.endDotsValue, this.clearText)
			}
			if (fromHandleDBLoad && typeof firstLoad === "function") {
				firstLoad(option)
			}
		}
		this.endDotsValue = null
		this.setState({mouseHover: {count: null, id: null}})
	}

	handleOnChange = (field) => {
		const {onChange} = this.props
		if (isSafe(onChange) && typeof onChange === "function") {
			onChange(field.get("value"))
		}
		field.sync(field.get("value"))
	}

	handleClickAway = () => {
		this.inputText = this.textFieldInputReference.value
		if (!this.props.freeText) this.textFieldInputReference.value = this.props.field.value = ""
		this.setState({data: []})
	}

	hightlightSearch = (label, search) => {
		let re = new RegExp(this.props.removePunctuation ? removePunctuation(search) : search, "gi")
		if (isEmpty(search)) {
			return label
		}
		return label.replace(
			re,
			`<b>${(this.props.removePunctuation ? removePunctuation(search) : search).toUpperCase()}</b>`
		)
	}

	getIXSRequestId = (id) => {
		this.acceptResponse = id == this.currentReq
	}

	handleDBSearch(value, forceSearch = false) {
		if (value == "" && !forceSearch) {
			this.innerValue = ""
			this.inputText = ""
			this.props.field.set("value", "")
			this.handleOnChange(this.props.field)
			this.handleValidation(this.props.field)
			if (typeof this.props.onClear === "function") this.props.onClear()
			this.setState({data: []})
		} else {
			this.inputText = value
			if (this.props.freeText && isSafe(this.props.freeTextField)) {
				this.props.freeTextField.set(value)
			}
			if (
				(isNotEmpty(value) && value.length >= +this.props.minLengthForSearch) ||
				(isNotEmpty(value) && isEmpty(this.props.minLengthForSearch)) ||
				forceSearch
			) {
				this.setState({isLoadingData: true})
				delayedCallback(500, async (searchId) => {
					this.currentReq = searchId
					if (isNotSafe(value) || isEmpty(value) || value.length < +this.props.minLengthForSearch) {
						this.setState({data: [], isLoadingData: false}, () => {
							return
						})
					}
					let searchRequest = getFilters([
						`${isNotEmpty(this.props.searchFilter) ? this.props.searchFilter : "search_column"}=${
							this.props.removePunctuation ? removePunctuation(value) : value
						}`
					])
					if (
						isSafe(this.props.addSpecialFilter) &&
						typeof this.props.addSpecialFilter === "function" &&
						isSafe(this.props.addSpecialFilter()) &&
						this.props.addSpecialFilter().length
					) {
						searchRequest.filters.push(...this.props.addSpecialFilter())
					}
					searchRequest["row_count_full"] = 200
					if (isSafe(this.props.specialLoadFunction) && typeof this.props.specialLoadFunction == "function") {
						const responseData = await this.props.specialLoadFunction(searchRequest, this.apiCallType)
						if (isSafe(this.props.specialDataFilter) && typeof this.props.specialDataFilter === "function") {
							this.setState({data: this.props.specialDataFilter(responseData), isLoadingData: false})
						} else {
							this.setState({data: responseData, isLoadingData: false})
						}
					} else {
						this.props
							.api(searchRequest, this.apiCallType)
							.call(null, searchId, (id) => this.getIXSRequestId(id))
							.then((response) => {
								if (this.acceptResponse) {
									if (isSafe(this.props.specialDataFilter) && typeof this.props.specialDataFilter === "function") {
										this.setState({data: this.props.specialDataFilter(response.rows), isLoadingData: false})
									} else {
										this.setState({data: response.rows, isLoadingData: false})
									}
								}
							})
					}
				})
			}
		}
	}

	async handleDBLoad(forcedRefresh = false) {
		this.innerValue = this.props.field.value
		this.isLoading = true
		if (isNotSafe(this.props.field.value) || isEmpty(this.props.field.value)) {
			if (this.props.field.value == "") {
				this.innerValue = ""
				this.inputText = ""
				this.props.field.set("value", "")
				if (isSafe(this.textFieldInputReference)) {
					this.textFieldInputReference.value = ""
				}
				this.handleValidation(this.props.field)
				if (typeof this.props.onClear === "function") this.props.onClear()
			}
			return
		}
		let searchRequest = getFilters([
			`${isNotEmpty(this.props.filterValue) ? this.props.filterValue : "code"}=${this.props.field.value}`
		])
		if (
			isSafe(this.props.addSpecialFilter) &&
			typeof this.props.addSpecialFilter === "function" &&
			isSafe(this.props.addSpecialFilter()) &&
			this.props.addSpecialFilter().length
		) {
			searchRequest.filters.push(...this.props.addSpecialFilter())
		}

		if (isSafe(this.props.specialLoadFunction) && typeof this.props.specialLoadFunction == "function") {
			const responseData = await this.props.specialLoadFunction(searchRequest, this.apiCallType)
			if (isSafe(responseData) && isSafe(responseData[0])) {
				this.handleChoose(responseData[0], true, forcedRefresh)
			} else {
				this.innerValue = ""
				this.props.field.set("value", "")
				if (typeof this.props.onEmptyLoadResponse === "function") this.props.onEmptyLoadResponse()
				if (typeof this.props.onClear === "function") this.props.onClear()
			}
			this.isLoading = false
		} else {
			let api = isNotEmpty(this.props.loadApi) ? this.props.loadApi : this.props.api
			api(searchRequest, this.apiCallType)
				.call()
				.then((response) => {
					if (isSafe(response.rows) && isSafe(response.rows[0])) {
						this.handleChoose(response.rows[0], true, forcedRefresh)
					} else {
						this.innerValue = ""
						this.props.field.set("value", "")
						if (typeof this.props.onEmptyLoadResponse === "function") this.props.onEmptyLoadResponse()
						if (typeof this.props.onClear === "function") this.props.onClear()
					}
					this.isLoading = false
				})
				.catch(() => {
					this.isLoading = false
				})
		}
	}
	componentDidMount() {
		if (this.props.field.value != "" && this.textFieldInputReference.value == "" && !this.isLoading) {
			this.handleDBLoad()
		} else if (
			this.innerValue == "" &&
			this.props.field.value == "" &&
			this.props.freeText &&
			isSafe(this.props.freeTextField)
		) {
			this.textFieldInputReference.value = this.props.freeTextField.value
			this.handleFreeTextValidator()
		} else {
			if (isSafe(this.props.onMountWithoutLoad)) {
				this.props.onMountWithoutLoad()
			}
		}
		this.props.field.set("options", {
			forceRefresh: () => {
				setTimeout(() => {
					this.forceRefresh()
				}, 0)
			},
			searchLastStringAgain: () => {
				this.searchLastStringAgain()
			},
			removeLastSearchString: () => {
				this.previouslySearchedString = ""
			}
		})
	}

	componentDidUpdate() {
		this.handleFreeTextValidator()
		if (this.props.field.value != "" && this.textFieldInputReference.value == "" && !this.isLoading) {
			this.handleDBLoad()
		} else if (
			this.innerValue == "" &&
			this.props.field.value == "" &&
			this.props.freeText &&
			isSafe(this.props.freeTextField)
		) {
			this.textFieldInputReference.value = this.props.freeTextField.value
		} else if (this.innerValue != this.props.field.value) {
			this.handleDBLoad()
		}
	}

	handleModalRowClick = (dataRow) => {
		this.handleChoose(dataRow)
		this.handleModalClose()
	}

	handleModalClose = () => {
		this.setState({modalIsOpen: false})
	}

	onMouseOver = (n, item) => {
		this.setState({mouseHover: {count: n + 1, id: item._id}})
	}

	onMouseLeave = () => {
		this.setState({mouseHover: {count: null, id: null}})
	}

	forceRefresh = () => {
		this.handleDBLoad(true)
	}

	searchLastStringAgain = () => {
		if (this.previouslySearchedString != "") {
			this.textFieldInputReference.value = this.previouslySearchedString
			this.handleDBSearch(this.previouslySearchedString)
		}
	}

	clearText = () => {
		if (!this.props.disabled) {
			this.innerValue = ""
			this.inputText = ""
			this.previouslySearchedString = ""
			this.props.field.set("value", "")
			if (isSafe(this.textFieldInputReference)) {
				this.textFieldInputReference.value = ""
			}
			this.handleValidation(this.props.field)
			if (typeof this.props.onClear === "function") this.props.onClear()
		}
	}

	textFieldInputReference

	onInputFocus = () => {
		if (this.props.showTopRows) {
			this.handleDBSearch("", true)
		}
	}

	render() {
		const {field, inputRenderer, white, autoFocus = false, disabled} = this.props
		const bindedField = field.bind()
		const labelJSX =
			isSafe(field.get("rules")) && field.get("rules").includes("required") ? (
				<span className="xs-input-label xs-required">{bindedField.label} *</span>
			) : (
				<span className="xs-optional">{bindedField.label}</span>
			)

		let levelJSON = {
			1: "#64b5f6",
			2: "#0071ff",
			3: "#ffce00",
			4: "#ff9700",
			5: "#f44336"
		}

		let classIdentifier = getFieldIdentifier(field, "xs-input-filter")

		return (
			<div className={classIdentifier}>
				<div className="xs-filter">
					<TextField
						data-tip
						data-for={"xsAutocompleteFieldValue" + field.id}
						data-multiline={true}
						inputProps={{
							onFocus: () => {
								this.onInputFocus()
							},
							ref: (input) => (this.textFieldInputReference = input),
							onKeyDown: (e) => {
								if (e.key === "Tab") {
									if (isSafe(this.state.data) && this.state.data.length == 1) {
										this.handleChoose(this.state.data[0])
									}
								}
							},
							className: white ? "xs-white" : "",
							tabIndex: isSafe(this.props.tabindex) ? this.props.tabindex : ""
						}}
						InputProps={{
							classes: {
								root:
									isSafe(field.get("rules")) && field.get("rules").includes("required") && !this.state.isValid
										? "xs-input-error"
										: ""
							},
							endAdornment: (
								// isSafe(this.props.modalConfig) && typeof this.props.modalConfig === "function" ? (
								// 	<InputAdornment position="end" variant="filled" className={"xs-search-modal"}>
								// 		<XsIconButton
								// 			className="xs-menu-btn"
								// 			icon={<MoreHorizIcon />}
								// 			onClick={() => this.handleClick(this.textFieldInputReference.value)}
								// 		/>
								// 	</InputAdornment>
								// ) : null

								<InputAdornment position="end" variant="filled" className="xs-align endAbdornment">
									{isSafe(this.props.clearText) && (
										<XsIconButton
											className={`xs-menu-btn button-autocomplete-clear ${white ? "bgSnowWhite" : ""}`}
											icon={<i className="fal fa-times fa-lg"></i>}
											onClick={() => this.clearText()}
										/>
									)}
									{isSafe(this.props.modalConfig) && typeof this.props.modalConfig === "function" && (
										<XsIconButton
											className={`xs-menu-btn button-autocomplete-expand ${white ? "bgSnowWhite" : ""}`}
											icon={<i className="far fa-ellipsis-h fa-lg"></i>}
											onClick={() => this.handleClick(this.textFieldInputReference.value)}
										/>
									)}
								</InputAdornment>
							)
						}}
						autoComplete="off"
						InputLabelProps={{
							shrink: true
						}}
						disabled={disabled}
						label={labelJSX}
						placeholder={isSafe(this.props.placeholder) ? this.props.placeholder : ""}
						autoFocus={autoFocus}
						onChange={(e) => {
							this.handleDBSearch(e.target.value)
							this.handleFreeTextValidator()
							if (isSafe(this.props.freeTextField)) {
								this.props.freeTextField.set(this.textFieldInputReference.value)
								this.props.freeTextField.onChange(this.textFieldInputReference.value)
							}
						}}
						onBlur={(e) => {
							if (typeof this.props.onBlur === "function") this.props.onBlur(e)
							if (isSafe(this.textFieldInputReference)) {
								this.previouslySearchedString = this.textFieldInputReference.value
							}
							delayedCallback(0, () => {
								if (this.props.field.value == "")
									if (!this.props.freeText) {
										if (isSafe(this.textFieldInputReference)) {
											this.textFieldInputReference.value = ""
										}
									} else {
										if (isSafe(this.props.freeTextField)) {
											this.props.freeTextField.set(this.textFieldInputReference.value)
											this.props.freeTextField.onChange(this.textFieldInputReference.value)
										}
									}
							})
						}}
					/>
					{/* {isSafe(this.props.modalConfig) && typeof this.props.modalConfig === "function" && (
						<XsIconButton
							className="xs-menu-btn"
							icon={<MoreHorizIcon />}
							onClick={() => this.handleClick(this.textFieldInputReference.value)}
						/>
					)} */}
				</div>
				{this.state.data && this.state.data.length > 0 && (
					<ClickAwayListener onClickAway={this.handleClickAway}>
						<Paper
							elevation={10}
							className={classnames("xs-filter-menu", {"xs-bottom": this.props.placementMenu == "top"})}
							style={{maxWidth: isNotEmpty(this.props.maxWidthMenu) ? `${this.props.maxWidthMenu}px` : "unset"}}
						>
							{this.state.data.length > 0 &&
								this.state.data.map((option, idx) => (
									<MenuItem key={idx} component="div" onClick={() => this.handleChoose(option)}>
										{/* {renderHTML(isSafe(inputRenderer) && typeof inputRenderer === "function" ? this.hightlightSearch(inputRenderer(option), this.textFieldInputReference.value) : this.hightlightSearch(option, this.textFieldInputReference.value))} */}
										<div className="xs-row xs-flex xs-justify-between">
											<span
												dangerouslySetInnerHTML={{
													__html:
														isSafe(inputRenderer) && typeof inputRenderer === "function"
															? this.hightlightSearch(inputRenderer(option, true), this.textFieldInputReference.value)
															: this.hightlightSearch(option, this.textFieldInputReference.value)
												}}
											/>
											{this.props.endDots && (
												<span className="xs-circle-level">
													{[0, 1, 2, 3, 4].map((item, idx) => {
														let currCount =
															isSafe(this.state.mouseHover) && isSafe(this.state.mouseHover.count)
																? this.state.mouseHover.count
																: 1
														let currId =
															isSafe(this.state.mouseHover) && isSafe(this.state.mouseHover.id)
																? this.state.mouseHover.id
																: null
														if (option._id == currId) {
															if (idx < currCount) {
																return (
																	<i
																		key={idx}
																		className={`fas fa-circle xs-circle-${idx}`}
																		style={{color: levelJSON[currCount]}}
																		onMouseOver={() => this.onMouseOver(item, option)}
																		onMouseLeave={() => this.onMouseLeave()}
																		onClick={() => {
																			this.endDotsValue = item
																		}}
																	/>
																)
															} else {
																return (
																	<i
																		key={idx}
																		onClick={() => {
																			this.endDotsValue = item
																		}}
																		onMouseOver={() => this.onMouseOver(item, option)}
																		onMouseLeave={() => this.onMouseLeave()}
																		className={`fas fa-circle xs-circle-${idx}`}
																		style={{color: "#78849E"}}
																	/>
																)
															}
														} else {
															if (idx < 1) {
																return (
																	<i
																		key={idx}
																		className={`fas fa-circle xs-circle-${idx}`}
																		style={{color: "#64b5f6"}}
																		onMouseOver={() => this.onMouseOver(item, option)}
																		onMouseLeave={() => this.onMouseLeave()}
																		onClick={() => {
																			this.endDotsValue = item
																		}}
																	/>
																)
															} else {
																return (
																	<i
																		key={idx}
																		onClick={() => {
																			this.endDotsValue = item
																		}}
																		onMouseOver={() => this.onMouseOver(item, option)}
																		onMouseLeave={() => this.onMouseLeave()}
																		className={`fas fa-circle xs-circle-${idx}`}
																		style={{color: "#78849E"}}
																	/>
																)
															}
														}
													})}
												</span>
											)}
										</div>
									</MenuItem>
								))}
						</Paper>
					</ClickAwayListener>
				)}
				{this.state.isLoadingData && (
					<ClickAwayListener onClickAway={this.handleClickAway}>
						<Paper
							elevation={10}
							className={classnames("xs-filter-menu", {"xs-bottom": this.props.placementMenu == "top"})}
						>
							<MenuItem key={1} component="div">
								<div className="xs-row xs-flex xs-justify-between">
									<span
										dangerouslySetInnerHTML={{
											__html: "Vyhľadávam..."
										}}
									/>
								</div>
							</MenuItem>
						</Paper>
					</ClickAwayListener>
				)}
				{isSafe(this.textFieldInputReference) && isNotEmpty(this.textFieldInputReference.value) && isSafe(field) && (
					<ReactTooltip data-multiline={true} id={"xsAutocompleteFieldValue" + field.id}>
						{isNotEmpty(this.textFieldInputReference.value) ? renderHTML(this.textFieldInputReference.value) : ""}
					</ReactTooltip>
				)}
				{isSafe(this.props.modalConfig) && typeof this.props.modalConfig === "function" && (
					<AutocompleteModal
						isOpen={this.state.modalIsOpen}
						onClose={this.handleModalClose}
						modalTitle={bindedField.label}
						uri={this.props.api("", this.apiCallType).getPath()}
						searchValue={this.inputText}
						config={this.props.modalConfig(this.inputText, this.handleModalRowClick)}
						specFilter={isSafe(this.props.addSpecialFilter) ? this.props.addSpecialFilter : null}
						renderFooterInModal={this.props.renderFooterInModal}
					/>
				)}
			</div>
		)
	}
}
