"use strict"

import {observable, action} from "mobx"

class ErrorStore {
	@observable isOpen = false
	@observable status = ""
	@observable statusText = ""
	@observable response = undefined
	@observable additionalInfo = null
	@observable showMoreInfo = false

	@action open() {
		this.isOpen = true
		if (
			isSafe(this.additionalInfo) &&
			isNotEmpty(this.additionalInfo.path) &&
			this.additionalInfo.path.includes("/sec/")
		) {
			this.additionalInfo = null
		}
	}

	@action close() {
		this.isOpen = false
		this.showMoreInfo = false
		this.additionalInfo = null
		// this.status = ""
		// this.statusText = ""
		// this.response = undefined
	}
}

var singleton = new ErrorStore()
export default singleton
