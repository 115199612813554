import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsDropdownList from "../../../../global/ui/xsDropdown/xsDropdownList"
import XsChip from "../../../../global/ui/xsChip/xsChip"

import {FormControlLabel, Grid, TextField, Checkbox} from "@material-ui/core"

import InputAdornment from "@material-ui/core/InputAdornment"
import PatientList from "./patientList"
import DataStore from "../../stores/DataStore"
import UIStore from "../../stores/UIStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import NewPatientDialogStore from "../../stores/NewPatientDialogStore"
import ExportToXlsDialogStore from "../../stores/ExportToXlsDialogStore"
import ExportBatchDialog from "./exportBatchDialog"
import ExportBatchDialogStore from "../../stores/ExportBatchDialogStore"
import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"
import MovePatientsToAnotherOrgunitDialog from "./movePatientsToAnotherOrgunitDialog"
import MovePatientsToAnotherOrgunitStore from "../../stores/MovePatientsToAnotherOrgunitStore"
// import {getUserAccessRights} from "../../../../global/helpers/actions"

import SearchDropdownNoForm from "../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"

import DiagnosesSearch from "./patientListDiagnosesSearch"
import NewPatientDialog from "./newPatientDialog"
import ExportToXlsDialog from "../exportToXlsDialog/exportToXlsDialog"
import CheckMedicineCardDialog from "../../checkMedicineCardDialog"

import Storage from "../../../../global/storage/storage"
import {LAST_PATIENTS} from "../../../../global/config/constants"
import RouterStore from "../../../../global/store/RouterStore"

@injectIntl
@observer
export default class PatientListContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTag: "empty",
			searchValue: ""
		}
		UIStore.mainMenuTab = "mainmenu"
		DataStore.search_column_patientListFilter = undefined
		DataStore.diagnoses_patientListFilter = undefined
		DataStore.all_patientListFilter = undefined
		DataStore.insurer_patientListFilter = undefined

		DataStore.patientsFilteredDiagnoses = null
		DataStore.tags_patientListFilter = null

		GlobalStore.refreshCodeLists(["patientTagTypes"])
	}

	searchPatient = (value) => {
		this.setState({searchValue: value})

		delayedCallback(500, () =>
			value.length >= 3
				? (DataStore.search_column_patientListFilter = value.includes("/") ? value.replace("/", "") : value)
				: (DataStore.search_column_patientListFilter = undefined)
		)
	}

	renderTags = () => {
		let tagItems = []

		if (isSafe(DataStore.patientsTagsData) && DataStore.patientsTagsData.length > 0) {
			tagItems.push({
				text_value: this.props.intl.formatMessage({id: "Patient.PatientList.Filter.All"}),
				id_value: "empty"
			})

			DataStore.patientsTagsData.forEach((tag) => {
				tag.values.forEach((value) => {
					if (value.text_value != "VIP") {
						// EMA-12145
						tagItems.push({
							text_value: value.text_value,
							id_value: value.id_value,
							associated_column: tag.associated_column
						})
					}
				})
			})
		}

		return tagItems
	}

	render() {
		const tagChecked =
			isSafe(DataStore.tags_patientListFilter) && DataStore.tags_patientListFilter.length > 0
				? DataStore.tags_patientListFilter.map((x) => x.id_value)
				: null

		let lastPatients = Storage.getItem(LAST_PATIENTS)

		return (
			<Grid container className="xs-patient-list-container">
				{isSafe(lastPatients) && lastPatients.length > 0 && (
					<Grid item xs={12} style={{paddingTop: "20px"}}>
						<Grid container direction="row">
							<Grid item xs={12}>
								<FormattedMessage id="Common.label.lastOpened" />:
								{lastPatients.reverse().map((patient, idx) => {
									return (
										<XsChip
											key={idx}
											className="xs-lastPatient-chip"
											label={
												isNotEmpty(patient.name)
													? patient.name
													: isSafe(patient.dataRow)
													? `${patient.dataRow.profile.first_name} ${patient.dataRow.profile.last_name}`
													: ""
											}
											max={50}
											onClick={() => {
												RouterStore.push(`/patient?id=${patient.person_id}`)
											}}
										/>
									)
								})}
							</Grid>
						</Grid>
					</Grid>
				)}
				<Grid item xs={12} className="xs-new-patient">
					{/* {getUserAccessRights("SRVZO.API.REST.MAIN./object/messages/notifyusers") && (
						<XsButton
							className={
								DataStore.patientChecked.length > 0 ? "xs-primary xs-outline mr-3" : "xs-default xs-outline mr-3"
							}
							disabled={DataStore.patientChecked.length == 0}
							icon={<i className="fal fa-file-excel fa-lg" />}
							onClick={() => {
								MovePatientsToAnotherOrgunitStore.open()
							}}
							text={<FormattedMessage id="Common.label.moveToAnotherOrgunit" />}
						/>
					)} */}
					<XsButton
						// <i class="fas fa-file-export"></i>
						className="xs-primary xs-outline mr-3 patientlist-export"
						icon={<i className="fal fa-file-excel fa-lg" />}
						onClick={() => {
							ExportBatchDialogStore.open()
						}}
						text={<FormattedMessage id="Common.label.export515" defaultMessage="Export 515" />}
					/>
					<XsButton
						// <i class="fas fa-file-export"></i>
						className="xs-primary xs-outline mr-3 patientlist-export"
						icon={<i className="fal fa-file-excel fa-lg" />}
						onClick={() => {
							ExportToXlsDialogStore.open()
						}}
						text={<FormattedMessage id="Common.label.export" defaultMessage="Export to excel" />}
					/>
					{/* <XsButton
            className="xs-primary xs-outline"
            icon={<i className="fal fa-user"></i>}
            onClick={() => {
              UIStore.patientDetailTab = "PersonalInfo"
              DataStore.nameNewPatient = undefined
              RouterStore.push("/patient")
            }
            }
            text={<FormattedMessage id="Patient.form.patientnew.newpatient"
              defaultMessage="New patient" />}
          /> */}
					<XsButton
						className="xs-primary xs-outline patientlist-newpatient"
						icon={<i className="fal fa-user fa-lg" />}
						onClick={() => {
							DataStore.nameNewPatient = ""
							DataStore.isCreatedFromEvent = false
							NewPatientDialogStore.open(this.state.searchValue)
							// UIStore.patientDetailTab = "PersonalInfo"
							// DataStore.nameNewPatient = undefined
							// RouterStore.push("/patient")
						}}
						text={<FormattedMessage id="Patient.form.patientnew.newpatient" defaultMessage="New patient" />}
					/>
				</Grid>
				<Grid item xs={12} className="patientlist-header">
					<Grid container direction="row" justify="space-between">
						<Grid item>
							<Grid container direction="row" spacing={8} className="xs-patient-left-filters">
								<Grid item xs={7}>
									<TextField
										id="PatientListSearchInput"
										placeholder={this.props.intl.formatMessage({id: "Common.searchDropdown.search"})}
										className="xs-search-component"
										inputProps={{
											className: "xs-search-input"
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start" className="xs-search-adornment">
													<i className="fal fa-search" />
												</InputAdornment>
											)
										}}
										value={this.state.searchValue}
										onChange={(e) => this.searchPatient(e.target.value)}
										autoComplete="off"
									/>
								</Grid>
								<Grid item xs={5}>
									<XsDropdownList
										emptyValue
										labelAsPlaceholder
										label={this.props.intl.formatMessage({id: "Capitation.form.insurers"})}
										items={RegistersCacheStore.insurersRegister}
										renderer={(item) => {
											return <span>{item.name_ext}</span>
										}}
										column="_id"
										value={DataStore.insurer_patientListFilter}
										onChange={(value) => (DataStore.insurer_patientListFilter = value)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<div className="xs-filters">
								<FormControlLabel
									control={
										<Checkbox
											className="xs-orgunit-checkbox"
											checked={DataStore.orgUnit_patientListFilter}
											onChange={() => (DataStore.orgUnit_patientListFilter = !DataStore.orgUnit_patientListFilter)}
										/>
									}
									label={
										<span className="xs-orgunit-label">
											<FormattedMessage id="Patient.PatientList.Filter.Orgunit" />
										</span>
									}
								/>
								<SearchDropdownNoForm
									items={
										isSafe(GlobalStore.CL["patientTagTypes"])
											? GlobalStore.CL["patientTagTypes"].map((tag) => {
													return {
														id: tag._id,
														search_string: tag.search_column,
														// primaryText: tag.name_ext,
														secondaryText: tag.name_ext,
														checkValue: isSafe(tagChecked) && tagChecked.includes(tag._id)
													}
											  })
											: []
									}
									multiSelect={true}
									id="patientListContainer_tags"
									label={<FormattedMessage id="Common.label.tags" />}
									onClose={(data) => {
										const filters =
											data.length > 0
												? data.map((x) => {
														return {id_value: x.id}
												  })
												: null

										DataStore.tags_patientListFilter = filters
									}}
								/>
								<DiagnosesSearch />
								<XsDropdownList
									items={this.renderTags()}
									renderer={(item) => {
										return <span>{item.text_value}</span>
									}}
									column="id_value"
									value={this.state.activeTag}
									chooseItem={(item) => {
										this.setState({activeTag: item.id_value})
										DataStore.all_patientListFilter = {
											associated_column: item.associated_column,
											id_value: item.id_value
										}
									}}
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<PatientList id={this.props.id} data={DataStore.patientsData} />
				</Grid>
				<NewPatientDialog />
				<CheckMedicineCardDialog />
				<ExportToXlsDialog
					onSuccess={(type) => {
						DataStore.exportPatients(type)
					}}
				/>
				{MovePatientsToAnotherOrgunitStore.isOpen && <MovePatientsToAnotherOrgunitDialog />}
				<ExportBatchDialog />
			</Grid>
		)
	}
}
