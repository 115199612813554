import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import ReactTooltip from "react-tooltip"
import Tooltip from "@material-ui/core/Tooltip"
import config from "../../../../global/config/settings"
import {getFilters, GWApiCall} from "../../../../global/helpers/api"
import {
	getUserPersonnelID,
	getUserCompanyInfo,
	getSelectedOrgUnitID,
	getSelectedOrgUnit,
	getUser
} from "../../../../global/helpers/actions"
import moment from "moment"
import api from "../../actions/api"
import PatientInfoForm from "./patientInfo/patientInfoForm"
// import PatientHeader from "./patientHeader/patientHeader"
import PatientCard from "./patientCard/patientCardContainer"
import DataStore from "../../stores/DataStore"
import UIStore from "../../stores/UIStore"
import GlobalStore from "../../../../global/store/GlobalStore"
// import WarningStore from "../../../../global/store/WarningStore"
import SmsNotificationDialog from "./cardFromDovera/smsNotificationDialog"
import PatientDetailActionsContainer from "./patientDetailActions/patientDetailActionsContainer"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import AddEventDialogContainer from "../calendar/addEventDialogContainer"
import EditEventDialogStore from "../../stores/EditEventDialogStore"
import EditEventDialogContainer from "../calendar/editEventDialogContainer"
import PatientAlertsDialog from "./patientSidebar/patientAlertsDialog"
import PatientAlertsDialogStore from "../../stores/PatientAlertsDialogStore"
import PatientAllergiesDialog from "./patientSidebar/patientAllergiesDialog"
import PatientAllergiesDialogStore from "../../stores/PatientAllergiesDialogStore"
import PatientTagsDialog from "./patientSidebar/patientTagsDialog"
import PatientTagsDialogStore from "../../stores/PatientTagsDialogStore"
import PatientImplantDialog from "./patientSidebar/patientImplantDialog"
import PatientImplantDialogStore from "../../stores/PatientImplantDialogStore"
import PatientProblemsDialog from "./problems/problemsDialog"
import PatientPayDebtDialog from "./patientHeader/patientPayDebtDialog"
import PatientBloodTypeDialog from "./patientSidebar/patientBloodTypeDialog"
import PatientBloodTypeDialogStore from "../../stores/PatientBloodTypeDialogStore"
import PatientTemplatesDialog from "./patientDetailActions/patientTemplatesDialog"
import PrintTemplatesDialog from "./printTemplates/printTemplatesDialog"
import PatientDeliveryDialog from "./patientDelivery/patientDeliveryDialog"
import MedicineCardDialog from "./patientPrescriptionForm/medicineCardDialog"
import AddDeliveryPatientDetailDialog from "./patientDelivery/addDeliveryPatientDetailDialog"
import ShowPDFDialog from "./patientInsuranceServices/showPDFDialog"
import RequestsSVLZInsurerViewerDialog from "./patientInsuranceServices/requestsSVLZInsurerViewerDialog"
import AcceptOfTheTermDialog from "./patientInsuranceServices/acceptOfTheTermDialog"
import RequestsSVLZInsurerDialog from "./patientInsuranceServices/requestsSVLZInsurerDialog"
import RequestsSVLZDialog from "./patientInsuranceServices/requestSVZLDialog"
import PatientProblemsDialogStore from "../../stores/PatientProblemsDialogStore"
import SmsNotificationStore from "../../stores/SmsNotificationStore"
import PatientDetailActionsStore from "../../stores/PatientDetailActionsStore"
import PatientTemplatesDialogStore from "../../stores/PatientTemplatesDialogStore"
import PrintTemplatesStore from "../../stores/PrintTemplatesStore"
import RequestsSVLZInsurerStore from "../../stores/RequestsSVLZInsurerStore"
import MedicineCardDialogStore from "../../stores/MedicineCardDialogStore"
import HospicomDialog from "../patientDetail/patientCard/hospicom/hospicomDialog"

import ProblemsStore from "../../stores/ProblemsStore"
import PatientInsuranceServices from "./patientInsuranceServices/patientInsuranceServices"

// import "./patientDetail.less"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import PatientRecordStore from "../../stores/PatientRecordStore"
import PatientPayDebtDialogStore from "../../stores/PatientPayDebtDialogStore"
import PatientDeliveryDialogStore from "../../stores/PatientDeliveryDialogStore"
import PatientPrescriptionStore from "../../stores/PatientPrescriptionStore"

@injectIntl
@observer
export default class PatientDetailContainer extends React.Component {
	constructor(props) {
		super(props)

		//Načítať personInfo (entityInfo)
		ProblemsStore.problemsDTO = null
		UIStore.patientDetailTab = "EHR"
		// UIStore.mainMenuTab = "mainmenu"
		UIStore.mainMenuTab = "patientDetail"
		UIStore.insuranceMessage = undefined

		this.state = {
			allergiesOpen: false,
			tagsOpen: false,
			alertsOpen: false,
			implantsOpen: false
		}

		this._isMounted = false

		if (isSafe(this.props.id)) {
			DataStore.patientDTO.set("personId", this.props.id)
			const afterLoad = (info) => {
				const clientInfo = info.specific_rels.find((sr) => sr._type === "Entity.Data.Client")
				document.title = `${info.identifier} | ambee | SPIRE Life Solutions`
				const JRUZIDObj = isSafe(clientInfo.identifiers)
					? clientInfo.identifiers.find((row) => row.type === "JRUZID")
					: null
				const selectedOrgUnit = getSelectedOrgUnit()
				const personnel_id = getUserPersonnelID()
				const user = getUser()
				const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
				const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
				const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
				const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
				const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

				GlobalStore.PrZSRegisteredInEhealthState = 3

				GWApiCall({
					method: "POST",
					uri: `${config.GW_BASE_URL}/RegisterPrZS`,
					body: JSON.stringify({
						Force: true,
						AmbeePatientId: clientInfo._id,
						PatientIdentificationNumber: info.identifier,
						PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
						AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
						AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
						AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
						AmbeePersonnelId: personnel_id,
						OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
						PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
						PersonnelExpertiseJRUZVersion: specVer,
						PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
						// backup pre stare EHGW:
						JRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
						PersonalIdentificationNumber: info.identifier
					})
				})
					.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
					.then((data) => {
						if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
							GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
							// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
						}
						GlobalStore.PrZSRegisteredInEhealthState = isEmpty(data.ErrorMessage) && isSafe(data.JRUZId) ? 1 : 2
					})
					.catch(() => {
						GlobalStore.PrZSRegisteredInEhealthState = 2
					})

				const patient = clientInfo
				const companyInfo = getUserCompanyInfo()

				// DataStore.getPatientContacts(this.props.id)
				DataStore.contactUnknow = props.intl.formatMessage({id: "PatientDetail.cards.addContact"})
				DataStore.contactUnknowText = props.intl.formatMessage({id: "PatientDetail.cards.contactUnknow"})

				// DataStore.getPatientAddresses(this.props.id)
				DataStore.addressUnknow = props.intl.formatMessage({id: "PatientDetail.cards.addressUnknow"})
				DataStore.addressDesc = props.intl.formatMessage({id: "PatientDetail.cards.contactAddress"})

				// DataStore.getInsurances...
				DataStore.nonInsurerTxt = props.intl.formatMessage({id: "Patient.header.nonInsurer"})
				DataStore.addNowTxt = props.intl.formatMessage({id: "Patient.header.nonInsurer.addNow"})
				DataStore.patientIsNotEUInsuranceTxt = props.intl.formatMessage({
					id: "Common.label.patientIsNotEUInsurance"
				})
				DataStore.fixNowTxt = props.intl.formatMessage({id: "Common.label.fixNow"})
				DataStore.patientIsSKInsurer = props.intl.formatMessage({id: "Common.label.patientIsSKInsurer"})
				DataStore.patientIsEUInsuranceTxt = props.intl.formatMessage({id: "Common.label.patientIsEUInsurance"})

				const searchRequest = getFilters([`client_id=${clientInfo._id}`])
				api
					.loadCompanyPatients(companyInfo.id, searchRequest)
					.call()
					.then((response) => {
						const currPatient = response.rows[0]
						if (isSafe(currPatient)) {
							DataStore.patientDTO.set("lastVisit", currPatient.last_visit)
							DataStore.patientDTO.set("nextVisit", currPatient.next_event_from)
							DataStore.patientDTO.set("lastPreventiveExamination", currPatient.last_preventive_examination)
							DataStore.patientDTO.set("nextEventId", currPatient.next_event_id)
							DataStore.patientDTO.set("nextEventName", currPatient.next_event_service_name)
							DataStore.patientDTO.set("insurerShortName", currPatient.insurer_short_name)
						}
					})

				//kontrola ci je platic alebo neplatic

				api
					.overpoistenca(DataStore.patientDTO.get("patientID"), getSelectedOrgUnitID())
					.call()
					.then((response) => {
						if (isSafe(response)) {
							DataStore.nonPayer =
								isSafe(response.ma_narok_na_odkladnu_zs) && !response.ma_narok_na_odkladnu_zs
									? props.intl.formatMessage({id: "Patient.patientHeader.nonPayer"})
									: ""
							DataStore.patientDTO.set(
								"isNonPayer",
								isSafe(response.ma_narok_na_odkladnu_zs) &&
									isSafe(response.je_neplatic) &&
									!response.ma_narok_na_odkladnu_zs &&
									response.je_neplatic
									? true
									: false
							)

							if (
								isSafe(response.poistny_vztah_poistenca) &&
								response.poistny_vztah_poistenca === "JePoistencom" &&
								DataStore.patientDTO.get("insType") != "VZP"
							) {
								UIStore.insuranceMessage = {
									message: props.intl.formatMessage({id: "Common.label.patientIsSKInsurer"}),
									subMessage: props.intl.formatMessage({id: "Common.label.fixNow"})
								}
							} else if (
								!DataStore.patientDTO.get("isEUInsured") &&
								response.poistny_vztah_poistenca === "JePoistencomEU"
							) {
								UIStore.insuranceMessage = {
									message: props.intl.formatMessage({id: "Common.label.patientIsEUInsurance"}),
									subMessage: props.intl.formatMessage({id: "Common.label.fixNow"})
								}
							} else if (
								isSafe(response.poistny_vztah_poistenca) &&
								response.poistny_vztah_poistenca === "NieJePoistencom"
							) {
								UIStore.insuranceMessage = {
									message: props.intl.formatMessage({id: "Patient.header.nonInsurer"}),
									subMessage: props.intl.formatMessage({id: "Patient.header.nonInsurer.addNow"})
								}
							} else {
								UIStore.insuranceMessage = undefined
							}
						}
					})

				ProblemsStore.loadPatientProblems()

				// let capitationListFilter = getFilters(
				// 	[`personnel_id=${personnel_id}`, `patient_id=${clientInfo._id}`, `provider_id=${getSelectedOrgUnitID()}`],
				// 	{
				// 		from: moment(1).format(config.DB_DATE_FORMAT),
				// 		to: moment().format(config.DB_DATE_FORMAT)
				// 	}
				// )

				//Implant
				api
					.loadImplant(clientInfo._id)
					.call()
					.then((implants) => {
						DataStore.patientDTO.set("implants", implants.records)
					})

				//Upozornenia
				api
					.loadAlerts(clientInfo._id)
					.call()
					.then((alerts) => {
						let alertsRecords = []
						alerts.records.forEach((rec) => {
							alertsRecords.push({
								_id: rec._id,
								alert: rec.description,
								severity: isSafe(rec.severity) ? rec.severity.name_ext : "",
								code: isSafe(rec.severity) ? rec.severity.code : ""
							})
						})

						DataStore.patientDTO.set("alertsChips", alertsRecords)
						if (isSafe(DataStore.alertsChipsForm)) {
							DataStore.alertsChipsForm.bindData(alertsRecords)
						}

						//Kapitácie
						// api
						// 	.loadCapitation(capitationListFilter)
						// 	.call()
						// 	.then((capitationList) => {
						// 		DataStore.patientDTO.set("capitationsDTO", capitationList.rows)
						// 	})

						//Tagy
						api
							.loadTags("Entity.Data.Client", clientInfo._id, "Patient")
							.call()
							.then((tags) => {
								let tagRecords = []
								tags.forEach((rec) => {
									tagRecords.push({
										_id: rec._id,
										tagType: isSafe(rec.type) ? rec.type._id : "",
										tagName: isSafe(rec.type) ? rec.type.name_ext : "",
										newTagType: "",
										deleteTagType: ""
									})
								})

								DataStore.patientDTO.set("tagsChips", tagRecords)
								if (isSafe(DataStore.tagsChipsForm)) {
									DataStore.tagsChipsForm.bindData(tagRecords)
								}

								//Alergie
								api
									.loadAllergies(patient._id)
									.call()
									.then((allergies) => {
										let records = []
										allergies.records.forEach((rec) => {
											records.push({
												_id: rec._id,
												allergy: isSafe(rec.type) ? rec.type.name_ext : isSafe(rec.description) ? rec.description : "",
												allergen: isSafe(rec.allergen) ? rec.allergen.name_ext : "",
												allergenCode: isSafe(rec.allergen) ? rec.allergen._id : "",
												atcCode: isSafe(rec.atc) ? rec.atc._id : "",
												atc: isSafe(rec.atc) ? rec.atc.name_ext : "",
												severity: isSafe(rec.severity) ? rec.severity.name_ext : "",
												code: isSafe(rec.severity) ? rec.severity.code : "",
												symptoms: isSafe(rec.symptoms) ? rec.symptoms : []
											})
										})

										DataStore.patientDTO.set("alergiesChips", records)
										if (isSafe(DataStore.alergiesChipsForm)) {
											DataStore.alergiesChipsForm.bindData(records)
										}

										if (this._isMounted) {
											this.setState({
												isOpen: false
											})
										}
									})
							})
					})

				//Ziskanie krvnej skupiny pacienta
				api
					.loadBloodType(patient._id)
					.call()
					.then((response) => {
						DataStore.patientDTO.set("bloodType", response.records[0])
					})
			}

			//Načítanie pacienta do DTO
			DataStore.loadEntity(this.props.id, afterLoad)
		}

		// GlobalStore.refreshCodeLists(["patientTagTypes", "implantGroup", "classification"])
	}

	componentDidMount() {
		this._isMounted = true
		GlobalStore.forms = 0
	}

	//Zatvárame kartu pacienta, mažeme všetko
	componentWillUnmount() {
		DataStore.lastVisitedPatientID = DataStore.patientDTO.get("patientID")
		DataStore.patientDTO.clear()
		DataStore.clearCheckedEHR()
		DataStore.clearFormDataState()
		UIStore.patientOverviewFilter = null
		UIStore.patientCardTab = ""
		this._isMounted = false
		UIStore.EHRfilterValue = "overview"
		UIStore.overviewTab = "overview"
		UIStore.EHRversionFilterText = ""
		UIStore.EHRversionFilterValue = ""
		PatientDeliveryDialogStore.close()
		PatientPrescriptionStore.drugPrices.clear()
		PatientPrescriptionStore.drugPricesFull.clear()
		PatientRecordStore.defaultSendingType = null
		PatientPrescriptionStore.patientPresence = null
		document.title = "ambee | SPIRE Life Solutions"
	}

	onHandleDialog = () => {
		this.setState({
			isOpen: true
		})
	}

	handleClose = () => {
		this.setState({
			isOpen: false
		})
	}

	renderAlerts = () => {
		let alerts = []

		let alertsCount = DataStore.patientDTO.get("alertsChips").length
		let count = !this.state.alertsOpen ? 3 : alertsCount

		DataStore.patientDTO.get("alertsChips").forEach((alert, idx) => {
			if (idx < count) {
				let levelJSON = {level: 1, color: "#01579b"}
				if (alert.code === "Low") {
					levelJSON = {level: 2, color: "#39B0DD"}
				} else if (alert.code === "Medium") {
					levelJSON = {level: 3, color: "#FFB900"}
				} else if (alert.code === "High") {
					levelJSON = {level: 4, color: "#FF623F"}
				} else if (alert.code === "Critical") {
					levelJSON = {level: 5, color: "#C6184E"}
				} else {
					levelJSON = {level: 1, color: "#01579b"}
				}

				alerts.push(
					<div key={idx} className="xs-item xs-row xs-flex" onClick={() => PatientAlertsDialogStore.open(alert)}>
						<div className="xs-item-content xs-column xs-flex xs-justify">
							<div className="xs-primary-info" data-tip data-for={`alertPrimaryInfo_${idx}`}>
								{alert.alert.shortTo(15)}
							</div>
							<ReactTooltip id={`alertPrimaryInfo_${idx}`}>{alert.alert}</ReactTooltip>
							{/* <div className="xs-secondary-info">{alert.severity.shortTo(16)}</div> */}
						</div>
						<div className="xs-item-content xs-column xs-flex xs-justify">
							<div className="xs-primary-info xs-severity">
								{[1, 2, 3, 4, 5].map((item, idx) => {
									if (idx < levelJSON.level) {
										return <i key={idx} className="fas fa-circle" style={{color: levelJSON.color}} />
									} else {
										return <i key={idx} className="far fa-circle" style={{color: "#78849E"}} />
									}
								})}
							</div>
							<div className="xs-secondary-info xs-severity">{alert.severity}</div>
						</div>
						<div className="xs-right-icon xs-align xs-justify" data-tip data-for="editAlert">
							<i className="fal fa-chevron-right fa-lg" />
						</div>
						<ReactTooltip id="editAlert">
							<FormattedMessage id="Common.label.editAlert" />
						</ReactTooltip>
					</div>
				)
			}
		})

		if (!this.state.alertsOpen && alertsCount > 3) {
			alerts.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.showAllAlerts"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({alertsOpen: true})}
					>
						<i className="fal fa-chevron-down fa-lg"></i>
					</div>
				</Tooltip>
			)
		} else if (this.state.alertsOpen && alertsCount > 3) {
			alerts.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.hideAlerts"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({alertsOpen: false})}
					>
						<i className="fal fa-chevron-up fa-lg"></i>
					</div>
				</Tooltip>
			)
		}

		return alerts
	}

	renderAllergies = () => {
		let allergies = []

		let allergiesCount = DataStore.patientDTO.get("alergiesChips").length
		let count = !this.state.allergiesOpen ? 3 : allergiesCount

		{
			DataStore.patientDTO.get("alergiesChips").map((allergy, idx) => {
				if (idx < count) {
					let levelJSON = {level: 1, color: "#01579b"}
					if (allergy.code === "Low") {
						levelJSON = {level: 2, color: "#39B0DD"}
					} else if (allergy.code === "Medium") {
						levelJSON = {level: 3, color: "#FFB900"}
					} else if (allergy.code === "High") {
						levelJSON = {level: 4, color: "#FF623F"}
					} else if (allergy.code === "Critical") {
						levelJSON = {level: 5, color: "#C6184E"}
					} else {
						levelJSON = {level: 1, color: "#01579b"}
					}

					allergies.push(
						<div
							key={idx}
							className="xs-item xs-row xs-flex"
							onClick={() => {
								PatientAllergiesDialogStore.open(allergy)
							}}
						>
							<div className="xs-item-content xs-column xs-flex xs-justify info">
								<div className="xs-primary-info" data-tip data-for={`allergyPrimaryInfo_${idx}`}>
									{allergy.allergy.shortTo(15)}
								</div>
								<ReactTooltip id={`allergyPrimaryInfo_${idx}`}>{allergy.allergy}</ReactTooltip>
								<div className="xs-secondary-info">{allergy.allergen.shortTo(17)}</div>
								<div className="xs-secondary-info">{allergy.atc.shortTo(17)}</div>
							</div>
							<div className="xs-item-content xs-column xs-flex xs-justify">
								<div className="xs-primary-info xs-severity">
									{[1, 2, 3, 4, 5].map((item, idx) => {
										if (idx < levelJSON.level) {
											return <i key={idx} className="fas fa-circle" style={{color: levelJSON.color}} />
										} else {
											return <i key={idx} className="far fa-circle" style={{color: "#78849E"}} />
										}
									})}
								</div>
								<div className="xs-secondary-info xs-severity">{allergy.severity}</div>
							</div>
							<div className="xs-right-icon xs-align xs-justify" data-tip data-for="editAllergy">
								<i className="fal fa-chevron-right fa-lg" />
							</div>
							<ReactTooltip id="editAllergy">
								<FormattedMessage id="Common.label.editAllergy" />
							</ReactTooltip>
						</div>
					)
				}
			})
		}

		if (!this.state.allergiesOpen && allergiesCount > 3) {
			allergies.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.showAllAllergies"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({allergiesOpen: true})}
					>
						<i className="fal fa-chevron-down fa-lg"></i>
					</div>
				</Tooltip>
			)
		} else if (this.state.allergiesOpen && allergiesCount > 3) {
			allergies.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.hideAllergies"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({allergiesOpen: false})}
					>
						<i className="fal fa-chevron-up fa-lg"></i>
					</div>
				</Tooltip>
			)
		}

		return allergies
	}

	renderTags = () => {
		let tags = []

		let tagsCount = DataStore.patientDTO.get("tagsChips").length
		let count = !this.state.tagsOpen ? 3 : tagsCount

		{
			DataStore.patientDTO.get("tagsChips").map((tag, idx) => {
				if (idx < count) {
					tags.push(
						<div key={idx} className="xs-item xs-row xs-flex" onClick={() => PatientTagsDialogStore.open(tag)}>
							<div className="xs-item-content xs-column xs-flex xs-justify">
								<div className="xs-primary-info">{tag.tagName}</div>
								<div className="xs-secondary-info" />
							</div>
							<div className="xs-right-icon xs-align xs-justify" data-tip data-for="editTag">
								<i className="fal fa-chevron-right fa-lg" />
							</div>
							<ReactTooltip id="editTag">
								<FormattedMessage id="Common.label.editTag" />
							</ReactTooltip>
						</div>
					)
				}
			})
		}

		if (!this.state.tagsOpen && tagsCount > 3) {
			tags.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.showAllTags"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({tagsOpen: true})}
					>
						<i className="fal fa-chevron-down fa-lg"></i>
					</div>
				</Tooltip>
			)
		} else if (this.state.tagsOpen && tagsCount > 3) {
			tags.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.hideTags"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({tagsOpen: false})}
					>
						<i className="fal fa-chevron-up fa-lg"></i>
					</div>
				</Tooltip>
			)
		}

		return tags
	}

	renderImplants = () => {
		let implants = []

		let implantsCount = DataStore.patientDTO.get("implants").length
		let count = !this.state.implantsOpen ? 3 : implantsCount

		{
			DataStore.patientDTO.get("implants").map((implant, idx) => {
				let implantNameExt =
					isSafe(implant) && isSafe(implant.group) && isSafe(implant.group.name_ext) ? implant.group.name_ext : ""
				if (idx < count) {
					implants.push(
						<div key={idx} className="xs-item xs-row xs-flex" onClick={() => PatientImplantDialogStore.open(implant)}>
							<div className="xs-item-content xs-column xs-flex xs-justify">
								<div className="xs-primary-info" data-tip data-for={`implantPrimaryInfo_${idx}`}>
									{isNotEmpty(implantNameExt)
										? implantNameExt.shortTo(27)
										: isNotEmpty(implant.description)
										? implant.description.shortTo(27)
										: ""}
								</div>
								<ReactTooltip id={`implantPrimaryInfo_${idx}`}>{implantNameExt}</ReactTooltip>
								<div className="xs-secondary-info" />
							</div>
							<div className="xs-right-icon xs-align xs-justify" data-tip data-for="editImplant">
								<i className="fal fa-chevron-right fa-lg" />
							</div>
							<ReactTooltip id="editImplant">
								<FormattedMessage id="Common.label.editImplant" />
							</ReactTooltip>
						</div>
					)
				}
			})
		}

		if (!this.state.implantsOpen && implantsCount > 3) {
			implants.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.showAllImplants"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({implantsOpen: true})}
					>
						<i className="fal fa-chevron-down fa-lg"></i>
					</div>
				</Tooltip>
			)
		} else if (this.state.implantsOpen && implantsCount > 3) {
			implants.push(
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.hideImplants"
					})}
				>
					<div
						key={5}
						className="xs-item xs-toggle-btn xs-row xs-flex xs-justify xs-align"
						onClick={() => this.setState({implantsOpen: false})}
					>
						<i className="fal fa-chevron-up fa-lg"></i>
					</div>
				</Tooltip>
			)
		}

		return implants
	}

	render() {
		if (
			isSafe(DataStore.patientDTO.get("patient")) &&
			isSafe(DataStore.patientDTO.get("patient").validInfo) &&
			isSafe(DataStore.patientDTO.get("patient").validInfo.client)
		) {
			// const patient = isSafe(DataStore.patientDTO.get("patient")) ? DataStore.patientDTO.get("patient").specific_rels.find(sr => { return sr._type === "Entity.Data.Client" }) : null

			let pacInfo = DataStore.patientDTO.get("patient")

			const patient = pacInfo.validInfo.client
			let gender = isSafe(pacInfo) && isSafe(pacInfo.gender) && pacInfo.gender.name_ext ? pacInfo.gender.name_ext : ""
			let patientIdentifier = isSafe(pacInfo) && isSafe(pacInfo.identifier) ? pacInfo.identifier : ""
			let patientAge = isSafe(pacInfo) && isSafe(pacInfo.age) ? pacInfo.age : ""

			let content = undefined

			if (UIStore.patientDetailTab === "EHR") {
				content = (
					<div id="patientCardContainer">
						<PatientCard id={this.props.id} patientID={patient._id} />
					</div>
				)
			}
			if (UIStore.patientDetailTab === "PersonalInfo") {
				content = <PatientInfoForm id={this.props.id} clientId={patient._id} />
			}
			if (UIStore.patientDetailTab === "InsuranceServices") {
				content = (
					<div id="patientInsuranceServices">
						<PatientInsuranceServices patientId={patient._id} />
					</div>
				)
			}

			const nextVisit = DataStore.patientDTO.get("nextVisit")
			const lastVisit = DataStore.patientDTO.get("lastVisit")
			const lastPreventiveExamination = DataStore.patientDTO.get("lastPreventiveExamination")

			const patientContacts = DataStore.patientDTO.get("contacts")

			let patient_phone = ""
			if (isSafe(patientContacts) && patientContacts.length > 0) {
				const primaryContact = patientContacts.find((x) => x.primary && (isNotEmpty(x.mobile) || isNotEmpty(x.phone)))
				if (isSafe(primaryContact)) {
					const phone = isNotEmpty(primaryContact.mobile) ? primaryContact.mobile : primaryContact.phone
					patient_phone = phone
				} else {
					const contact = patientContacts.find((x) => isNotEmpty(x.mobile) || isNotEmpty(x.phone))
					if (isSafe(contact)) {
						const phone = isNotEmpty(contact.mobile) ? contact.mobile : contact.phone
						patient_phone = phone
					}
				}
			}

			return (
				<section id="xsPatientDetailContainer" className={UIStore.showHeaderInPatientDetail ? "xs-infoHeader" : ""}>
					<section className="xs-patient-container-section xs-row xs-flex pb-8">
						<div className="xs-patient-aside-scrollbar">
							<aside className="xs-patient-aside xs-column">
								<div className="xs-section-header xs-row">
									{this.props.intl.formatMessage({id: "Common.label.baseInfo"}).toUpperCase()}
								</div>
								<div className="xs-base-info">
									{DataStore.patientDTO.get("isNonPayer") && (
										<div
											className={`xs-item xs-row xs-flex xs-bg-redDanger ${
												DataStore.patientDTO.get("insurerCodeExt") != 24 ? "xs-no-cursor" : ""
											}`}
											onClick={() => {
												if (DataStore.patientDTO.get("insurerCodeExt") == 24) {
													PatientPayDebtDialogStore.dajsumudlhu(false)
												}
											}}
										>
											<div className="xs-left-icon xs-align xs-justify">
												<i className="fal fa-exclamation-triangle fa-lg xs-snowWhite" />
											</div>
											<div className="xs-item-content xs-column xs-flex xs-justify">
												<div className="xs-primary-info xs-snowWhite">
													{DataStore.nonPayer}
													{/* {isNotEmpty(DataStore.nonPayer) && isNotEmpty(DataStore.patientDTO.get("insurerType")) ? ", " : ""}{DataStore.patientDTO.get("insurerType")} */}
												</div>
												<div className="xs-snowWhite fontSize13">
													{DataStore.patientDTO.get("insurerCodeExt") == 24 && (
														<FormattedMessage id="Patient.sidebar.payDept" />
													)}
												</div>
											</div>
											<div className="xs-right-icon xs-align xs-justify">
												<i className="fal fa-chevron-right fa-lg xs-snowWhite" />
											</div>
										</div>
									)}
									<div className="xs-item xs-no-cursor xs-row xs-flex">
										<div className="xs-left-icon xs-align xs-justify">
											{DataStore.patientDTO.get("isCapitation") ? (
												<i className="fas fa-user fa-lg xs-greenSuccess" />
											) : (
												<i className="fal fa-user fa-lg xs-greyDefault" />
											)}
										</div>
										<div className="xs-item-content xs-column xs-flex xs-justify">
											<div className="xs-primary-info">{patientIdentifier}</div>
											<div className="xs-secondary-info">
												{gender} {patientAge},
												<span className="xs-bold">
													{" "}
													{isNotEmpty(DataStore.patientDTO.get("insurer")) ? DataStore.patientDTO.get("insurer") : ""}
												</span>
											</div>
										</div>
										{DataStore.patientDTO.get("isCapitation") && (
											<div className="xs-row xs-align">
												<div
													className="xs-badge xs-bg-blueDark xs-row xs-justify xs-align"
													data-tip
													data-for="capitation"
												>
													K
												</div>
												<ReactTooltip id="capitation">
													<FormattedMessage id="Patient.header.tooltip.patientCapitation" />
												</ReactTooltip>
											</div>
										)}
										{DataStore.patientDTO.get("isEUInsured") && (
											<div className="xs-row xs-align">
												<div
													className="xs-badge xs-bg-infoDark xs-row xs-justify xs-align"
													data-tip
													data-for="insurerEU"
												>
													EÚ
												</div>
												<ReactTooltip id="insurerEU">
													<FormattedMessage id="Common.label.insurerEU" />
												</ReactTooltip>
												{/* <ReactTooltip id="euinsurer">
                        <FormattedMessage id="Patient.header.tooltip.patientCapitation" />
                      </ReactTooltip> */}
											</div>
										)}
										<div
											data-tip
											data-for="editPersonData"
											className="xs-right-icon xs-align xs-justify pointer"
											onClick={() => {
												UIStore.patientDetailTab = "PersonalInfo"
												UIStore.patientInfoTab = "contacts"
											}}
										>
											<i className="fal fa-chevron-right fa-lg" />
										</div>
										<ReactTooltip id="editPersonData">
											<FormattedMessage id="Common.label.editPersonData" />
										</ReactTooltip>
									</div>
									<div
										className="xs-item xs-row xs-flex"
										onClick={() => {
											UIStore.patientDetailTab = "PersonalInfo"
											UIStore.patientInfoTab = "contacts"
										}}
									>
										<div className="xs-left-icon xs-align xs-justify">
											<i className="fal fa-address-book fa-lg xs-greyDefault" />
										</div>
										<div className="xs-item-content xs-column xs-flex xs-justify">
											{isSafe(DataStore.patientDTO.get("currContact")) && (
												<div>
													<div className="xs-primary-info patient-current-contact">
														{isNotEmpty(DataStore.patientDTO.get("currContact").contact)
															? DataStore.patientDTO.get("currContact").contact
															: DataStore.contactUnknow}
													</div>
													<div className="xs-secondary-info">
														{isNotEmpty(DataStore.patientDTO.get("currContact").contact_type)
															? DataStore.patientDTO.get("currContact").contact_type
															: DataStore.contactUnknowText}
													</div>
												</div>
											)}
										</div>
										<div className="xs-right-icon xs-align xs-justify" data-tip data-for="addOrEditContact">
											<i className="fal fa-chevron-right fa-lg" />
										</div>
										<ReactTooltip id="addOrEditContact">
											<FormattedMessage id="Common.label.addOrEditContact" />
										</ReactTooltip>
									</div>
									<div
										className="xs-item xs-row xs-flex"
										onClick={() => {
											UIStore.patientDetailTab = "PersonalInfo"
											UIStore.patientInfoTab = "contactaddress"
										}}
									>
										<div className="xs-left-icon xs-align xs-justify">
											<i className="fal fa-address-card fa-lg xs-greyDefault" />
										</div>
										<div className="xs-item-content xs-column xs-flex xs-justify">
											{isSafe(DataStore.patientDTO.get("currAddress")) && (
												<div>
													<div className="xs-primary-info patient-current-address">
														{isNotEmpty(DataStore.patientDTO.get("currAddress").address)
															? DataStore.patientDTO.get("currAddress").address
															: DataStore.addressUnknow}
													</div>
													<div className="xs-secondary-info">
														{isNotEmpty(DataStore.patientDTO.get("currAddress").address_desc)
															? DataStore.patientDTO.get("currAddress").address_desc
															: DataStore.addressDesc}
													</div>
												</div>
											)}
										</div>
										<div className="xs-right-icon xs-align xs-justify" data-tip data-for="addOrEditAddress">
											<i className="fal fa-chevron-right fa-lg" />
										</div>
										<ReactTooltip id="addOrEditAddress">
											<FormattedMessage id="Common.label.addOrEditAddress" />
										</ReactTooltip>
									</div>
								</div>
								<div>
									<div className="xs-section-header xs-row xs-justify-between pt-4">
										<div className="xs-row xs-align">
											{this.props.intl.formatMessage({id: "Common.menu.calendar"}).toUpperCase()}
										</div>
										<div className="pointer">
											<XsIconButton
												className="mr-3"
												icon={
													<span>
														<i className="fal fa-plus fa-lg" data-tip data-for="addEvent" />
														<ReactTooltip id="addEvent">
															<FormattedMessage id="Common.label.addEvent" />
														</ReactTooltip>
													</span>
												}
												onClick={() => {
													AddEventDialogStore.planNextEvent({
														client_id: DataStore.patientDTO.get("patientID"),
														first_name: DataStore.patientDTO.get("patient").first_name,
														last_name: DataStore.patientDTO.get("patient").last_name,
														contact: patient_phone,
														nextVisit: DataStore.patientDTO.get("nextVisit"),
														insurer_short_name: DataStore.patientDTO.get("insurerShortName"),
														age: DataStore.patientDTO.get("patient").age,
														insurer_name:
															isSafe(DataStore.patientDTO.get("patient").validInfo) &&
															isSafe(DataStore.patientDTO.get("patient").validInfo.insurance) &&
															isSafe(DataStore.patientDTO.get("patient").validInfo.insurance.insurer) &&
															isSafe(DataStore.patientDTO.get("patient").validInfo.insurance.insurer.entity)
																? DataStore.patientDTO.get("patient").validInfo.insurance.insurer.entity.name
																: undefined
													})
												}}
											/>
										</div>
									</div>

									{isSafe(DataStore.patientDTO.get("patient")) &&
										(isSafe(nextVisit) || isSafe(lastVisit) || isSafe(lastPreventiveExamination)) && (
											<div className="xs-event">
												<div
													className="xs-item xs-row xs-flex"
													onClick={() => {
														isSafe(nextVisit) && isNotEmpty(nextVisit)
															? EditEventDialogStore.planNextEvent(DataStore.patientDTO.get("nextEventId"))
															: AddEventDialogStore.planNextEvent({
																	client_id: DataStore.patientDTO.get("patientID"),
																	first_name: DataStore.patientDTO.get("patient").first_name,
																	last_name: DataStore.patientDTO.get("patient").last_name,
																	nextVisit: DataStore.patientDTO.get("nextVisit"),
																	insurer_short_name: DataStore.patientDTO.get("insurerShortName"),
																	age: DataStore.patientDTO.get("patient").age,
																	insurer_name:
																		isSafe(DataStore.patientDTO.get("patient").validInfo) &&
																		isSafe(DataStore.patientDTO.get("patient").validInfo.insurance) &&
																		isSafe(DataStore.patientDTO.get("patient").validInfo.insurance.insurer) &&
																		isSafe(DataStore.patientDTO.get("patient").validInfo.insurance.insurer.entity)
																			? DataStore.patientDTO.get("patient").validInfo.insurance.insurer.entity.name
																			: undefined
															  })
													}}
												>
													<div className="xs-left-icon xs-align xs-justify" data-tip data-for="nextVisit">
														<i className="fas fa-bell fa-lg xs-greyDefault" />
													</div>
													<ReactTooltip id="nextVisit">
														<FormattedMessage id="Patient.labels.nextVisit" defaultMessage="Next visit" />
													</ReactTooltip>
													<div className="xs-item-content xs-column xs-flex xs-justify">
														<div className="xs-primary-info">
															{isSafe(nextVisit) && (
																<div className="xs-row">
																	<div>
																		<FormattedMessage id="Common.label.nextVisit" />:{" "}
																		{moment(nextVisit).format("DD.MM.YYYY HH:mm")}
																	</div>
																</div>
															)}
														</div>
														<div className="xs-secondary-info">
															{isSafe(lastVisit) && (
																<div className="xs-row">
																	<div>
																		<FormattedMessage id="Patient.labels.lastVisit" />:{" "}
																		{moment(lastVisit).format("DD.MM.YYYY")}
																	</div>
																</div>
															)}
														</div>
														{isSafe(lastPreventiveExamination) && (
															<div className="xs-secondary-info">
																<div className="xs-row">
																	<div className="xs-bold">
																		<FormattedMessage id="Common.label.lastPP" />:{" "}
																		{moment(lastPreventiveExamination).format("DD.MM.YYYY")}
																	</div>
																</div>
															</div>
														)}
													</div>

													<Tooltip
														title={
															isNotEmpty(nextVisit)
																? this.props.intl.formatMessage({
																		id: "Common.label.editEventInCalendar"
																  })
																: this.props.intl.formatMessage({
																		id: "Common.label.planNextVisit"
																  })
														}
													>
														<div className="xs-right-icon xs-align xs-justify">
															<i className="fal fa-chevron-right fa-lg" />
														</div>
													</Tooltip>
												</div>
											</div>
										)}
								</div>
								{isSafe(DataStore.patientDTO.get("alergiesChips")) &&
									DataStore.patientDTO.get("alergiesChips").length > 0 && (
										<div>
											<div className="xs-section-header xs-row xs-justify-between pt-4">
												<div className="xs-row xs-align">
													{this.props.intl.formatMessage({id: "PatientDetail.header.allergies"}).toUpperCase()}
													<div className="xs-count-items" data-tip data-for="countSaveAllergies">
														{isSafe(DataStore.patientDTO.get("alergiesChips")) &&
														DataStore.patientDTO.get("alergiesChips").length > 0
															? DataStore.patientDTO.get("alergiesChips").length
															: 0}
													</div>
													<ReactTooltip id="countSaveAllergies">
														<FormattedMessage id="Common.label.countSaveAllergies" />
													</ReactTooltip>
												</div>
												<div className="pointer">
													<XsIconButton
														className="mr-3"
														icon={
															<span>
																<i className="fal fa-plus fa-lg" data-tip data-for="addAllergy" />
																<ReactTooltip id="addAllergy">
																	<FormattedMessage id="Common.label.addAllergy" />
																</ReactTooltip>
															</span>
														}
														onClick={() => PatientAllergiesDialogStore.open()}
													/>
												</div>
											</div>
											<div className="xs-alergies">{this.renderAllergies()}</div>
										</div>
									)}
								{isSafe(DataStore.patientDTO.get("alertsChips")) && DataStore.patientDTO.get("alertsChips").length > 0 && (
									<div>
										<div className="xs-section-header xs-row xs-justify-between pt-4">
											<div className="xs-row xs-align">
												{this.props.intl.formatMessage({id: "Patient.sidebar.alerts"}).toUpperCase()}
												<div className="xs-count-items" data-tip data-for="countSaveWarnings">
													{isSafe(DataStore.patientDTO.get("alertsChips")) &&
													DataStore.patientDTO.get("alertsChips").length > 0
														? DataStore.patientDTO.get("alertsChips").length
														: 0}
												</div>
												<ReactTooltip id="countSaveWarnings">
													<FormattedMessage id="Common.label.countSaveWarnings" />
												</ReactTooltip>
											</div>
											<div className="pointer">
												<XsIconButton
													className="mr-3"
													icon={
														<span>
															<i className="fal fa-plus fa-lg" data-tip data-for="addAlert" />
															<ReactTooltip id="addAlert">
																<FormattedMessage id="Common.label.addAlert" />
															</ReactTooltip>
														</span>
													}
													onClick={() => PatientAlertsDialogStore.open()}
												/>
											</div>
										</div>
										<div className="xs-alerts">{this.renderAlerts()}</div>
									</div>
								)}
								{isSafe(DataStore.patientDTO.get("bloodType")) && (
									<div>
										<div className="xs-section-header xs-row pt-4">
											{this.props.intl.formatMessage({id: "Patient.sidebar.bloodType"}).toUpperCase()}
										</div>
										<div
											className="xs-bloodType"
											onClick={() => {
												PatientBloodTypeDialogStore.open(
													DataStore.patientDTO.get("bloodType")._id,
													DataStore.patientDTO.get("bloodType").type._id,
													DataStore.patientDTO.get("bloodType").type.name_ext,
													DataStore.patientDTO.get("bloodType").written_at
												)
											}}
										>
											<div className="xs-item xs-row xs-flex">
												<div className="xs-left-icon xs-align xs-justify">
													<i className="fas fa-vial fa-lg xs-greyDefault"></i>
												</div>
												<div className="xs-item-content xs-column xs-flex xs-justify">
													<div className="xs-primary-info">{DataStore.patientDTO.get("bloodType").type.name_ext}</div>
													<div className="xs-secondary-info" />
												</div>
												<div className="xs-right-icon xs-align xs-justify" data-tip data-for="editBloodType">
													<i className="fal fa-chevron-right fa-lg" />
												</div>
												<ReactTooltip id="editBloodType">
													<FormattedMessage id="Common.label.editBloodType" />
												</ReactTooltip>
											</div>
										</div>
									</div>
								)}
								{isSafe(DataStore.patientDTO.get("tagsChips")) && DataStore.patientDTO.get("tagsChips").length > 0 && (
									<div>
										<div className="xs-section-header xs-row xs-justify-between pt-4">
											<div className="xs-row xs-align">
												{this.props.intl.formatMessage({id: "Patient.sidebar.tags"}).toUpperCase()}
												<div className="xs-count-items" data-tip data-for="countSaveTags">
													{isSafe(DataStore.patientDTO.get("tagsChips")) &&
													DataStore.patientDTO.get("tagsChips").length > 0
														? DataStore.patientDTO.get("tagsChips").length
														: 0}
												</div>
												<ReactTooltip id="countSaveTags">
													<FormattedMessage id="Common.label.countSaveTags" />
												</ReactTooltip>
											</div>
											<div className="pointer">
												<XsIconButton
													className="mr-3"
													icon={
														<span>
															<i className="fal fa-plus fa-lg" data-tip data-for="addTag" />
															<ReactTooltip id="addTag">
																<FormattedMessage id="Common.label.addTag" />
															</ReactTooltip>
														</span>
													}
													onClick={() => PatientTagsDialogStore.open()}
												/>
											</div>
										</div>
										<div className="xs-tags">{this.renderTags()}</div>
									</div>
								)}
								{isSafe(DataStore.patientDTO.get("implants")) && DataStore.patientDTO.get("implants").length > 0 && (
									<div>
										<div className="xs-section-header xs-row xs-justify-between pt-4">
											<div className="xs-row xs-align">
												{this.props.intl.formatMessage({id: "Patient.sidebar.implants"}).toUpperCase()}
												<div className="xs-count-items" data-tip data-for="countSaveImplants">
													{isSafe(DataStore.patientDTO.get("implants")) &&
													DataStore.patientDTO.get("implants").length > 0
														? DataStore.patientDTO.get("implants").length
														: 0}
												</div>
												<ReactTooltip id="countSaveImplants">
													<FormattedMessage id="Common.label.countSaveImplants" />
												</ReactTooltip>
											</div>
											<div className="pointer">
												<XsIconButton
													className="mr-3"
													icon={
														<span>
															<i className="fal fa-plus fa-lg" data-tip data-for="addImplantat" />
															<ReactTooltip id="addImplantat">
																<FormattedMessage id="Common.label.addImplant" />
															</ReactTooltip>
														</span>
													}
													onClick={() => PatientImplantDialogStore.open()}
												/>
											</div>
										</div>
										<div className="xs-implants">{this.renderImplants()}</div>
									</div>
								)}
							</aside>
						</div>
						<article
							className="xs-patient-main-section xs-column xs-flex"
							ref={(divRef) => (UIStore.patientCardDivRef = divRef)}
						>
							<article>{content}</article>
						</article>
					</section>
					<PatientTagsDialog />
					{PatientProblemsDialogStore.isOpen && <PatientProblemsDialog />}
					<PatientAllergiesDialog />
					<PatientAlertsDialog />
					<PatientImplantDialog />
					{SmsNotificationStore.isOpen && <SmsNotificationDialog />}
					{AddEventDialogStore.isOpen && <AddEventDialogContainer />}
					{EditEventDialogStore.isOpen && <EditEventDialogContainer />}
					{PatientDetailActionsStore.isOpen && <PatientDetailActionsContainer />}
					{PatientTemplatesDialogStore.isOpen && <PatientTemplatesDialog patientId={patient._id} />}
					{PatientPayDebtDialogStore.isOpen && <PatientPayDebtDialog />}
					<PatientBloodTypeDialog />
					{PrintTemplatesStore.isOpen && <PrintTemplatesDialog />}
					{PatientDeliveryDialogStore.isOpen && <PatientDeliveryDialog />}
					<AddDeliveryPatientDetailDialog />
					{RequestsSVLZInsurerStore.isOpen && <RequestsSVLZInsurerDialog patientId={patient._id} />}
					{RequestsSVLZInsurerStore.isOpenViewer && <RequestsSVLZInsurerViewerDialog patientId={patient._id} />}
					{RequestsSVLZInsurerStore.isOpenAcceptOfTheTerm && <AcceptOfTheTermDialog patientId={patient._id} />}
					{RequestsSVLZInsurerStore.isOpenSVLZRequest && <RequestsSVLZDialog />}
					{RequestsSVLZInsurerStore.isOpenShowPDF && <ShowPDFDialog />}
					{MedicineCardDialogStore.isOpen && <MedicineCardDialog />}
					{<HospicomDialog />}
					<XsConfirmationDialog
						name="xsGenerateRecordFromPrescription"
						title={<FormattedMessage id="Common.label.warning" />}
						text={<FormattedMessage id="Patient.form.patientrecord.generateFromPrescription" />}
						onConfirmation={() =>
							PatientRecordStore.generateRecordFromPrescription(GlobalStore.confirmationDialogParams.recipes)
						}
					/>
				</section>
			)
		}
		return null
	}
}
