import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"
import {getSelectedOrgUnitID, getUserDoctorInfo} from "../../../../../../global/helpers/actions"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

export default {
	load() {
		const dgn = RegisterCacheStore.diagnosisRegister.find((x) => x.code_ext == "I83.9")

		return {
			fields: {
				zsType: {
					label: <FormattedMessage id="Agreement.Container.Detail.Attribute.TypeZS" />,
					rules: "required",
					value: "DC"
				},
				proposalDate: {
					label: <FormattedMessage id="Common.label.proposal" />,
					type: "date",
					value: moment(),
					rules: "required"
				},
				proposalDateZS: {
					label: <FormattedMessage id="Common.label.proposalDateZS" />,
					type: "date",
					rules: "required"
					// value: moment()
				},
				plannedOrgunit: {
					label: <FormattedMessage id="Common.label.plannedOrgunit" />,
					value: getSelectedOrgUnitID(),
					rules: "required"
				},
				diagnosis: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
					rules: "required",
					value: isSafe(dgn) ? dgn._id : ""
				},
				sendingDoctorPersonnelExpertiseId: {
					label: <FormattedMessage id="Patient.form.patientrecord.doctor" />
				},
				sendingDoctorPersonnelId: {},
				sendingDoctorExpertiseId: {},
				sendingDoctorOUPZS: {
					label: <FormattedMessage id="Patient.form.patientrecord.doctorOUPZ" />
				},
				// doctorOUPZSId: {},
				responsibleDoctor: {
					label: <FormattedMessage id="Common.label.responsibleDoctor" />,
					value:
						isSafe(getUserDoctorInfo()) && isSafe(getUserDoctorInfo().expertise) ? getUserDoctorInfo().expertise.id : ""
				},
				responsibleDoctorExpertiseId: {},
				responsibleDoctorPersonnelId: {},
				noteForHospicom: {
					label: <FormattedMessage id="Common.label.noteForHospicom" />
				},
				internalNote: {
					label: <FormattedMessage id="Common.label.internalNote" />
				},
				delivery: {
					label: <FormattedMessage id="Common.label.delivery" />,
					rules: "required"
				},
				expectedPrice: {
					label: <FormattedMessage id="Common.label.expectedPrice" />,
					rules: "required"
				},
				drugsType: {
					label: <FormattedMessage id="Common.label.selectType" />
				},
				drugs: {
					label: <FormattedMessage id="Common.label.drugs" />
				},
				drugsAmout: {
					label: <FormattedMessage id="Patient.form.patientPrescription.packs" />,
					value: 1
				},
				drugPrice: {
					label: <FormattedMessage id="Calendar.overPrescription.dialog.price" />
				},
				organType: {
					label: <FormattedMessage id="Common.label.organType" />
				},
				medEquip: {
					label: <FormattedMessage id="Patient.form.patientPrescription.medDev" />
				},
				state: {
					label: <FormattedMessage id="Common.label.state" />,
					rules: "required",
					value: "N"
				},
				insurer_code_ext: {},

				confirmed_at: {label: "Dátum schválenia ZP"},
				confirmed_at_wasWarningOpened: {
					type: "checkbox",
					value: false
				},
				incoming_at: {label: "Skutočný nástup"},
				incoming_at_wasWarningOpened: {
					type: "checkbox",
					value: false
				},
				patientInfo: {value: ""},
				patientId: {},

				recordId: {}
			}
		}
	}
}
