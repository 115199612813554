"use strict"
import moment from "moment"
import api from "../actions/api"
import RouterStore from "./../../../global/store/RouterStore"
import {observable, action} from "mobx"
import {getUserCompanyInfo} from "../../../global/helpers/actions"
import {getFilters} from "../../../global/helpers/api"

import GlobalStore from "../../../global/store/GlobalStore"
import AgreementsStore from "./AgreementsStore"
import settings from "../../../global/config/settings"

class AgreementAttributeStore {
	@observable healthTypes = []
	@observable healthTypesJSON = {}
	@observable updatedHealthTypesPriceLimitPerHC = {}
	@observable deliveries = []
	@observable deliveriesGrid = {}
	@observable deliveriesGridJSON = {}
	@observable deliveryPricePerDeliveryGridJSON = {}
	@observable pricePerPointFormDeliveryId = ""
	@observable pricePerDeliveryFormDeliveryId = ""
	@observable pricePerPointFormDelivery = ""
	@observable pricePerDeliveryFormDelivery = ""
	@observable pointPerDeliveryFormDeliveryId = ""
	@observable pointPerDeliveryFormDelivery = ""
	@observable pointPerDeliveryFormPoints = ""
	@observable diagnosis = []
	@observable diagnosisGrid = {}
	@observable diagnosisGridJSON = {}
	@observable deliveriesItem = []
	@observable deliveriesItemGrid = {}
	@observable deliveryItemsJSON = {}
	@observable pricePerDeliveryFormDiagnosis = ""
	@observable pricePerDeliveryFormDiagnosisId = ""
	@observable insuranceGridJSON = {}

	@observable pricePerAttributeItemFormDeliveryId = ""
	@observable pricePerAttributeItemFormDelivery = ""

	agreementPartFormRef = null

	//Ids
	tempPart = undefined
	partId = undefined
	partValidity = undefined

	@observable providers = []
	@observable providersChecked = {} //evidencia zachecknutych!
	@observable providersSpecificRelsIds = {}
	providersChanged = [] //zmenene!

	//pridanie a uprava odseku (part)
	@observable isNewPart = false
	@observable isEditAttributeGrid = false
	contractId = undefined

	// agreementAttr = []
	// @observable agreementPartAttributes = []
	@observable attributeDataJSON = {}
	@observable agreementAttributeTypes = []
	@observable typeFilter = ""

	//pridanie a uprava attribute
	@observable agreementAttributeAgeFrom = ""
	@observable agreementAttributeAgeTo = ""
	@observable agreementAttributeCurrency = ""
	@observable agreementAttributePricePerLimitType = ""
	@observable agreementAttributeInsuranceType = ""
	@observable agreementAttributeCapitationChecked = false

	//edit grid attributes
	updatedAttribute = {}
	// changedKeys = []

	@action chooseAttributeType(attrType) {
		this.updatedAttribute = {}
		this.typeFilter = attrType
	}

	@action updateAttribute(id, column, value) {
		if (isNotSafe(this.updatedAttribute[id])) {
			this.updatedAttribute[id] = {}
		}

		this.updatedAttribute[id][column] = value
	}

	@action changedAttribute(type, id, column, value) {
		if (column === "deliveries") {
			if (isEmpty(value)) {
				this.attributeDataJSON[type][id][column] = null
			} else {
				this.attributeDataJSON[type][id][column] = [
					{
						_type: "EHR.CL.Bill.Delivery",
						_id: value
					}
				]
			}
		} else if (column === "delivery_item") {
			this.attributeDataJSON[type][id][column] = {
				_ref: true,
				_type: "EHR.CL.Bill.DeliveryItem",
				_id: value
			}
		} else {
			this.attributeDataJSON[type][id][column] = value
		}
	}

	@action handleChange(key, value) {
		this[key] = value
	}

	@action handleCheckbox(key, value, provider) {
		if (value) {
			this.providersChecked[key] = value
			let existProvider = this.providersChanged.find((x) => x.provider_id == key)
			if (isEmpty(existProvider)) {
				this.providersChanged.push(provider)
			}
		} else {
			this.providersChecked[key] = value
		}
	}

	@action showAttributes(partId) {
		if (isNotEmpty(partId)) {
			this.isNewPart = false
			RouterStore.push(`/part?id=${partId}`)
			this.loadPart(partId)
		} else {
			this.isNewPart = true
			// this.agreementPartDateFrom = this.agreementDateFrom
			// this.agreementPartDateTo = this.agreementDateTo
			RouterStore.push(`/part?id=`)
		}
	}

	@action loadDepartments() {
		const request = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: `${getUserCompanyInfo().id}`
						}
					]
				}
			]
		}

		api
			.loadProviders(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					let items = {}

					response.rows.forEach((row) => {
						items[row.provider_id] = false
					})
					// this.providersChecked = items
					this.providers = response.rows
				}
			})
	}

	// @action removeAttribute() {
	//   const request = {
	//     "_id": GlobalStore.confirmationDialogParams.attributeId,
	//     "_ref": false,
	//     "active": false,
	//     "part": {
	//       "_id": this.partId,
	//       "_type": "EHR.Data.Contract.Part"
	//     }
	//   }

	//   api.saveAttributes(request).call().then(response => {
	//     if (isSafe(response) && isSafe(response._id)) {
	//       this.loadPart(this.partId)
	//       this.resetDataAttributeForm()
	//       GlobalStore.setNotificationMessage("Agreement.Container.Detail.RemoveAttribute.Notification")
	//     }
	//   })
	// }

	@action saveAttributes(fnEnableSaving) {
		let attrCurrency = this.agreementAttributeCurrency

		if (this.agreementAttributeCurrency.includes(",")) {
			attrCurrency = this.agreementAttributeCurrency.replace(",", ".")
		}

		const request = {
			_id: "",
			_ref: false,
			active: true,
			age_unit: {
				_id: "yr",
				_type: "EHR.CL.Medication.Unit"
			},
			currency: {
				_id: "EUR",
				_type: "IXS.CL.Data.CurrencyUnit"
			},
			part: {
				_id: this.partId,
				_type: "EHR.Data.Contract.Part"
			},
			sufficient: true,
			type: this.typeFilter,
			validity: {
				from:
					isSafe(this.partValidity) && isNotEmpty(this.partValidity.from)
						? moment(this.partValidity.from).format(settings.DB_DATE_FORMAT)
						: "",
				to:
					isSafe(this.partValidity) && isNotEmpty(this.partValidity.to)
						? moment(this.partValidity.to).format(settings.DB_DATE_FORMAT)
						: ""
			}
		}

		switch (this.typeFilter) {
			case "EHR.Data.Contract.SK.Attribute:PoPD":
				request["delivery"] = this.pointPerDeliveryFormDeliveryId
				request["price_in_points"] = this.pointPerDeliveryFormPoints
				request["_type"] = "EHR.Data.Contract.SK.PointsPerDelivery"
				break
			case "EHR.Data.Contract.SK.Attribute:PPC":
				request["age_from"] = this.agreementAttributeAgeFrom
				request["age_to"] = this.agreementAttributeAgeTo
				request["price_in_currency"] = attrCurrency
				request["_type"] = "EHR.Data.Contract.SK.PricePerCapitation"
				break
			case "EHR.Data.Contract.SK.Attribute:PPCa":
				request["_type"] = "EHR.Data.Contract.SK.PricePerCapitationAdditional"
				request["price_in_currency"] = attrCurrency
				break
			case "EHR.Data.Contract.SK.Attribute:PLZS":
				request["_type"] = "EHR.Data.Contract.SK.PriceLimitPerHCType"
				request["price_in_currency"] = attrCurrency
				request["h_c_type"] = +this.agreementAttributePricePerLimitType
				break
			case "EHR.Data.Contract.SK.Attribute:PPP":
				request["price_in_currency"] = attrCurrency
				request["_type"] = "EHR.Data.Contract.SK.PricePerPoint"
				if (isNotEmpty(this.pricePerPointFormDeliveryId)) {
					request["deliveries"] = [
						{
							_type: "EHR.CL.Bill.Delivery",
							_id: this.pricePerPointFormDeliveryId
						}
					]
				}
				break
			case "EHR.Data.Contract.SK.Attribute:PPD":
				request["price_in_currency"] = attrCurrency
				request["_type"] = "EHR.Data.Contract.SK.PricePerDelivery"
				request["delivery"] = this.pricePerDeliveryFormDeliveryId
				request["diagnosis"] = isEmpty(this.pricePerDeliveryFormDiagnosisId)
					? null
					: this.pricePerDeliveryFormDiagnosisId
				request["insurance_type"] = isEmpty(this.agreementAttributeInsuranceType)
					? null
					: this.agreementAttributeInsuranceType
				request["in_capitation"] = this.agreementAttributeCapitationChecked
				break
			case "EHR.Data.Contract.SK.Attribute:PPDI":
				request["price_in_currency"] = attrCurrency
				request["_type"] = "EHR.Data.Contract.SK.PricePerDeliveryItem"
				request["delivery_item"] = {
					_ref: true,
					_type: "EHR.CL.Bill.DeliveryItem",
					_id: this.pricePerAttributeItemFormDeliveryId
				}
				break
		}

		api
			.saveAttributes(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					this.loadPart(this.partId)
					this.resetDataAttributeForm()
					GlobalStore.setNotificationMessage("Agreement.Container.Detail.SaveAttribute.Notification")
					fnEnableSaving()
				}
			})
	}

	@action updateAttributes() {
		const request = []

		// if (this.changedKeys.length > 0) {
		//   this.changedKeys.forEach(type => {
		Object.keys(this.attributeDataJSON[this.typeFilter]).forEach((id) => {
			if (this.attributeDataJSON[this.typeFilter][id].active) {
				let item = {
					_id: `${id}`,
					_ref: false,
					active: true,
					age_unit: {
						_id: "yr",
						_type: "EHR.CL.Medication.Unit"
					},
					currency: {
						_id: "EUR",
						_type: "IXS.CL.Data.CurrencyUnit"
					},
					part: {
						_id: this.partId,
						_type: "EHR.Data.Contract.Part"
					},
					price_in_points: null,
					sufficient: true,
					type: this.typeFilter
				}

				switch (this.typeFilter) {
					case "EHR.Data.Contract.SK.Attribute:PPC":
						item["validity"] = {
							from:
								isSafe(this.partValidity) && isNotEmpty(this.partValidity.from)
									? moment(this.partValidity.from).format(settings.DB_DATE_FORMAT)
									: "",
							to:
								isSafe(this.partValidity) && isNotEmpty(this.partValidity.to)
									? moment(this.partValidity.to).format(settings.DB_DATE_FORMAT)
									: ""
						}
						break
					case "EHR.Data.Contract.SK.Attribute:PPCa":
						item["validity"] = {
							from:
								isSafe(this.partValidity) && isNotEmpty(this.partValidity.from)
									? moment(this.partValidity.from).format(settings.DB_DATE_FORMAT)
									: "",
							to:
								isSafe(this.partValidity) && isNotEmpty(this.partValidity.to)
									? moment(this.partValidity.to).format(settings.DB_DATE_FORMAT)
									: ""
						}
						break
				}

				let record = this.attributeDataJSON[this.typeFilter][id]

				if (isSafe(record)) {
					Object.keys(record).forEach((value) => {
						if (value === "price_in_currency") {
							let attrCurrency = `${record[value]}`

							if (attrCurrency.includes(",")) {
								attrCurrency = attrCurrency.replace(",", ".")
							}

							item[value] = attrCurrency
						} else if (value === "insurance_type" || value === "diagnosis") {
							item[value] = isEmpty(record[value]) ? null : record[value]
						} else {
							item[value] = record[value]
						}
					})
				}

				request.push(item)
			} else {
				request.push({
					_id: id,
					_ref: false,
					active: false,
					part: {
						_id: this.partId,
						_type: "EHR.Data.Contract.Part"
					}
				})
			}
		})
		//   })
		// }

		api
			.saveAttributes(request)
			.call()
			.then((response) => {
				if (isSafe(response) && response.length > 0) {
					this.loadPart(this.partId)
					GlobalStore.setNotificationMessage("Agreement.Container.Detail.UpdateAttribute.Notification")
				}
				this.updatedAttribute = {}
			})
	}

	@action loadPart(partId) {
		api
			.loadPart(partId)
			.call()
			.then((response) => {
				if (isSafe(response)) {
					// let attributes = []

					this.tempPart = response
					this.partId = response._id
					this.partValidity = response.validity

					let priceLimitPerHCRow = {}
					let deliveriesJSON = {}
					let diagnosisJSON = {}
					let deliveryJSON = {}
					let insuranceJSON = {}
					let deliveryItemsJSON = {}

					let items = {}

					if (isSafe(response.attributes) && response.attributes.length > 0) {
						response.attributes.forEach((attr) => {
							if (!items.hasOwnProperty(attr.type)) {
								items[attr.type] = {}
							}

							items[attr.type][attr._id] = {
								from: isSafe(attr.validity) && isSafe(attr.validity.from) ? attr.validity.from : "",
								to: isSafe(attr.validity) && isSafe(attr.validity.to) ? attr.validity.to : "",
								age_from: attr.age_from,
								age_to: attr.age_to,
								age_unit: isSafe(attr.age_unit) && isSafe(attr.age_unit._id) ? attr.age_unit._id : "",
								price_in_currency: isNotEmpty(attr.price_in_currency)
									? `${attr.price_in_currency}`.replace(".", ",")
									: "",
								price_in_points: isNotEmpty(attr.price_in_points) ? attr.price_in_points : "",
								h_c_type: isSafe(attr.h_c_type) && isSafe(attr.h_c_type.code) ? attr.h_c_type.code : "",
								insurance_type:
									isSafe(attr.insurance_type) && isNotEmpty(attr.insurance_type._id) ? attr.insurance_type._id : "",
								in_capitation: isSafe(attr.in_capitation) ? attr.in_capitation : undefined,
								type: attr.type,
								_type: attr._type,
								id: attr._id,
								active: attr.active
							}

							if (isSafe(attr.delivery_item)) {
								items[attr.type][attr._id]["delivery_item"] = {
									_id: attr.delivery_item._id,
									_type: attr.delivery_item._type,
									code_ext: attr.delivery_item.code_ext
								}
							}

							if (isSafe(attr.diagnosis)) {
								items[attr.type][attr._id]["diagnosis"] = {
									_id: attr.diagnosis._id,
									_type: attr.diagnosis._type
								}
							}

							if (isSafe(attr.deliveries) && isSafe(attr.deliveries[0])) {
								items[attr.type][attr._id]["deliveries"] = [
									{
										_id: attr.deliveries[0]._id,
										_type: attr.deliveries[0]._type
									}
								] //attr.deliveries,
							}

							if (isSafe(attr.delivery)) {
								items[attr.type][attr._id]["delivery"] = {
									_id: attr.delivery._id,
									_type: attr.delivery._type,
									code: attr.delivery.code
								}
							}

							if (isSafe(attr.h_c_type) && isNotEmpty(attr.h_c_type.code)) {
								priceLimitPerHCRow[attr._id] = attr.h_c_type.code
							}

							if (isSafe(attr.deliveries) && attr.deliveries.length > 0) {
								deliveriesJSON[attr._id] =
									isSafe(attr.deliveries[0]) && isSafe(attr.deliveries[0].code_ext)
										? attr.deliveries[0].code_ext + " " + attr.deliveries[0].name_ext
										: ""
							}

							if (isSafe(attr.delivery) && isNotEmpty(attr.delivery._id)) {
								deliveryJSON[attr._id] = attr.delivery.code_ext + " " + attr.delivery.name_ext
							}

							if (isSafe(attr.insurance_type) && isNotEmpty(attr.insurance_type._id)) {
								insuranceJSON[attr._id] = attr.insurance_type.name_ext
							}

							if (isSafe(attr.diagnosis) && isNotEmpty(attr.diagnosis._id)) {
								diagnosisJSON[attr._id] = attr.diagnosis.code_ext + " " + attr.diagnosis.name_ext
							}

							if (isSafe(attr.delivery_item) && isNotEmpty(attr.delivery_item._id)) {
								deliveryItemsJSON[attr._id] = attr.delivery_item.name_ext
							}
						})
					}

					this.deliveryPricePerDeliveryGridJSON = deliveryJSON
					this.deliveriesGridJSON = deliveriesJSON
					this.diagnosisGridJSON = diagnosisJSON
					this.insuranceGridJSON = insuranceJSON
					this.updatedHealthTypesPriceLimitPerHC = priceLimitPerHCRow
					this.deliveryItemsGridJSON = deliveryItemsJSON

					if (isSafe(response.specific_rels) && response.specific_rels.length > 0) {
						let changed = []
						this.providersChecked = {}

						response.specific_rels.forEach((specRel) => {
							if (isSafe(specRel) && isSafe(specRel.specific_rel)) {
								this.providersChecked[specRel.specific_rel._id] = true
								this.providersSpecificRelsIds[specRel.specific_rel._id] = specRel._id

								changed.push({
									provider_id: specRel.specific_rel._id,
									name_ext: specRel.specific_rel.name_ext
								})
							}
						})

						this.providersChanged = changed
					} else {
						this.providersChanged = []
						this.providersChecked = {}
						this.providersSpecificRelsIds = {}
					}

					this.insertDataToPartForm(response)
					this.attributeDataJSON = items
				}
			})
	}

	@action removePart() {
		const request = {
			_id: GlobalStore.confirmationDialogParams.partId,
			_ref: false,
			_type: "EHR.Data.Contract.Part",
			active: false,
			contract: {
				_id: this.contractId,
				_type: "EHR.Data.Contract"
			}
		}

		api
			.savePart(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					GlobalStore.setNotificationMessage("Agreement.Container.Detail.RemovePart.Notification")
					AgreementsStore.loadParts(this.contractId)
				}
			})
	}

	@action savePart(form) {
		let spec_rels = []

		if (isSafe(this.providersChanged) && this.providersChanged.length > 0) {
			this.providersChanged.forEach((provider) => {
				spec_rels.push({
					_id: "",
					_ref: false,
					_type: "Contract.Data.Contract.PartSpecificRel",
					active: true,
					specific_rel: {
						_id: provider.provider_id,
						_type: "Entity.Data.OrgUnit"
					},
					type: "GeneralContract",
					validity: {
						from: null,
						to: null
					}
				})
			})
		}

		const request = {
			_id: "",
			_ref: false,
			_type: "EHR.Data.Contract.Part",
			active: true,
			contract: {
				_id: this.contractId,
				_type: "EHR.Data.Contract"
			},
			number: form.$("agreement_part_number").value,
			specific_rels: spec_rels,
			subject_matter: form.$("subject_matter").value,
			sufficient: form.$("sufficient").value,
			validity: {
				from: isNotEmpty(form.$("from").value) ? moment(form.$("from").value).format(settings.DB_DATE_FORMAT) : null,
				to: isNotEmpty(form.$("to").value) ? moment(form.$("to").value).format(settings.DB_DATE_FORMAT) : null
			}
		}

		api
			.savePart(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					GlobalStore.setNotificationMessage("Agreement.Container.Detail.SavePart.Notification")
					this.showAttributes(response._id)
				}
			})
	}

	@action updatePart(form) {
		let spec_rels = []

		if (isSafe(this.providersChanged) && this.providersChanged.length > 0) {
			this.providersChanged.forEach((provider) => {
				spec_rels.push({
					_id: isSafe(this.providersSpecificRelsIds[provider.provider_id])
						? this.providersSpecificRelsIds[provider.provider_id]
						: "",
					_ref: false,
					_type: "Contract.Data.Contract.PartSpecificRel",
					active: this.providersChecked[provider.provider_id],
					specific_rel: {
						_id: provider.provider_id,
						_type: "Entity.Data.OrgUnit"
					},
					type: "GeneralContract",
					validity: {
						from: null,
						to: null
					}
				})
			})
		}

		if (isSafe(this.tempPart.attributes) && this.tempPart.attributes.length > 0) {
			let tempAttr = []
			this.tempPart.attributes.forEach((attr) => {
				attr.validity.from = form.$("from").value
				attr.validity.to = isNotEmpty(form.$("to").value)
					? moment(form.$("to").value).format(settings.DB_DATE_FORMAT)
					: null

				if (isNotEmpty(attr.age_unit)) {
					attr.age_unit = {
						_id: attr.age_unit._id,
						_type: attr.age_unit._type
					}
				}

				if (isNotEmpty(attr.currency)) {
					attr.currency = {
						_id: attr.currency._id,
						_type: attr.currency._type
					}
				}

				if (isNotEmpty(attr.delivery)) {
					attr.delivery = {
						_id: attr.delivery._id,
						_type: attr.delivery._type
					}
				}

				if (isNotEmpty(attr.diagnosis)) {
					attr.diagnosis = {
						_id: attr.diagnosis._id,
						_type: attr.diagnosis._type
					}
				}

				if (isNotEmpty(attr.delivery_item)) {
					attr.delivery_item = {
						_id: attr.delivery_item._id,
						_type: attr.delivery_item._type
					}
				}

				if (isNotEmpty(attr.deliveries) && attr.deliveries.length > 0) {
					let tempDel = []

					attr.deliveries.forEach((delivery) => {
						tempDel.push({
							_id: delivery._id,
							_type: delivery._type
						})
					})

					attr.deliveries = tempDel
				}

				if (isNotEmpty(attr.insurance_type)) {
					attr.insurance_type = {
						_id: attr.insurance_type._id,
						_type: attr.insurance_type._type
					}
				}

				if (isNotEmpty(attr.h_c_type)) {
					attr.h_c_type = {
						_id: attr.h_c_type._id,
						_type: attr.h_c_type._type
					}
				}

				tempAttr.push(attr)
			})

			this.tempPart.attributes = tempAttr
		}

		const request = {
			_id: this.tempPart._id,
			_ref: false,
			_type: "EHR.Data.Contract.Part",
			active: this.tempPart.active,
			attributes: this.tempPart.attributes,
			contract: {
				_id: this.tempPart.contract._id,
				_type: "EHR.Data.Contract"
			},
			number: form.$("agreement_part_number").value,
			specific_rels: spec_rels,
			subject_matter: form.$("subject_matter").value,
			sufficient: form.$("sufficient").value,
			validity: {
				from: isNotEmpty(form.$("from").value) ? moment(form.$("from").value).format(settings.DB_DATE_FORMAT) : null,
				to: isNotEmpty(form.$("to").value) ? moment(form.$("to").value).format(settings.DB_DATE_FORMAT) : null
			}
		}

		api
			.savePart(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					GlobalStore.setNotificationMessage("Agreement.Container.Detail.SavePart.Notification")
				}
			})
	}

	@action isValidGrid() {
		let isValid = true

		Object.keys(this.attributeDataJSON[this.typeFilter]).forEach((keyAttr) => {
			if (
				this.typeFilter ===
				"EHR.Data.Contract.SK.Attribute:PPC" /* || this.typeFilter === "EHR.Data.Contract.SK.Attribute:PPCa"*/
			) {
				if (!isPrice(this.attributeDataJSON[this.typeFilter][keyAttr].price_in_currency)) {
					isValid = false
				}
			} else if (this.typeFilter === "EHR.Data.Contract.SK.Attribute:PoPD") {
				if (isEmpty(this.attributeDataJSON[this.typeFilter][keyAttr].price_in_points)) {
					isValid = false
				}
			} else {
				if (!isPrice(this.attributeDataJSON[this.typeFilter][keyAttr].price_in_currency)) {
					isValid = false
				}
			}
		})

		return isValid
	}

	@action hasIntersect() {
		let isValid = false

		Object.keys(this.attributeDataJSON[this.typeFilter]).some((attrKey) => {
			let from = +this.attributeDataJSON[this.typeFilter][attrKey].age_from
			let to = +this.attributeDataJSON[this.typeFilter][attrKey].age_to

			Object.keys(this.attributeDataJSON[this.typeFilter]).some((key) => {
				let a = +this.attributeDataJSON[this.typeFilter][key].age_from
				let b = +this.attributeDataJSON[this.typeFilter][key].age_to

				// if (from != a && to != b) {
				if (attrKey != key) {
					if ((a <= from && from <= b) || (a <= to && to <= b) || (from <= a && a <= to) || (from <= b && b <= to)) {
						isValid = true
						return true
					}
				}
			})

			return isValid
		})

		return isValid
	}

	@action hasDuplicityDelivery() {
		let isValid = false

		Object.keys(this.attributeDataJSON[this.typeFilter]).some((attrKey) => {
			let attrA = this.attributeDataJSON[this.typeFilter][attrKey]
			let deliveryIdA = isSafe(attrA.delivery) && isNotEmpty(attrA.delivery._id) ? attrA.delivery._id : attrA.delivery
			let diagnosisIdA =
				isNotEmpty(attrA.diagnosis) && isNotEmpty(attrA.diagnosis._id)
					? attrA.diagnosis._id
					: isSafe(attrA.diagnosis)
					? attrA.diagnosis
					: ""
			let insuranceTypeA = isNotEmpty(attrA.insurance_type) ? attrA.insurance_type : ""

			Object.keys(this.attributeDataJSON[this.typeFilter]).some((key) => {
				let attrB = this.attributeDataJSON[this.typeFilter][key]
				let deliveryIdB = isSafe(attrB.delivery) && isNotEmpty(attrB.delivery._id) ? attrB.delivery._id : attrB.delivery
				let diagnosisIdB =
					isNotEmpty(attrB.diagnosis) && isNotEmpty(attrB.diagnosis._id)
						? attrB.diagnosis._id
						: isSafe(attrB.diagnosis)
						? attrB.diagnosis
						: "" //pretoze ked zmenim diagnozu, tak sa nastvi hned id...object sa vracia pri nacitani...
				let insuranceTypeB = isNotEmpty(attrB.insurance_type) ? attrB.insurance_type : ""

				if (attrKey != key) {
					if (
						attrA.active &&
						attrB.active &&
						`${deliveryIdA}`.trim() === `${deliveryIdB}`.trim() &&
						`${diagnosisIdA}`.trim() === `${diagnosisIdB}`.trim() &&
						`${insuranceTypeA}`.trim() === `${insuranceTypeB}`.trim() &&
						attrA.in_capitation === attrB.in_capitation
					) {
						isValid = true
						return true
					}
				}
			})

			return isValid
		})

		return isValid
	}

	@action hasDuplicityDeliveryPoint() {
		let isValid = false

		Object.keys(this.attributeDataJSON[this.typeFilter]).some((attrKey) => {
			let attrA = this.attributeDataJSON[this.typeFilter][attrKey]
			if (isSafe(attrA.delivery)) {
				let deliveryIdA = attrA.delivery._id

				Object.keys(this.attributeDataJSON[this.typeFilter]).some((key) => {
					let attrB = this.attributeDataJSON[this.typeFilter][key]
					if (isSafe(attrB.delivery)) {
						let deliveryIdB = attrB.delivery._id
						if (attrKey != key) {
							if (attrA.active && attrB.active && `${deliveryIdA}`.trim() === `${deliveryIdB}`.trim()) {
								isValid = true
								return true
							}
						}
					}
				})
			}

			return isValid
		})

		return isValid
	}

	@action hasDuplicityDeliveries() {
		let isValid = false

		Object.keys(this.attributeDataJSON[this.typeFilter]).some((attrKey) => {
			let attrA = this.attributeDataJSON[this.typeFilter][attrKey]
			if (isSafe(attrA.deliveries) && isSafe(attrA.deliveries[0])) {
				let deliveryIdA = attrA.deliveries[0]._id

				Object.keys(this.attributeDataJSON[this.typeFilter]).some((key) => {
					let attrB = this.attributeDataJSON[this.typeFilter][key]
					if (isSafe(attrB.deliveries) && isSafe(attrB.deliveries[0])) {
						let deliveryIdB = attrB.deliveries[0]._id

						if (attrKey != key) {
							if (attrA.active && attrB.active && `${deliveryIdA}`.trim() === `${deliveryIdB}`.trim()) {
								isValid = true
								return true
							}
						}
					}
				})
			}

			return isValid
		})

		return isValid
	}

	@action loadHealthType() {
		api
			.loadHealthType()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					let items = {}

					response.rows.forEach((type) => {
						items[type.code] = type.name_ext
					})

					this.healthTypesJSON = items
					this.healthTypes = response.rows
				}
			})
	}

	@action loadAttrType() {
		api
			.loadAttributeType()
			.call()
			.then((attrType) => {
				if (isSafe(attrType) && isSafe(attrType.rows) && attrType.rows.length > 0) {
					this.agreementAttributeTypes = attrType.rows
					this.typeFilter = attrType.rows[0]._id
				}
			})
	}

	@action searchDeliveries(value, key) {
		const searchRequest = getFilters([`code_ext=${value}`])
		api
			.loadDeliveries(searchRequest)
			.call()
			.then((response) => {
				if (isNotEmpty(key)) {
					this.deliveriesGrid[key] = response.rows
				} else {
					this.deliveries = response.rows
				}
			})
	}

	@action searchDeliveriesItem(value, key) {
		const searchRequest = getFilters([`search_column=${value}`])
		api
			.getDeliveryItems(searchRequest)
			.call()
			.then((response) => {
				if (isNotEmpty(key)) {
					this.deliveriesItemGrid[key] = response.rows
				} else {
					this.deliveriesItem = response.rows
				}
			})
	}

	@action searchDiagnosis(value, key) {
		const searchRequest = getFilters([`search_column=${value}`])
		api
			.loadDiagnosisCl(searchRequest)
			.call()
			.then((response) => {
				if (isNotEmpty(key)) {
					this.diagnosisGrid[key] = response.rows
				} else {
					this.diagnosis = response.rows
				}
			})
	}

	@action insertDataToPartForm(partData) {
		this.agreementPartFormRef.$("agreement_part_number").value = partData.number
		this.agreementPartFormRef.$("from").value =
			isSafe(partData.validity) && isNotEmpty(partData.validity.from) ? moment(partData.validity.from) : ""
		this.agreementPartFormRef.$("to").value =
			isSafe(partData.validity) && isNotEmpty(partData.validity.to) ? moment(partData.validity.to) : ""
		this.agreementPartFormRef.$("subject_matter").value = partData.subject_matter
		this.agreementPartFormRef.$("sufficient").value = partData.sufficient

		this.isNewPart = false
	}

	@action resetDataAttributeForm() {
		this.agreementAttributeAgeFrom = ""
		this.agreementAttributeAgeTo = ""
		this.agreementAttributeCurrency = ""
		this.agreementAttributePricePerLimitType = ""
		this.agreementAttributeInsuranceType = ""
		this.agreementAttributeCapitationChecked = false
		this.pricePerPointFormDeliveryId = ""
		this.pricePerPointFormDelivery = ""
		this.pricePerDeliveryFormDelivery = ""
		this.pricePerDeliveryFormDiagnosis = ""
		this.pricePerDeliveryFormDiagnosisId = ""
		this.pricePerAttributeItemFormDeliveryId = ""
		this.pricePerAttributeItemFormDelivery = ""
		this.pointPerDeliveryFormDelivery = ""
		this.pointPerDeliveryFormPoints = ""
		this.pointPerDeliveryFormDeliveryId = ""
	}
}

var singleton = new AgreementAttributeStore()
export default singleton
