import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "request_examination_radiofarmakom"
				},
				name: {
					label: <FormattedMessage id="Common.label.fullName" />,
					rules: "required"
				},
				identifier: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />,
					rules: "required"
				},
				address: {
					label: "Adresa",
					rules: "required"
				},
				phone: {
					label: "Tel. pacienta",
					rules: "required"
				},
				insurerCode: {
					label: "Z.P.",
					rules: "required"
				},
				idDrg: {
					label: "Id. DRG"
				},
				weight: {
					label: "Hmotnosť",
					rules: "required"
				},
				height: {
					label: "Výška",
					rules: "required"
				},
				sendingMedicalDevice: {
					label: "Odosielajúce zdravotnícke zariadenie",
					rules: "required"
				},
				orgunitCode: {
					label: "Kód oddelenia (ambulancie)",
					rules: "required"
				},
				sendingDoctorPhone: {
					label: "Tel. kontakt na odosielajúceho lekára",
					rules: "required"
				},
				sendingDocotrCode: {
					label: "Kód odosielajúceho lekára",
					rules: "required"
				},
				clinicalDiagnosis: {
					label: "Klinická diagnóza",
					rules: "required"
				},
				diagnosisNameExt: {},
				diagnosisCodeExt: {},
				typeOfExaminationRequired: {
					label: "Druh požadovaného vyšetrenia",
					rules: "required"
				},
				question: {
					label: "Ktorá otázka má byť požadovaným vyšetrením zodpovedaná",
					rules: "required"
				},
				additionalClinicalData: {
					label: "Doplňujúce klinické a laboratórne údaje"
				},
				resultMethod: {
					label: "Výsledky iných zobrazovacích metód"
				},
				drugTreatment: {
					label: "Medikamentózna liečba"
				},
				radioterapy: {
					label: "Rádioterapia"
				},
				operationDelivery: {
					label: "Operačné výkony"
				},
				previousDiagnostic: {
					label: "Predchadzajúca diagnostická alebo terapeutická aplikácia radiofarmák (dátum, druh, prip. výška dávky)"
				},
				datetime: {
					label: "Dátum",
					rules: "required",
					value: moment(),
					type: "date"
				},
				orderDate: {
					label: "Pacient objednaný na vyšetrenie na deň a hodinu",
					rules: "required",
					value: moment()
				},
				date: {
					value: moment().format("DD.MM.YYYY")
				},
				hour: {
					value: moment().format("HH:mm")
				}
				// hour: {
				// 	label: "hodinu"
				// }
			}
		}
	}
}
