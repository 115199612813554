"use strict"

import React from "react"
import {observer} from "mobx-react"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import AddIcon from "@material-ui/icons/Add"
import RouterStore from "../../../../../global/store/RouterStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import api from "../../../actions/api"
import {FormattedMessage} from "react-intl"
import PatientSicknessAbsStore from "../../../stores/PatientSicknessAbsStore"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import moment from "moment"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import settings from "../../../../../global/config/settings"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"

@observer
export default class DeliveriesSicknessAbsForm extends React.Component {
	constructor(props) {
		super(props)
		this.loaded = false
	}

	componentDidMount() {
		PatientSicknessAbsStore.clearData("deliveriesChips")
		GlobalStore.refreshCodeLists(["deliveryExtension"])
	}

	componentWillUnmount() {
		this.loaded = false
	}

	initLoad = () => {}

	handleDeliveriesChipFormDefaults = () => {}

	handleDeliveryChange = (form, name, codeExt) => {
		let values = form.get("value")
		if (isSafe(name)) {
			values.itemLabel = name
			values.itemCode = codeExt
		}
		form.set(values)
	}

	handleDeliveryFocus = () => {
		PatientSicknessAbsStore.deliveriesChipsForm.handleFocus()
	}

	handleDeliveryExtensionChanged = (form) => {
		let delivery_extension = form.fields.get("delivery_extension")
		let dateField = form.fields.get("request_date")
		let delExtObj = null

		if (isNotEmpty(delivery_extension.value)) {
			delExtObj = GlobalStore.CL["deliveryExtension"].find((row) => row._id == delivery_extension.value)
			if (isSafe(delExtObj) && delExtObj.code_ext == "VYZI") {
				if (isSafe(dateField)) {
					dateField.set("rules", "required")
				}
			} else if (isSafe(delivery_extension)) {
				dateField.set("rules", "")
			}
		} else {
			dateField.set("rules", "")
		}
	}

	render() {
		return (
			<div>
				<div>
					<FormattedMessage id="Patient.form.patientrecord.deliveries" />
				</div>
				<XsChipForm
					ref={(chipForm) => {
						PatientSicknessAbsStore.deliveriesChipsForm = chipForm
					}}
					field={this.props.sickForm.$("deliveriesChipsForm")}
					configuration={{
						header: {
							chipsRenderer: (key, dataRow, clickHandler) => (
								<XsChip
									key={key}
									tooltip={isSafe(dataRow["itemLabel"]) ? dataRow["itemLabel"]["value"] : ""}
									label={isSafe(dataRow["itemCode"]) ? dataRow["itemCode"]["value"] : ""}
									onClick={(e) => clickHandler(false, e, key)}
								/>
							),

							addButtonRenderer: (clickHandler) => (
								<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={clickHandler} />
							)
						},
						columns: [
							{
								name: "item",
								label: <FormattedMessage id="Common.label.delivery" />,
								width: 2,
								validationRule: "required",
								renderer: (field, index, fieldKey, onChangeHandler, returnFormData, fields, focusRef) => (
									<XsAutocomplete
										ref={(i) => focusRef(i, index)}
										field={field}
										minLengthForSearch="1"
										api={api.loadDeliveries}
										searchFilter={"code_ext"}
										loadApi={api.loadSpecificDelivery}
										inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
										saveValue={(obj) => obj.code}
										postAction={(obj) => {
											this.handleDeliveryChange(
												onChangeHandler(fieldKey),
												`${obj.code_ext} - ${obj.name_ext}`,
												obj.code_ext
											)
										}}
										firstLoad={() => {
											this.handleDeliveryFocus()
										}}
										onMountWithoutLoad={() => {
											setTimeout(() => {
												this.handleDeliveryFocus()
											}, 0)
										}}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />,
														design: {
															width: "10%"
														},
														type: "string",
														dbName: "code_ext",
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													name_ext: {
														title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.name_ext" />,
														type: "string",
														dbName: "search_column",
														design: {
															width: "90%"
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														}
													}
												},
												options: {
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								)
							},
							{
								name: "itemLabel",
								renderer: () => {},
								width: 0
							},
							{
								name: "itemCode",
								renderer: () => {},
								width: 0
							},
							{
								name: "delivery_extension",
								label: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.deliveryExtension" />,
								width: 2,
								validationRule: "required",
								renderer: (field, index, fieldKey, onChangeHandler) => (
									<SearchSelect
										field={field}
										tooltipProp="description"
										items={GlobalStore.CL["deliveryExtension"]}
										onChange={() => this.handleDeliveryExtensionChanged(onChangeHandler(fieldKey))}
										onClear={() => setTimeout(() => this.handleDeliveryExtensionChanged(onChangeHandler(fieldKey)), 0)}
										required
									/>
								)
							},
							{
								name: "diagnosis",
								label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
								width: 2,
								validationRule: "required|string",
								renderer: (field) => (
									<XsAutocompleteLocal
										field={field}
										minLengthForSearch="3"
										data={RegisterCacheStore.diagnosisRegister}
										inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
										saveValue={(obj) => obj.code}
										firstLoad={() => {
											this.handleDeliveryFocus()
										}}
									/>
								),
								// defaultValue: () => { return (isSafe(PatientSicknessAbsStore.diagnosisChipsForm) && isSafe(PatientSicknessAbsStore.diagnosisChipsForm.getData()) && isSafe(PatientSicknessAbsStore.diagnosisChipsForm.getData()["0"]) && PatientSicknessAbsStore.diagnosisChipsForm.getData()["0"].diagnosis === "PRIMARY" ? PatientSicknessAbsStore.diagnosisChipsForm.getData()["0"].item : "") }
								defaultValue: () => {
									return isSafe(this.props.sickForm) &&
										isSafe(this.props.sickForm.$("diagnosis")) &&
										isNotEmpty(this.props.sickForm.$("diagnosis").value)
										? this.props.sickForm.$("diagnosis").value
										: ""
								}
							},
							{
								name: "request_date",
								label: <FormattedMessage id="Patient.form.patientsicknessabs.deliveries.requestdate" />,
								width: 1,
								// validationRule: "required",
								renderer: (field) => <XsDateTimePicker field={field} showTimeSelect={false} />
								// defaultValue: () => moment().format(settings.DB_DATETIME_FORMAT),
							},
							{
								name: "count",
								label: <FormattedMessage id="Delivery.List.Count" />,
								width: 1,
								validationRule: "required|integer|min:1",
								renderer: (field) => <XsInput field={field} min="1" type="number" />,
								defaultValue: 1
							},
							{
								name: "colPaymentType",
								label: <FormattedMessage id="Common.label.payerType" />,
								width: 1,
								validationRule: "required",
								defaultValue: "S",
								renderer: (field) => (
									<SearchSelect
										field={field}
										items={GlobalStore.CL["payerType"]}
										tabindex="-1"
										getOptionLabel={(row) => `${row.code_ext} - ${row.name_ext}`}
									/>
								)
							},
							{
								name: "delivery_date_time",
								label: <FormattedMessage id="Common.label.date" />,
								width: 1,
								validationRule: "required",
								renderer: (field) => <XsDateTimePicker field={field} showTimeSelect={false} />,
								defaultValue: () => {
									return isSafe(this.props.sickForm) &&
										isSafe(this.props.sickForm.$("from")) &&
										isNotEmpty(this.props.sickForm.$("from").value)
										? moment(this.props.sickForm.$("from").value).format(settings.DB_DATETIME_FORMAT)
										: moment().format(settings.DB_DATETIME_FORMAT)
								}
							}
						],
						options: {
							disabled: false,
							isFormMode: false,
							showInCenter: true,
							maxWidth: true,
							//isModalMode: false,
							onFormOpen: () => {},
							onFormClose: () => {},
							onFormSave: (values) => {
								PatientSicknessAbsStore.handleSaveDeliveries(
									values,
									RouterStore.location.search.replace(/([^0-9])+/, "")
								)
							}
						},
						data: PatientSicknessAbsStore.deliveriesChips
					}}
				/>
			</div>
		)
	}
}
