import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				id: {},
				item_order: {
					label: <FormattedMessage id="Food.FoodContainer.order" />
				},
				gender: {
					label: <FormattedMessage id="Common.label.gender" />
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />
				},
				insurerType: {
					label: <FormattedMessage id="Agreement.Container.Detail.Attribute.InsuranceType" />
				},
				service: {
					label: <FormattedMessage id="Calendar.addEvent.dialog.service" />,
					rules: "required"
				},
				valid_from: {
					label: <FormattedMessage id="Patient.form.patientPrescription.exeptionValidityFrom" />,
					rules: "required"
				},
				valid_to: {
					label: <FormattedMessage id="Patient.form.patientPrescription.validityTo" />
				},
				age_from: {
					label: <FormattedMessage id="Agreement.Container.Detail.Attribute.AgeFrom" />,
					rules: "integer|min:0"
				},
				age_to: {
					label: <FormattedMessage id="Agreement.Container.Detail.Attribute.AgeTo" />,
					rules: "integer|min:0"
				},
				duration: {
					label: <FormattedMessage id="Admin.codelist.providedservicesList.duration" />,
					rules: "required|numeric|min:1"
				},
				default: {
					label: <FormattedMessage id="Admin.codelist.providedservicesList.defaultServices" />,
					type: "checkbox"
				},
				back_color: {
					label: <FormattedMessage id="Admin.codelist.providedservicesList.backgroundColor" />,
					value: "#FFFFFF"
				},
				fore_color: {
					label: <FormattedMessage id="Admin.codelist.providedservicesList.fontColor" />,
					value: "#000000"
				}
			}
		}
	}
}
