import React from "react"
import {observer} from "mobx-react"
import {injectIntl /*, FormattedMessage*/} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid, InputAdornment} from "@material-ui/core"

import "./printTemplatesStyle.less"

// import GlobalStore from "../../../../../../global/store/GlobalStore"
import fields from "./beforePatientDischargeForHomeTreatmentFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import api from "../../../../actions/api"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
// import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
// import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
// import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

import DataStore from "../../../../stores/DataStore"
import TemplateForm from "../../patientRecordForm/templateForm"
import {handleInsertHelpersIntoTextArea} from "../../../../../../global/helpers/actions"
import XsSearchDropDownAction from "../../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"
import AddIcon from "@material-ui/icons/Add"

@injectIntl
@observer
export default class BeforePatientDischargeForHomeTreatment extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		this.ordinanceTemplates = null
		this.ordinanceCursor = null
		this.templateName = "TMPL:ORDINANCE"
	}

	uncheckValue = (fieldName) => {
		this.form.$(fieldName).value = false
	}

	componentDidMount() {
		this.loadTextareaTemplates()
	}

	loadTextareaTemplates = async () => {
		await DataStore.loadTemplatesSync(this.templateName)
		this.refreshTextareaTemplates()
	}

	refreshTextareaTemplates = () => {
		this.ordinanceTemplates =
			isSafe(DataStore.templatesAll) &&
			isSafe(DataStore.templatesAll[this.templateName]) &&
			isSafe(DataStore.templatesAll[this.templateName].records) &&
			DataStore.templatesAll[this.templateName].records.length > 0
				? DataStore.templatesAll[this.templateName].records
						.sort((a, b) => {
							if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
							if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
							return 0
						})
						.map((r) => {
							return {
								label: r.name,
								itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
								action: (e) => DataStore.editTemplate(e.currentTarget, r)
							}
						})
				: []
	}

	render() {
		this.refreshTextareaTemplates()

		return (
			<div className="pa-4">
				<Grid container spacing={8}>
					<Grid item xs={6}>
						<XsInput field={this.form.$("name")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("phone")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("email")} />
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("address")} />
					</Grid>

					<Grid item xs={3}>
						<XsInput field={this.form.$("deliveryCode")} />
					</Grid>
					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("deliveryDate")} showTimeSelect={false} />
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2} className="xs-bold">
							Pacient
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Pri vedomí:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isConscious_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isConscious_inv").value = true
										} else {
											this.form.$("isConscious_inv").value = false
										}
										this.uncheckValue("isConscious_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isConscious_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isConscious_inv").value = true
										} else {
											this.form.$("isConscious_inv").value = false
										}
										this.uncheckValue("isConscious_yes")
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Orientovaný:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isOriented_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isOriented_inv").value = true
										} else {
											this.form.$("isOriented_inv").value = false
										}
										this.uncheckValue("isOriented_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isOriented_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isOriented_inv").value = true
										} else {
											this.form.$("isOriented_inv").value = false
										}
										this.uncheckValue("isOriented_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2} className="xs-bold">
							Dýchanie
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Spontánne:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isBreathing_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isBreathing_inv").value = true
										} else {
											this.form.$("isBreathing_inv").value = false
										}
										this.uncheckValue("isBreathing_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isBreathing_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isBreathing_inv").value = true
										} else {
											this.form.$("isBreathing_inv").value = false
										}
										this.uncheckValue("isBreathing_yes")
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Eupnoe:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isEupnoe_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isEupnoe_inv").value = true
										} else {
											this.form.$("isEupnoe_inv").value = false
										}
										this.uncheckValue("isEupnoe_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("isEupnoe_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("isEupnoe_inv").value = true
										} else {
											this.form.$("isEupnoe_inv").value = false
										}
										this.uncheckValue("isEupnoe_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2} className="xs-bold">
							Pulz
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Pravidelný:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasPulse_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasPulse_inv").value = true
										} else {
											this.form.$("hasPulse_inv").value = false
										}
										this.uncheckValue("hasPulse_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasPulse_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasPulse_inv").value = true
										} else {
											this.form.$("hasPulse_inv").value = false
										}
										this.uncheckValue("hasPulse_yes")
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Dobre plnený:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasPulseWhole_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasPulseWhole_inv").value = true
										} else {
											this.form.$("hasPulseWhole_inv").value = false
										}
										this.uncheckValue("hasPulseWhole_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasPulseWhole_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasPulseWhole_inv").value = true
										} else {
											this.form.$("hasPulseWhole_inv").value = false
										}
										this.uncheckValue("hasPulseWhole_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2}></Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Hodnota:
							</Grid>
							<Grid item xs={6}>
								<div style={{width: "140px"}}>
									<XsInput
										field={this.form.$("pulseValue")}
										InputProps={{
											endAdornment: (
												<InputAdornment className="xs-endUnit" position="end">
													min
												</InputAdornment>
											)
										}}
									/>
								</div>
							</Grid>
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Kapilárny návrat:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasCapilarReturn_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasCapilarReturn_inv").value = true
										} else {
											this.form.$("hasCapilarReturn_inv").value = false
										}
										this.uncheckValue("hasCapilarReturn_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasCapilarReturn_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasCapilarReturn_inv").value = true
										} else {
											this.form.$("hasCapilarReturn_inv").value = false
										}
										this.uncheckValue("hasCapilarReturn_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2} className="xs-bold">
							TK
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Stabilný:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("tk_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("tk_inv").value = true
										} else {
											this.form.$("tk_inv").value = false
										}
										this.uncheckValue("tk_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("tk_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("tk_inv").value = true
										} else {
											this.form.$("tk_inv").value = false
										}
										this.uncheckValue("tk_yes")
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Hodnota:
							</Grid>
							<Grid item xs={6}>
								<div style={{width: "140px"}}>
									<XsInput
										field={this.form.$("tkValue")}
										InputProps={{
											endAdornment: (
												<InputAdornment className="xs-endUnit" position="end">
													Torr
												</InputAdornment>
											)
										}}
									/>
								</div>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2} className="xs-bold">
							Rana
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Neprekrvacaná:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("wound_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("wound_inv").value = true
										} else {
											this.form.$("wound_inv").value = false
										}
										this.uncheckValue("wound_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("wound_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("wound_inv").value = true
										} else {
											this.form.$("wound_inv").value = false
										}
										this.uncheckValue("wound_yes")
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Obväz skontrolovaný:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("bandage_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("bandage_inv").value = true
										} else {
											this.form.$("bandage_inv").value = false
										}
										this.uncheckValue("bandage_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("bandage_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("bandage_inv").value = true
										} else {
											this.form.$("bandage_inv").value = false
										}
										this.uncheckValue("bandage_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2} className="xs-bold">
							Analgézia
						</Grid>
						<Grid item container xs={5} alignItems="center">
							<Grid item xs={5}>
								Uspokojivá:
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("analgezia_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("analgezia_inv").value = true
										} else {
											this.form.$("analgezia_inv").value = false
										}
										this.uncheckValue("analgezia_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("analgezia_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("analgezia_inv").value = true
										} else {
											this.form.$("analgezia_inv").value = false
										}
										this.uncheckValue("analgezia_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Pacient toleruje prívod tekutín per os
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_can_drink_liquid_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_can_drink_liquid_inv").value = true
										} else {
											this.form.$("patient_can_drink_liquid_inv").value = false
										}
										this.uncheckValue("patient_can_drink_liquid_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_can_drink_liquid_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_can_drink_liquid_inv").value = true
										} else {
											this.form.$("patient_can_drink_liquid_inv").value = false
										}
										this.uncheckValue("patient_can_drink_liquid_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Pacient sa vymočil spontánne
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_can_piss_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_can_piss_inv").value = true
										} else {
											this.form.$("patient_can_piss_inv").value = false
										}
										this.uncheckValue("patient_can_piss_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_can_piss_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_can_piss_inv").value = true
										} else {
											this.form.$("patient_can_piss_inv").value = false
										}
										this.uncheckValue("patient_can_piss_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Pacient je schopný samostatnej chôdze
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_can_walk_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_can_walk_inv").value = true
										} else {
											this.form.$("patient_can_walk_inv").value = false
										}
										this.uncheckValue("patient_can_walk_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_can_walk_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_can_walk_inv").value = true
										} else {
											this.form.$("patient_can_walk_inv").value = false
										}
										this.uncheckValue("patient_can_walk_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Pacient chodí o barlách
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_use_crutches_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_use_crutches_inv").value = true
										} else {
											this.form.$("patient_use_crutches_inv").value = false
										}
										this.uncheckValue("patient_use_crutches_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("patient_use_crutches_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("patient_use_crutches_inv").value = true
										} else {
											this.form.$("patient_use_crutches_inv").value = false
										}
										this.uncheckValue("patient_use_crutches_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Dostal pacient domov inštrukcie
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasInstructions_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasInstructions_inv").value = true
										} else {
											this.form.$("hasInstructions_inv").value = false
										}
										this.uncheckValue("hasInstructions_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("hasInstructions_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("hasInstructions_inv").value = true
										} else {
											this.form.$("hasInstructions_inv").value = false
										}
										this.uncheckValue("hasInstructions_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Ukončená OAIM a ošetrovateľská správa
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("oam_ended_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("oam_ended_inv").value = true
										} else {
											this.form.$("oam_ended_inv").value = false
										}
										this.uncheckValue("oam_ended_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("oam_ended_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("oam_ended_inv").value = true
										} else {
											this.form.$("oam_ended_inv").value = false
										}
										this.uncheckValue("oam_ended_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Nasledujúca kontrola
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("next_checkup_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("next_checkup_inv").value = true
										} else {
											this.form.$("next_checkup_inv").value = false
										}
										this.uncheckValue("next_checkup_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("next_checkup_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("next_checkup_inv").value = true
										} else {
											this.form.$("next_checkup_inv").value = false
										}
										this.uncheckValue("next_checkup_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={4} className="xs-bold">
							Pooperačná ordinácia je napísaná
						</Grid>
						<Grid item container xs={5} alignItems="center" style={{marginLeft: "2px"}}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("after_operation_yes")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("after_operation_inv").value = true
										} else {
											this.form.$("after_operation_inv").value = false
										}
										this.uncheckValue("after_operation_no")
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("after_operation_no")}
									onChange={(target) => {
										if (!target.value) {
											this.form.$("after_operation_inv").value = true
										} else {
											this.form.$("after_operation_inv").value = false
										}
										this.uncheckValue("after_operation_yes")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsTextArea
								field={this.form.$("ordinations")}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={this.ordinanceTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													if (isNotEmpty(this.form.$("ordinations").value) && this.ordinanceCursor) {
														handleInsertHelpersIntoTextArea(
															this.form,
															"ordinations",
															this.ordinanceCursor.cursor,
															data.itemData
														)
													} else {
														this.form.$("ordinations").value += data.itemData
													}
													this.ordinanceCursor = null
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (data && (isNotSafe(this.ordinanceCursor) || this.ordinanceCursor.cursor != data.cursor)) {
										this.ordinanceCursor = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("entry_time")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("leave_time")} />
						</Grid>
					</Grid>
				</Grid>
				<TemplateForm type={this.templateName} />
			</div>
		)
	}
}
