import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./labRequestFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import api from "../../../../actions/api"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {getFilters} from "../../../../../../global/helpers/api"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class LabRequestTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.state = {
			doctorOUPZSItems: [],
			substitutingDoctorOUPZSItems: []
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		this.form.$("name_medirex").value =
			props.values.name_reversed.length > 20
				? props.values.name_reversed.slice(0, 19) + "..."
				: props.values.name_reversed

		if (isEmpty(this.form.$("paperTemplateId").value)) {
			this.form.$("paperTemplateId").value = PrintTemplatesStore.labTemplates.find(
				(x) => x.id == this.form.$("lab").value
			).paperTemplate
		}

		if (isEmpty(this.form.$("aplha_street").value) && isNotEmpty(this.form.$("street").value)) {
			this.form.$("aplha_street").value = isNotEmpty(this.form.$("house_number").value)
				? this.form.$("street").value + ", " + this.form.$("house_number").value
				: this.form.$("street").value
		}
		if (isEmpty(this.form.$("aplha_city").value) && isNotEmpty(this.form.$("city").value)) {
			this.form.$("aplha_city").value = this.form.$("city").value
		}
		if (isEmpty(this.form.$("aplha_zipcode").value) && isNotEmpty(this.form.$("postcode").value)) {
			this.form.$("aplha_zipcode").value = this.form.$("postcode").value
		}
		if (this.form.$("genderMan").value == false && this.form.$("genderFemale").value == false) {
			if (this.form.$("sex").value == 1) {
				this.form.$("genderMan").value = true
			}
			if (this.form.$("sex").value == 2) {
				this.form.$("genderFemale").value = true
			}
		}

		this.form
			.$("first_name")
			.value.substring(0, 21)
			.split("")
			.forEach((char, idx) => {
				this.form.$("first_name" + idx).value = char
			})

		this.form
			.$("last_name")
			.value.substring(0, 21)
			.split("")
			.forEach((char, idx) => {
				this.form.$("last_name" + idx).value = char
			})

		this.form
			.$("idHospPrip")
			.value.substring(0, 12)
			.split("")
			.forEach((char, idx) => {
				this.form.$("idHospPrip" + idx).value = char
			})

		this.form
			.$("patientId")
			.value.substring(0, 21)
			.split("")
			.forEach((char, idx) => {
				this.form.$("patientId" + idx).value = char
			})

		this.form
			.$("sendingDoctorACode")
			.value.substring(0, 10)
			.split("")
			.forEach((char, idx) => {
				this.form.$("sendingDoctorACode" + idx).value = char
			})

		this.form
			.$("sendingDoctorPCode")
			.value.substring(0, 12)
			.split("")
			.forEach((char, idx) => {
				this.form.$("sendingDoctorPCode" + idx).value = char
			})

		this.form
			.$("substitutingACode")
			.value.substring(0, 10)
			.split("")
			.forEach((char, idx) => {
				this.form.$("substitutingACode" + idx).value = char
			})

		this.form
			.$("substitutingPCode")
			.value.substring(0, 12)
			.split("")
			.forEach((char, idx) => {
				this.form.$("substitutingPCode" + idx).value = char
			})

		this.form
			.$("countryForm")
			.value.substring(0, 2)
			.split("")
			.forEach((char, idx) => {
				this.form.$("countryForm" + idx).value = char
			})

		this.form
			.$("diagHlKod")
			.value.toString()
			.replace(".", "")
			.substring(0, 5)
			.split("")
			.forEach((char, idx) => {
				this.form.$("diagHlKod" + idx).value = char
			})

		this.form
			.$("diagPridrKod")
			.value.toString()
			.replace(".", "")
			.substring(0, 5)
			.split("")
			.forEach((char, idx) => {
				this.form.$("diagPridr" + idx).value = char
			})

		this.form
			.$("diagVedlKod")
			.value.toString()
			.replace(".", "")
			.substring(0, 5)
			.split("")
			.forEach((char, idx) => {
				this.form.$("diagVedlA" + idx).value = char
			})

		this.form
			.$("diagVedl2Kod")
			.value.toString()
			.replace(".", "")
			.substring(0, 5)
			.split("")
			.forEach((char, idx) => {
				this.form.$("diagVedlB" + idx).value = char
			})

		this.form
			.$("bpb_phone")
			.value.substring(0, 14)
			.split("")
			.forEach((char, idx) => {
				this.form.$("bpb_phone" + idx).value = char
			})

		moment(this.form.$("date").value)
			.format("DDMMYY")
			.split("")
			.forEach((char, idx) => {
				this.form.$("date" + idx).value = char
			})
		moment(this.form.$("dateBlood").value)
			.format("DDMMYYHHmm")
			.split("")
			.forEach((char, idx) => {
				this.form.$("dateBlood" + idx).value = char
			})
		if (isNotEmpty(this.form.$("dateMenstr").value)) {
			moment(this.form.$("dateMenstr").value)
				.format("DDMMYY")
				.split("")
				.forEach((char, idx) => {
					this.form.$("dateMenstr" + idx).value = char
				})
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid item xs={4}>
						<SearchSelect
							field={this.form.$("lab")}
							getOptionLabel={(obj) => obj.label}
							items={[
								{label: "ALPHA MEDICAL", value: "alphamedical", _id: "alphamedical"},
								{label: "MEDIREX", value: "medirex", _id: "medirex"},
								{label: "SYNLAB", value: "synlab", _id: "synlab"},
								{label: "BPB MED", value: "bpbmed", _id: "bpbmed"}
							]}
							onChange={(val) => {
								const templateValue = PrintTemplatesStore.labTemplates.find((x) => x.id == val).paperTemplate
								this.form.$("paperTemplateId").value = templateValue
							}}
						/>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsInput field={this.form.$("identifier")} />
						</Grid>
						{this.form.$("lab").value == "alphamedical" && (
							<Grid item xs={2}>
								<XsDateTimePicker field={this.form.$("birthdate")} showTimeSelect={false} />
							</Grid>
						)}
						<Grid item xs={3}>
							<XsInput
								field={this.form.$("first_name")}
								onChange={(val) => {
									val
										.substring(0, 21)
										.split("")
										.forEach((char, idx) => {
											this.form.$("first_name" + idx).value = char
										})
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsInput
								field={this.form.$("last_name")}
								onChange={(val) => {
									val
										.substring(0, 21)
										.split("")
										.forEach((char, idx) => {
											this.form.$("last_name" + idx).value = char
										})
								}}
							/>
						</Grid>
					</Grid>

					{(this.form.$("lab").value == "alphamedical" || this.form.$("lab").value == "bpbmed") && (
						<React.Fragment>
							<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
								<Grid item xs={5}>
									<XsInput field={this.form.$("aplha_street")} />
								</Grid>
								<Grid item xs={5}>
									<XsInput field={this.form.$("aplha_city")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("aplha_zipcode")} />
								</Grid>
							</Grid>
							{this.form.$("lab").value == "alphamedical" && (
								<div style={{marginBottom: "20px"}}>
									* adresu pacienta žiadame vyplniť v prípade samoplatcu alebo vyšetrení hlásených v zmysle zákona č.
									355/2007 Z. z.
								</div>
							)}
						</React.Fragment>
					)}

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("genderMan")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("genderFemale")}
								onChange={(field) => {
									if (field.value == false) {
										this.form.$("dateMenstr").value = ""
										for (let idx = 0; idx < 6; idx++) {
											this.form.$("dateMenstr" + idx).value = ""
										}
									}
								}}
							/>
						</Grid>
						{this.form.$("lab").value == "synlab" && (
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("gravidity")} />
							</Grid>
						)}
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						{this.form.$("lab").value == "alphamedical" ? (
							<Grid item xs={4}>
								<XsInput field={this.form.$("payer")} />
							</Grid>
						) : (
							<Grid item xs={2}>
								<XsInput field={this.form.$("insurerCode")} />
							</Grid>
						)}
						{this.form.$("lab").value != "bpbmed" && (
							<Grid item xs={5}>
								<XsInput
									field={this.form.$("idHospPrip")}
									onChange={(val) => {
										val
											.substring(0, 12)
											.split("")
											.forEach((char, idx) => {
												this.form.$("idHospPrip" + idx).value = char
											})
									}}
								/>
							</Grid>
						)}
					</Grid>

					<div style={{marginTop: "30px", marginBottom: "10px"}}>
						EÚ poistenci - k žiadanke priložiť kópiu preukazu poistenca
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						{this.form.$("lab").value == "synlab" && (
							<Grid item xs={3}>
								<XsCheckbox field={this.form.$("insurerEU")} />
							</Grid>
						)}
						<Grid item xs={2}>
							<XsInput
								field={this.form.$("countryForm")}
								onChange={(val) => {
									val
										.substring(0, 2)
										.split("")
										.forEach((char, idx) => {
											this.form.$("countryForm" + idx).value = char
										})
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("typZs")} />
						</Grid>
						<Grid item xs={4}>
							<XsInput
								field={this.form.$("patientId")}
								onChange={(val) => {
									val
										.substring(0, 21)
										.split("")
										.forEach((char, idx) => {
											this.form.$("patientId" + idx).value = char
										})
								}}
							/>
						</Grid>
					</Grid>

					{this.form.$("lab").value == "bpbmed" && (
						<Grid item xs={12}>
							<XsInput field={this.form.$("bpb_note")} />
						</Grid>
					)}

					{this.form.$("lab").value != "bpbmed" && this.form.$("lab").value != "medirex" && (
						<React.Fragment>
							<div style={{marginTop: "20px"}}>Fakturovať:</div>
							<Grid container direction="row" align="left" spacing={8}>
								<Grid item xs={4}>
									<XsCheckbox field={this.form.$("pay_doctor")} />
								</Grid>
								<Grid item xs={2}>
									<XsCheckbox field={this.form.$("pay_patient")} />
								</Grid>
								{this.form.$("lab").value == "synlab" && (
									<Grid item xs={3}>
										<XsCheckbox field={this.form.$("self_pay")} />
									</Grid>
								)}
								{this.form.$("lab").value == "alphamedical" && (
									<Grid item xs={3}>
										<XsCheckbox
											field={this.form.$("fee_free")}
											onChange={(val) => {
												this.form.$("fee_freeInv").value = !val
											}}
										/>
									</Grid>
								)}
							</Grid>
						</React.Fragment>
					)}

					<div style={{marginTop: "30px", marginBottom: "10px"}}>
						Diagnózy:
						{/* <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.FormLabel1" /> */}
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6} className="xs-autocomplete-element">
							<XsAutocompleteLocal
								field={this.form.$("diagHl")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("diagHlKod").value = obj.code_ext
									const diagH1Str = obj.code_ext.toString().replace(".", "")
									diagH1Str
										.substring(0, 5)
										.split("")
										.forEach((char, idx) => {
											this.form.$("diagHlKod" + idx).value = char
										})
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={6} className="xs-autocomplete-element">
							<XsAutocompleteLocal
								field={this.form.$("diagPridr")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("diagPridrKod").value = obj.code_ext
									const diagPridrStr = obj.code_ext.toString().replace(".", "")
									diagPridrStr
										.substring(0, 5)
										.split("")
										.forEach((char, idx) => {
											this.form.$("diagPridr" + idx).value = char
										})
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>
					{this.form.$("lab").value != "alphamedical" && (
						<Grid container direction="row" align="left" spacing={8}>
							<Grid item xs={6} className="xs-autocomplete-element">
								<XsAutocompleteLocal
									field={this.form.$("diagVedl")}
									minLengthForSearch="1"
									data={RegisterCacheStore.diagnosisRegister}
									inputRenderer={(obj) => {
										this.form.$("diagVedlKod").value = obj.code_ext
										const diagVedlAStr = obj.code_ext.toString().replace(".", "")
										diagVedlAStr
											.substring(0, 5)
											.split("")
											.forEach((char, idx) => {
												this.form.$("diagVedlA" + idx).value = char
											})
										return obj.code_ext + " " + obj.name_ext
									}}
									saveValue={(obj) => obj.code}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
													type: "string",
													dbName: "code_ext",
													design: {
														width: "150px"
													},
													filter: {
														gridWidth: 3,
														defaultValue: "",
														renderElement: "input"
													}
												},
												name_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
													type: "string",
													dbName: "search_column",
													filter: {
														gridWidth: 9,
														defaultValue: textValue,
														renderElement: "input"
													}
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												}
											}
										}
									}}
								/>
							</Grid>
							{(this.form.$("lab").value == "synlab" || this.form.$("lab").value == "bpbmed") && (
								<Grid item xs={6} className="xs-autocomplete-element">
									<XsAutocompleteLocal
										field={this.form.$("diagVedl2")}
										minLengthForSearch="1"
										data={RegisterCacheStore.diagnosisRegister}
										inputRenderer={(obj) => {
											this.form.$("diagVedl2Kod").value = obj.code_ext
											const diagVedlBStr = obj.code_ext.toString().replace(".", "")
											diagVedlBStr
												.substring(0, 5)
												.split("")
												.forEach((char, idx) => {
													this.form.$("diagVedlB" + idx).value = char
												})
											return obj.code_ext + " " + obj.name_ext
										}}
										saveValue={(obj) => obj.code}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
														type: "string",
														dbName: "code_ext",
														design: {
															width: "150px"
														},
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													name_ext: {
														title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
														type: "string",
														dbName: "search_column",
														filter: {
															gridWidth: 9,
															defaultValue: textValue,
															renderElement: "input"
														}
													}
												},
												options: {
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								</Grid>
							)}
						</Grid>
					)}

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("dateBlood")}
								showTimeSelect={true}
								onChange={(val) => {
									const value = val.format("DDMMYYHHmm")
									value.split("").forEach((char, idx) => {
										this.form.$("dateBlood" + idx).value = char
									})
								}}
							/>
						</Grid>
						{this.form.$("lab").value != "bpbmed" && (
							<Grid item xs={3}>
								<XsDateTimePicker
									field={this.form.$("date")}
									showTimeSelect={false}
									onChange={(val) => {
										const value = val.format("DDMMYY")
										value.split("").forEach((char, idx) => {
											this.form.$("date" + idx).value = char
										})
									}}
								/>
							</Grid>
						)}
						{(this.form.$("lab").value == "medirex" || this.form.$("lab").value == "bpbmed") &&
							this.form.$("genderFemale").value == true && (
								<Grid item xs={3}>
									<XsDateTimePicker
										field={this.form.$("dateMenstr")}
										showTimeSelect={false}
										onChange={(val) => {
											const value = val.format("DDMMYY")
											value.split("").forEach((char, idx) => {
												this.form.$("dateMenstr" + idx).value = char
											})
										}}
										onBlur={() => {
											if (isNotSafe(this.form.$("dateMenstr").value)) {
												for (let idx = 0; idx < 6; idx++) {
													this.form.$("dateMenstr" + idx).value = ""
												}
											}
										}}
									/>
								</Grid>
							)}
					</Grid>

					{/* Odporucajuci lekar */}
					{this.form.$("lab").value != "bpbmed" && this.form.$("lab").value != "synlab" && (
						<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
							<Grid item xs={4} className="xs-autocomplete-element">
								<XsAutocomplete
									field={this.form.$("sendingDoctorPersonnelExpertiseId")}
									minLengthForSearch="3"
									clearText
									api={api.loadPersonnel}
									filterValue={"personnel_expertise_id"}
									inputRenderer={(obj, menuItem = false) =>
										(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
											? "<b style=color:#00A787>"
											: "") +
										(obj.identifier ? obj.identifier + " / " : "") +
										obj.full_name +
										" / " +
										(obj.expertise_code ? obj.expertise_code + " " : "") +
										obj.expertise +
										(isNotEmpty(obj.preferred_provider_code) ? " / " + obj.preferred_provider_code : "") +
										(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
											? "</b>"
											: "")
									}
									saveValue={(obj) => obj.personnel_expertise_id}
									postAction={(obj, firstLoad) => {
										if (!firstLoad) {
											this.form.$("sendingDoctor").value = ""
											this.form.$("sendingDoctorACode").value = ""
											this.form.$("doctorOUPZS").value = ""
											this.form.$("sendingDoctorFullName").value = ""
											this.setState({doctorOUPZSItems: []})
										}
										this.form.$("sendingDoctor").value = obj.personnel_id
										this.form.$("sendingDoctorACode").value = obj.identifier
										this.form.$("sendingDoctorFullName").value = obj.full_name
										obj.identifier
											.substring(0, 10)
											.split("")
											.forEach((char, idx) => {
												this.form.$("sendingDoctorACode" + idx).value = char
											})

										if (isNotEmpty(obj.personnel_expertise_id)) {
											const searchRequest = getFilters([`personnel_expertise_id=${obj.personnel_expertise_id}`])
											api
												.loadProviders(searchRequest)
												.call()
												.then((response) => {
													if (isSafe(response) && isSafe(response.rows)) {
														let items = []
														response.rows.forEach((item) => {
															items.push({_id: item.provider_id, name_ext: item.provider_code})
														})
														this.setState({doctorOUPZSItems: items})
														let doctorOUPZS
														let preferredOUPZS = null
														if (isSafe(response.rows) && response.rows.length > 1) {
															preferredOUPZS = response.rows.find((row) => {
																return row.code_ext === obj.preferred_provider_code
															})
														}
														if (response.rows.length === 1 || isSafe(preferredOUPZS)) {
															if (response.rows.length === 1) {
																doctorOUPZS =
																	isEmpty(response.rows[0].provider_id) === "" ? null : response.rows[0].provider_id
															} else {
																doctorOUPZS = preferredOUPZS.provider_id
															}
															if (!firstLoad) {
																this.form.$("doctorOUPZS").value = doctorOUPZS
																const codeItem = items.find((x) => x._id == doctorOUPZS)
																if (isSafe(codeItem)) {
																	this.form.$("sendingDoctorPCode").value = codeItem.name_ext
																	codeItem.name_ext
																		.substring(0, 12)
																		.split("")
																		.forEach((char, idx) => {
																			this.form.$("sendingDoctorPCode" + idx).value = char
																		})
																}
															}
														}
													}
												})
										}
									}}
									onClear={() => {
										this.form.$("sendingDoctor").value = ""
										this.form.$("sendingDoctorACode").value = ""
										this.form.$("doctorOUPZS").value = ""
										this.form.$("sendingDoctorFullName").value = ""
										this.setState({doctorOUPZSItems: []})
									}}
								/>
							</Grid>
							<Grid item xs={4} className="xs-autocomplete-element">
								{isSafe(this.state.doctorOUPZSItems) && this.state.doctorOUPZSItems.length > 0 ? (
									<SearchSelect
										field={this.form.$("doctorOUPZS")}
										disabled={isEmpty(this.form.$("sendingDoctor").value)}
										items={this.state.doctorOUPZSItems}
										onChange={(val) => {
											const code = this.state.doctorOUPZSItems.find((x) => x._id == val).name_ext
											this.form.$("sendingDoctorPCode").value = code

											code
												.substring(0, 12)
												.split("")
												.forEach((char, idx) => {
													this.form.$("sendingDoctorPCode" + idx).value = char
												})
										}}
									/>
								) : (
									<XsAutocomplete
										field={this.form.$("doctorOUPZS")}
										minLengthForSearch="3"
										api={api.loadProviders}
										clearText
										disabled={isEmpty(this.form.$("sendingDoctor").value)}
										filterValue={"provider_id"}
										inputRenderer={(obj) =>
											(obj.provider_code ? obj.provider_code + " / " : "") +
											obj.company_name +
											" / " +
											(obj.expertise_code ? obj.expertise_code + " " : "") +
											obj.expertise_name_ext
										}
										saveValue={(obj) => obj.provider_id}
										postAction={(obj) => {
											this.form.$("sendingDoctorPCode").value = obj.provider_code

											obj.provider_code
												.substring(0, 12)
												.split("")
												.forEach((char, idx) => {
													this.form.$("sendingDoctorPCode" + idx).value = char
												})
										}}
									/>
								)}
							</Grid>
						</Grid>
					)}

					{/* Zastupujuci lekar */}
					{/* zatial zakomentovane pre synlab */}
					{/* {this.form.$("lab").value == "synlab" && (
						<Grid container direction="row" align="left" spacing={8}>
							<Grid item xs={4} className="xs-autocomplete-element">
								<XsAutocomplete
									field={this.form.$("substitutingPersonnelExpertiseId")}
									minLengthForSearch="3"
									clearText
									api={api.loadPersonnel}
									filterValue={"personnel_expertise_id"}
									inputRenderer={(obj, menuItem = false) =>
										(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
											? "<b style=color:#00A787>"
											: "") +
										(obj.identifier ? obj.identifier + " / " : "") +
										obj.full_name +
										" / " +
										(obj.expertise_code ? obj.expertise_code + " " : "") +
										obj.expertise +
										(isNotEmpty(obj.preferred_provider_code) ? " / " + obj.preferred_provider_code : "") +
										(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
											? "</b>"
											: "")
									}
									saveValue={(obj) => obj.personnel_expertise_id}
									postAction={(obj, firstLoad) => {
										if (!firstLoad) {
											this.form.$("substitutingDoctor").value = ""
											this.form.$("substitutingDoctorOUPZS").value = ""
											this.form.$("substitutingACode").value = ""
											this.form.$("substitutingFullName").value = ""
											this.setState({substitutingDoctorOUPZSItems: []})
										}
										this.form.$("substitutingDoctor").value = obj.personnel_id
										this.form.$("substitutingACode").value = obj.identifier
										this.form.$("substitutingFullName").value = obj.full_name
										obj.identifier
											.substring(0, 10)
											.split("")
											.forEach((char, idx) => {
												this.form.$("substitutingACode" + idx).value = char
											})

										if (isNotEmpty(obj.personnel_expertise_id)) {
											const searchRequest = getFilters([`personnel_expertise_id=${obj.personnel_expertise_id}`])
											api
												.loadProviders(searchRequest)
												.call()
												.then((response) => {
													if (isSafe(response) && isSafe(response.rows)) {
														let items = []
														response.rows.forEach((item) => {
															items.push({_id: item.provider_id, name_ext: item.provider_code})
														})
														this.setState({substitutingDoctorOUPZSItems: items})
														let preferredOUPZS = null
														if (isSafe(response.rows) && response.rows.length > 1) {
															preferredOUPZS = response.rows.find((row) => {
																return row.code_ext === obj.preferred_provider_code
															})
														}
														if (response.rows.length === 1 || isSafe(preferredOUPZS)) {
															let doctorOUPZS
															if (response.rows.length === 1) {
																doctorOUPZS =
																	isEmpty(response.rows[0].provider_id) === "" ? null : response.rows[0].provider_id
															} else {
																doctorOUPZS = preferredOUPZS.provider_id
															}
															if (!firstLoad) {
																this.form.$("substitutingDoctorOUPZS").value = doctorOUPZS
															}
														}
													}
												})
										}
									}}
									onClear={() => {
										this.form.$("substitutingDoctor").value = ""
										this.form.$("substitutingDoctorOUPZS").value = ""
										this.form.$("substitutingACode").value = ""
										this.form.$("substitutingFullName").value = ""
										this.setState({substitutingDoctorOUPZSItems: []})
									}}
								/>
							</Grid>
							<Grid item xs={4} className="xs-autocomplete-element">
								{isSafe(this.state.substitutingDoctorOUPZSItems) &&
								this.state.substitutingDoctorOUPZSItems.length > 0 ? (
									<SearchSelect
										field={this.form.$("substitutingDoctorOUPZS")}
										disabled={isEmpty(this.form.$("substitutingPersonnelExpertiseId").value)}
										items={this.state.substitutingDoctorOUPZSItems}
										onChange={(val) => {
											const code = this.state.doctorOUPZSItems.find((x) => x._id == val).name_ext
											this.form.$("substitutingPCode").value = code

											code
												.substring(0, 12)
												.split("")
												.forEach((char, idx) => {
													this.form.$("substitutingPCode" + idx).value = char
												})
										}}
									/>
								) : (
									<XsAutocomplete
										field={this.form.$("substitutingDoctorOUPZS")}
										minLengthForSearch="3"
										api={api.loadProviders}
										clearText
										disabled={isEmpty(this.form.$("substitutingPersonnelExpertiseId").value)}
										filterValue={"provider_id"}
										inputRenderer={(obj) =>
											(obj.provider_code ? obj.provider_code + " / " : "") +
											obj.company_name +
											" / " +
											(obj.expertise_code ? obj.expertise_code + " " : "") +
											obj.expertise_name_ext
										}
										saveValue={(obj) => obj.provider_id}
										postAction={(obj) => {
											this.form.$("substitutingPCode").value = obj.provider_code

											obj.provider_code
												.substring(0, 12)
												.split("")
												.forEach((char, idx) => {
													this.form.$("substitutingPCode" + idx).value = char
												})
										}}
									/>
								)}
							</Grid>
						</Grid>
					)} */}

					{this.form.$("lab").value == "alphamedical" && (
						<React.Fragment>
							<div style={{marginTop: "30px", marginBottom: "10px"}}>
								Alpha medical:
								{/* <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.FormLabel1" /> */}
							</div>
							<Grid container direction="row" align="left" spacing={8}>
								<Grid item xs={6}>
									<XsInput field={this.form.$("aplha_pills")} />
								</Grid>
								<Grid item xs={6}>
									<XsInput field={this.form.$("aplha_importantNote")} />
								</Grid>
							</Grid>

							<Grid container direction="row" align="left" spacing={8}>
								<Grid item xs={2}>
									<XsInput field={this.form.$("aplha_height")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("aplha_weight")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("aplha_mc")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("aplha_diureza")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("aplha_diurezaTime")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("aplha_hebd")} />
								</Grid>
							</Grid>
						</React.Fragment>
					)}

					{this.form.$("lab").value == "medirex" && (
						<React.Fragment>
							<div style={{marginTop: "30px", marginBottom: "10px"}}>
								Medirex:
								{/* <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.FormLabel1" /> */}
							</div>
							<Grid container direction="row" align="left" spacing={8}>
								<Grid item xs={6}>
									<XsInput field={this.form.$("medirex_email")} />
								</Grid>
							</Grid>
						</React.Fragment>
					)}

					{this.form.$("lab").value == "synlab" && (
						<React.Fragment>
							<div style={{marginTop: "30px", marginBottom: "10px"}}>Synlab:</div>
							<Grid container direction="row" align="left" spacing={8}>
								<Grid item xs={2}>
									<XsCheckbox field={this.form.$("synlab_studia")} />
								</Grid>
								<Grid item xs={6}>
									<XsInput field={this.form.$("synlab_studiaDetail")} />
								</Grid>
							</Grid>
							<Grid container direction="row" align="left" spacing={8}>
								<Grid item xs={6}>
									<XsInput field={this.form.$("synlab_department")} />
								</Grid>
								<Grid item xs={6}>
									<XsInput field={this.form.$("synlab_telShort")} />
								</Grid>
							</Grid>
						</React.Fragment>
					)}

					{this.form.$("lab").value == "bpbmed" && (
						<Grid item xs={12}>
							<XsInput
								field={this.form.$("bpb_phone")}
								onChange={(val) => {
									val
										.substring(0, 14)
										.split("")
										.forEach((char, idx) => {
											this.form.$("bpb_phone" + idx).value = char
										})
								}}
							/>
						</Grid>
					)}
				</Grid>
			</div>
		)
	}
}
