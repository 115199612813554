import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "request_CT_IMEA"
				},

				idHospPrip: {
					label: "Číslo hospitalizačného prípadu"
				},

				name: {},
				identifier: {},
				insurerCode: {},

				address: {},
				addressForm: {
					label: <FormattedMessage id="Patient.form.contactForm.address" />
				},

				employment: {
					label: "Zamestnanie"
				},

				doctorname: {},
				orgunitname: {},
				orgunitaddress: {},

				doctorcode: {},
				orgunitcode: {
					label: "Kód odosielajúceho lekára (ambulancie)"
				},

				odporPersonnelExpertiseId: {
					label: "Odporúčajúci lekár"
				},
				odporDoctorId: {},
				odporDoctorOUPZS: {
					label: "Odporúčajúci OUPZS"
				},
				odporACode: {},
				odporPCode: {},
				//
				clinicalDiagnosis: {
					label: "Klinická diagnóza",
					rules: "required"
				},
				diagnosisNameExt: {},
				diagnosisCodeExt: {},
				sendingDoctor: {
					label: "Odosielajúci ústav, oddelenie, meno lekára"
				},
				orgunitcontact: {
					label: "tel. číslo"
				},
				doctorCode: {},
				doctorName: {},
				phone: {},

				examinationDatetime: {
					label: "Termín CT vyšetrenia",
					value: moment()
				},
				examinationDate: {
					value: moment().format("DD.MM.YYYY")
				},
				examinationTime: {
					value: moment().format("HH:mm")
				},

				anamnesis: {
					label: "Anamnéza"
				},
				alergyAnamnesis: {
					label: "Alergická anamnéza"
				},
				epikriza: {
					label: "Terajšie ochorenie - epikríza"
				},
				rtgUsg: {
					label: "Závery predchádzajúcich rtg a USG vyšetrení"
				},
				ctOrgunitName: {
					label: "Pracovisko"
				},
				ctExaminationDate: {
					label: "Dátum vyšetrenia",
					value: moment(),
					type: "date"
				},
				ctExaminationEnd: {
					label: "Záver"
				},
				questionCTExamination: {
					label: "Ktorý orgán má byť CT vyšetrený"
				},
				questionCTExaminationAnswer: {
					label: "Otázka, ktorá má byť CT vyšetrením zodpovedaná"
				},
				date: {
					label: "Dátum",
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
