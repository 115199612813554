import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		// let dgvalue = null

		//Ak máme novú PN predplníme diagnózu z dnešhého dekurzu
		// if (!PatientRecordStore.sicknessAbsID) {
		// 	if (DataStore.patientDTO) {
		// 		const lastRecord = DataStore.patientDTO.get("lastRecord")
		// 		if (lastRecord && moment(lastRecord.created_at).isSame(moment(), "day")) {
		// 			dgvalue = lastRecord.diagnosis
		// 		}
		// 	}
		// }

		return {
			fields: {
				print: {
					value: false,
					rules: "boolean"
				},
				sub_type: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
					value: "DPN",
					rules: "required"
				},
				rel_type: {
					label: <FormattedMessage id="Patient.form.patientsicknessabs.reltype" />
					// rules: "required"
				},
				number: {
					label: <FormattedMessage id="Common.label.number" />,
					rules: "required"
				},
				numberMclaim: {
					label: <FormattedMessage id="Common.label.number" />
				},
				from: {
					label: <FormattedMessage id="Capitation.List.From" />,
					rules: "required",
					type: "date"
				},
				to: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date"
				},
				work_capable_since: {
					label: <FormattedMessage id="Patient.form.patientsicknessabs.workcapablesince" />,
					type: "date"
				},
				childBirth: {
					label: <FormattedMessage id="Patient.form.patientsicknessabs.childBirth" />,
					type: "date"
				},
				maternity_claim_from: {
					label: <FormattedMessage id="Patient.form.patientsicknessabs.maternity_claim_from" />,
					type: "date"
				},
				diagnosis: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />
					// value: dgvalue
				},
				diagnosisCode: {},
				id: {},
				description: {
					label: <FormattedMessage id="Patient.form.patientPrescription.note" />,
					rules: "string"
				},
				deliveriesChipsForm: {
					value: "{}"
				},
				type_disabled: {
					type: "checkbox",
					value: false
				},
				employer_plain: {
					label: <FormattedMessage id="Patient.form.contractForm.contract" />
				}
			}
		}
	}
}
