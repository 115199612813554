//@flow

import {withStyles} from "@material-ui/core/styles"
import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import renderHTML from "react-render-html"
// import XsDropDownButton from "../../../../../global/ui/xsDropDownButton/xsDropDownButton"
import XsMultiButton from "../../../../../global/ui/xsButton/xsMultiButton"
import LabResults from "../labResults/labResults"
import AnamnesisRow from "./anamnesisRow/anamnesisRow"
import ClinicalValueRow from "./clinicalValueRow/clinicalValueRow"
import EHealthRecordDialog from "./eHealthRecordDialog"
import ExternalRow from "./externalRow/externalRow"
import PrescriptionRow from "./prescriptionRow/PrescriptionRow"
import PrintTemplatesRow from "./printTemplatesRow/printTemplatesRow"
import RecordRow from "./recordRow/recordRow"
import RequestRow from "./requestRow/requestRow"
import SicknessAbsRow from "./sicknessAbsRow/sicknessAbsRow"
import OperationRow from "./operationRow/OperationRow"
import "./timeline.less"
import TimelineFilters from "./timelineFilters/timelineFilters"
import TimelineRow from "./timelineRow"
import UIStore from "../../../stores/UIStore"
import config from "../../../../../global/config/settings"
import PrintTemplatesStore from "../../../stores/PrintTemplatesStore"
import moment from "moment"

const styles = () => ({
	actionButton: {
		textAlign: "right",
		paddingTop: "20px",
		width: "100%",
		display: "inline-block"
	}
})

export class Timeline extends React.Component {
	constructor(props) {
		super(props)

		this.state = UIStore.overviewTabCounts
	}
	getBulletClass = (item) => {
		const typeCode = item.type_code
		const values = {
			blueBullet: ["RX", "PSC"],
			redBullet: []
		}

		let bulletClass = Object.keys(values).find((key) => {
			return values[key].includes(typeCode)
		})

		if (item.type_code == "RX") {
			if (isNotEmpty(item.barcode) && item.digital_signature_status == 0) {
				bulletClass = "warningBullet"
			}
			if (isEmpty(item.barcode) && item.digital_signature_status == 0) {
				bulletClass = "redBullet"
			}
			if (isEmpty(item.barcode) && item.digital_signature_status == 1) {
				bulletClass = "redBullet"
			}
			if (isNotEmpty(item.barcode) && item.digital_signature_status == 1) {
				bulletClass = "greenBullet"
			}
			if (item.digital_signature_status == 2) {
				bulletClass = "greyBullet"
			}
		}

		if ((item.type_code == "DEK" || item.type_code == "PN" || item.type_code == "OPP") && item.has_deliveries) {
			bulletClass = "greyBullet pointer "
		}

		if (
			item.type_code == "PN" &&
			isSafe(item.sickness_abs) &&
			isSafe(item.sickness_abs.validity) &&
			isSafe(item.sickness_abs.validity.to) &&
			moment(item.sickness_abs.validity.to)
				.add(-1, "days")
				.isBefore(moment())
		) {
			if (item.has_deliveries) {
				bulletClass = "greenBullet"
			} else {
				bulletClass = "bullet bgGreenSuccess"
			}
		}

		if (item.version === "draft") {
			bulletClass = "bullet draft"
		}

		return bulletClass ? bulletClass : "bullet"
	}

	getBulletTooltip = (item) => {
		let tooltip = ""

		if (item.version == "draft") {
			tooltip = "Common.label.unsaveRecord"
		}

		if (item.type_code == "DEK" && item.has_deliveries) {
			tooltip = "Common.label.performenceScoredInDekurz"
		}

		if (item.type_code == "PN" && item.has_deliveries) {
			tooltip = "Common.label.performenceScoredInPN"
		}

		if (item.type_code == "RX") {
			if (isEmpty(item.barcode) && item.digital_signature_status == 0) {
				tooltip = "Common.label.receptNotSigned"
			}
			if (isEmpty(item.barcode) && item.digital_signature_status == 1) {
				tooltip = "Common.label.receptNotSend"
			}
			if (isNotEmpty(item.barcode) && item.digital_signature_status == 0) {
				tooltip = "Common.label.receptHasBarcodeButNotSigned"
			}
			if (isNotEmpty(item.barcode) && item.digital_signature_status == 1) {
				tooltip = "Common.label.receptSigned"
			}
			if (item.digital_signature_status == 2) {
				tooltip = "Common.label.receptInProgress"
			}

			if (item.version == "draft") {
				tooltip = "Common.label.unsavePrescription"
			}
		}

		return tooltip
	}

	getBulletContent = (item) => {
		const values = {
			Rx: ["RX", "PSC"]
		}

		let bulletContent = null

		bulletContent = Object.keys(values).find((key) => {
			return values[key].includes(item.type_code)
		})

		if ((item.type_code == "DEK" || item.type_code == "PN" || item.type_code == "OPP") && item.has_deliveries) {
			bulletContent = (
				<i
					className="fal fa-check fa-lg pa-2"
					onClick={() => {
						item.loadDeliveries = true
						item.forceUpdate = (isEmpty(item.forceUpdate) ? 0 : item.forceUpdate) + 1
						this.forceUpdate()
					}}
				></i>
			)
		}

		return bulletContent ? bulletContent : ""
	}

	renderComponent = (item) => {
		let component = null
		let barcode = item.barcode

		if (barcode) {
			barcode = barcode.replace(")", "")
			barcode = barcode.replace("(", "")
		}

		//Vlozi do objektu index riadku aby sa nasledne dali data zoradit EMA-14362
		if (
			isSafe(item) &&
			isSafe(item.type_code) &&
			item.type_code == "LAB" &&
			isSafe(item.data) &&
			isSafe(item.data.rows)
		) {
			item.data.rows.forEach((row, index) => {
				row.indexNumber = index
			})
		}

		switch (item.type_code) {
			case "LAB":
				component = <LabResults results={item.data.rows} />
				break
			case "ANA":
				component = <AnamnesisRow item={item} /> //<div>{item.data.map(i=>{return renderHTML(i.content)})}</div>
				break
			case "KV":
				component = <ClinicalValueRow data={item.clinical_values} />
				break
			case "PN":
				component = <SicknessAbsRow item={item} loadDeliveries={item.loadDeliveries} forceUpdate={item.forceUpdate} />
				break
			// case "SCR":
			// 	component = <ScoringRow item={item} />
			// 	break
			case "NZIS":
				component = <ExternalRow item={item} />
				break
			case "OV":
			case "ZV":
				component = (
					<RequestRow
						summaries={item.data ? item.data : []}
						expertiseCode={item.request.receiving_expertise_code_ext}
						expertiseName={item.request.receiving_expertise_name_ext}
						attachments={item.attachments}
						request={item}
						barCode={
							item.external && item.external.request_extension ? item.external.request_extension : item.ehealth_id
						}
					/>
				)
				break
			case "RX":
				component = (
					<PrescriptionRow
						supplement={renderHTML(item.prescription.supplement)}
						diagnosisCode={item.diagnosis_code_ext}
						diagnosisName={item.diagnosis_name_ext}
						atcCode={item.prescription.atc_code_ext}
						atcName={item.prescription.atc_name_ext}
						productCode={item.prescription.product_code}
						sendingDoctorName={item.prescription.sending_doctor_name}
						substitutingDoctorCard={item.prescription.substituting_doctor_card}
						note={item.prescription.note}
						repeatsNumber={item.prescription.repeats_number}
						repeatsType={item.prescription.repeats_type_name_ext}
						validityTo={item.prescription.validity ? item.prescription.validity.to : null}
						barcode={barcode}
						lifeThreatening={
							isSafe(item.record_diagnoses) &&
							item.record_diagnoses.length > 0 &&
							isSafe(item.record_diagnoses[0].life_threatening) &&
							item.record_diagnoses[0].life_threatening
								? true
								: false
						}
					/>
				)
				break
			case "DEK":
				component = (
					<RecordRow
						summary={item.data ? item.data : ""}
						diagnosisCode={item.diagnosis_code_ext}
						diagnosisName={item.diagnosis_name_ext}
						sendingDoctor={item.sending_doctor}
						substitutingDoctor={item.substituting_doctor}
						clinicalValues={item.clinical_values}
						attachments={item.attachments}
						loadDeliveries={item.loadDeliveries}
						recordId={item.record_id}
						forceUpdate={item.forceUpdate}
						lifeThreatening={
							isSafe(item.record_diagnoses) &&
							item.record_diagnoses.length > 0 &&
							isSafe(item.record_diagnoses[0].life_threatening) &&
							item.record_diagnoses[0].life_threatening
								? true
								: false
						}
					/>
				)
				break
			case "POD":
				component = (
					<PrescriptionRow
						attachments={item.attachments}
						supplement={renderHTML(item.prescription.supplement)}
						medication={item.medication}
						diagnosisCode={item.diagnosis_code_ext}
						diagnosisName={item.diagnosis_name_ext}
						atcCode={item.prescription.atc_code_ext}
						atcName={item.prescription.atc_name_ext}
						productCode={item.prescription.product_code}
						sendingDoctorName={item.prescription.sending_doctor_name}
						substitutingDoctorCard={item.prescription.substituting_doctor_card}
						note={item.prescription.note}
						repeatsNumber={item.prescription.repeats_number}
						repeatsType={item.prescription.repeats_type_name_ext}
						validityTo={item.prescription.validity ? item.prescription.validity.to : null}
						barcode={barcode}
						lifeThreatening={
							isSafe(item.record_diagnoses) &&
							item.record_diagnoses.length > 0 &&
							isSafe(item.record_diagnoses[0].life_threatening) &&
							item.record_diagnoses[0].life_threatening
								? true
								: false
						}
						barcodeFormat="CODE128"
					/>
				)
				break
			case "REP":
				component = <PrintTemplatesRow item={item} />
				break
			case "OPP":
				component = <OperationRow item={item} loadDeliveries={item.loadDeliveries} forceUpdate={item.forceUpdate}/>
				break
			default:
				component = <div dangerouslySetInnerHTML={{__html: item.data ? item.data.content : ""}} />
		}

		return component
	}

	getTitleClass = (item) => {
		let retVal = ""

		if (item.version === "draft" || (item.external && item.external.version === "draft")) {
			retVal = "draft"
		}

		return retVal
	}

	getReportTitle = (item) => {
		let templateName = ""
		let content

		try {
			content = base64DecodeUnicode(item.data[0].content)
		} catch (e) {
			logger("PrintTemplatesRow base64DecodeUnicode content error", e)
		}

		try {
			let contentJSON = JSON.parse(content)
			if (contentJSON.templateId == "lab_Request") {
				templateName =
					"Laboratórna žiadanka " + PrintTemplatesStore.labTemplates.find((t) => t.id == contentJSON.lab).label
			} else if (contentJSON.templateId == "medical_opinion_4versions") {
				templateName = `${this.props.intl.formatMessage({id: "PrintTemplate.MedicalOpinion.Versions.SubTitle"})} - ${
					PrintTemplatesStore.medicalOpinionVersionTemplates.find((t) => t.id == contentJSON.version).label
				}`
			} else if (contentJSON.templateId == "header" && isNotEmpty(contentJSON.customTemplateName)) {
				templateName = contentJSON.customTemplateName
			} else {
				templateName = PrintTemplatesStore.getTemplateName(contentJSON.templateId)
			}
		} catch (e) {
			// logger("PrintTemplatesRow parsing content error", e)
		}

		return templateName
	}

	render() {
		const {dateFormat = "MMMM DD, YYYY", items, timeFilterValues, hideToday, actionButton} = this.props
		const {getBulletClass, getBulletContent, renderComponent} = this
		// const pkgText = " " + this.props.intl.formatMessage({ id: "Patient.form.patientPrescription.pkg" });
		const pagerText = this.props.intl.formatMessage(
			{id: "Common.label.overiewNextRecords"},
			{
				count: Math.min(
					config.OVERVIEW_PAGE_SIZE,
					items.length - (this.state[UIStore.overviewTab] || config.OVERVIEW_PAGE_SIZE)
				)
			}
		)
		return (
			<div className="timeLine">
				{
					<TimelineFilters
						timeFilterValues={timeFilterValues.items}
						reloadOverview={this.props.reloadOverview}
						actions={this.props.actions}
					/>
				}
				{actionButton && (
					<div className={this.props.classes.actionButton}>
						{/* <XsDropDownButton buttons={actionButton} /> */}
						<XsMultiButton className="xs-outline xs-info" buttons={actionButton} />
					</div>
				)}
				<div className="content">
					{
						<div>
							{!hideToday && (
								<TimelineRow
									date={new Date()}
									hideTime={true}
									dateFormat={dateFormat}
									subTitle={<FormattedMessage id="Common.label.today" defaultMessage="Today" />}
								/>
							)}
							{items.slice(0, this.state[UIStore.overviewTab] || config.OVERVIEW_PAGE_SIZE).map((item, index) => {
								const bulletClass = getBulletClass(item)
								const bulletContent = getBulletContent(item)
								const bulletTooltip = this.getBulletTooltip(item)

								return (
									<TimelineRow
										key={index}
										EHR={item}
										date={item.created_at}
										dateFormat={dateFormat}
										bulletClass={bulletClass}
										bulletContent={bulletContent}
										bulletTooltip={bulletTooltip}
										// title={renderHTML(item.name + (item.type_code === "RX" ? pkgText : ""))}
										title={
											item.type_code === "REP"
												? this.getReportTitle(item)
												: htmlToPlain(item.name /*+ (item.type_code === "RX" ? pkgText : "")*/).replace(/\/ *\//g, "/")
										}
										titleClass={this.getTitleClass(item)}
										// lifeThreatening={isLifeThreatening}
										subTitle={item.description}
										actions={item.actions}
										attachments={item.attachments}
									>
										{renderComponent(item)}
									</TimelineRow>
								)
							})}
							{items.length > (this.state[UIStore.overviewTab] || config.OVERVIEW_PAGE_SIZE) && (
								<div
									className="pager"
									align="center"
									onClick={() => {
										const newState = Object.assign({}, this.state, {
											[UIStore.overviewTab]:
												(this.state[UIStore.overviewTab] || config.OVERVIEW_PAGE_SIZE) + config.OVERVIEW_PAGE_SIZE
										})
										UIStore.overviewTabCounts = newState
										this.setState(newState)
									}}
								>
									{pagerText}
								</div>
							)}
						</div>
					}
				</div>
				<EHealthRecordDialog />
			</div>
		)
	}
}

export default withStyles(styles)(injectIntl(Timeline))
