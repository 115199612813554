import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import {withStyles} from "@material-ui/core/styles"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RouterStore from "../../../../../global/store/RouterStore"
import OverviewContainer from "./overviewContainer"
import PatientRecordForm from "../patientRecordForm/patientRecordForm"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import PatientAnamnesisForm from "../patientAnamnesisForm/patientAnamnesisForm"
import PatientAnamnesisStore from "../../../stores/PatientAnamnesisStore"
import PatientOperationForm from "../patientOperationForm/patientOperationForm"
import PatientRequestContainer from "../patientRequestForm/patientRequestContainer"
import PatientRequestStore from "../../../stores/PatientRequestStore"
import PatientSicknessAbsForm from "../patientSicknessAbsForm/patientSicknessAbsForm"
import ProblemsContainer from "../problems/problemsContainer"
import PatientPrescriptionFormContainer from "../patientPrescriptionForm/patientPrescriptionContainer"
import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
import UIStore from "../../../stores/UIStore"
import DataStore from "../../../stores/DataStore"
import PatientMedicationContainer from "../patientMedicationForm/patientMedicationContainer"
import PatientMedicationStore from "../../../stores/PatientMedicationStore"
import PatientSicknessAbsStore from "../../../stores/PatientSicknessAbsStore"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import PatientCardContainerStore from "../../../stores/PatientCardContainerStore"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {Tooltip} from "@material-ui/core"

import PrescriptionFields from "../patientPrescriptionForm/patientPrescriptionFields"

import Recordfields from "../patientRecordForm/patientRecordFields"
import RecordHooks from "../patientRecordForm/patientRecordFormHooks"

import AnamnesisFields from "../patientAnamnesisForm/patientAnamnesisFields"
import AnamnesisHooks from "../patientAnamnesisForm/PatientAnamnesisHooks"

import RequestFields from "../patientRequestForm/patientRequestFields"

import Sickenssfields from "../patientSicknessAbsForm/patientSicknessAbsFields"
import SicknessHooks from "../patientSicknessAbsForm/PatientSicknessAbsHooks"

import OperationFields from "../patientOperationForm/patientOperationFields"
import OperationHooks from "../patientOperationForm/patientOperationHooks"
import PatientOperationStore from "../../../stores/PatientOperationStore"

import MedicationFields from "../patientMedicationForm/patientMedicationFields"

// import bindings from "../../../../../global/ui/globalUISchemeBindings"
import {
	getUserDoctorInfo,
	getKeyInfo,
	useInterval,
	useMergeState,
	getSelectedOrgUnitID
} from "../../../../../global/helpers/actions"
import StorageEx from "../../../../../global/storage/storageEx"
import moment from "moment"
import WarningStore from "../../../../../global/store/WarningStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import ScoringList from "../scoring/scoringList"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import RecordRePrintDialog from "../patientRecordForm/RecordRePrintDialog"
import api from "../../../actions/api"
import {getFilters} from "../../../../../global/helpers/api"
import {professionalActivityToMovement} from "../../../../../global/helpers/functions"
import PrescriptionTemplateDialog from "./prescriptionTemplateDialog"
import PrescriptionTemplateDialogStore from "../../../stores/PrescriptionTemplateDialogStore"

const styles = () => ({
	warningBadge: {
		color: "white",
		position: "absolute",
		right: "2px",
		top: "2px",
		backgroundColor: "#FFC107",
		width: "20px",
		borderRadius: "30px"
	}
})

function PatientCardContainer(props) {
	const clearStorage = (formType, stayOpen = false, askQuestion = false) => {
		const storageKey = getKeyInfo(formType)

		const handler = () => {
			StorageEx.deleteByKey(storageKey)

			if (stayOpen === false) {
				setState({[formType]: {show: false}})
				state[formType].controlRender(Date.now())
				UIStore.patientCardTab = ""
			}
		}
		if (askQuestion === true && StorageEx.exists(storageKey)) {
			GlobalStore.openConfirmationDialog("patientCardAction", {
				onConfirm: handler,
				text: "Common.label.cancelForm"
			})
		} else {
			handler()
		}
	}

	const deleteOnlyFromStorage = (formType) => {
		StorageEx.deleteByKey(getKeyInfo(formType))
	}

	const handleActionDialog = async (formType, handler, onClose) => {
		if (StorageEx.exists(getKeyInfo(formType))) {
			GlobalStore.openConfirmationDialog("patientCardAction", {
				onConfirm: handler,
				onClose: onClose,
				text: "Common.label.overwriteFrom"
			})
		} else {
			await handler()
		}
	}

	const checkRecordForToday = () => {
		if (!StorageEx.exists(getKeyInfo("RECORD"))) {
			PatientRecordStore.checkRecordForToday(() => {
				GlobalStore.openConfirmationDialog("multipleRecordsForToday")
			})
		}
	}

	const recordHooks = RecordHooks.load(props, clearStorage)
	const anamnesisHooks = AnamnesisHooks.load(props, clearStorage)
	const sicknessHooks = SicknessHooks.load(props, clearStorage)
	const operationHooks = OperationHooks.load(props, clearStorage)
	const emptyHooks = {
		onSubmit() {},
		onSuccess() {},
		onError() {}
	}

	const [renderPrescription, setRenderPrescription] = useState(null)
	const [renderRecord, setRenderRecord] = useState(null)
	const [renderAnamnesis, setRenderAnamnesis] = useState(null)
	const [renderOperation, setRenderOperation] = useState(null)
	const [renderSickness, setRenderSickness] = useState(null)
	const [renderMedication, setRenderMedication] = useState(null)
	const [renderRequest, setRenderRequest] = useState(null)

	const getCreateFormFunction = (formType) => {
		switch (formType) {
			case "PRESCRIPTION":
				return () => new MobxReactForm(PrescriptionFields.load(), {plugins: {dvr: validatorjs}, hooks: emptyHooks})
			case "REQUEST":
				return () => new MobxReactForm(RequestFields.load(), {plugins: {dvr: validatorjs}, hooks: emptyHooks})
			case "MEDICATION":
				return () => new MobxReactForm(MedicationFields.load(), {plugins: {dvr: validatorjs}, hooks: emptyHooks})
			default:
				console.warn("ZLY FORMTYPE")
		}
	}

	const [state, setState] = useMergeState({
		PRESCRIPTION: {
			multiforms: true,
			show: false,
			forms: {1: getCreateFormFunction("PRESCRIPTION")()},
			lastData: null,
			controlRender: setRenderPrescription
		},
		RECORD: {
			show: false,
			form: new MobxReactForm(Recordfields.load(), {plugins: {dvr: validatorjs}, hooks: recordHooks}),
			lastData: null,
			controlRender: setRenderRecord
		},
		ANAMNESIS: {
			show: false,
			form: new MobxReactForm(AnamnesisFields.load(), {plugins: {dvr: validatorjs}, hooks: anamnesisHooks}),
			lastData: null,
			controlRender: setRenderAnamnesis
		},
		REQUEST: {
			multiforms: true,
			show: false,
			forms: {1: getCreateFormFunction("REQUEST")()},
			lastData: null,
			controlRender: setRenderRequest
		},
		SICKNESS: {
			show: false,
			form: new MobxReactForm(Sickenssfields.load(), {plugins: {dvr: validatorjs}, hooks: sicknessHooks}),
			lastData: null,
			controlRender: setRenderSickness
		},
		OPERATION: {
			show: false,
			form: new MobxReactForm(OperationFields.load(), {plugins: {dvr: validatorjs}, hooks: operationHooks}),
			lastData: null,
			controlRender: setRenderOperation
		},
		MEDICATION: {
			multiforms: true,
			show: false,
			forms: {1: getCreateFormFunction("MEDICATION")()},
			lastData: null,
			controlRender: setRenderMedication
		}
	})

	// const record = 5 //state.RECORD.show
	// const operation = 4 //state.OPERATION.show

	useInterval(() => {
		for (const formType of Object.keys(state)) {
			const formTypeObj = state[formType]
			if (formTypeObj.show) {
				const storageKey = getKeyInfo(formType)

				let changeOccured = false

				if (formTypeObj.multiforms === true) {
					const lastDataObj = JSON.parse(formTypeObj.lastData)
					const actualDataObj = formTypeObj.forms
					const actualCount = Object.keys(actualDataObj).length
					const lastCount = Object.keys(lastDataObj).length
					changeOccured = actualCount !== lastCount
					if (!changeOccured) {
						changeOccured = Object.keys(actualDataObj).some((key) => {
							const actualFormData = JSON.stringify(actualDataObj[key].values())
							const lastFormData = JSON.stringify(lastDataObj[key])
							if (lastFormData !== actualFormData) {
								try {
									logger("LAST -> NEW", objectChanges(JSON.parse(lastFormData), JSON.parse(actualFormData)))
									logger("LAST -> NEW", JSON.parse(lastFormData), JSON.parse(actualFormData))
								} catch (e) {
									logger("cant parse")
								}
							}
							return lastFormData !== actualFormData
						})
					}
				} else {
					if (formTypeObj.form) {
						const actualFormData = JSON.stringify(formTypeObj.form.values())
						changeOccured = formTypeObj.lastData !== actualFormData
						if (changeOccured) {
							try {
								logger("LAST -> NEW", objectChanges(JSON.parse(formTypeObj.lastData), JSON.parse(actualFormData)))
							} catch (e) {
								logger("cant parse")
							}
						}
					} else {
						changeOccured = false
					}
				}

				if (changeOccured) {
					let dataForSave = null
					if (formTypeObj.multiforms === true) {
						let objForSave = {}
						Object.keys(formTypeObj.forms).forEach((key) => {
							objForSave[key] = formTypeObj.forms[key].values()
						})
						dataForSave = JSON.stringify(objForSave)
					} else {
						dataForSave = JSON.stringify(formTypeObj.form.values())
					}
					if (Object.keys(storageKey).every((x) => isSafe(storageKey[x]))) {
						StorageEx.updateByKey(storageKey, dataForSave)
					} else {
						console.error("Key is not valid (saving to storage is stopped)!", storageKey)
					}
					setState({[formType]: {lastData: dataForSave}})
				}
			}
		}
	}, 200)

	//PRESCRIPTION / recept lieky
	useEffect(() => {
		const storageData = StorageEx.getByKey(getKeyInfo("PRESCRIPTION"))
		const codeList = [
			"medicationSubTypes",
			"medicationDosageTypes",
			"medicationRoutes",
			"medicationRepeats",
			"units",
			"IVLs",
			"medicationDoseUnits",
			"medicationRoutesPrescription"
		]

		const loadPrescriptionFromStorage = async () => {
			await GlobalStore.refreshCodeListsSync(codeList)
			await DataStore.loadTemplatesSync("RINDIVID")
			const storageDataObj = JSON.parse(storageData)
			state.PRESCRIPTION.forms = {}
			Object.keys(storageDataObj).forEach((key) => {
				const formObj = getCreateFormFunction("PRESCRIPTION")()
				formObj.set(storageDataObj[key])
				state.PRESCRIPTION.forms[key] = formObj
			})
			setState({PRESCRIPTION: {show: true, lastData: storageData}})
			GlobalStore.increaseForms()
		}

		const loadPrescriptionDefs = async () => {
			await GlobalStore.refreshCodeListsSync(codeList)
			await DataStore.loadTemplatesSync("RINDIVID")
			// najprv clear formu
			state.PRESCRIPTION.forms = {1: getCreateFormFunction("PRESCRIPTION")()}
			await PatientPrescriptionStore.loadFormWithDefs(state.PRESCRIPTION.forms[1], props)
			await PatientPrescriptionStore.checkIfNonPayer(state.PRESCRIPTION.forms[1])
			setState({PRESCRIPTION: {show: true, lastData: JSON.stringify({1: state.PRESCRIPTION.forms[1].values()})}})
			GlobalStore.increaseForms()
		}

		if (storageData) {
			loadPrescriptionFromStorage()
		} else {
			loadPrescriptionDefs()
		}
	}, [renderPrescription])

	//DEKURZ/RECORD
	useEffect(() => {
		const codeListNames = [
			"units",
			"recordMeasures",
			"diagnoseTypes",
			"generalSubItemType",
			"insureeType",
			"refundType",
			"senderType",
			"priorities",
			"confidentialityLevel",
			"deliveryItem",
			"payerType",
			"deliveryExtension"
		]

		async function loadRecordDefs() {
			// najprv clear formu
			state.RECORD.form.reset()
			await GlobalStore.refreshCodeListsSync(codeListNames)
			await DataStore.loadTemplatesSync(["AMB", "OF", "Recomm", "DC", "IEXAM"], "RECORD")
			PatientRecordStore.providedServicesSearchValue = ""
			PatientRecordStore.defaultSendingType = ""
			PatientRecordStore.dropedFiles = []
			//Defaultne prazdny riadok prvej diagnozy
			state.RECORD.form.$("diagnosisChipsForm").value = JSON.stringify([
				{col1: "", col1NameExt: "", col2: "PRIMARY", col3: "", col4: false}
			])
			if (PatientRecordStore.masterRecord && !PatientRecordStore.readonlyform) {
				let diagnosisFromMaster = [
					{
						col1: PatientRecordStore.masterRecord.diagnosis,
						col1NameExt: "",
						col2: "PRIMARY",
						col3: "",
						col4: ""
					}
				]
				state.RECORD.form.$("diagnosisChipsForm").value = JSON.stringify(diagnosisFromMaster)
			}
			if (!PatientRecordStore.readonlyform) {
				PatientRecordStore.insertServiceNameToForm(
					state.RECORD.form,
					RouterStore.location.search.replace(/([^0-9])+/, "")
				)
			}

			if (!PatientRecordStore.readonlyform) {
				let doctor = getUserDoctorInfo()
				if (isSafe(doctor.expertise)) {
					//ak  ma  lekar  jeden  z  kodov  tak  citlivost  v  dekurze  je  vysoka  EMA-8505
					if (["144", "005", "074", "105", "223", "352", "073", "367"].includes(doctor.expertise.code_ext)) {
						state.RECORD.form.$("confidentialityLevel").value = "high"
						state.RECORD.form.$("confidentialityLevelDisable").value = true
					}
				}
				await PatientRecordStore.loadLastPrescriptionSendingDoctor(state.RECORD.form)
				await PatientRecordStore.loadProvidedServices(
					state.RECORD.form,
					RouterStore.location.search.replace(/([^0-9])+/, "")
				)
			}

			setState({RECORD: {show: true, lastData: JSON.stringify(state.RECORD.form.values())}})
			PatientRecordStore.getServicesForPatient()
			GlobalStore.increaseForms()
		}

		const loadRecordFromStorage = async () => {
			await GlobalStore.refreshCodeListsSync(codeListNames)
			await DataStore.loadTemplatesSync(["AMB", "OF", "Recomm", "DC"], "RECORD")
			state.RECORD.form.set(JSON.parse(storageData))
			setState({RECORD: {show: true, lastData: JSON.stringify(state.RECORD.form.values())}})
			PatientRecordStore.getServicesForPatient()
			GlobalStore.increaseForms()
		}

		const storageData = StorageEx.getByKey(getKeyInfo("RECORD"))
		if (storageData) {
			loadRecordFromStorage()
		} else {
			loadRecordDefs()
		}
	}, [renderRecord])

	//ANAMNESIS / Anamneza
	useEffect(() => {
		async function loadAnamnesisDefs() {
			// najprv clear formu
			state.ANAMNESIS.form.reset()
			await DataStore.loadTemplatesSync("ANA")
			await PatientAnamnesisStore.loadAnamData(state.ANAMNESIS.form)
			setState({ANAMNESIS: {show: true, lastData: JSON.stringify(state.ANAMNESIS.form.values())}})
			GlobalStore.increaseForms()
		}

		const loadAnamnesisFromStorage = async () => {
			await DataStore.loadTemplatesSync("ANA")
			state.ANAMNESIS.form.set(JSON.parse(storageData))
			setState({ANAMNESIS: {show: true, lastData: JSON.stringify(state.ANAMNESIS.form.values())}})
			GlobalStore.increaseForms()
		}

		const storageData = StorageEx.getByKey(getKeyInfo("ANAMNESIS"))
		if (storageData) {
			loadAnamnesisFromStorage()
		} else {
			loadAnamnesisDefs()
		}
	}, [renderAnamnesis])

	//SICKNESS / Soc.poist
	useEffect(() => {
		async function loadSicknessDefs() {
			// najprv clear formu
			state.SICKNESS.form.reset()
			await GlobalStore.refreshCodeListsSync(["sicknessAbsenceTypes", "relationshipType", "deliveryExtension"])
			await PatientSicknessAbsStore.getNumber(state.SICKNESS.form, props.intl)
			if (!PatientSicknessAbsStore.sicknessAbsID) {
				// Ak máme novú PN predplníme diagnózu z dnešhého dekurzu
				if (DataStore.patientDTO) {
					const lastRecord = DataStore.patientDTO.get("lastRecord")
					if (lastRecord && moment(lastRecord.created_at).isSame(moment(), "day")) {
						state.SICKNESS.form.$("diagnosis").set("value", lastRecord.diagnosis)
						PatientSicknessAbsStore.set("sicknessAbsLoaded", true)
					}
				}
			}

			PatientSicknessAbsStore.deliveriesChipsInit(state.SICKNESS.form)
			setState({SICKNESS: {show: true, lastData: JSON.stringify(state.SICKNESS.form.values())}})
			GlobalStore.increaseForms()
		}

		async function loadSicknessFromStorage() {
			await GlobalStore.refreshCodeListsSync(["sicknessAbsenceTypes", "relationshipType"])
			state.SICKNESS.form.set(JSON.parse(storageData))
			setState({SICKNESS: {show: true, lastData: JSON.stringify(state.SICKNESS.form.values())}})
			GlobalStore.increaseForms()
		}

		const storageData = StorageEx.getByKey(getKeyInfo("SICKNESS"))
		if (storageData) {
			loadSicknessFromStorage()
		} else {
			loadSicknessDefs()
		}
	}, [renderSickness])

	//OPERATION / Op.protokol
	useEffect(() => {
		const storageData = StorageEx.getByKey(getKeyInfo("OPERATION"))

		const loadOperationFromStorage = async () => {
			PatientOperationStore.loadOperationData()
			await DataStore.loadTemplatesSync("OP")
			state.OPERATION.form.set(JSON.parse(storageData))
			setState({OPERATION: {show: true, lastData: JSON.stringify(state.OPERATION.form.values())}})
			GlobalStore.increaseForms()
		}

		const loadOperationDefs = async () => {
			// najprv clear formu
			PatientOperationStore.loadOperationData()
			state.OPERATION.form.reset()
			PatientOperationStore.providedServicesSearchValue = ""
			PatientOperationStore.defaultSendingType = ""
			await PatientOperationStore.loadDefaultDelivaries(state.OPERATION.form)
			await DataStore.loadTemplatesSync("OP")
			state.OPERATION.form.$("examdate").set(moment())
			state.OPERATION.form.$("diagnosisChipsForm").value = JSON.stringify([
				{col1: "", col1NameExt: "", col2: "PRIMARY", col3: "", col4: false}
			])
			setState({OPERATION: {show: true, lastData: JSON.stringify(state.OPERATION.form.values())}})
			GlobalStore.increaseForms()
		}

		if (storageData) {
			loadOperationFromStorage()
		} else {
			loadOperationDefs()
		}
	}, [renderOperation])

	//MEDICATION / Medikacia
	useEffect(() => {
		const storageData = StorageEx.getByKey(getKeyInfo("MEDICATION"))
		const codeListNames = [
			"medicationSubTypes",
			"medicationRoutes",
			"medicationDoseUnits",
			"vaccinationTypes",
			"patientLocations",
			"medicationRoutesMedication"
		]

		const loadMedicationFromStorage = async () => {
			await GlobalStore.refreshCodeListsSync(codeListNames)
			// await DataStore.loadTemplatesSync("OP")
			const storageDataObj = JSON.parse(storageData)
			state.MEDICATION.forms = {}
			Object.keys(storageDataObj).forEach((key) => {
				const formObj = getCreateFormFunction("MEDICATION")()
				formObj.set(storageDataObj[key])
				state.MEDICATION.forms[key] = formObj
			})
			setState({MEDICATION: {show: true, lastData: storageData}})
			GlobalStore.increaseForms()
		}

		const loadMedicationDefs = async () => {
			await GlobalStore.refreshCodeListsSync(codeListNames)
			// najprv clear formu
			state.MEDICATION.forms = {1: getCreateFormFunction("MEDICATION")()}
			//state.MEDICATION.forms[1].reset()
			await PatientMedicationStore.loadDefaultDiagnosis(state.MEDICATION.forms[1])
			setState({MEDICATION: {show: true, lastData: JSON.stringify({1: state.MEDICATION.forms[1].values()})}})
			GlobalStore.increaseForms()
		}

		if (storageData) {
			loadMedicationFromStorage()
		} else {
			loadMedicationDefs()
		}
	}, [renderMedication])

	//REQUEST / ziadanka
	useEffect(() => {
		const storageData = StorageEx.getByKey(getKeyInfo("REQUEST"))

		const loadRequestFromStorage = async () => {
			await GlobalStore.refreshCodeListsSync(["diagnoseTypes", "priorities", "professionalActivity"])
			await DataStore.loadTemplatesSync("REQ")
			const storageDataObj = JSON.parse(storageData)
			state.REQUEST.forms = {}
			Object.keys(storageDataObj).forEach((key) => {
				const formObj = getCreateFormFunction("REQUEST")()
				formObj.set(storageDataObj[key])
				state.REQUEST.forms[key] = formObj
			})
			setState({REQUEST: {show: true, lastData: storageData}})
			GlobalStore.increaseForms()
		}

		const loadRequestDefs = async () => {
			await GlobalStore.refreshCodeListsSync(["diagnoseTypes", "priorities", "professionalActivity"])
			await DataStore.loadTemplatesSync("REQ")
			// najprv clear formu
			state.REQUEST.forms = {1: getCreateFormFunction("REQUEST")()}
			PatientRequestStore.selectedEHR = []
			PatientRecordStore.record = null
			await PatientRequestStore.loadDefaultDiagnosis(state.REQUEST.forms[1])
			//FIXME zistit logiku pretoze teraz to nemoze fungovat
			// if (PatientRecordStore.record.diagnosis) {
			// 	state.REQUEST.forms[1].$("diagnosis").value = PatientRecordStore.record.diagnosis
			// }

			PatientRequestStore.setProfessionalActivity(state.REQUEST.forms[1])

			setState({REQUEST: {show: true, lastData: JSON.stringify({1: state.REQUEST.forms[1].values()})}})
			GlobalStore.increaseForms()
		}

		if (storageData) {
			loadRequestFromStorage()
		} else {
			loadRequestDefs()
		}
	}, [renderRequest])

	// FLAGS pre viacnasobne kliknutia
	let prescriptionActionFlag = false
	let recordActionFlag = false
	let sicknessActionFlag = false
	let anamnesisActionFlag = false
	let operationActionFlag = false
	let requestActionFlag = false
	let medicationActionFlag = false

	const overviewActions = {
		prescription: {
			represcribe: async (ids) => {
				const handler = async () => {
					UIStore.patientCardTab = ""
					setState({PRESCRIPTION: {show: false}})
					//dostanem Array objektov z ktorych cez mam ziskam array idciek a z toho cez Set vyberiem iba unique id
					const uniqueIds = [...new Set(ids.map((obj) => obj.record_id))]
					state.PRESCRIPTION.forms = {}
					for (let index = 0; index < uniqueIds.length; index++) {
						const id = uniqueIds[index]
						state.PRESCRIPTION.forms[index] = getCreateFormFunction("PRESCRIPTION")()
						await PatientPrescriptionStore.loadFormData(state.PRESCRIPTION.forms[index], id, props)
					}

					let objForSave = {}
					Object.keys(state.PRESCRIPTION.forms).forEach((key) => {
						objForSave[key] = state.PRESCRIPTION.forms[key].values()
					})
					setState({PRESCRIPTION: {show: true, lastData: JSON.stringify(objForSave)}})
					DataStore.clearCheckedEHR()
					UIStore.patientCardTab = "prescription"
					PatientPrescriptionStore.checkRepetitur(state.PRESCRIPTION.forms, props.intl)
					prescriptionActionFlag = false
				}

				const closeDialog = () => {
					prescriptionActionFlag = false
				}

				if (!prescriptionActionFlag) {
					prescriptionActionFlag = true
					await handleActionDialog("PRESCRIPTION", handler, closeDialog)
				}
			}
		},
		record: {
			reexamination: async (record) => {
				const id = record.record_id

				const checkForDeliveries = async () => {
					setTimeout(async () => {
						if (record.has_deliveries) {
							GlobalStore.openConfirmationDialog("loadRecordWithDeliveries", {
								onConfirm: reexaminationHandler,
								text: "Common.label.loadRecordWithDeliveries"
							})
						} else {
							await reexaminationHandler(false)
						}
					}, 0)
				}

				const reexaminationHandler = async (forceLoadDeliveries) => {
					UIStore.patientCardTab = ""
					setState({RECORD: {show: false}})
					state.RECORD.form.$("recordId").value = id
					await PatientRecordStore.insertFormData(state.RECORD.form, forceLoadDeliveries)
					if (!forceLoadDeliveries) {
						await PatientRecordStore.loadProvidedServices(
							state.RECORD.form,
							RouterStore.location.search.replace(/([^0-9])+/, ""),
							true
						)
					}
					StorageEx.deleteByKey(getKeyInfo("RECORD"))
					setState({RECORD: {show: true, lastData: JSON.stringify(state.RECORD.form.values())}})
					DataStore.clearCheckedEHR()
					UIStore.patientCardTab = "record"
					recordActionFlag = false
				}

				const closeDialog = () => {
					recordActionFlag = false
				}

				if (!recordActionFlag) {
					recordActionFlag = true
					await handleActionDialog("RECORD", checkForDeliveries, closeDialog)
				}
			},
			editdraft: async (id) => {
				const editdraftHandler = async () => {
					// console.time("edit") //eslint-disable-line
					UIStore.patientCardTab = ""
					setState({RECORD: {show: false}})
					state.RECORD.form.$("recordId").set(id)
					await PatientRecordStore.insertFormData(state.RECORD.form)
					setState({RECORD: {show: true, lastData: JSON.stringify(state.RECORD.form.values())}})
					UIStore.patientCardTab = "record"
					recordActionFlag = false
					// console.timeEnd("edit") //eslint-disable-line
				}

				const closeDialog = () => {
					recordActionFlag = false
				}

				if (!recordActionFlag) {
					recordActionFlag = true
					await handleActionDialog("RECORD", editdraftHandler, closeDialog)
				}
			},
			scoring: async (id) => {
				const scoringHandler = async () => {
					UIStore.patientCardTab = ""
					setState({RECORD: {show: false}})
					state.RECORD.form.$("recordId").value = id
					PatientRecordStore.readonlyform = true
					await PatientRecordStore.insertFormData(state.RECORD.form)
					setState({RECORD: {show: true, lastData: JSON.stringify(state.RECORD.form.values())}})
					UIStore.patientCardTab = "record"
					if (isSafe(UIStore.patientCardDivRef)) {
						UIStore.patientCardDivRef.scrollTo(0, 0)
					}
					recordActionFlag = false
				}

				const closeDialog = () => {
					recordActionFlag = false
				}

				if (!recordActionFlag) {
					recordActionFlag = true
					await handleActionDialog("RECORD", scoringHandler, closeDialog)
				}
			},
			patientSicknessAbs: async (record) => {
				const sicknessHandler = async () => {
					UIStore.patientCardTab = ""
					PatientSicknessAbsStore.recordLoaded = false
					await PatientSicknessAbsStore.loadRecord(record)
					PatientSicknessAbsStore.deliveriesChipsInit(state.SICKNESS.form)
					UIStore.patientCardTab = "sicknessabs"
					recordActionFlag = false
				}

				const closeDialog = () => {
					recordActionFlag = false
				}

				if (!recordActionFlag) {
					recordActionFlag = true
					await handleActionDialog("SICKNESS", sicknessHandler, closeDialog)
				}
			},
			print: async (amb) => {
				recordActionFlag = true
				await PatientRecordStore.printRecord(amb.record_id, DataStore.patientDTO.get("patient"), {
					dateFormat: props.intl.formatMessage({id: "Application.moment.dateformat"})
				})
				recordActionFlag = false
			},
			request: async (amb) => {
				recordActionFlag = true
				UIStore.patientCardTab = ""
				if (!PatientRequestStore.isSelectedEHR(amb)) {
					// PatientRecordStore.recordLoaded = false
					// PatientRequestStore.recordLoaded = false
					PatientRecordStore.loadRecord(amb)
					if (
						PatientRecordStore.record &&
						PatientRecordStore.record.diagnosis &&
						isSafe(state.REQUEST.forms) &&
						isSafe(state.REQUEST.forms[1])
					) {
						// props.form.$("diagnosis").value = PatientRecordStore.record.diagnosis
						state.REQUEST.forms[1].$("diagnosis").value = PatientRecordStore.record.diagnosis
					}
				} else {
					WarningStore.open(props.intl.formatMessage({id: "Patient.form.patientRequest.masterrecordwarning"}))
				}
				UIStore.patientCardTab = "request"
				recordActionFlag = false
			}
		},
		sickness: {
			edit: async (id) => {
				const editHandler = async () => {
					UIStore.patientCardTab = ""
					setState({SICKNESS: {show: false}})
					await PatientSicknessAbsStore.loadSicknessAbsForm(state.SICKNESS.form, id, props.intl)
					setState({SICKNESS: {show: true, lastData: JSON.stringify(state.SICKNESS.form.values())}})
					UIStore.patientCardTab = "sicknessabs"
					sicknessActionFlag = false
				}

				const closeDialog = () => {
					sicknessActionFlag = false
				}

				if (!sicknessActionFlag) {
					sicknessActionFlag = true
					await handleActionDialog("SICKNESS", editHandler, closeDialog)
				}
			}
		},
		anamnesis: {
			edit: async () => {
				const editHandler = async () => {
					UIStore.patientCardTab = ""
					setState({ANAMNESIS: {show: false}})
					await DataStore.loadTemplatesSync("ANA")
					await PatientAnamnesisStore.loadAnamData(state.ANAMNESIS.form)
					setState({ANAMNESIS: {show: true, lastData: JSON.stringify(state.ANAMNESIS.form.values())}})
					UIStore.patientCardTab = "anamnesis"
					if (isSafe(UIStore.patientCardDivRef)) {
						UIStore.patientCardDivRef.scrollTo(0, 0)
					}
					anamnesisActionFlag = false
				}
				const closeDialog = () => {
					anamnesisActionFlag = false
				}

				if (!anamnesisActionFlag) {
					anamnesisActionFlag = true
					await handleActionDialog("ANAMNESIS", editHandler, closeDialog)
				}
			},
			print: async (ana) => {
				await PatientAnamnesisStore.printAnamnesis(ana, DataStore.patientDTO.get("patient"))
			}
		},
		operation: {
			edit: async (op) => {
				const editHandler = async () => {
					UIStore.patientCardTab = ""
					setState({OPERATION: {show: false}})
					await DataStore.loadTemplatesSync("OP")
					state.OPERATION.form.$("recordId").value = op.record_id
					await PatientOperationStore.loadOperation(state.OPERATION.form, op.record_id)
					setState({OPERATION: {show: true, lastData: JSON.stringify(state.OPERATION.form.values())}})
					UIStore.patientCardTab = "operation"
					if (isSafe(UIStore.patientCardDivRef)) {
						UIStore.patientCardDivRef.scrollTo(0, 0)
					}
					operationActionFlag = false
				}
				const closeDialog = () => {
					operationActionFlag = false
				}

				if (!operationActionFlag) {
					operationActionFlag = true
					await handleActionDialog("OPERATION", editHandler, closeDialog)
				}
			},
			print: async (op) => {
				await PatientOperationStore.printOperation(op.record_id)
			}
		},
		request: {
			represcribe: async (ids, draft = false) => {
				const handler = async () => {
					UIStore.patientCardTab = ""
					// dostanem Array objektov z ktorych cez mam ziskam array idciek a z toho cez Set vyberiem iba unique id
					const uniqueIds = [...new Set(ids.map((obj) => obj.record_id))]
					state.REQUEST.forms = {}
					for (let index = 0; index < uniqueIds.length; index++) {
						const id = uniqueIds[index]
						state.REQUEST.forms[index] = getCreateFormFunction("REQUEST")()
						await PatientRequestStore.loadFormData(state.REQUEST.forms[index], id, draft)
					}
					let objForSave = {}
					Object.keys(state.REQUEST.forms).forEach((key) => {
						objForSave[key] = state.REQUEST.forms[key].values()
					})

					setState({REQUEST: {show: true, lastData: JSON.stringify(objForSave)}})
					DataStore.clearCheckedEHR()
					UIStore.patientCardTab = "request"
					requestActionFlag = false
				}

				const closeDialog = () => {
					requestActionFlag = false
				}

				if (!requestActionFlag) {
					requestActionFlag = true
					await handleActionDialog("REQUEST", handler, closeDialog)
				}
			}
		},
		medication: {
			represcribe: async (ids) => {
				const handler = async () => {
					UIStore.patientCardTab = ""
					setState({MEDICATION: {show: false}})
					//dostanem Array objektov z ktorych cez mam ziskam array idciek a z toho cez Set vyberiem iba unique id
					const uniqueIds = [...new Set(ids.map((obj) => obj.record_id))]
					state.MEDICATION.forms = {}
					for (let index = 0; index < uniqueIds.length; index++) {
						const id = uniqueIds[index]
						state.MEDICATION.forms[index] = getCreateFormFunction("MEDICATION")()
						await PatientMedicationStore.loadFormData(state.MEDICATION.forms[index], id)
					}

					let symptomsText = "<br/>"
					if (ids.length > 1) {
						ids.pop()
					}
					ids.forEach((obj) => {
						const actualSymptoms = obj.symptoms.map((x) => x.type.name_ext).join(", ")
						if (isNotEmpty(actualSymptoms)) {
							symptomsText += `<b>${obj.medication.product_printable_name}</b>: ${actualSymptoms} <br/>`
						}
					})
					let objForSave = {}
					Object.keys(state.MEDICATION.forms).forEach((key) => {
						objForSave[key] = state.MEDICATION.forms[key].values()
					})

					setState({MEDICATION: {show: true, lastData: JSON.stringify(objForSave)}})
					DataStore.clearCheckedEHR()
					UIStore.patientCardTab = "medication"
					if (symptomsText != "<br/>") {
						setTimeout(() => {
							WarningStore.open(
								`${props.intl.formatMessage({
									id: "Common.label.medicationSymptomsWarning"
								})}: ${symptomsText}`
							)
						}, 1000)
					}
					medicationActionFlag = false
					if (isSafe(UIStore.patientCardDivRef)) {
						UIStore.patientCardDivRef.scrollTo(0, 0)
					}
				}

				const closeDialog = () => {
					medicationActionFlag = false
				}

				if (!medicationActionFlag) {
					requestActionFlag = true
					await handleActionDialog("MEDICATION", handler, closeDialog)
				}
			}
		}
	}

	const insertDefaultDeliveriesToOPP = async () => {
		setState({OPERATION: {show: false}})
		await PatientOperationStore.loadDefaultDelivaries(state.OPERATION.form)
		setState({OPERATION: {show: true, lastData: JSON.stringify(state.OPERATION.form.values())}})
	}

	UIStore.patientCardContainerOPPRefresh = insertDefaultDeliveriesToOPP

	const changeRecordDefaultDoctor = (
		substitutingPersonnelExpertiseId,
		substitutingDoctor,
		substitutingExpertiseId,
		substitutingDoctorOUPZS
	) => {
		if (
			isNotEmpty(substitutingPersonnelExpertiseId) &&
			isNotEmpty(substitutingDoctor) &&
			isNotEmpty(substitutingExpertiseId) &&
			isNotEmpty(substitutingDoctorOUPZS)
		) {
			if (
				(isEmpty(state.RECORD.form.$("substitutingPersonnelExpertiseId").value) &&
					isEmpty(state.RECORD.form.$("substitutingDoctor").value) &&
					isEmpty(state.RECORD.form.$("substitutingExpertiseId").value) &&
					isEmpty(state.RECORD.form.$("substitutingDoctorOUPZS").value)) ||
				!StorageEx.exists(getKeyInfo("RECORD"))
			) {
				setState({RECORD: {show: false}})
				state.RECORD.form.$("substitutingPersonnelExpertiseId").value = substitutingPersonnelExpertiseId
				state.RECORD.form.$("substitutingDoctor").value = substitutingDoctor
				state.RECORD.form.$("substitutingExpertiseId").value = substitutingExpertiseId
				state.RECORD.form.$("substitutingDoctorOUPZS").value = substitutingDoctorOUPZS
				setState({RECORD: {show: true, lastData: JSON.stringify(state.RECORD.form.values())}})
			}
		}
	}

	const changePrescriptionDefaultDoctor = (
		substitutingPersonnelExpertiseId,
		substitutingDoctor,
		substitutingExpertiseId,
		substitutingDoctorOUPZS
	) => {
		if (
			isNotEmpty(substitutingPersonnelExpertiseId) &&
			isNotEmpty(substitutingDoctor) &&
			isNotEmpty(substitutingExpertiseId) &&
			isNotEmpty(substitutingDoctorOUPZS)
		) {
			setState({PRESCRIPTION: {show: false}})
			let objForSave = {}
			Object.keys(state.PRESCRIPTION.forms).forEach((key) => {
				let form = state.PRESCRIPTION.forms[key]
				if (
					(isEmpty(form.$("substitutingPersonnelExpertiseId").value) &&
						isEmpty(form.$("substitutingDoctor").value) &&
						isEmpty(form.$("substitutingExpertiseId").value) &&
						isEmpty(form.$("substitutingDoctorOUPZS").value)) ||
					!StorageEx.exists(getKeyInfo("PRESCRIPTION"))
				) {
					form.$("substitutingPersonnelExpertiseId").value = substitutingPersonnelExpertiseId
					form.$("substitutingDoctor").value = substitutingDoctor
					form.$("substitutingExpertiseId").value = substitutingExpertiseId
					form.$("substitutingDoctorOUPZS").value = substitutingDoctorOUPZS
					form.$("showDetails").value = true
				}
				objForSave[key] = form.values()
			})
			setState({PRESCRIPTION: {show: true, lastData: JSON.stringify(objForSave)}})
		}
	}

	const insertDefaultDiagnosisToPrescription = (diagnosisId, diagnosisVal) => {
		setState({PRESCRIPTION: {show: false}})
		let objForSave = {}
		Object.keys(state.PRESCRIPTION.forms).forEach((key) => {
			let form = state.PRESCRIPTION.forms[key]
			if (isEmpty(form.$("diagnosis").value) || !StorageEx.exists(getKeyInfo("PRESCRIPTION"))) {
				form.$("diagnosis").value = diagnosisId
				let stringToAdd = null
				if (diagnosisVal.code_ext == "U99.01") {
					stringToAdd = "Ink. II.st."
				} else if (diagnosisVal.code_ext == "U99.02") {
					stringToAdd = "Ink. III.st. trvalá"
				} else if (diagnosisVal.code_ext == "U99.03") {
					stringToAdd = "Ink. III.st. trvalá, nezvratná"
				}

				if (isNotEmpty(stringToAdd)) {
					if (isEmpty(htmlToPlain(form.$("prescriptionReason").value))) {
						form.$("prescriptionReason").value = stringToAdd
						form.$("showDetails").value = true
					}
					if (form.$("quantization").value == "VAR" && isEmpty(htmlToPlain(form.$("dosage").value))) {
						form.$("dosage").value = stringToAdd
					}
				}
			}
			objForSave[key] = form.values()
		})
		setState({PRESCRIPTION: {show: true, lastData: JSON.stringify(objForSave)}})
	}

	const insertDefaultDiagnosisToRequest = (diagnosisId) => {
		setState({REQUEST: {show: false}})
		let objForSave = {}
		Object.keys(state.REQUEST.forms).forEach((key) => {
			let form = state.REQUEST.forms[key]
			if (isEmpty(form.$("diagnosis").value) || !StorageEx.exists(getKeyInfo("REQUEST"))) {
				form.$("diagnosis").value = diagnosisId
			}
			objForSave[key] = form.values()
		})
		setState({REQUEST: {show: true, lastData: JSON.stringify(objForSave)}})
	}

	const insertDefaultDiagnosisToSickness = (diagnosisId) => {
		setState({SICKNESS: {show: false}})
		state.SICKNESS.form.$("diagnosis").value = diagnosisId
		let deliveries = JSON.parse(state.SICKNESS.form.$("deliveriesChipsForm").value)
		if (isSafe(deliveries) && Array.isArray(deliveries)) {
			deliveries.forEach((row) => {
				if (isEmpty(row.diagnosis)) {
					row.diagnosis = diagnosisId
				}
			})
			state.SICKNESS.form.$("deliveriesChipsForm").value = JSON.stringify(deliveries)
		}
		setState({SICKNESS: {show: true, lastData: JSON.stringify(state.SICKNESS.form.values())}})
	}

	const insertDefaultDiagnosisToMedication = (diagnosisId) => {
		setState({MEDICATION: {show: false}})
		let objForSave = {}
		Object.keys(state.MEDICATION.forms).forEach((key) => {
			let form = state.MEDICATION.forms[key]
			if (isEmpty(form.$("diagnosis").value) || !StorageEx.exists(getKeyInfo("MEDICATION"))) {
				form.$("diagnosis").value = diagnosisId
			}
			objForSave[key] = form.values()
		})
		setState({MEDICATION: {show: true, lastData: JSON.stringify(objForSave)}})
	}

	const insertDefaultDiagnosisToForms = (diagnosis) => {
		//Nastavi def. diagnozu do lieky,ziadanky,soc.poist,medikacia
		if (isSafe(diagnosis) && isNotEmpty(diagnosis.code)) {
			let diagnosisId = "" + diagnosis.code
			insertDefaultDiagnosisToPrescription(diagnosisId, diagnosis)
			insertDefaultDiagnosisToRequest(diagnosisId)
			insertDefaultDiagnosisToSickness(diagnosisId)
			insertDefaultDiagnosisToMedication(diagnosisId)
		}
	}

	const insertRecordPriorityToRequest = (priority) => {
		if (!StorageEx.exists(getKeyInfo("REQUEST"))) {
			setState({REQUEST: {show: false}})
			let objForSave = {}
			Object.keys(state.REQUEST.forms).forEach((key) => {
				let form = state.REQUEST.forms[key]
				form.$("priority").value = priority
				objForSave[key] = form.values()
			})
			setState({REQUEST: {show: true, lastData: JSON.stringify(objForSave)}})
		}
	}

	// Podla ziadanky vlozi do vykononu 4 alebo 8 rozpisaneho dekurzu hodnotu movmentu
	const insertRequestValsToRecordDeliveries = async (requestForms) => {
		let firstForm = requestForms[Object.keys(requestForms).shift()]
		if (StorageEx.exists(getKeyInfo("RECORD")) && isSafe(firstForm)) {
			let recordDeliveries = state.RECORD.form.$("deliveriesChipsForm").value
			if (isNotEmpty(recordDeliveries) && recordDeliveries != "{}" && recordDeliveries != "[]") {
				recordDeliveries = JSON.parse(recordDeliveries)
				let indexOfDeliveryForRequest = recordDeliveries.map((row) => row.col1CodeExt).indexOf("4")
				if (indexOfDeliveryForRequest == -1) {
					indexOfDeliveryForRequest = recordDeliveries.map((row) => row.col1CodeExt).indexOf("8")
				}
				if (isSafe(firstForm) && isNotEmpty(indexOfDeliveryForRequest) && indexOfDeliveryForRequest != -1) {
					let reqData = firstForm.values()
					let expertiseResponse = await api.loadExpertise(getFilters([`code=${reqData.expertise}`])).call()
					let professionalActivity = GlobalStore.CL["professionalActivity"].find(
						(row) => row._id == reqData.professional_activity
					)

					let expertise = null
					if (isSafe(expertiseResponse) && isSafe(expertiseResponse.rows) && isSafe(expertiseResponse.rows[0])) {
						expertise = expertiseResponse.rows[0]
					}

					if (
						isSafe(professionalActivity) &&
						isSafe(expertise) &&
						isSafe(recordDeliveries[indexOfDeliveryForRequest]) &&
						isEmpty(recordDeliveries[indexOfDeliveryForRequest].col6)
					) {
						let movement = professionalActivityToMovement(professionalActivity.code_ext.toString())
						if (isNotEmpty(movement)) {
							movement += expertise.code_ext
							recordDeliveries[indexOfDeliveryForRequest].col6 = movement
							state.RECORD.form.$("deliveriesChipsForm").value = JSON.stringify(recordDeliveries)
						}
					}
				}
			}
		}
	}

	const renderWarningBadge = (formName) => {
		const sf = DataStore.patientDTO.get("savedForms")
		if (DataStore.patientDTO && sf && sf.includes(formName)) {
			return <div className={props.classes.warningBadge}>!</div>
		}
	}

	const openAllDrafts = async () => {
		UIStore.isFormSaving = true
		let openForm = null
		let drafts = await PatientCardContainerStore.loadTodaysDrafts()

		if (isSafe(drafts)) {
			//Load ziadaniek
			if (isSafe(drafts.requests) && drafts.requests.length > 0 && !StorageEx.exists(getKeyInfo("REQUEST"))) {
				//Uprava ids do podoby takej ako chodia z overview
				let reqIds = drafts.requests.map((obj) => {
					return {
						record_id: obj._id
					}
				})
				if (isEmpty(openForm)) {
					openForm = "request"
				}
				await overviewActions.request.represcribe(reqIds, true)
			}

			//Load dekurzu
			if (isSafe(drafts.ambulance_id) && !StorageEx.exists(getKeyInfo("RECORD"))) {
				openForm = "record"
				await overviewActions.record.editdraft(drafts.ambulance_id._id)
			}
			if (isSafe(drafts.drugs) && drafts.drugs.length > 0 && !StorageEx.exists(getKeyInfo("PRESCRIPTION"))) {
				//Load preskripcie
				//Uprava ids do podoby takej ako chodia z overview
				let drugIds = drafts.drugs.map((obj) => {
					return {
						record_id: obj._id
					}
				})
				if (isEmpty(openForm)) {
					openForm = "prescription"
				}
				await overviewActions.prescription.represcribe(drugIds)
			}
		}

		if (isNotEmpty(openForm)) {
			UIStore.patientCardTab = openForm
		} else if (isNotSafe(drafts.ambulance_id) && drafts.drugs.length == 0 && drafts.requests.length == 0) {
			WarningStore.open(props.intl.formatMessage({id: "Common.label.noDraftsForToday"}))
		} else {
			WarningStore.open(props.intl.formatMessage({id: "Common.label.draftsAlreadyLoaded"}))
		}
		UIStore.isFormSaving = false
	}

	const showTabsIcons = () => {
		if (UIStore.patientCardTab == "") {
			return Object.keys(state).some((key) => StorageEx.exists(getKeyInfo(key)))
		} else {
			return true
		}
	}

	const insertScoringToRecord = (scoreValue) => {
		if (isNotEmpty(state.RECORD.form.$("deliveriesChipsForm").value)) {
			setState({RECORD: {show: false}})
			let form = state.RECORD.form
			let deliveriesChipsForm = JSON.parse(form.$("deliveriesChipsForm").value)
			deliveriesChipsForm.forEach((row, index) => {
				if (row.col1CodeExt == "H0006") {
					let addItems =
						isNotEmpty(form.$("deliveriesFormAddItems").value) && form.$("deliveriesFormAddItems").value != "[]"
							? JSON.parse(form.$("deliveriesFormAddItems").value)
							: {}
					addItems[index] = [
						{
							type: "adigen",
							typeNameExt: "Iná pripočítateľná položka",
							product: "SKOR0" + scoreValue,
							quantity: 1,
							unitEndText: "",
							unit: 0
						}
					]
					form.$("deliveriesFormAddItems").value = JSON.stringify(addItems)
					PatientRecordStore.deliveriesAddItemChips = addItems
				}
			})
			setState({RECORD: {show: true, lastData: JSON.stringify(state.RECORD.form.values())}})
		}
	}

	const handleLoadPrescriptionTemplate = async (key) => {
		let form = state.PRESCRIPTION.forms[key]
		if (isNotEmpty(form.$("productCode").value)) {
			let filter = {
				filters: [
					{
						associated_column: "product_id",
						predicate: "=",
						values: [
							{
								id_value: form.$("productCode").value
							}
						]
					},
					{
						associated_column: "provider_id",
						predicate: "=",
						values: [
							{
								id_value: getSelectedOrgUnitID()
							}
						]
					}
				]
			}
			let response = await api.loadPrescriptionAsTemplate(filter).call()
			if (isSafe(response) && isSafe(response.rows) && response.rows.length) {
				PrescriptionTemplateDialogStore.open(response.rows, key, null)
			}
		}
	}

	const loadPrescriptionTemplate = async (data, key) => {
		let objForSave = {}
		let form = state.PRESCRIPTION.forms[key]
		form.reset()
		await setTimeout(async () => {
			await PatientPrescriptionStore.loadFormDataFromTemplate(form, data, props)
			objForSave[key] = form.values()
			setState({PRESCRIPTION: {show: true, lastData: JSON.stringify(objForSave)}})
		}, 0)
	}

	return (
		<div>
			<XsButton
				ref={(btn) => (PatientCardContainerStore.loadAllDraftsBtnRef = btn)}
				className="hide"
				onClick={() => openAllDrafts()}
			/>
			{showTabsIcons() && (
				<XsTabsIcon
					//Force refresh v global store je reakcia ktoru odpalia formy ked vsetky sa loadnu
					refresh={GlobalStore.force}
					value={UIStore.patientCardTab}
					onChangeTab={(value) => {
						UIStore.patientCardTab = value
					}} //  className="overviewTabs" //  horizontal={true}
					mainClass="xs-patient-card-tab"
					background="white" //  background="white" //  //  headerClassName="xs-horizontal-filled" //  //  scrollButtons={true} //  value={UIStore.patientCardTab} //  onChangeTab={(value)  =>  UIStore.patientCardTab  =  value} //  horizontal={true} //  mainClass="xs-patient-card-tab"
					tabs={[
						{
							label: props.intl.formatMessage({id: "Common.label.anamnes"}),
							labelClassName: "button-anamnesis-form",
							value: "anamnesis", //  hidden:  UIStore.patientCardTab  !==  "anamnesis"  &&  UIStore.patientCardTab  !==  "record",
							badge: renderWarningBadge("PatientAnamnesisForm"),
							warning: StorageEx.exists(getKeyInfo("ANAMNESIS")),
							icon: (
								<Tooltip
									title={
										StorageEx.exists(getKeyInfo("ANAMNESIS")) ? (
											<FormattedMessage id="Common.label.conceptRecord" />
										) : (
											""
										)
									}
									placement="top"
								>
									{UIStore.patientCardTab === "anamnesis" ? (
										<i className="fas  fa-stethoscope  fa-2x" />
									) : (
										<i className="fal  fa-stethoscope  fa-2x" />
									)}
								</Tooltip>
							),
							content: (
								<div id="patientAnamnesisFormContainer">
									{state.ANAMNESIS.show ? (
										<PatientAnamnesisForm form={state.ANAMNESIS.form} options={{onClose: clearStorage}} />
									) : (
										<div>
											<XsLoading overlay={false} />
										</div>
									)}
								</div>
							)
						},
						{
							label: props.intl.formatMessage({id: "Common.label.dekurz"}),
							labelClassName: "button-record-form",
							value: "record", //  hidden:  UIStore.patientCardTab  !==  "record"  &&  UIStore.patientCardTab  !==  "anamnesis",
							badge: renderWarningBadge("PatientRecordForm"),
							warning: StorageEx.exists(getKeyInfo("RECORD")),
							// disabled: !state.RECORD.show,
							icon: (
								<Tooltip
									title={
										StorageEx.exists(getKeyInfo("RECORD")) ? <FormattedMessage id="Common.label.conceptRecord" /> : ""
									}
									placement="top"
								>
									<i className={`${UIStore.patientCardTab === "record" ? "fas" : "fal"}  fa-notes-medical  fa-2x`} />
								</Tooltip>
							),
							content: (
								<div id="patientRecordFormContainer">
									{state.RECORD.show ? (
										<PatientRecordForm
											id={props.id}
											form={state.RECORD.form}
											options={{
												onClose: clearStorage,
												deleteOnlyFromStorage,
												changePrescriptionDefaultDoctor: changePrescriptionDefaultDoctor,
												insertDefaultDiagnosisToForms: insertDefaultDiagnosisToForms,
												checkRecordForToday,
												insertRecordPriorityToRequest
											}}
										/>
									) : (
										<div>
											<XsLoading overlay={false} />
										</div>
									)}
								</div>
							)
						},
						{
							label: props.intl.formatMessage({id: "Common.label.drugs"}),
							labelClassName: "button-prescription-form",
							icon: (
								<Tooltip
									title={
										StorageEx.exists(getKeyInfo("PRESCRIPTION")) ? (
											<FormattedMessage id="Common.label.conceptRecord" />
										) : (
											""
										)
									}
									placement="top"
								>
									{UIStore.patientCardTab === "prescription" ? (
										<i className="fas  fa-pills  fa-2x" />
									) : (
										<i className="fal  fa-pills  fa-2x" />
									)}
								</Tooltip>
							),
							value: "prescription", //  hidden:  UIStore.patientCardTab  !==  "prescription",
							warning: StorageEx.exists(getKeyInfo("PRESCRIPTION")),
							content: (
								<div>
									{state.PRESCRIPTION.show ? (
										<PatientPrescriptionFormContainer
											forms={state.PRESCRIPTION.forms}
											options={{
												onClose: clearStorage,
												deleteOnlyFromStorage,
												createFormFunction: getCreateFormFunction("PRESCRIPTION"),
												changeRecordDefaultDoctor: changeRecordDefaultDoctor,
												handleLoadPrescriptionTemplate
											}}
										/>
									) : (
										<div>
											<XsLoading overlay={false} />
										</div>
									)}
								</div>
							)
						},

						{
							label: props.intl.formatMessage({id: "Common.label.requests"}),
							labelClassName: "button-request-form",
							value: "request", //  hidden:  UIStore.patientCardTab  !==  "request",
							warning: StorageEx.exists(getKeyInfo("REQUEST")),
							icon: (
								<Tooltip
									title={
										StorageEx.exists(getKeyInfo("REQUEST")) ? <FormattedMessage id="Common.label.conceptRecord" /> : ""
									}
									placement="top"
								>
									{UIStore.patientCardTab === "request" ? (
										<i className="fas  fa-file-contract  fa-2x" />
									) : (
										<i className="fal  fa-file-contract  fa-2x" />
									)}
								</Tooltip>
							),
							content: (
								<div id="patientRequestFormContainer">
									<PatientRequestContainer
										forms={state.REQUEST.forms}
										options={{
											createFormFunction: getCreateFormFunction("REQUEST"),
											onClose: clearStorage,
											deleteOnlyFromStorage,
											recordForm: state.RECORD.form,
											anamnesisForm: state.ANAMNESIS.form,
											insertRequestValsToRecordDeliveries
										}}
									/>
								</div>
							)
						},
						{
							label: props.intl.formatMessage({id: "PatientDetail.cards.PN"}),
							labelClassName: "button-sicknessabs-form",
							value: "sicknessabs", //  hidden:  UIStore.patientCardTab  !==  "sicknessabs",
							warning: StorageEx.exists(getKeyInfo("SICKNESS")),
							badge: renderWarningBadge("PatientSicknessAbsForm"),
							icon: (
								<Tooltip
									title={
										StorageEx.exists(getKeyInfo("SICKNESS")) ? <FormattedMessage id="Common.label.conceptRecord" /> : ""
									}
									placement="top"
								>
									{UIStore.patientCardTab === "sicknessabs" ? (
										<i className="fas  fa-id-badge  fa-2x" />
									) : (
										<i className="fal  fa-id-badge  fa-2x" />
									)}
								</Tooltip>
							),
							content: (
								<div id="patientSickessAbsFormContainer">
									{state.SICKNESS.show ? (
										<PatientSicknessAbsForm form={state.SICKNESS.form} options={{onClose: clearStorage}} />
									) : (
										<h1>
											<XsLoading overlay={false} />
										</h1>
									)}
								</div>
							)
						},
						{
							label: props.intl.formatMessage({id: "PatientDetail.cards.medication"}),
							labelClassName: "button-medication-form",
							value: "medication", //  hidden:  UIStore.patientCardTab  !==  "medication",
							warning: StorageEx.exists(getKeyInfo("MEDICATION")),
							icon: (
								<Tooltip
									title={
										StorageEx.exists(getKeyInfo("MEDICATION")) ? (
											<FormattedMessage id="Common.label.conceptRecord" />
										) : (
											""
										)
									}
									placement="top"
								>
									{UIStore.patientCardTab === "medication" ? (
										<i className="fas  fa-syringe  fa-2x" />
									) : (
										<i className="fal  fa-syringe  fa-2x" />
									) //  content:  <PatientMedicationForm  represcribeId={PatientMedicationStore.represcribeId}  />
									}
								</Tooltip>
							),
							content: (
								<PatientMedicationContainer
									forms={state.MEDICATION.forms}
									options={{
										onClose: clearStorage,
										createFormFunction: getCreateFormFunction("MEDICATION")
									}}
								/>
							)
						},
						{
							label: props.intl.formatMessage({id: "PatientDetail.cards.opProtocol"}),
							labelClassName: "button-operation-form",
							value: "operation", //  hidden:  UIStore.patientCardTab  !==  "operation",
							warning: StorageEx.exists(getKeyInfo("OPERATION")),
							badge: renderWarningBadge("PatientOperationForm"),
							icon: (
								<Tooltip
									title={
										StorageEx.exists(getKeyInfo("OPERATION")) ? (
											<FormattedMessage id="Common.label.conceptRecord" />
										) : (
											""
										)
									}
									placement="top"
								>
									{UIStore.patientCardTab === "operation" ? (
										<i className="fas  fa-file-medical-alt  fa-2x" />
									) : (
										<i className="fal  fa-file-medical-alt  fa-2x" />
									)}
								</Tooltip>
							),
							content: (
								<div id="patientOperationFormContainer">
									{state.OPERATION.show ? (
										<PatientOperationForm form={state.OPERATION.form} options={{onClose: clearStorage}} />
									) : (
										<h1>
											<XsLoading overlay={false} />
										</h1>
									)}
								</div>
							)
						},
						{
							label: props.intl.formatMessage({id: "PatientDetail.cards.patientScoring"}),
							labelClassName: "button-scoring-form",
							value: "scoring",
							icon:
								UIStore.patientCardTab === "scoring" ? (
									<i className="fas fa-ballot-check fa-2x"></i>
								) : (
									<i className="fal fa-ballot-check fa-2x"></i>
								),
							content: (
								<div id="patientScoringContainer">
									<ScoringList
										options={{
											insertScoringToRecord: insertScoringToRecord
										}}
									/>
								</div>
							)
						}
						//  { // label:  props.intl.formatMessage({id:  "PatientDetail.cards.patientTemplates"}), // value:  "templates", // icon: //  UIStore.patientCardTab  ===  "templates"  ?  ( //   <i  className="fas  fa-file-alt  fa-2x"  /> //  )  :  ( //   <i  className="fal  fa-file-alt  fa-2x"  /> //  ), // content:  <PrintTemplatesList  /> //  }
					]}
				/>
			)}
			<ProblemsContainer />
			<OverviewContainer id={props.id} patientID={DataStore.patientDTO.get("patientID")} actions={overviewActions} />
			{GlobalStore.confirmationDialogOpened === "patientCardAction" && (
				<XsConfirmationDialog
					name={"patientCardAction"}
					type="warning"
					text={<FormattedMessage id={GlobalStore.confirmationDialogParams.text} />}
					onConfirmation={() => GlobalStore.confirmationDialogParams.onConfirm()}
					onCancel={() => {}}
					// headerConfig={{
					// 	text: "Patient.form.savedrecord.title",
					// 	values: {
					// 		datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(
					// 			this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
					// 		)
					// 	},
					// 	icon: <i className="fal fa-file-download fa-2x"></i>
					// }}
					// cancelBtn={{
					// 	icon: <i className="fal fa-times fa-lg"></i>,
					// 	text: "Common.btn.not.load"
					// }}
					confirmBtn={{text: "Common.dialog.Yes"}}
				/>
			)}
			{GlobalStore.confirmationDialogOpened === "loadRecordWithDeliveries" && (
				<XsConfirmationDialog
					name={"loadRecordWithDeliveries"}
					text={<FormattedMessage id={GlobalStore.confirmationDialogParams.text} />}
					onConfirmation={() => {
						GlobalStore.confirmationDialogParams.onConfirm(false)
					}}
					onCancel={() => GlobalStore.confirmationDialogParams.onConfirm(true)}
					confirmBtn={{text: "Common.dialog.No"}}
					cancelBtn={{text: "Common.dialog.Yes"}}
				/>
			)}
			{GlobalStore.confirmationDialogOpened === "multipleRecordsForToday" && (
				<XsConfirmationDialog
					name={"multipleRecordsForToday"}
					text={<FormattedMessage id={"Common.label.multipleRecordsError"} />}
					onConfirmation={() => {
						setTimeout(() => {
							window.scrollTo(0, 0)
						}, 0)
					}}
					onCancel={() => {
						clearStorage("RECORD")
					}}
				/>
			)}
			<PrescriptionTemplateDialog load={loadPrescriptionTemplate} />
			<RecordRePrintDialog />
		</div>
	)
}

//  export  default  withStyles(styles)(injectIntl(PatientCardContainer))
export default withStyles(styles)(injectIntl(observer(PatientCardContainer)))
