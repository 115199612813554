import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				anamnesis: {
					label: <FormattedMessage id="Patient.form.patientanamnesis.anamnesis" />,
					rules: "required"
				},
				socialAnamnesis: {
					label: <FormattedMessage id="Patient.form.patientanamnesis.socialAnamnesis" />
				},
				deliveryAnamnesis: {
					label: <FormattedMessage id="Patient.form.patientanamnesis.deliveryAnamnesis" />
				},
				deliveryDate: {
					label: <FormattedMessage id="Patient.form.patientanamnesis.deliveryDate" />,
					type: "date"
				},
				print: {
					type: "checkbox",
					value: true
				},
				id: {},
				wrt_recorded_event: {},
				closeForm: {}
			}
		}
	}
}
