//@flow
import ReactTooltip from "react-tooltip"
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import moment from "moment"

import {Grid} from "@material-ui/core"

import DeleteIcon from "@material-ui/icons/Clear"
import {getSelectedOrgUnitID} from "../../../../global/helpers/actions"

import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import EditEventDialogStore from "../../stores/EditEventDialogStore"
import api from "../../actions/api"

@injectIntl
@observer
export default class AddEventHeader extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			first_name,
			last_name,
			insurer_short_name,
			age,
			contact,
			identifier,
			next_event_id,
			next_event_from,
			next_event_service_name,
			in_capitation
		} = AddEventDialogStore.selectedPatient

		const lastName = isNotEmpty(last_name) ? last_name.toUpperCase() : ""
		const insurerName = isNotEmpty(insurer_short_name) ? `(${insurer_short_name})` : ""
		const ages = isNotEmpty(age) ? `, ${age} ` + this.props.intl.formatMessage({id: "Common.label.yearsShortcut"}) : ""
		const patientIdentifier = isNotEmpty(identifier) ? `, ${identifier}` : ""
		const patientContact = isNotEmpty(contact) ? `, ${contact}` : ""

		return (
			<React.Fragment>
				<Grid item container className="xs-relative pt-4" justify="center">
					<Grid item>
						<i className="fal fa-calendar-plus fa-3x greyDark" />
					</Grid>
					<XsIconButton className="xs-close-btn" icon={<DeleteIcon onClick={() => AddEventDialogStore.close()} />} />
				</Grid>
				{isNotEmpty(next_event_from) && isEmpty(AddEventDialogStore.unknowPatientName) && (
					<React.Fragment>
						<Grid
							item
							container
							justify="center"
							alignItems="center"
							spacing={8}
							className="xs-blueInfo pointer fontSize13"
							data-tip
							data-for="clickHereCanPlanNextEvent"
							onClick={() => {
								AddEventDialogStore.close()
								api
									.loadPatientsAgenda(getSelectedOrgUnitID(), {
										event_id: next_event_id
									})
									.call()
									.then((res) => {
										if (isSafe(res) && isSafe(res[0])) {
											EditEventDialogStore.open(res[0])
										}
									})
							}}
						>
							<Grid item>
								<i className="fal fa-info-circle"></i>
							</Grid>
							<Grid item>{moment(next_event_from).format("DD.MM.YYYY HH:mm")}</Grid>
						</Grid>
						<ReactTooltip id="clickHereCanPlanNextEvent">
							<FormattedMessage
								id="Common.label.clickHereCanPlanNextEvent"
								values={{
									service:
										isNotEmpty(next_event_service_name) && next_event_service_name.length > 20
											? next_event_service_name.substring(0, 17) + "..."
											: next_event_service_name
								}}
							/>
						</ReactTooltip>
					</React.Fragment>
				)}
				<Grid
					item
					container
					spacing={8}
					alignItems="center"
					justify="center"
					className={`xs-bold ${!AddEventDialogStore.isOpenedNextVisitDialog ? "" : "pt-2 pb-3"}`}
				>
					<Grid item>
						{isSafe(AddEventDialogStore.unknowPatientName) ? (
							AddEventDialogStore.unknowPatientName
						) : (
							<React.Fragment>
								{`${lastName} ${first_name}${ages}${patientIdentifier} ${insurerName}${patientContact}`}
								{in_capitation && (
									<React.Fragment>
										<span className="ml-1 xs-capitation-badge bgBlueDark " data-tip data-for="capitation">
											K
										</span>
										<ReactTooltip id="capitation">
											<FormattedMessage id="Patient.header.tooltip.patientCapitation" />
										</ReactTooltip>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
					</Grid>
					{AddEventDialogStore.isNonPayer && (
						<Grid className="pl-3 pointer">
							<i className="fas fa-exclamation-triangle xs-redDanger" data-tip data-for="nonpayer" />
							<ReactTooltip id="nonpayer">
								<FormattedMessage id="Patient.patientHeader.nonPayer" />
							</ReactTooltip>
						</Grid>
					)}
				</Grid>
				{!AddEventDialogStore.isOpenedNextVisitDialog && (
					<Grid
						item
						container
						justify="center"
						alignItems="center"
						spacing={8}
						className="pointer xs-blueInfo fontSize13"
						onClick={() => {
							AddEventDialogStore.patientList = []
							AddEventDialogStore.step = 1
							AddEventDialogStore.unknowPatientName = undefined
							AddEventDialogStore.isNonPayer = false
						}}
					>
						<Grid item>
							<i className="fal fa-search" />
						</Grid>
						<Grid item>
							<FormattedMessage id="Calendar.addEvent.dialog.changePerson" />
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		)
	}
}
