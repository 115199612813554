import React, {Component} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import ReactTooltip from "react-tooltip"
import classnames from "classnames"

import XsMenu from "../xsMenu/xsMenu"
import XsButton from "../xsButton/xsButton"
import XsIconButton from "../xsButton/xsIconButton"
// import XsMultiButton from "../xsButton/xsMultiButton"
import TourDialog from "./tourDialog"

import RouterStore from "../../store/RouterStore"
import GlobalStore from "../../store/GlobalStore"
import UIStore from "../../../modules/ambulance/stores/UIStore"
import TourStore from "../../store/TourStore"
import DataStore from "../../../modules/ambulance/stores/DataStore"
import EHealthProgressDialogStore from "../../../modules/ambulance/stores/EHealthProgressDialogStore"
import {logout, getKeyInfo} from "../../../global/helpers/actions"
import settings from "../../config/settings"

import ContactFormStore from "../../../modules/ambulance/stores/ContactFormStore"
import PatientDetailActionsStore from "../../../modules/ambulance/stores/PatientDetailActionsStore"
import CommMsgQueueDialogStore from "../../../modules/ambulance/stores/CommMsgQueueDialogStore"
import StorageManagementDialogStore from "../../../modules/ambulance/stores/StorageManagementDialogStore"
import PatientCardContainerStore from "../../../modules/ambulance/stores/PatientCardContainerStore"
import PatientDeliveryDialogStore from "../../../modules/ambulance/stores/PatientDeliveryDialogStore"
import NotificationCenterStore from "../../../modules/ambulance/components/notificationCenter/NotificationCenterStore"
import {getUserAccessRights} from "../../helpers/actions"

import PrintTemplatesStore from "../../../modules/ambulance/stores/PrintTemplatesStore"

import "./xsHeader.less"
import {Badge} from "@material-ui/core"
import StorageEx from "../../storage/storageEx"

@injectIntl
@observer
class XsHeader extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showPatientInfo: false
		}

		GlobalStore.setUserData()
	}

	componentDidMount = () => {
		window.addEventListener("scroll", this.handleScroll)

		setTimeout(() => {
			if (GlobalStore.GWAppRunningState === 0 && isNotEmpty(GlobalStore.userName) && settings.GW_BASE_URL) {
				// timetout kvoli oneskoreniu GWAppRunningState, lebo sa spusti 2x GetStatus
				GlobalStore.checkGWAppPost()
			}
		}, 2000)
	}

	componentDidUpdate() {
		// if (GlobalStore.GWAppRunningState === 1) {
		// 	fetch(`${settings.GW_BASE_URL}/GetStatus`, {
		// 		method: "GET"
		// 	})
		// 		.then((resp) => resp.json())
		// 		.then((data) => {
		// 			GlobalStore.ZprRegisteredInEhealthState = data.ZprRegistered ? 1 : 2
		// 			if (isSafe(DataStore.patientDTO.get("patientID"))) {
		// 				// tretia gulicka len ak som v pacientovi
		// 				GlobalStore.PrZSRegisteredInEhealthState = data.PrZSRegistered ? 1 : 2
		// 			} else {
		// 				GlobalStore.PrZSRegisteredInEhealthState = 0
		// 			}
		// 		})
		// }
	}

	componentWillUnmount = () => {
		window.removeEventListener("scroll", this.handleScroll)
	}

	handleScroll = () => {
		if (this.props.isPatientDetail && window.pageYOffset > 110) {
			UIStore.showHeaderInPatientDetail = true
		} else {
			UIStore.showHeaderInPatientDetail = false
		}
	}

	showProgress = () => {
		EHealthProgressDialogStore.open()
	}

	render() {
		const isMainPage = RouterStore.location.pathname === settings.HOMEPAGE

		const {formatMessage} = this.props.intl

		let items = [
			{
				name: <FormattedMessage id="Common.menu.admin-cl" />,
				onClick: () => {
					RouterStore.push("/admin-cl")
					UIStore.mainMenuTab = "admin"
				},
				icon: <i className="fas fa-list-ol fa-lg" />
			}
		]

		if (isSafe(getUserAccessRights("SRVZO.API.REST.MAIN./session/sec-resource"))) {
			items.push({
				name: <FormattedMessage id="Common.menu.users" />,
				onClick: () => {
					RouterStore.push("/users")
					UIStore.mainMenuTab = "admin"
				},
				icon: <i className="fas fa-users" />
			})
		}

		//Debug položky v menu
		if (settings.DEBUG == true) {
			//Routes - API cesty
			items.push({
				name: "Routes",
				onClick: () => {
					RouterStore.push("/routes")
					UIStore.mainMenuTab = "admin"
				},
				icon: <i className="fal fa-route fa-lg" />
			})

			//Translation
			items.push({
				name: <FormattedMessage id="Admin.translation.translation" />,
				onClick: () => {
					RouterStore.push("/translation")
				},
				icon: <i className="far fa-language fa-lg" />
			})
		}

		items.push({
			name: "EHGW",
			onClick: () => {
				RouterStore.push("/ehgw")
				UIStore.mainMenuTab = "admin"
			},
			icon: <i className="fal fa-globe fa-lg" />
		})

		items.push({
			name: "Notifikácie",
			onClick: () => {
				RouterStore.push("/notify")
				UIStore.mainMenuTab = "admin"
			},
			icon: <i className="fal fa-bell fa-lg" />
		})

		const serverRoleClass = settings.SERVERROLE ? ` serverrole-${settings.SERVERROLE.toLowerCase()}` : ""

		let commMsgQueueError = false
		if (isSafe(UIStore.commMsgQueue)) {
			UIStore.commMsgQueue.forEach((value) => {
				if (value.state == 2) {
					commMsgQueueError = true
					return
				} else if (!commMsgQueueError) {
					commMsgQueueError = false
				}
			})
		} else {
			commMsgQueueError = false
		}
		let envelopeClass = `headerEnvelope ${commMsgQueueError ? " headerEnvelopeRed" : ""}`
		let storageManagementClass = `headerCog ${
			StorageEx.getAllWithInfo(getKeyInfo()).length > 0 ? " headerCogOrange" : ""
		}`

		let notificationCount =
			isSafe(NotificationCenterStore.receiveNotify) && NotificationCenterStore.receiveNotify.length > 0
				? NotificationCenterStore.receiveNotify.filter((x) => !x.read).length
				: ""

		return (
			// <header id="xsHeader" className={fixed ? "xs-fixed-header" : ""}>

			<header id="xsHeader" className={classnames({"xs-fixed-header": UIStore.mainMenuTab != "patientDetail"})}>
				<div className={`xs-main-header${serverRoleClass}`}>
					<div className="xs-logo">
						<img
							src="/public/images/ambee-logo-white-transparent.png"
							onClick={() => {
								RouterStore.push(settings.HOMEPAGE)
								UIStore.mainMenuTab = "mainmenu"
							}}
						/>
						<div className="xs-header-circles" onClick={() => this.showProgress()}>
							<i className={`fas fa-circle xs-${GlobalStore.gwApp.get("Me")}`} data-tip data-for="firstCircle" />
							<ReactTooltip id="firstCircle" place="bottom" effect="solid">
								<FormattedMessage id="Patient.header.firstCircle.tooltip" />
							</ReactTooltip>
							<i
								className={`fas fa-circle xs-${
									// gwAppStateColor === "red" && zprStateColor !== "grey" ? "red" : zprStateColor
									GlobalStore.gwApp.get("Zpr")
								}`}
								data-tip
								data-for="secondCircle"
							/>
							<ReactTooltip id="secondCircle" place="bottom" effect="solid">
								<FormattedMessage id="Patient.header.secondCircle.tooltip" />
							</ReactTooltip>
							{/* <i
								className={`fas fa-circle xs-${
									// gwAppStateColor === "red" && przsStateColor !== "grey" ? "red" : przsStateColor
									GlobalStore.gwApp.get("PrZS")
								}`}
								data-tip
								data-for="thirdCircle"
							/>
							<ReactTooltip id="thirdCircle" place="bottom" effect="solid">
								<FormattedMessage id="Patient.header.thirdCircle.tooltip" />
							</ReactTooltip> */}
						</div>
						<ReactTooltip id="commMsgQueueDialog" place="bottom" effect="solid">
							<FormattedMessage id="Common.label.tasksInBackground" />
						</ReactTooltip>
						<div
							className={envelopeClass}
							onClick={() => {
								CommMsgQueueDialogStore.open()
							}}
							data-tip
							data-for="commMsgQueueDialog"
						>
							<Badge
								// showZero={true}
								badgeContent={isSafe(UIStore.commMsgQueue) ? UIStore.commMsgQueue.size : 0}
								classes={{badge: "commMsgQueueBadge"}}
							>
								<i className="fas fa-envelope fa-lg" />
							</Badge>
						</div>
						<div
							className={storageManagementClass}
							onClick={() => {
								StorageManagementDialogStore.open()
							}}
							data-tip
							data-for="storageManagementDialog"
						>
							<Badge
								// showZero={true}
								badgeContent={StorageManagementDialogStore.unsavedData.length}
								classes={{badge: "storageManagementBadge"}}
							>
								<i className="fas fa-tasks fa-lg" />
							</Badge>
						</div>
					</div>
					<div className="xs-main-menu">
						<div>
							<XsMenu default={<i className="fas fa-sliders-v fa-lg" />} className={"menu-admin"} items={items} />
						</div>
						<div
							className={`xs-main-menu-btn ${UIStore.mainMenuTab === "agreements" ? "xs-active" : ""}`}
							onClick={() => {
								RouterStore.push("/agreements")
								UIStore.mainMenuTab = "agreements"
							}}
						>
							<i
								className={`${UIStore.mainMenuTab === "agreements" ? "fas" : "fal"} fa-file-contract fa-lg`}
								data-tip
								data-for="agreements"
							/>
							<ReactTooltip id="agreements">
								<FormattedMessage id="Common.menu.agreements" />
							</ReactTooltip>
							{UIStore.mainMenuTab === "agreements" && <FormattedMessage id="Common.menu.agreements" />}
						</div>
						<div
							className={`xs-main-menu-btn ${UIStore.mainMenuTab === "reports" ? "xs-active" : ""}`}
							onClick={() => {
								RouterStore.push("/reports")
								UIStore.mainMenuTab = "reports"
							}}
						>
							<i
								className={`${UIStore.mainMenuTab === "reports" ? "fas" : "fal"} fa-chart-pie fa-lg`}
								data-tip
								data-for="reports"
							/>
							<ReactTooltip id="reports">
								<FormattedMessage id="Common.menu.reports" />
							</ReactTooltip>
							{UIStore.mainMenuTab === "reports" && <FormattedMessage id="Common.menu.reports" />}
						</div>
						<div
							className={`xs-main-menu-btn ${UIStore.mainMenuTab === "employees" ? "xs-active" : ""}`}
							onClick={() => {
								RouterStore.push("/employees")
								UIStore.mainMenuTab = "employees"
							}}
						>
							<i
								className={`${UIStore.mainMenuTab === "employees" ? "fas" : "fal"} fa-address-card fa-lg`}
								data-tip
								data-for="employees"
							/>
							<ReactTooltip id="employees">
								<FormattedMessage id="Common.menu.employees" />
							</ReactTooltip>
							{UIStore.mainMenuTab === "employees" && <FormattedMessage id="Common.menu.employees" />}
						</div>
						<div
							className={`xs-main-menu-btn ${UIStore.mainMenuTab === "capitations" ? "xs-active" : ""}`}
							onClick={() => {
								RouterStore.push("/deliveries")
								UIStore.mainMenuTab = "capitations"
							}}
						>
							<i
								className={`${UIStore.mainMenuTab === "capitations" ? "fas" : "fal"} fa-euro-sign fa-lg`}
								data-tip
								data-for="capitations"
							/>
							<ReactTooltip id="capitations">
								<FormattedMessage id="Common.menu.capitationspage" />
							</ReactTooltip>
							{UIStore.mainMenuTab === "capitations" && <FormattedMessage id="Common.menu.capitationspage" />}
						</div>
						<div
							className={`xs-main-menu-btn ${
								UIStore.mainMenuTab === "mainmenu" || UIStore.mainMenuTab === "patientDetail" ? "xs-active" : ""
							}`}
							onClick={() => {
								RouterStore.push(settings.HOMEPAGE)
								UIStore.mainMenuTab = "mainmenu"
							}}
						>
							<i
								className={`${
									UIStore.mainMenuTab === "mainmenu" || UIStore.mainMenuTab === "patientDetail" ? "fas" : "fal"
								} fa-calendar fa-lg`}
								data-tip
								data-for="mainmenu"
							/>
							<ReactTooltip id="mainmenu">
								<FormattedMessage id="Common.menu.calendar" />
							</ReactTooltip>
							{(UIStore.mainMenuTab === "mainmenu" || UIStore.mainMenuTab === "patientDetail") && (
								<FormattedMessage id="Common.menu.calendar" />
							)}
						</div>
						<div>
							<XsMenu
								default={GlobalStore.currentOrgName}
								maxWidth={200}
								className={"menu-orgunits"}
								items={GlobalStore.orgunits}
								disabled={!isMainPage}
								buttonStyle={{color: "white"}}
								onClick={(dataRow) => GlobalStore.changeOrgUnit(dataRow)}
							/>
						</div>
						<div className="xs-user-menu">
							<XsMenu
								default={GlobalStore.userName}
								className={"menu-info"}
								items={[
									{
										name: "Helpdesk",
										className: "menuitem-helpdesk",
										onClick: () => window.open("https://helpdesk.ambee.sk", "_blank"),
										icon: <i className="far fa-question-circle"></i>
									},
									{
										name: (
											<div className="xs-relative">
												Upozornenia{" "}
												{isNotEmpty(notificationCount) && (
													<div className="xs-notification-badge xs-menu-badge">{notificationCount}</div>
												)}
											</div>
										),
										icon: <i className="fal fa-bell"></i>,
										onClick: () => NotificationCenterStore.openNotification()
									},
									{
										name: "Nastavenia",
										icon: <i className="fal fa-cog"></i>,
										onClick: () => {
											RouterStore.push("/settings")
											UIStore.mainMenuTab = ""
										}
									},
									{
										name: formatMessage({id: "Common.menu.logout"}),
										className: "menuitem-logout",
										onClick: async () => {
											UIStore.isFormSaving = true
											await GlobalStore.logoutGWApp()
											logout()
										},
										icon: <i className="fas fa-power-off"></i>
									}
								]}
							/>
							{isNotEmpty(notificationCount) && <div className="xs-notification-badge">{notificationCount}</div>}
						</div>
						<div>
							<i className="fal fa-info-circle pointer snowWhite ml-3 fa-lg" onClick={() => TourStore.open()}></i>
						</div>
					</div>
				</div>
				{/* {
          isPatientDetail && UIStore.showHeaderInPatientDetail && isSafe(DataStore.patientDTO.get("patient")) &&
          <div className="xs-header-patientInfo">
            <div className="xs-patientInfo-item xs-bold">{DataStore.patientDTO.get("patient").full_name}</div>
            <div className="xs-patientInfo-item"><h5>{age}</h5></div>
            <div className="xs-patientInfo-item"><h5><span>ID: </span>{identifier}</h5></div>
            <div className="xs-patientInfo-item"><h5><span><FormattedMessage id="Common.label.insurer" />: </span>{isNotEmpty(DataStore.patientDTO.get("insurer")) ? DataStore.patientDTO.get("insurer") : ""}</h5></div>
            {
              (isNotEmpty(DataStore.nonPayer) || isNotEmpty(DataStore.patientDTO.get("insurerType"))) &&
              <div className="xs-patientInfo-item">
                <h5><span className="xs-patient-nonpayer">{DataStore.nonPayer}</span>{isNotEmpty(DataStore.nonPayer) && isNotEmpty(DataStore.patientDTO.get("insurerType")) ? ", " : ""}{DataStore.patientDTO.get("insurerType")}</h5>
              </div>
            }
            <div className="xs-patientInfo-item">
              <h5 data-tip data-for="pacHeaderCapTootltip">{DataStore.patientDTO.get("isCapitation") ? <div className="xs-capitation-patient-icon">K</div> : ""}</h5>
              <ReactTooltip id="pacHeaderCapTootltip">
                <FormattedMessage id="Patient.header.tooltip.patientCapitation" />
              </ReactTooltip>
            </div>
          </div>
        } */}
				{UIStore.mainMenuTab === "patientDetail" && (
					<div className="xs-patient-header xs-row">
						<div className="xs-sidebar">
							<div className="xs-full-name xs-row xs-align" data-tip data-for="fullName">
								{isSafe(DataStore.patientDTO.get("patient")) ? DataStore.patientDTO.get("patient").full_name : ""}
							</div>
							{isNotEmpty(DataStore.patientDTO.get("patient")) && (
								<ReactTooltip id="fullName">{DataStore.patientDTO.get("patient").full_name}</ReactTooltip>
							)}
						</div>
						<div className="xs-section xs-row xs-flex">
							<nav className="xs-patient-detail-nav xs-row xs-align">
								<XsButton
									text={<FormattedMessage id="PatientDetail.cards.patientEHR" />}
									onClick={() => (UIStore.patientDetailTab = "EHR")}
									className={UIStore.patientDetailTab === "EHR" ? "xs-active mr-3 button-ehr" : "mr-3 button-ehr"}
								/>
								<XsButton
									text={<FormattedMessage id="Common.label.personalInfo" />}
									onClick={() => {
										UIStore.patientInfoTab = "contacts"
										ContactFormStore.isAdd = false
										UIStore.patientDetailTab = "PersonalInfo"
									}}
									className={
										UIStore.patientDetailTab === "PersonalInfo"
											? "xs-active mr-3 button-personal-info"
											: "mr-3 button-personal-info"
									}
								/>
								<XsButton
									text={<FormattedMessage id="PatientDetail.cards.insuranceServices" />}
									onClick={() => (UIStore.patientDetailTab = "InsuranceServices")}
									className={
										UIStore.patientDetailTab === "InsuranceServices"
											? "xs-active mr-3 button-insurance-info"
											: "mr-3 button-insurance-info"
									}
								/>
								<XsIconButton
									rect
									tooltip={formatMessage({id: "Invoice.form.VirtualCashRegister"})}
									icon={<i className="fal fa-money-bill-alt fa-lg white" />}
									className="xs-success mr-3"
									onClick={() => window.open("https://vrp.financnasprava.sk/#!/login", "_blank")}
								/>
								<XsIconButton
									rect
									tooltip={formatMessage({id: "Common.label.overviewPatientDelivery"})}
									icon={<i className="fal fa-hand-holding-usd fa-lg white" />}
									className="xs-success"
									onClick={() => PatientDeliveryDialogStore.open()}
								/>
								{false && (
									<XsButton
										text={<FormattedMessage id="PatientDetail.cards.aesculap" />}
										onClick={() => (UIStore.patientDetailTab = "Aesculap")}
										className={UIStore.patientDetailTab === "Aesculap" ? "xs-active" : ""}
									/>
								)}
							</nav>
							{isSafe(UIStore.insuranceMessage) &&
								isNotEmpty(UIStore.insuranceMessage.message) &&
								isNotEmpty(UIStore.insuranceMessage.subMessage) && (
									<div
										className="xs-insurance-warning xs-row xs-align"
										onClick={() => {
											UIStore.patientDetailTab = "PersonalInfo"
											UIStore.patientInfoTab = "insurers"
										}}
									>
										<i className="fal fa-exclamation-triangle fa-2x xs-amberWarning xs-warning-icon" />
										<div className="xs-column xs-bold">
											<span className="fontSize15">{UIStore.insuranceMessage.message}</span>
											<span className="fontSize13">{UIStore.insuranceMessage.subMessage}</span>
										</div>
									</div>
								)}
							<nav className="xs-patient-detail-actions xs-row">
								<div data-tip data-for="loadAllDrafts">
									<XsIconButton
										className="mr-3"
										icon={<i className="fal fa-arrow-from-bottom fa-lg button-hotbar-prescription" />}
										onClick={() => PatientCardContainerStore.loadAllDraftsBtnRef.props.onClick()}
									/>
								</div>
								<div data-tip data-for="prescribeDrug">
									<XsIconButton
										className="mr-3"
										icon={<i className="fal fa-pills fa-lg button-hotbar-prescription" />}
										onClick={() => UIStore.onHandleTabs("prescription")}
									/>
								</div>
								<div data-tip data-for="record">
									<XsIconButton
										className="mr-3"
										icon={<i className="fal fa-notes-medical fa-lg button-hotbar-record" />}
										onClick={() => UIStore.onHandleTabs("record")}
									/>
								</div>
								<div data-tip data-for="anamnesis">
									<XsIconButton
										className="mr-3"
										icon={<i className="fal fa-stethoscope fa-lg button-hotbar-anamnesis" />}
										onClick={() => UIStore.onHandleTabs("anamnesis")}
									/>
								</div>
								<div data-tip data-for="emptyRecord">
									<XsIconButton
										className="mr-3"
										icon={<i className="fal fa-clipboard fa-lg button-hotbar-record" />}
										onClick={() => PrintTemplatesStore.open("header", PrintTemplatesStore.getCommonValues())}
									/>
								</div>
								<div>
									<XsIconButton
										icon={
											<span>
												<i className="fal fa-plus fa-lg xs-black" data-tip data-for="addOtherFunctions" />
												<ReactTooltip id="addOtherFunctions" effect="solid">
													<FormattedMessage id="Common.label.addOtherFunctions" />
												</ReactTooltip>
											</span>
										}
										onClick={() => PatientDetailActionsStore.open()}
									/>
								</div>
							</nav>
						</div>
						<ReactTooltip id="loadAllDrafts">
							<FormattedMessage id="Common.label.loadTodayDrafs" />
						</ReactTooltip>
						<ReactTooltip id="prescribeDrug">
							<FormattedMessage id="Common.label.prescribeDrug" />
						</ReactTooltip>
						<ReactTooltip id="emptyRecord">
							<FormattedMessage id="Common.label.generalRecord" />
						</ReactTooltip>
						<ReactTooltip id="record">
							<FormattedMessage id="Common.label.dekurz" />
						</ReactTooltip>
						<ReactTooltip id="anamnesis">
							<FormattedMessage id="Common.label.anamnes" />
						</ReactTooltip>
					</div>
				)}
				<TourDialog />
			</header>
		)
	}
}

export default XsHeader
