import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
// import api from "../../../actions/api"
// import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import RouterStore from "../../../../../global/store/RouterStore"
import DraftReportStore from "../../../stores/DraftReportStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
// import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import TableStore from "../../../stores/TableStore"
// import ExportDraftReportDialog from "./exportDraftReportDialog"
import moment from "moment"
// import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class DraftReportList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false
		DraftReportStore.draftList = []

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("draftReportList") &&
					TableStore.tables["draftReportList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["draftReportList"]["index"]
				}

				DraftReportStore.loadDraftReport(form, props.intl.formatMessage({id: "Common.label.dateFilterRequired"}))
			},
			onError() {}
		}

		if (isEmpty(DraftReportStore.formRef)) {
			DraftReportStore.formRef = new MobxReactForm(
				{
					fields: {
						datefrom: {
							label: <FormattedMessage id="Capitation.List.From" />,
							type: "date",
							value: moment().startOf("month")
						},
						dateto: {
							label: <FormattedMessage id="Capitation.List.To" />,
							type: "date",
							value: moment().endOf("month")
						},
						// orgUnit: {
						// 	label: <FormattedMessage id="Common.label.orgunit" />,
						// 	value: getSelectedOrgUnitID()
						// },
						personIdentifier: {
							label: <FormattedMessage id="Patient.labels.IDNumber" />
						}
					}
				},
				{plugins: {dvr: validatorjs}, hooks, bindings}
			)
		}

		this.form = DraftReportStore.formRef

		DraftReportStore.loadDraftReport(this.form, props.intl.formatMessage({id: "Common.label.dateFilterRequired"}))
	}

	onHandleRowClick = (dataRow) => {
		if (dataRow.person_id) {
			RouterStore.push(`/patient?id=${dataRow.person_id}`)
		}
	}

	render() {
		DraftReportStore.isChangedFilter(this.form.values())

		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})
		return (
			<Grid container className="xs-draftReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						{/* <Grid item xs={2}>
							<XsSearchSelect white field={this.form.$("orgUnit")} items={GlobalStore.orgunits} />
						</Grid> */}
						<Grid item xs={2}>
							<XsInput white field={this.form.$("personIdentifier")} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : DraftReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							config={{
								columnDefs: {
									created_at: {
										title: <FormattedMessage id="Capitation.List.From" />,
										type: "datetime",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									patient_full_name: {
										title: <FormattedMessage id="Common.label.fullName" />,
										type: "string",
										design: {
											width: "100px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									record_type_name: {
										title: <FormattedMessage id="Common.label.recordTypeName" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									org_unit_code_ext: {
										title: <FormattedMessage id="Common.label.orgUnitCode" />,
										type: "string",
										design: {
											width: "100px"
										}
									}
								},
								options: {
									name: "draftReportList",
									showCursor: true,
									// selectRow: true,
									defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										// const lastInsurance =
										// 	isSafe(dataRow.full_patient) && dataRow.full_patient.insurances.length > 0
										// 		? dataRow.full_patient.insurances.find((i) => i.last)
										// : null
										return dataRow
									}
								},
								dataSource: DraftReportStore.draftList
							}}
						/>
					)}
				</Grid>
				{/* <ExportDraftReportDialog form={this.form} /> */}
			</Grid>
		)
	}
}
