"use strict"

import {observable, action} from "mobx"

import {getSelectedOrgUnitID} from "../../../global/helpers/actions"

import DataStore from "./DataStore"
import UIStore from "./UIStore"

import api from "../actions/api"

class MovePatientsToAnotherOrgunitStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close(form) {
		form.reset()
		this.isOpen = false
		DataStore.patientChecked = []
	}

	@action movePatients(form) {
		const formValues = form.values()
		UIStore.isFormSaving = true

		let req = {
			patient: DataStore.patientChecked,
			target_unit: {_id: formValues.orgunitId, _type: "Entity.Data.OrgUnit", _ref: true},
			include_capitation: formValues.moveCapitation
		}

		api

			.moveRecords(req, getSelectedOrgUnitID())
			.call()
			.then((res) => {
				if (isSafe(res)) {
					this.close(form)
				}
				UIStore.isFormSaving = false
			})
	}
}

var singleton = new MovePatientsToAnotherOrgunitStore()
export default singleton
