import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "agreement_health_care"
				},

				// name: {},
				first_name: {
					label: "Meno"
				},
				last_name: {
					label: "Priezvisko"
				},
				identifier: {},
				insurerCode: {},

				address: {},
				addressForm: {
					label: <FormattedMessage id="Patient.form.contactForm.address" />
				},
				phone: {},
				phoneForm: {
					label: "Mobil"
				},
				email: {},
				emailForm: {
					label: "E-mail"
				},

				doctorname: {},
				doctoraddress: {},
				orgunitcontact: {},
				doctorcode: {},
				orgunitcode: {},

				///

				oldDoctor: {
					label: "Adresa doterajšieho lekára"
				},

				oldDoctorFullName: {},
				oldDoctorId: {},
				oldDoctorExpertiseId: {
					label: "Doterajší lekár"
				},
				oldDoctorOUPZS: {
					label: "Doterajší OUPZS"
				},
				oldDoctorACode: {},
				oldDoctorPCode: {},

				newDate: {
					label: "Deň uzavretia novej dohody o PZS",
					value: moment(),
					type: "date"
				},

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
