"use strict"
// default imports for all farms
import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
// import MobxReactForm from "mobx-react-form"
// import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"
import moment from "moment"
import {FormattedMessage, injectIntl} from "react-intl"
import api from "../../../actions/api"

// import UI controls here
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsNumberInput from "../../../../../global/ui/xsInput/xsNumberInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsRadioButtonGroup from "../../../../../global/ui/xsRadioButtonGroup/xsRadioButtonGroup"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsGroupValidator from "../../../../../global/ui/xsGroupValidator/xsGroupValidator"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

//material UI
// import Snackbar from "@material-ui/core/Snackbar"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Badge from "@material-ui/core/Badge"

//Stores
import DataStore from "../../../stores/DataStore"
import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import UIStore from "../../../stores/UIStore"
import WarningStore from "../../../../../global/store/WarningStore"
import GenerikaDialogStore from "../../../stores/GenerikaDialogStore"
import RindividHelpDialogStore from "../../../stores/RindividHelpDialogStore"

//dialogs
import DrugInteractionsDialog from "./drugInteractionsDialog"
import MedicineCardDialog from "./medicineCardDialog"
import GenerikaDialog from "./generikaDialog"
import ConfirmInteracationDialog from "./confirmInteractionDialog"
import DoctorOUPZwarningDialogStore from "../../../stores/DoctorOUPZwarningDialogStore"
import DoctorOUPZwarningDialog from "./doctorOUPZwarningDialog"
import RindividHelpDialog from "./rindividHelpDialog"

//mobxForm stuff
// import fields from "./patientPrescriptionFields"
// import dataBindings from "./patientPrescriptionBindings"
// import {loadDataToFormByScheme} from "../../../../../global/helpers/bindings"
// import bindings from "../../../../../global/ui/globalUISchemeBindings"

//helper functions
import {getSelectedOrgUnitID, getUserDoctorInfo} from "../../../../../global/helpers/actions"
import renderHTML from "react-render-html"
//autosave
// import {SaveableForm} from "../saveableForm/saveableForm"
import TemplateForm from "../patientRecordForm/templateForm"
// import actionPatientInfo from "../../../actions/patientInfo"
// import {formDataStateCL} from "../../../../../global/config/codeLists"

//other
import PrescriptionLimitations from "./prescriptionLimitations"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"

const styles = () => ({
	paddingRightSide: {
		// paddingLeft: "20px",
		paddingTop: "20px"
	}
})

// @observer
// export class PatientPrescriptionForm extends SaveableForm {
function PatientPrescriptionForm(props) {
	useEffect(() => {
		PatientPrescriptionStore.checkValidationRules(props.form)
		checkDateValidity()
		setTimeout(() => {
			GlobalStore.handleClickPrescriptionFocus(props.keyVal)
		}, 0)
	}, [])

	const [loadingDrugsFormGenerics, setloadingDrugsFormGenerics] = useState(false)

	const handleChangeQuantization = (value, initLoad = false) => {
		if (value === "FIX" || value === "XYZW" || value === "AE") {
			props.form.$("quantization").value = "FIX"
			props.form.$("dosage").set("label", <FormattedMessage id="Patient.form.patientPrescription.numberOfTablets" />)
			if (value === "AE") {
				props.form.$("quantizationRadio").value = "second"
			} else if (value === "XYZW") {
				props.form.$("quantizationRadio").value = "first"
			}
			// setState({showArea: false})
			props.form.$("showArea").value = false
			if (!initLoad) {
				props.form.$("dosage").value = ""
				props.form.$("repeatingMoment").value = "D1"
				if (isSafe(props.form.$("drugs").options.forceRefresh) && props.form.$("prescriptionType").value == "RBULK") {
					props.form.$("drugs").options.forceRefresh()
				} else if (props.form.$("prescriptionType").value == "RDIETETIC" && isNotEmpty(props.form.$("medFood").value)) {
					props.form.$("medicationRoute").value = "PO"
				}
			}
		} else {
			props.form.$("dosage").set("label", "")
			props.form.$("dosage").value = ""
			// if (fromConstructor) {
			// 	state.showArea = true
			// } else {
			// 	setState({showArea: true})
			// }
			props.form.$("showArea").value = true
			props.form.$("unit").value = ""
			props.form.$("medicationRoute").value = ""
			props.form.$("repeatingMoment").value = ""
			props.form.$("days").value = ""
		}
		PatientPrescriptionStore.checkValidationRules(props.form)
	}

	const handleRadio = () => {
		props.form.$("quantizationRadio") === "first"
			? (props.form.$("dosage_type").value = "XYZW")
			: (props.form.$("dosage_type").value = "AE")
		PatientPrescriptionStore.generateDrugDays(props.form)
		setDosageText()
		PatientPrescriptionStore.checkValidationRules(props.form)
	}

	const disableRepeat = (value) => {
		if (value) {
			props.form.$("repeatType").value = "1"
			props.form.$("repeatTypeSecondary").value = ""
			props.form.$("maxRepeats").value = 1
			props.form.$("repeatPlain").value = ""
			// setState({
			// 	disableRepeat: true
			// })
			props.form.$("disableRepeat").value = true
		} else {
			// setState({
			// 	disableRepeat: false
			// })
			props.form.$("disableRepeat").value = false
		}
	}

	const disableOpiateCheckbox = (value) => {
		props.form.$("disableOpiateCheckbox").value = value
		// setState({
		// 	disableOpiateCheckbox: value
		// })
	}

	const setDosageText = () => {
		let dsText
		let _ = props.form
		if (_.$("quantizationRadio").value === "first")
			dsText = `${+_.$("morning").value}-${+_.$("lunch").value}-${+_.$("evening").value}-${+_.$("sleep").value}`
		else dsText = `${_.$("dosage").value}`
		dsText +=
			isSafe(_.$("unit").value) && _.$("unit").value !== "" && _.$("unit").value !== "PC"
				? " " +
				  (isSafe(GlobalStore.CL["medicationDoseUnits"]) &&
				  isSafe(GlobalStore.CL["medicationDoseUnits"].find((i) => _.$("unit").value === i.code))
						? GlobalStore.CL["medicationDoseUnits"].find((i) => _.$("unit").value === i.code).name_ext
						: "")
				: ""
		if (_.$("quantizationRadio").value === "second")
			dsText +=
				isSafe(_.$("everyHour").value) && _.$("everyHour").value !== ""
					? (+_.$("everyHour").value > 1 ? " á " : " á ") + _.$("everyHour").value + " hod."
					: ""
		if (
			isSafe(GlobalStore.CL["medicationRepeats"]) &&
			isSafe(_.$("repeatingMoment").value) &&
			_.$("repeatingMoment").value !== ""
		) {
			let data = GlobalStore.CL["medicationRepeats"].find((i) => _.$("repeatingMoment").value === i.code)
			if (isSafe(data)) dsText += " " + data.name_ext.toLowerCase()
			else dsText += " "
		}
		if (
			isSafe(GlobalStore.CL["medicationRoutes"]) &&
			isSafe(_.$("medicationRoute").value) &&
			_.$("medicationRoute").value !== ""
		) {
			let data = GlobalStore.CL["medicationRoutes"].find((i) => _.$("medicationRoute").value === i.code)
			if (isSafe(data)) dsText += " " + data.name_ext
			else dsText += " "
		}
		dsText +=
			isSafe(_.$("days").value) && _.$("days").value !== ""
				? " po dobu " + _.$("days").value + (+_.$("days").value > 1 ? " dní" : " dňa")
				: ""
		_.$("ds").value = dsText
		PatientPrescriptionStore.checkValidationRules(props.form)
	}

	const typeChanged = () => {
		if (props.form.$("prescriptionType").value == "MEDEQUIP") {
			props.form.$("quantization").set("value", "VAR")
			handleChangeQuantization("VAR")
			PatientPrescriptionStore.genererateValidity(props.form, props)
		} else {
			props.form.$("medDevDescription").value = ""
		}
		if (props.form.$("prescriptionType").value == "RBULK") {
			props.form.$("quantization").set("value", "FIX")
			handleChangeQuantization("FIX")
			PatientPrescriptionStore.genererateValidity(props.form, props)
			if (isSafe(props.form.$("drugs").options.forceRefresh)) {
				setTimeout(() => {
					props.form.$("drugs").options.forceRefresh()
				}, 0)
			}
		} else {
			props.form.$("days").value = ""
		}
		if (props.form.$("prescriptionType").value == "RDIETETIC") {
			props.form.$("unit").value = ""
			setDosageText()
		}
		if (props.form.$("prescriptionType").value != "RINDIVID") {
			PatientPrescriptionStore.ingredientsChipsForm = {}
			PatientPrescriptionStore.ingredientsChips = {}
		} else if (props.form.$("prescriptionType").value == "RINDIVID") {
			if (isSafe(GlobalStore.CL["IVLs"])) {
				let tmp = GlobalStore.CL["IVLs"].find((obj) => obj.code_ext == "M01003")
				if (isSafe(tmp)) {
					props.form.$("productCode").set("value", "" + tmp.code)
					props.form.$("quantization").set("value", "REC")
					handleChangeQuantization("REC")
				}
			}
			GlobalStore.handleClickPrescriptionFocus(props.keyVal)
		}
		props.form.$("medEquipDoseUnit").value = ""
		disableRepeat(
			!(props.form.$("prescriptionType").value == "RBULK" || props.form.$("prescriptionType").value == "MEDEQUIP")
		)
		props.form.$("disableRepeat").value = !(
			props.form.$("prescriptionType").value == "RBULK" || props.form.$("prescriptionType").value == "MEDEQUIP"
		)
		PatientPrescriptionStore.prescriptionLimitations[props.keyVal] = []
		props.form.$("expertiseWarningText").value = ""
		props.form.$("supplement").value = ""
		PatientPrescriptionStore.setDrugPrice(props.formKey, 0, 0)
		PatientPrescriptionStore.checkValidationRules(props.form)
	}
	const repeatTypeChanged = () => {
		if (props.form.$("repeatType").value == "1") {
			props.form.$("maxRepeats").value = 1
		} else {
			props.form.$("maxRepeats").value = 2
		}
		PatientPrescriptionStore.checkValidationRules(props.form)
	}

	const handleChipformChange = (form, name) => {
		let values = form.get("value")
		if (isSafe(name)) {
			values.col1NameExt = name
		}
		form.set(values)
	}

	const doseUnitTransform = () => {
		switch (props.form.$("medEquipDoseUnit").value) {
			case "PKG":
				return "Balenie"
			case "PC":
				return "Kus"
			default:
				return props.form.$("medEquipDoseUnit").value
		}
	}

	const checkDoctorExpertise = (expertises) => {
		let warningString = getDoctorExpertiseString(expertises)
		if (isNotEmpty(warningString)) {
			// setState({expertiseWarningText: warningString})
			props.form.$("expertiseWarningText").value = warningString
			// WarningStore.open(warningString)
		}
	}

	const getDoctorExpertiseString = (expertises) => {
		let doctor = getUserDoctorInfo()
		if (
			isNotSafe(doctor.expertise) ||
			(isSafe(doctor.expertise) && isNotSafe(expertises.find((exp) => exp.code == doctor.expertise.code)))
		) {
			let warningString =
				"<b>" +
				props.intl.formatMessage({
					id: "Patient.form.patientPrescription.expertiseWarning"
				}) +
				"</b> " +
				props.intl.formatMessage({
					id: "Common.label.allowedExpertises"
				}) +
				" "
			expertises.forEach((row, idx) => {
				warningString +=
					row.code_ext +
					(isSafe(row.name_ext) ? " (" + row.name_ext + ")" : "") +
					(idx != expertises.length - 1 ? ", " : "")
			})
			return warningString
		} else return ""
	}

	const checkDrugWarnings = (drug) => {
		//EQUORAL liek na test pre krosnerovu
		let warningString = ""
		if (isSafe(drug.expertises) && drug.expertises.length > 0) {
			// warningString = getDoctorExpertiseString(drug.expertises)
			// setState({expertiseWarningText: getDoctorExpertiseString(drug.expertises)})
			props.form.$("expertiseWarningText").value = getDoctorExpertiseString(drug.expertises)
		}
		if (
			drug.opioid ||
			drug.dispensation_type == "Rb" ||
			(typeof drug.dispensation_type === "object" && drug.dispensation_type._id == "Rb")
		) {
			warningString += isNotEmpty(warningString)
				? "<br/><br/>" + props.intl.formatMessage({id: "Patient.form.patientPrescription.opiateWarning"})
				: props.intl.formatMessage({id: "Patient.form.patientPrescription.opiateWarning"})
		}
		if (isNotEmpty(warningString)) {
			WarningStore.open(warningString)
		}
	}

	const checkSendingDoctorExpertise = (doctorExpertise) => {
		if (isNotEmpty(props.form.$("expertiseWarningText").value)) {
			if (!props.form.$("expertiseWarningText").value.includes(doctorExpertise)) {
				WarningStore.open(props.intl.formatMessage({id: "Common.label.prescriptionChangeDoctorWarning"}))
			}
		}
	}

	const checkDateValidity = (doctorRecomDate = null, recommendationDateValidity = null) => {
		let doctorDate = isSafe(doctorRecomDate) ? doctorRecomDate : props.form.$("doctorDate").value
		let endDate = isSafe(recommendationDateValidity)
			? recommendationDateValidity
			: props.form.$("recommendationDateValidity").value

		if (typeof endDate === "string" && isNotEmpty(endDate) && endDate.length >= 10) {
			endDate = moment(endDate)
		}

		if (typeof doctorDate === "string" && isNotEmpty(doctorDate) && doctorDate.length >= 10) {
			doctorDate = moment(doctorDate)
		}

		if (isSafe(endDate) && moment.isMoment(endDate) && endDate.isBefore(moment(), "day")) {
			WarningStore.open(
				props.intl.formatMessage({
					id: "Common.label.validityDateOlderThanNow"
				})
			)
		} else {
			if (
				isNotEmpty(doctorDate) &&
				moment.isMoment(doctorDate) &&
				isEmpty(endDate) &&
				doctorDate.diff(moment(), "days") < -365
			) {
				WarningStore.open(
					props.intl.formatMessage({
						id: "Patient.form.patientPrescription.oldDateWarning"
					})
				)
			} else if (
				isNotEmpty(doctorDate) &&
				moment.isMoment(doctorDate) &&
				isNotEmpty(endDate) &&
				moment.isMoment(endDate) &&
				endDate.isBefore(doctorDate, "day")
			) {
				WarningStore.open(
					props.intl.formatMessage({
						id: "Common.label.dateIsOlderThanValidityDate"
					})
				)
			}
		}
	}

	const generateRecommValidity = (date) => {
		if (isSafe(date) && moment.isMoment(date) && isEmpty(props.form.$("recommendationDateValidity").value)) {
			props.form.$("recommendationDateValidity").value = moment(date).add(1, "year")
		}
	}

	const checkReplacementProhibited = () => {
		props.form.$("replacementProhibited").value = false
		PatientPrescriptionStore.prohibitedChips[props.keyVal] = []
		if (
			props.form.$("prescriptionType").value == "RBULK" &&
			isNotEmpty(props.form.$("productCode").value) &&
			isNotEmpty(props.form.$("drugs").value) &&
			props.form.$("prescriptionBySupplement").value
		) {
			// setState({showReplacementProhibited: true})
			props.form.$("showReplacementProhibited").value = true
		} else {
			// setState({showReplacementProhibited: false})
			props.form.$("showReplacementProhibited").value = false
			props.form.$("replacementProhibited").value = false
			PatientPrescriptionStore.prohibitedChips[props.keyVal] = []
		}
	}

	const showReplacementProhibited = () => {
		if (
			props.form.$("prescriptionType").value == "RBULK" &&
			isNotEmpty(props.form.$("productCode").value) &&
			isNotEmpty(props.form.$("drugs").value) &&
			props.form.$("prescriptionBySupplement").value
		) {
			props.form.$("showReplacementProhibited").value = true
		}
	}

	const generatePrescriptionReason = (diagnosisVal) => {
		let stringToAdd = null
		if (diagnosisVal.code_ext == "U99.01") {
			stringToAdd = "Ink. II.st."
		} else if (diagnosisVal.code_ext == "U99.02") {
			stringToAdd = "Ink. III.st. trvalá"
		} else if (diagnosisVal.code_ext == "U99.03") {
			stringToAdd = "Ink. III.st. trvalá, nezvratná"
		}

		if (isNotEmpty(stringToAdd)) {
			if (isEmpty(htmlToPlain(props.form.$("prescriptionReason").value))) {
				props.form.$("prescriptionReason").value = stringToAdd
				props.form.$("showDetails").value = true
			}
			if (props.form.$("quantization").value == "VAR" && isEmpty(htmlToPlain(props.form.$("dosage").value))) {
				props.form.$("dosage").value = stringToAdd
			}
		}
	}

	// const showDetails = () => {
	// 	// setState({showDetails: true})
	// 	props.form.$("showDetails").value
	// }

	const repeatTypeData = [
		{
			code: "0",
			_id: "0",
			name_ext: <FormattedMessage id="Patient.form.patientPrescription.repeat" />,
			search_column: <FormattedMessage id="Patient.form.patientPrescription.repeat" />
		},
		{
			code: "1",
			_id: "1",
			name_ext: <FormattedMessage id="Patient.form.patientPrescription.single" />,
			search_column: <FormattedMessage id="Patient.form.patientPrescription.single" />
		}
	]
	const patientPaysData = [
		{
			code: "NEZML",
			_id: "NEZML",
			name_ext: "Nezmluvný lekár"
		},
		{
			code: "JASAM",
			_id: "JASAM",
			name_ext: "Pacient hradí sám"
		}
	]
	const filteredMedicationDosageTypes = isSafe(GlobalStore.CL["medicationDosageTypes"])
		? GlobalStore.CL["medicationDosageTypes"].filter((x) => x.code == "VAR" || x.code == "REC")
		: []

	const iplText = props.intl.formatMessage({id: "Patient.form.patientPrescription.ingredientsChips"})

	return (
		<section className="xs-patient-card-form xs-patient-prescription-form xs-column">
			{UIStore.isFormSaving && (
				<XsLoading
					overlay={true}
					message={isNotEmpty(UIStore.isFormSavingMessage) ? UIStore.isFormSavingMessage : ""}
				/>
			)}

			<div className="xs-patient-form xs-row xs-flex">
				<div className="xs-patient-form-left xs-flex">
					{/* {(DataStore.savedFormObject &&
							PatientPrescriptionStore.doctorLoadedFlag &&
							(DataStore.checkFormDataState(formName, formDataStateCL.WITHDEFS) ||
								DataStore.checkFormDataState(formName, formDataStateCL.EMPTY))) ||
							(GlobalStore.confirmationDialogOpened == formName &&
								renderConfirmDialog(
									() => (
										(PatientPrescriptionStore.doctorLoadedFlag = false),
										DataStore.setFormDataState(formName, formDataStateCL.MODIFIED)
									),
									() => (PatientPrescriptionStore.doctorLoadedFlag = false)
								))} */}
					<Grid container direction="row" spacing={8}>
						<Grid item xs={6}>
							<SearchSelect
								field={props.form.$("prescriptionType")}
								items={
									isSafe(GlobalStore.CL["medicationSubTypes"])
										? GlobalStore.CL["medicationSubTypes"].filter(
												(data) => data.code != "ADMDRUG" && data.code != "ADMVACC"
										  )
										: []
								}
								disabled={props.form.$("disableType").value}
								required
								onChange={() => {
									typeChanged()
								}}
							/>
						</Grid>
						{/* onChange={value => { handleChange("prescriptionType", value) */}
						<Grid item xs={6}>
							<SearchSelect
								field={props.form.$("repeatType")}
								items={repeatTypeData}
								required
								disabled={props.form.$("disableRepeat").value}
								onChange={() => {
									repeatTypeChanged()
								}}
							/>
						</Grid>
						{/* onChange={value => { handleChange("repeatType", value) }} */}
					</Grid>
					{props.form.$("repeatType").value == 0 && (
						<Grid container direction="row" spacing={8}>
							<Grid item xs={props.form.$("repeatTypeSecondary").value == "OTH" ? 3 : 4}>
								<SearchSelect
									field={props.form.$("repeatTypeSecondary")}
									items={
										isSafe(GlobalStore.CL["medicationRepeats"])
											? GlobalStore.CL["medicationRepeats"]
													.filter((a) => {
														return a.code == "M" || a.code == "QY" || a.code == "HY" || a.code == "OTH" //|| a.code == "Y"
													})
													.sort((a, b) => a.item_order - b.item_order)
											: []
									}
									onChange={() => {
										props.form.$("repeatPlain").value = ""
										PatientPrescriptionStore.genererateValidity(props.form, props)
									}}
									required
								/>
							</Grid>
							{props.form.$("repeatTypeSecondary").value == "OTH" && (
								<Grid item xs={3}>
									<XsNumberInput
										field={props.form.$("repeatPlain")}
										type={"number"}
										min={"1"}
										step={"1"}
										onChange={() => {
											PatientPrescriptionStore.genererateValidity(props.form, props)
										}}
									/>
								</Grid>
							)}
							<Grid item xs={props.form.$("repeatTypeSecondary").value == "OTH" ? 3 : 4}>
								<XsNumberInput
									field={props.form.$("maxRepeats")}
									type={"number"}
									min={"2"}
									step={"1"}
									onChange={() => {
										PatientPrescriptionStore.genererateValidity(props.form, props)
									}}
								/>
							</Grid>
							<Grid item xs={props.form.$("repeatTypeSecondary").value == "OTH" ? 3 : 4}>
								<XsDateTimePicker field={props.form.$("validity")} showTimeSelect={true} />
							</Grid>
						</Grid>
					)}
					<div className="xs-section">
						<div className="xs-prescritpion-type">
							<div className="xs-autocomplete">
								{props.form.$("prescriptionType").value == "RBULK" && (
									<div>
										<div
											style={{
												width:
													props.form.$("prescriptionType").value == "RBULK" &&
													isNotEmpty(props.form.$("productCode").value) &&
													isNotEmpty(props.form.$("drugs").value) &&
													props.form.$("prescriptionBySupplement").value
														? "85%"
														: "90%",
												display: "inline-block"
											}}
										>
											<XsAutocomplete
												field={props.form.$("drugs")}
												minLengthForSearch="3"
												api={api.loadDrugs}
												autoFocus={props.keyVal == 0 ? true : false}
												removePunctuation={true}
												clearText
												tabindex={1 + 100 * props.keyVal}
												renderFooterInModal={true}
												addSpecialFilter={() => {
													if (props.form.$("onlyCategorisedDrugs").value) {
														return [
															{
																associated_column: "only_categorized",
																predicate: "=",
																values: [
																	{
																		id_value: 1
																	}
																]
															}
														]
													} else {
														return null
													}
												}}
												ref={(input) => (GlobalStore.defaultPrescriptionFields[props.keyVal] = input)}
												inputRenderer={(obj, menuItem) =>
													(menuItem && obj.has_limitation
														? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
														: obj.code_ext) +
													" | " +
													(menuItem && obj.has_limitation
														? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
														: obj.name_ext) +
													" | " +
													obj.supplement +
													" | " +
													(obj.patient_payment
														? (menuItem ? "<span style=color:#01579b>" : "") +
														  obj.patient_payment +
														  (menuItem ? "</span>" : "") +
														  " | "
														: "") +
													(obj.insurer_payment
														? (menuItem ? "<span style=color:#00A787>" : "") +
														  obj.insurer_payment +
														  (menuItem ? "</span>" : "") +
														  " | "
														: "") +
													(obj.price ? (menuItem ? "<b>" : "") + obj.price + (menuItem ? "</b>" : "") + " | " : "") +
													obj.atc +
													" | " +
													obj.active_ingredient +
													" | " +
													obj.registrator_name
												}
												saveValue={(obj) => obj.code}
												onEmptyLoadResponse={() => {
													WarningStore.open(
														props.intl.formatMessage({
															id: "Common.label.unactiveDrug"
														})
													)
												}}
												postAction={(value, loaded, forcedRefresh) => {
													disableRepeat(value.antibiotic || value.opioid)
													disableOpiateCheckbox(value.opioid)
													props.form.$("opiate").value = value.opioid
													props.form.$("doseUnitId").value = value.dose_unit
													props.form.$("supplement").value = value.supplement
													props.form.$("productCode").value = "" + value.code
													props.form.$("prescriptionBySupplement").value = value.prescription_by_supplement
													props.form.$("isDrugCategorized").value = value.categorized
													props.form.$("drugBaseDoseAmount").value = value.package_size
													PatientPrescriptionStore.setDrugPrice(props.formKey, value.patient_payment, value.price)
													// PatientPrescriptionStore.drugPrices.set(props.keyVal, value.patient_payment)
													if (isEmpty(props.form.$("recordId").value)) {
														PatientPrescriptionStore.findDrugs(
															DataStore.patientDTO.get("patientID"),
															getSelectedOrgUnitID(),
															value.code
														)
													}
													PatientPrescriptionStore.loadPrescriptionLimitations(value._id, props.keyVal)
													if (!loaded || forcedRefresh) {
														props.form.$("unit").value = value.dose_unit
														props.form.$("medicationRoute").value = value.route
														PatientPrescriptionStore.generateDrugDays(props.form)
														setDosageText()
													}
													if (loadingDrugsFormGenerics) {
														setloadingDrugsFormGenerics(false)
														props.options.handleLoadPrescriptionTemplate(props.formKey)
													}
													if (!loaded && !forcedRefresh) {
														props.options.handleLoadPrescriptionTemplate(props.formKey)
														checkReplacementProhibited()
													} else if (loaded && !forcedRefresh) {
														showReplacementProhibited()
													}

													checkDrugWarnings(value)
													if (!forcedRefresh) {
														PatientPrescriptionStore.checkDrugsHistory(value._id)
													}

													//setDosageText sa musi volat na konci po vsetkych zmenach
													if (!loaded || forcedRefresh) {
														setDosageText()
													}
												}}
												modalConfig={(textValue, clickHandler) => {
													return {
														columnDefs: {
															code_ext: {
																title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
																type: "string",
																dbName: "code_ext"
															},
															name_ext: {
																title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.name_ext" />,
																type: "string",
																dbName: "search_column",
																filterLabel: <FormattedMessage id="Common.label.search" />,
																filter: {
																	gridWidth: 3,
																	defaultValue: textValue,
																	renderElement: "input"
																},
																sortable: true,
																design: {
																	body: {
																		className: (row) => (row.has_limitation ? "xs-amberWarning" : null)
																	}
																}
															},
															//Virtulany stlpec ktory sa nezobrazuje sluzi len na naviazanie filtra
															name_extFilter: {
																title: <FormattedMessage id="Delivery.list.modal.drugName" />,
																type: "string",
																dbName: "name_ext",
																filter: {
																	gridWidth: 3,
																	defaultValue: "",
																	renderElement: "input"
																},
																design: {
																	width: "0px",
																	body: {
																		className: "hide"
																	},
																	header: {
																		className: "hide"
																	}
																}
															},
															supplement: {
																title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
																type: "string",
																dbName: "supplement"
															},
															patient_payment: {
																title: (
																	<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
																),
																type: "string",
																dbName: "patient_payment"
															},
															insurer_payment: {
																title: (
																	<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
																),
																type: "string",
																dbName: "insurer_payment"
															},
															atc: {
																title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
																type: "string",
																dbName: "atc"
															},
															active_ingredient: {
																title: (
																	<FormattedMessage id="Patient.form.patientPrescription.drugsTable.active_ingredient" />
																),
																type: "string",
																dbName: "active_ingredient"
															},
															registrator_name: {
																title: <FormattedMessage id="Common.label.registratorName" />,
																type: "string",
																dbName: "registrator_name"
															}
														},
														options: {
															// selectRow: true,
															showCursor: true,
															onRowClick: (dataRow) => {
																clickHandler(dataRow)
															},
															renderHeaderAsFirstRow: true,
															// row_count_full: 100,
															findSameATC: true,
															paging: {
																rowsPerPage: 100,
																rowsPerPageOptions: [5, 10, 25, 50, 100]
															}
														}
														// filterValue: props.form.$("onlyCategorisedDrugs").value
														// 	? [
														// 			{
														// 				associated_column: "only_categorized",
														// 				predicate: "=",
														// 				values: [
														// 					{
														// 						id_value: 1
														// 					}
														// 				]
														// 			}
														// 	  ]
														// 	: undefined
													}
												}}
												onClear={() => {
													props.form.$("opiate").value = ""
													props.form.$("unit").value = ""
													props.form.$("medicationRoute").value = ""
													props.form.$("supplement").value = ""
													props.form.$("productCode").value = ""
													props.form.$("prescriptionBySupplement").value = ""
													props.form.$("isDrugCategorized").value = false
													// setState({expertiseWarningText: ""})
													props.form.$("expertiseWarningText").value = ""
													PatientPrescriptionStore.prescriptionLimitations[props.keyVal] = []
													PatientPrescriptionStore.setDrugPrice(props.formKey, 0, 0)
													checkReplacementProhibited()
													disableOpiateCheckbox(false)
													setDosageText()
												}}
											/>
										</div>
										<div className="xs-cat-checkbox">
											<XsCheckbox
												field={props.form.$("onlyCategorisedDrugs")}
												tooltipId={"Patient.form.patientPrescription.onlyCategorisedDrugsTooltip"}
												shrinkLabel={true}
												onChange={() => {
													if (
														props.form.$("drugs").value == "" &&
														props.form.$("onlyCategorisedDrugs").value == false
													) {
														props.form.$("drugs").options.searchLastStringAgain()
													} else {
														props.form.$("drugs").options.removeLastSearchString()
													}
													props.form.$("drugs").value = ""
												}}
											/>
										</div>
									</div>
								)}
								{props.form.$("prescriptionType").value == "MEDEQUIP" && (
									<XsAutocomplete
										field={props.form.$("medEquip")}
										minLengthForSearch="3"
										api={api.loadMedDev}
										clearText
										tabindex={1 + 100 * props.keyVal}
										autoFocus={props.keyVal == 0 ? true : false}
										ref={(input) => (GlobalStore.defaultPrescriptionFields[props.keyVal] = input)}
										inputRenderer={(obj, menuItem) =>
											(menuItem && obj.has_limitation
												? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
												: obj.code_ext) +
											" | " +
											(menuItem && obj.has_limitation
												? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
												: obj.name_ext) +
											" | " +
											(obj.supplement ? obj.supplement + " | " : "") +
											(obj.patient_payment
												? (menuItem ? "<span style=color:#01579b>" : "") +
												  obj.patient_payment +
												  (menuItem ? "</span>" : "") +
												  " | "
												: "") +
											(obj.insurer_payment
												? (menuItem ? "<span style=color:#00A787>" : "") +
												  obj.insurer_payment +
												  (menuItem ? "</span>" : "") +
												  " | "
												: "") +
											(obj.price ? (menuItem ? "<b>" : "") + obj.price + (menuItem ? "</b>" : "") + " | " : "")
										}
										saveValue={(obj) => obj.code}
										onEmptyLoadResponse={() => {
											WarningStore.open(
												props.intl.formatMessage({
													id: "Common.label.unactiveDrug"
												})
											)
										}}
										postAction={(value, loaded) => {
											props.form.$("productCode").value = "" + value.code
											props.form.$("doseUnitId").value = value.dose_unit
											props.form.$("medEquipDoseUnit").value = value.dose_amount_unit_code_ext
											props.form.$("medEquipCodeExt").value = value.code_ext
											PatientPrescriptionStore.setDrugPrice(props.formKey, value.patient_payment, value.price)
											PatientPrescriptionStore.checkDrugsHistory(value._id)
											if (isSafe(value.expertises) && value.expertises.length > 0) {
												checkDoctorExpertise(value.expertises)
											}
											if (!loaded) {
												props.options.handleLoadPrescriptionTemplate(props.formKey)
											}
											PatientPrescriptionStore.checkValidationRules(props.form)
										}}
										onClear={() => {
											PatientPrescriptionStore.setDrugPrice(props.formKey, 0, 0)
											props.form.$("productCode").value = ""
											props.form.$("medEquipDoseUnit").value = ""
											// setState({expertiseWarningText: ""})
											props.form.$("expertiseWarningText").value = ""
											props.form.$("medEquipCodeExt").value = ""
											PatientPrescriptionStore.checkValidationRules(props.form)
										}}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
														type: "string",
														dbName: "code_ext"
													},
													name_ext: {
														title: <FormattedMessage id="Common.label.medDev" />,
														type: "string",
														dbName: "search_column",
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														},
														sortable: true,
														design: {
															body: {
																className: (row) => (row.has_limitation ? "xs-amberWarning" : null)
															}
														}
													},
													supplement: {
														title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
														type: "string",
														dbName: "supplement"
													},
													patient_payment: {
														title: (
															<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
														),
														type: "string",
														dbName: "patient_payment"
													},
													insurer_payment: {
														title: (
															<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
														),
														type: "string",
														dbName: "insurer_payment"
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													},
													renderHeaderAsFirstRow: true
												}
											}
										}}
									/>
								)}
								{props.form.$("prescriptionType").value == "RINDIVID" && (
									<SearchSelect
										ref={(input) => (GlobalStore.defaultPrescriptionFields[props.keyVal] = input)}
										field={props.form.$("productCode")}
										items={GlobalStore.CL["IVLs"]}
										tabindex={1 + 100 * props.keyVal}
										required
									/>
								)}
								{props.form.$("prescriptionType").value == "RDIETETIC" && (
									<XsAutocomplete
										field={props.form.$("medFood")}
										minLengthForSearch="3"
										api={api.loadMedFood}
										clearText
										tabindex={1 + 100 * props.keyVal}
										autoFocus={props.keyVal == 0 ? true : false}
										ref={(input) => (GlobalStore.defaultPrescriptionFields[props.keyVal] = input)}
										inputRenderer={(obj, menuItem) =>
											(menuItem && obj.has_limitation
												? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
												: obj.code_ext) +
											" | " +
											(menuItem && obj.has_limitation
												? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
												: obj.name_ext) +
											" | " +
											(obj.supplement ? obj.supplement + " | " : "") +
											(obj.patient_payment
												? (menuItem ? "<span style=color:#01579b>" : "") +
												  obj.patient_payment +
												  (menuItem ? "</span>" : "") +
												  " | "
												: "") +
											(obj.insurer_payment
												? (menuItem ? "<span style=color:#00A787>" : "") +
												  obj.insurer_payment +
												  (menuItem ? "</span>" : "") +
												  " | "
												: "") +
											(obj.price ? (menuItem ? "<b>" : "") + obj.price + (menuItem ? "</b>" : "") + " | " : "")
										}
										saveValue={(obj) => obj.code}
										onEmptyLoadResponse={() => {
											WarningStore.open(
												props.intl.formatMessage({
													id: "Common.label.unactiveDrug"
												})
											)
										}}
										postAction={(value, loaded) => {
											props.form.$("productCode").value = "" + value.code
											props.form.$("doseUnitId").value = value.dose_unit
											props.form.$("medicationRoute").value = "PO"
											PatientPrescriptionStore.setDrugPrice(props.formKey, value.patient_payment, value.price)
											PatientPrescriptionStore.checkDrugsHistory(value._id)
											if (isSafe(value.expertises) && value.expertises.length > 0) {
												checkDoctorExpertise(value.expertises)
											}
											if (!loaded) {
												props.options.handleLoadPrescriptionTemplate(props.formKey)
											}
										}}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
														type: "string",
														dbName: "code_ext"
													},
													name_ext: {
														title: <FormattedMessage id="Common.label.medFood" />,
														type: "string",
														dbName: "search_column",
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														},
														sortable: true,
														design: {
															body: {
																className: (row) => (row.has_limitation ? "xs-amberWarning" : null)
															}
														}
													},
													supplement: {
														title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
														type: "string",
														dbName: "supplement"
													},
													patient_payment: {
														title: (
															<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
														),
														type: "string",
														dbName: "patient_payment"
													},
													insurer_payment: {
														title: (
															<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
														),
														type: "string",
														dbName: "insurer_payment"
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													},
													renderHeaderAsFirstRow: true
												}
											}
										}}
										onClear={() => {
											// setState({expertiseWarningText: ""})
											PatientPrescriptionStore.setDrugPrice(props.formKey, 0, 0)
											props.form.$("expertiseWarningText").value = ""
											props.form.$("medicationRoute").value = ""
										}}
									/>
								)}
							</div>
							<div className="xs-generika-btn">
								{props.form.$("prescriptionType").value == "RBULK" &&
									isNotEmpty(props.form.$("productCode").value) &&
									isNotEmpty(props.form.$("drugs").value) &&
									props.form.$("prescriptionBySupplement").value && (
										<XsButton
											className="xs-success"
											text="Generiká"
											onClick={() => {
												GenerikaDialogStore.findGenerics(
													DataStore.patientDTO.get("patientID"),
													getSelectedOrgUnitID(),
													props.form.$("productCode").value,
													props.form,
													() => {
														setloadingDrugsFormGenerics(true)
													}
												)
											}}
										/>
									)}
							</div>
						</div>
						<div className="xs-diagnosis">
							<XsAutocompleteLocal
								field={props.form.$("diagnosis")}
								minLengthForSearch="3"
								clearText
								data={RegisterCacheStore.diagnosisRegister}
								tabindex={1 + 100 * props.keyVal}
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								specialDataFilter={(data) =>
									data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
								}
								postAction={(obj, isLoaded, forcedRefresh, dotsVal, clearValue) => {
									if (!forcedRefresh) {
										if (["Z00.0", "Z17.", "Z12.1"].includes(obj.code_ext)) {
											WarningStore.open(
												props.intl.formatMessage(
													{
														id: "Common.label.restrictedDiagnosisForPrescription"
													},
													{
														diagnosis: obj.code_ext
													}
												),
												false
											)
											clearValue()
										}
										if (!isLoaded) {
											generatePrescriptionReason(obj)
										}
									}
								}}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											paging: {
												rowsPerPage: 100,
												rowsPerPageOptions: [5, 10, 25, 50, 100]
											}
										}
									}
								}}
							/>
						</div>
					</div>
					{props.form.$("prescriptionType").value == "RINDIVID" && (
						<div>
							<Grid container direction="row">
								<Grid item xs={12}>
									<XsTextArea
										keyVal={props.keyVal}
										field={props.form.$("supplement")}
										className="xs-height-125"
										hideToolbar
										tabindex={3 + 100 * props.keyVal}
										ref={(ref) => (PatientPrescriptionStore.refSupplement = ref)}
										onChangeSelection={(range) => {
											DataStore.templateSourceRange = range != null && range.length > 0 ? range : null
										}}
										onBlur={(data) => {
											if (
												data &&
												(isNotSafe(RindividHelpDialogStore.textAreaCursors.supplement) ||
													RindividHelpDialogStore.textAreaCursors.supplement.cursor != data.cursor)
											) {
												RindividHelpDialogStore.textAreaCursors.supplement = {
													cursor: data.cursor
												}
											}
										}}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={12} xl={6}>
									<XsTextArea
										keyVal={props.keyVal}
										field={props.form.$("production")}
										className="xs-height-100"
										tabindex={4 + 100 * props.keyVal}
										hideToolbar
										onChangeSelection={(range) => {
											DataStore.templateSourceRange = range != null && range.length > 0 ? range : null
										}}
										onBlur={(data) => {
											if (
												data &&
												(isNotSafe(RindividHelpDialogStore.textAreaCursors.production) ||
													RindividHelpDialogStore.textAreaCursors.production.cursor != data.cursor)
											) {
												RindividHelpDialogStore.textAreaCursors.production = {
													cursor: data.cursor
												}
											}
										}}
									/>
								</Grid>
								<Grid item xs={12} xl={6}>
									<XsTextArea
										keyVal={props.keyVal}
										field={props.form.$("useGuide")}
										className="xs-height-100"
										hideToolbar
										tabindex={5 + 100 * props.keyVal}
										onChangeSelection={(range) => {
											DataStore.templateSourceRange = range != null && range.length > 0 ? range : null
										}}
										onBlur={(data) => {
											if (
												data &&
												(isNotSafe(RindividHelpDialogStore.textAreaCursors.useGuide) ||
													RindividHelpDialogStore.textAreaCursors.useGuide.cursor != data.cursor)
											) {
												RindividHelpDialogStore.textAreaCursors.useGuide = {
													cursor: data.cursor
												}
											}
										}}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" className="xs-chipform-ipl">
								<XsChipForm
									ref={(chipForm) => (PatientPrescriptionStore.ingredientsChipsForm[props.keyVal] = chipForm)}
									field={props.form.$("ingredientsChipsForm")}
									label={iplText}
									configuration={{
										header: {
											chipsRenderer: (key, dataRow, clickHandler) => (
												<XsChip
													key={key}
													label={isSafe(dataRow["col1NameExt"]) ? dataRow["col1NameExt"]["value"] : ""}
													onClick={(e) => clickHandler(false, e, key)}
												/>
											),
											addButtonRenderer: (clickHandler) => (
												<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={clickHandler} />
											)
										},
										columns: [
											{
												name: "col1",
												label: <FormattedMessage id="Patient.form.patientPrescription.ingredients" />,
												width: 3,
												validationRule: "required",
												renderer: (field, index, fieldKey, onChangeHandler) => (
													<XsAutocomplete
														field={field}
														minLengthForSearch="3"
														api={api.loadIngrediens}
														inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
														saveValue={(obj) => obj.code}
														postAction={(obj) => {
															handleChipformChange(onChangeHandler(fieldKey), obj.name_ext)
														}}
													/>
												)
											},
											{
												name: "col1NameExt",
												renderer: () => {},
												width: 0
											},
											{
												name: "col2",
												label: <FormattedMessage id="Patient.form.patientPrescription.ingredientsAmount" />,
												width: 3,
												validationRule: "required",
												renderer: (field) => <XsInput field={field} />
											},
											{
												name: "col3",
												label: <FormattedMessage id="Common.label.unit" />,
												width: 3,
												validationRule: "required",
												renderer: (field) => <SearchSelect field={field} items={GlobalStore.CL["units"]} />
											}
										],
										options: {
											disabled: false,
											isFormMode: false,
											showInCenter: true,
											maxWidth: true,
											//isModalMode: false,
											onFormOpen: () => {},
											onFormClose: () => {},
											onFormSave: (values) => {
												PatientPrescriptionStore.ingredientsChips[props.keyVal] = values
											}
										},
										data: PatientPrescriptionStore.ingredientsChips[props.keyVal]
									}}
								/>
							</Grid>
						</div>
					)}
					<Grid container direction="row" spacing={8}>
						<Grid item xs={6}>
							<SearchSelect
								field={props.form.$("quantization")}
								/*disabled={props.form.$("prescriptionType").value == "MEDEQUIP"}*/ items={
									props.form.$("prescriptionType").value == "MEDEQUIP"
										? filteredMedicationDosageTypes
										: isSafe(GlobalStore.CL["medicationDosageTypes"])
										? GlobalStore.CL["medicationDosageTypes"].filter((x) => x.code !== "AE" && x.code !== "XYZW")
										: []
								}
								onChange={(value) => {
									handleChangeQuantization(value)
								}}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<XsNumberInput
								field={props.form.$("packs")}
								min={"0.01"}
								step={"0.01"}
								endText={doseUnitTransform()}
								onChange={() => {
									PatientPrescriptionStore.generateDrugDays(props.form)
									setDosageText()
								}}
							/>
						</Grid>
					</Grid>
					{!props.form.$("showArea").value && (
						<div>
							<Grid container direction="row">
								<Grid item xs={2}>
									<div className="xs-radio-buttons">
										<XsRadioButtonGroup
											name="types"
											field={props.form.$("quantizationRadio")}
											radioButtons={[
												{
													value: "first",
													label: <FormattedMessage id="Patient.form.patientPrescription.firstRadio" /> //context.intl.formatMessage({ id: "Patient.form.patientPrescription.firstRadio" })
												},
												{
													value: "second",
													label: <FormattedMessage id="Patient.form.patientPrescription.secondRadio" />, // "SECOND", //context.intl.formatMessage({ id: "Patient.form.patientPrescription.secondRadio" }),
													style: {marginTop: "13px"}
												}
											]}
											onChange={() => {
												handleRadio()
											}}
										/>
									</div>
								</Grid>
								<Grid item xs={10}>
									<XsGroupValidator
										content={
											<Grid container direction="row" spacing={8}>
												<Grid item xs={3}>
													<XsNumberInput
														field={props.form.$("morning")}
														// type={"number"}
														min={"0.1"}
														step={"0.01"}
														tabindex={3 + 100 * props.keyVal}
														onChange={() => {
															PatientPrescriptionStore.generateDrugDays(props.form)
															setDosageText()
														}}
														disabled={props.form.$("quantizationRadio").value == "second"}
													/>
												</Grid>
												<Grid item xs={3}>
													<XsNumberInput
														field={props.form.$("lunch")}
														// type={"number"}
														min={"0.1"}
														step={"0.01"}
														tabindex={4 + 100 * props.keyVal}
														onChange={() => {
															PatientPrescriptionStore.generateDrugDays(props.form)
															setDosageText()
														}}
														disabled={props.form.$("quantizationRadio").value == "second"}
													/>
												</Grid>
												<Grid item xs={3}>
													<XsNumberInput
														field={props.form.$("evening")}
														// type={"number"}
														min={"0.1"}
														step={"0.01"}
														tabindex={5 + 100 * props.keyVal}
														onChange={() => {
															PatientPrescriptionStore.generateDrugDays(props.form)
															setDosageText()
														}}
														disabled={props.form.$("quantizationRadio").value == "second"}
													/>
												</Grid>
												<Grid item xs={3}>
													<XsNumberInput
														field={props.form.$("sleep")}
														// type={"number"}
														min={"0.1"}
														step={"0.01"}
														tabindex={6 + 100 * props.keyVal}
														onChange={() => {
															PatientPrescriptionStore.generateDrugDays(props.form)
															setDosageText()
														}}
														disabled={props.form.$("quantizationRadio").value == "second"}
													/>
												</Grid>
											</Grid>
										}
										field={props.form.$("firstRadioValidator")}
									/>
									<Grid container direction="row" spacing={8}>
										<Grid item xs={6}>
											<XsNumberInput
												field={props.form.$("dosage")}
												// type={"number"}
												min={"0.1"}
												step={"0.01"}
												onChange={() => {
													PatientPrescriptionStore.generateDrugDays(props.form)
													setDosageText()
												}}
												disabled={props.form.$("quantizationRadio").value == "first"}
											/>
										</Grid>
										<Grid item xs={6}>
											<XsNumberInput
												field={props.form.$("everyHour")}
												// type={"number"}
												min={"1"}
												step={"1"}
												onChange={() => {
													PatientPrescriptionStore.generateDrugDays(props.form)
													setDosageText()
												}}
												disabled={props.form.$("quantizationRadio").value == "first"}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={3}>
									<SearchSelect
										field={props.form.$("unit")}
										items={GlobalStore.CL["medicationDoseUnits"]}
										onChange={setDosageText}
									/>
								</Grid>
								<Grid item xs={3}>
									<SearchSelect
										field={props.form.$("medicationRoute")}
										onChange={setDosageText}
										items={GlobalStore.CL["medicationRoutesPrescription"]}
									/>
								</Grid>
								<Grid item xs={3}>
									<SearchSelect
										field={props.form.$("repeatingMoment")}
										onChange={setDosageText}
										items={GlobalStore.CL["medicationRepeats"]}
									/>
								</Grid>
								<Grid item xs={3}>
									<XsNumberInput
										field={props.form.$("days")}
										// type={"number"}
										min={"1"}
										step={"0.01"}
										onChange={() => {
											setDosageText()
										}}
									/>
								</Grid>
							</Grid>
						</div>
					)}
					{props.form.$("showArea").value && (
						<Grid container direction="row">
							<Grid item xs={12}>
								<XsTextArea
									keyVal={props.keyVal}
									rows={10}
									rowsId={"prescriptionDosage"}
									hideToolbar={props.form.$("quantization").value == "VAR"}
									field={props.form.$("dosage")}
									maxLength={150}
									showNoLabel={true}
								/>
							</Grid>
						</Grid>
					)}
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<XsButton
								className="xs-outline xs-primary xs-show-details"
								text={
									<FormattedMessage
										id={
											props.form.$("showDetails").value
												? "Patient.form.patientPrescription.hideDetails"
												: "Patient.form.patientPrescription.showDetails"
										}
									/>
								}
								onClick={() => (props.form.$("showDetails").value = !props.form.$("showDetails").value)}
							/>
						</Grid>
						{props.form.$("prescriptionType").value != "RINDIVID" && (
							<Grid item className="fontSize15">
								<FormattedMessage id="Common.label.patientPaymentFull" />:
								<span className="pl-1">
									{isNotEmpty(
										PatientPrescriptionStore[
											isNotEmpty(props.form.$("pacientPaysReason").value) ||
											(!props.form.$("isDrugCategorized").value && props.form.$("prescriptionType").value == "RBULK")
												? "drugPricesFull"
												: "drugPrices"
										].get(props.formKey)
									) && isNotEmpty(props.form.$("packs").value)
										? (
												PatientPrescriptionStore[
													isNotEmpty(props.form.$("pacientPaysReason").value) ||
													(!props.form.$("isDrugCategorized").value &&
														props.form.$("prescriptionType").value == "RBULK")
														? "drugPricesFull"
														: "drugPrices"
												].get(props.formKey) * props.form.$("packs").value
										  ).toFixed(2)
										: 0}
								</span>
								<i className="fal fa-euro-sign pl-1"></i>
							</Grid>
						)}
					</Grid>
					{props.form.$("showDetails").value && (
						<div>
							{!props.form.$("showArea").value && (
								<Grid container direction="row">
									<Grid item xs={12}>
										<XsInput field={props.form.$("ds")} />
									</Grid>
								</Grid>
							)}
							<Grid container direction="row">
								<Grid item xs={12}>
									<XsInput field={props.form.$("note")} />
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={3}>
									<XsAutocomplete
										field={props.form.$("sendingDoctorPersonnelExpertiseId")}
										minLengthForSearch="3"
										clearText
										api={api.loadPersonnelAndProvider}
										filterValue={"personnel_expertise_id"}
										addSpecialFilter={() => {
											return [
												{
													associated_column: "has_contract",
													predicate: "=",
													values: [
														{
															id_value: true
														}
													]
												}
											]
										}}
										inputRenderer={(obj, menuItem = false) =>
											(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
												? "<b style=color:#00A787>"
												: "") +
											(obj.doctor_code ? obj.doctor_code + " / " : "") +
											obj.full_name +
											" / " +
											(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
											obj.cl_expertise_name_ext +
											(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
											(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
										}
										saveValue={(obj) => obj.personnel_expertise_id}
										postAction={(obj, firstLoad) => {
											if (!firstLoad) {
												props.form.$("sendingDoctor").value = ""
												props.form.$("sendingDoctorExpertiseId").value = ""
												PatientPrescriptionStore.doctorOUPZSItems[props.keyVal] = []
												props.form.$("doctorOUPZS").value = isNotEmpty(obj.provider_id) ? obj.provider_id : ""
											}
											props.form.$("sendingDoctor").value = obj.personnel_id
											props.form.$("sendingDoctorExpertiseId").value = obj.cl_expertise_id
											checkSendingDoctorExpertise(obj.cl_expertise_code_ext)
										}}
										onClear={() => {
											props.form.$("sendingDoctor").value = ""
											props.form.$("sendingDoctorExpertiseId").value = ""
											props.form.$("doctorOUPZS").value = ""
											props.form.$("insertDoctorToNextForm").value = false
											PatientPrescriptionStore.doctorOUPZSItems[props.keyVal] = []
											PatientPrescriptionStore.checkValidationRules(props.form)
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									{isSafe(PatientPrescriptionStore.doctorOUPZSItems) &&
									isSafe(PatientPrescriptionStore.doctorOUPZSItems[props.keyVal]) &&
									PatientPrescriptionStore.doctorOUPZSItems[props.keyVal].length > 0 ? (
										<SearchSelect
											disabled={isEmpty(props.form.$("sendingDoctor").value)}
											field={props.form.$("doctorOUPZS")}
											items={PatientPrescriptionStore.doctorOUPZSItems[props.keyVal]}
											onChange={(val) => {
												if (
													isNotEmpty(props.form.$("doctorOUPZS")) &&
													isNotEmpty(props.form.$("substitutingPersonnelExpertiseId")) &&
													isNotEmpty(val)
												) {
													DoctorOUPZwarningDialogStore.checkDoctor(
														DataStore.patientDTO.get("patientID"),
														getSelectedOrgUnitID(),
														props.form.$("sendingDoctorPersonnelExpertiseId").value,
														val
													)
												}
												PatientPrescriptionStore.checkValidationRules(props.form)
											}}
											required
										/>
									) : (
										<XsAutocomplete
											field={props.form.$("doctorOUPZS")}
											minLengthForSearch="3"
											api={api.loadProviders}
											clearText
											disabled={isEmpty(props.form.$("sendingDoctor").value)}
											filterValue={"provider_id"}
											inputRenderer={(obj) =>
												(obj.provider_code ? obj.provider_code + " / " : "") +
												obj.company_name +
												" / " +
												(obj.expertise_code ? obj.expertise_code + " " : "") +
												obj.expertise_name_ext
											}
											saveValue={(obj) => obj.provider_id}
											postAction={(obj) => {
												if (isNotEmpty(props.form.$("doctorOUPZS")) && isNotEmpty(props.form.$("sendingDoctor"))) {
													DoctorOUPZwarningDialogStore.checkDoctor(
														DataStore.patientDTO.get("patientID"),
														getSelectedOrgUnitID(),
														props.form.$("sendingDoctorPersonnelExpertiseId").value,
														obj.provider_id
													)
												}
												PatientPrescriptionStore.checkValidationRules(props.form)
											}}
										/>
									)}
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker
										field={props.form.$("doctorDate")}
										showTimeSelect={false}
										onSelect={(date) => {
											setTimeout(() => {
												PatientPrescriptionStore.checkValidationRules(props.form)
												checkDateValidity(date, null)
												if (isSafe(date) && moment.isMoment(date) && date.isAfter(moment(), "day")) {
													WarningStore.open(
														props.intl.formatMessage({
															id: "Common.label.dateInFuture"
														})
													)
												} else {
													generateRecommValidity(date)
												}
											}, 0)
										}}
									/>
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker
										field={props.form.$("recommendationDateValidity")}
										showTimeSelect={false}
										onSelect={(date) => {
											setTimeout(() => {
												checkDateValidity(null, date)
											}, 0)
										}}
									/>
								</Grid>
								{props.keyVal == 0 && (
									<Grid item xs={2}>
										<XsCheckbox
											className="pt-1"
											field={props.form.$("insertDoctorToNextForm")}
											disabled={isEmpty(props.form.$("sendingDoctorPersonnelExpertiseId").value)}
											tooltipId="Common.label.CopyDoctorToPrescription"
										/>
									</Grid>
								)}
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={4}>
									<XsAutocomplete
										field={props.form.$("substitutingPersonnelExpertiseId")}
										minLengthForSearch="3"
										clearText
										api={api.loadPersonnelAndProvider}
										filterValue={"personnel_expertise_id"}
										addSpecialFilter={() => {
											return [
												{
													associated_column: "has_contract",
													predicate: "=",
													values: [
														{
															id_value: true
														}
													]
												}
											]
										}}
										inputRenderer={(obj, menuItem = false) =>
											(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
												? "<b style=color:#00A787>"
												: "") +
											(obj.doctor_code ? obj.doctor_code + " / " : "") +
											obj.full_name +
											" / " +
											(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
											obj.cl_expertise_name_ext +
											(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
											(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
										}
										saveValue={(obj) => obj.personnel_expertise_id}
										postAction={(obj, firstLoad) => {
											if (!firstLoad) {
												props.form.$("substitutingDoctor").value = ""
												props.form.$("substitutingExpertiseId").value = ""
												PatientPrescriptionStore.substitutingDoctorOUPZSItems[props.keyVal] = []
												props.form.$("substitutingDoctorOUPZS").value = isNotEmpty(obj.provider_id)
													? obj.provider_id
													: ""
											}
											props.form.$("substitutingDoctor").value = obj.personnel_id
											props.form.$("substitutingExpertiseId").value = obj.cl_expertise_id
										}}
										onClear={() => {
											props.form.$("substitutingDoctor").value = ""
											props.form.$("substitutingExpertiseId").value = ""
											props.form.$("substitutingDoctorOUPZS").value = ""
											PatientPrescriptionStore.substitutingDoctorOUPZSItems[props.keyVal] = []
											PatientPrescriptionStore.checkValidationRules(props.form)
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									{isSafe(PatientPrescriptionStore.substitutingDoctorOUPZSItems) &&
									isSafe(PatientPrescriptionStore.substitutingDoctorOUPZSItems[props.keyVal]) &&
									PatientPrescriptionStore.substitutingDoctorOUPZSItems[props.keyVal].length > 0 ? (
										<SearchSelect
											field={props.form.$("substitutingDoctorOUPZS")}
											disabled={isEmpty(props.form.$("substitutingPersonnelExpertiseId").value)}
											items={PatientPrescriptionStore.substitutingDoctorOUPZSItems[props.keyVal]}
											onChange={(val) => {
												if (
													isNotEmpty(props.form.$("substitutingDoctorOUPZS")) &&
													isNotEmpty(props.form.$("substitutingPersonnelExpertiseId")) &&
													isNotEmpty(val)
												) {
													DoctorOUPZwarningDialogStore.checkDoctor(
														DataStore.patientDTO.get("patientID"),
														getSelectedOrgUnitID(),
														props.form.$("substitutingPersonnelExpertiseId").value,
														val
													)
												}
												PatientPrescriptionStore.checkValidationRules(props.form)
												props.options.changeRecordDefaultDoctor(
													props.form.$("substitutingPersonnelExpertiseId").value,
													props.form.$("substitutingDoctor").value,
													props.form.$("substitutingExpertiseId").value,
													props.form.$("substitutingDoctorOUPZS").value
												)
											}}
											required
										/>
									) : (
										<XsAutocomplete
											field={props.form.$("substitutingDoctorOUPZS")}
											minLengthForSearch="3"
											api={api.loadProviders}
											clearText
											disabled={isEmpty(props.form.$("substitutingPersonnelExpertiseId").value)}
											filterValue={"provider_id"}
											inputRenderer={(obj) =>
												(obj.provider_code ? obj.provider_code + " / " : "") +
												obj.company_name +
												" / " +
												(obj.expertise_code ? obj.expertise_code + " " : "") +
												obj.expertise_name_ext
											}
											saveValue={(obj) => obj.provider_id}
											postAction={(obj) => {
												if (
													isNotEmpty(props.form.$("substitutingDoctorOUPZS")) &&
													isNotEmpty(props.form.$("substitutingPersonnelExpertiseId"))
												) {
													DoctorOUPZwarningDialogStore.checkDoctor(
														DataStore.patientDTO.get("patientID"),
														getSelectedOrgUnitID(),
														props.form.$("substitutingPersonnelExpertiseId").value,
														obj.provider_id
													)
												}
												PatientPrescriptionStore.checkValidationRules(props.form)
											}}
										/>
									)}
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={4}>
									<SearchSelect field={props.form.$("pacientPaysReason")} items={patientPaysData} />
								</Grid>
								<Grid item xs={4}>
									<XsDateTimePicker field={props.form.$("hospitalizationDischargeDate")} showTimeSelect={false} />
								</Grid>
							</Grid>
							{props.form.$("prescriptionType").value == "MEDEQUIP" && (
								<Grid container direction="row">
									<Grid item xs={12}>
										<XsTextArea
											keyVal={props.keyVal}
											field={props.form.$("medDevDescription")}
											hideToolbar
											className="xs-height-75"
										/>
									</Grid>
								</Grid>
							)}
							<Grid container direction="row">
								<Grid item xs={6}>
									<XsTextArea
										keyVal={props.keyVal}
										hideToolbar
										field={props.form.$("prescriptionReason")}
										tooltip={props.intl.formatMessage({
											id: "Common.label.prescriptionReasonTooltip"
										})}
										className="xs-height-75 mr-1"
									/>
								</Grid>
								<Grid item xs={6}>
									<XsTextArea
										keyVal={props.keyVal}
										hideToolbar
										field={props.form.$("prescriptionNote")}
										className="xs-height-75 ml-1"
									/>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={4}>
									<XsCheckbox field={props.form.$("urgent")} />
								</Grid>
								<Grid item xs={4}>
									<XsCheckbox field={props.form.$("opiate")} disabled={props.form.$("disableOpiateCheckbox").value} />
								</Grid>
							</Grid>
							{props.form.$("prescriptionType").value == "RBULK" && props.form.$("showReplacementProhibited").value && (
								<Grid container direction="row">
									<Grid item xs={4}>
										<XsCheckbox
											field={props.form.$("replacementProhibited")}
											onChange={(field) => {
												if (
													isNotEmpty(props.form.$("prohibitedChipsForm").value) &&
													(props.form.$("prohibitedChipsForm").value != "[]" ||
														props.form.$("prohibitedChipsForm").value != "{}") &&
													!field.value
												) {
													GlobalStore.confirmationDialogOpened = "xsDeleteReplacment"
												}
											}}
										/>
									</Grid>
									{props.form.$("replacementProhibited").value && (
										<Grid item xs={8}>
											<XsChipForm
												ref={(chipForm) => (PatientPrescriptionStore.prohibitedChipsForm[props.keyVal] = chipForm)}
												field={props.form.$("prohibitedChipsForm")}
												configuration={{
													header: {
														chipsRenderer: (key, dataRow, clickHandler) => (
															<XsChip
																key={key}
																label={isSafe(dataRow["col1NameExt"]) ? dataRow["col1NameExt"]["value"] : ""}
																onClick={(e) => clickHandler(false, e, key)}
															/>
														),
														addButtonRenderer: (clickHandler) => (
															<XsIconButton
																className="xs-default xs-outline"
																icon={<AddIcon />}
																onClick={clickHandler}
															/>
														)
													},
													columns: [
														{
															name: "col1",
															label: "Zakázaný liek",
															width: 10,
															validationRule: "required",
															renderer: (field, index, fieldKey, onChangeHandler) => (
																<XsAutocomplete
																	field={field}
																	minLengthForSearch="3"
																	api={api.loadDrugs}
																	inputRenderer={(obj) =>
																		obj.code_ext +
																		" | " +
																		obj.name_ext +
																		" | " +
																		obj.supplement +
																		" | " +
																		(obj.patient_payment ? obj.patient_payment + " | " : "") +
																		(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																		obj.atc +
																		" | " +
																		obj.active_ingredient
																	}
																	saveValue={(obj) => obj.code}
																	postAction={(obj) => {
																		handleChipformChange(
																			onChangeHandler(fieldKey),
																			obj.code_ext +
																				" | " +
																				obj.name_ext +
																				" | " +
																				obj.supplement +
																				" | " +
																				(obj.patient_payment ? obj.patient_payment + " | " : "") +
																				(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																				obj.atc +
																				" | " +
																				obj.active_ingredient
																		)
																	}}
																/>
															)
														},
														{
															name: "col1NameExt",
															renderer: () => {},
															width: 0
														}
													],
													options: {
														disabled: false,
														isFormMode: false,
														showInCenter: true,
														maxWidth: true,
														//isModalMode: false,
														onFormOpen: () => {},
														onFormClose: () => {},
														onFormSave: (values) => {
															PatientPrescriptionStore.prohibitedChips[props.keyVal] = values
														}
													},
													data: PatientPrescriptionStore.prohibitedChips[props.keyVal]
												}}
											/>
										</Grid>
									)}
								</Grid>
							)}
							<Grid container direction="row">
								<Grid item xs={4}>
									<XsCheckbox field={props.form.$("exeptionCheck")} />
								</Grid>
								<Grid item xs={4}>
									<XsCheckbox
										field={props.form.$("newBornOnParentIdentifier")}
										tooltipId="Common.label.newBornOnParentIdentifierTooltip"
									/>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={4}>
									<XsCheckbox
										field={props.form.$("forcedSave")}
										onChange={() => PatientPrescriptionStore.checkValidationRules(props.form)}
									/>
								</Grid>
								{props.form.$("forcedSave").value && (
									<Grid item xs={8}>
										<XsInput field={props.form.$("forcedSaveReason")} />
									</Grid>
								)}
							</Grid>
							<Grid container direction="row">
								<Grid item xs={4}>
									<XsCheckbox
										field={props.form.$("permanentDisability")}
										onChange={() => PatientPrescriptionStore.checkValidationRules(props.form)}
										tooltipId="Common.label.permanentDisabilityTooltip"
									/>
								</Grid>
							</Grid>
						</div>
					)}
				</div>
				{props.form.$("prescriptionType").value == "RINDIVID" && (
					<div className="xs-patient-form-right">
						<div className="rindividHelp">
							<Grid item xs={12} className={props.classes.paddingRightSide}>
								<Badge
									className="rindividHelp-badge"
									badgeContent={
										isSafe(DataStore.templatesAll) &&
										isSafe(DataStore.templatesAll["RINDIVID"]) &&
										isSafe(DataStore.templatesAll["RINDIVID"].records)
											? DataStore.templatesAll["RINDIVID"].records.length
											: 0
									}
								>
									<XsButton
										text={<FormattedMessage id="Patient.form.template.templates" />}
										onClick={() => {
											if (props.form.$("supplement").focused) {
												RindividHelpDialogStore.open(props.form, "supplement")
											} else if (props.form.$("production").focused) {
												RindividHelpDialogStore.open(props.form, "production")
											} else if (props.form.$("useGuide").focused) {
												RindividHelpDialogStore.open(props.form, "useGuide")
											} else {
												RindividHelpDialogStore.open()
											}
										}}
										className="rindividHelp-button"
									/>
								</Badge>
								<XsIconButton
									className="xs-default xs-outline"
									icon={<AddIcon />}
									onClick={(e) => {
										DataStore.templateAnchorEl = e.currentTarget
										DataStore.templateDataKeyVal = props.keyVal
										if (DataStore.templateSourceRange) {
											let stringToTemplate = ""
											const {index, length} = DataStore.templateSourceRange
											if (props.form.$("supplement").focused) {
												const plain = htmlToPlain(props.form.$("supplement").value)
												stringToTemplate =
													plain.length <= length + 2 ? props.form.$("supplement").value : plain.substr(index, length)
											} else if (props.form.$("production").focused) {
												const plain = htmlToPlain(props.form.$("production").value)
												stringToTemplate =
													plain.length <= length + 2 ? props.form.$("production").value : plain.substr(index, length)
											} else if (props.form.$("useGuide").focused) {
												const plain = htmlToPlain(props.form.$("useGuide").value)
												stringToTemplate =
													plain.length <= length + 2 ? props.form.$("useGuide").value : plain.substr(index, length)
											}
											DataStore.templateSourceText = stringToTemplate
										}
										DataStore.openTemplateForm()
									}}
								/>
								<TemplateForm type="RINDIVID" keyVal={props.keyVal} />
							</Grid>
						</div>
					</div>
				)}
			</div>
			{isNotEmpty(props.form.$("expertiseWarningText").value) && (
				<Grid container direction="row">
					<Grid item xs={12}>
						<div className="expertiseWarningText">
							<i className="fal fa-exclamation-triangle fa-2x xs-amberWarning xs-warning-icon" />
							{renderHTML(props.form.$("expertiseWarningText").value)}
						</div>
					</Grid>
				</Grid>
			)}
			<PrescriptionLimitations formKey={props.keyVal} />
			<DrugInteractionsDialog />
			<GenerikaDialog />
			<MedicineCardDialog />
			<ConfirmInteracationDialog />
			<DoctorOUPZwarningDialog />
			<RindividHelpDialog formKey={props.keyVal} />
			<XsConfirmationDialog
				name="xsDeleteReplacment"
				title="Upozornenia"
				text="Chcete odstrániť zakázané lieky?"
				onConfirmation={() => {
					PatientPrescriptionStore.prohibitedChips[props.keyVal] = []
					props.form.$("prohibitedChipsForm").value = "[]"
				}}
				onCancel={() => props.form.$("replacementProhibited").set(true)}
			/>
			<XsConfirmationDialog
				name="xsCheckDrugs"
				title="Pozor"
				text="Tento liečebný prostriedok ste dnes už pacientovi predpísali. Prajete si napriek tomu pokračovať?"
				onCancel={() => {
					props.form.$("drugs").value = ""
					props.form.$("medEquip").value = ""
					props.form.$("medFood").value = ""
				}}
			/>
		</section>
	)
}
export default injectIntl(withStyles(styles)(observer(PatientPrescriptionForm)))
