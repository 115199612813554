"use strict"
import api from "../actions/api"
import moment from "moment"
import {observable, action} from "mobx"
import {getUserCompanyInfo, getSelectedOrgUnitID} from "../../../global/helpers/actions"
import settings from "../../../global/config/settings"
import WarningStore from "../../../global/store/WarningStore"
import UIStore from "./UIStore"
import RegistersCacheStore from "../../../global/store/RegistersCacheStore"

class HospicomReportStore {
	@observable hospicomList = []
	@observable isOpen = false
	@observable changedFilters = false
	@observable checkedRecordIds = []

	currFilters = {}
	formRef = null

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			// this.currFilters.orgUnit != formValues.orgUnit ||
			this.currFilters.personIdentifier != formValues.personIdentifier ||
			this.currFilters.insurer != formValues.insurer ||
			this.currFilters.healthCareStatus != formValues.healthCareStatus
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action loadHospicomReport(form, errorText) {
		UIStore.isFormSaving = true
		this.currFilters = form.values()
		let filters = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				},
				{
					associated_column: "org_unit_id",
					values: [
						{
							id_value: getSelectedOrgUnitID()
						}
					]
				}
			]
		}

		let refFormValues = form.values()
		if (isNotEmpty(refFormValues.datefrom)) {
			filters["time_from"] = moment(refFormValues.datefrom)
				.startOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		if (isNotEmpty(refFormValues.dateto)) {
			filters["time_to"] = moment(refFormValues.dateto)
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		if (
			isNotEmpty(refFormValues.date) &&
			isSafe(refFormValues.healthCareStatus) &&
			refFormValues.healthCareStatus == "N"
		) {
			filters.filters.push({
				associated_column: "proposed_at",
				values: [{id_value: moment(refFormValues.date).format(settings.DB_DATE_FORMAT)}]
			})
		}

		if (
			isNotEmpty(refFormValues.date) &&
			isSafe(refFormValues.healthCareStatus) &&
			refFormValues.healthCareStatus == "E"
		) {
			filters.filters.push({
				associated_column: "incoming_at",
				values: [{id_value: moment(refFormValues.date).format(settings.DB_DATE_FORMAT)}]
			})
		}

		if (isNotEmpty(refFormValues.healthCareStatus)) {
			filters.filters.push({associated_column: "status_code_ext", values: [{id_value: refFormValues.healthCareStatus}]})
		}

		if (isNotEmpty(refFormValues.insurer)) {
			let insurer = RegistersCacheStore.insurersRegister.find((obj) => obj._id == refFormValues.insurer)
			filters.filters.push({associated_column: "insurer_code_ext", values: [{id_value: insurer.code_ext}]})
		}
		if (isNotEmpty(refFormValues.personIdentifier)) {
			filters.filters.push({
				associated_column: "identifier",
				values: [
					{
						id_value: refFormValues.personIdentifier
					}
				]
			})
		}

		//org_unit_id
		//person_identifier

		api
			.loadHospicomReport(filters)
			.call()
			.then((response) => {
				this.hospicomList = response.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action export(form, errorText, checkedIds) {
		this.currFilters = form.values()
		let filters = {
			company_id: getUserCompanyInfo().id,
			provider_id: getSelectedOrgUnitID()
		}

		let refFormValues = form.values()
		if (isNotEmpty(refFormValues.datefrom)) {
			filters["time_from"] = moment(refFormValues.datefrom)
				.startOf("day")
				.format(settings.DB_DATE_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		if (isNotEmpty(refFormValues.dateto)) {
			filters["time_to"] = moment(refFormValues.dateto)
				.endOf("day")
				.format(settings.DB_DATE_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		if (isNotEmpty(refFormValues.healthCareStatus)) {
			filters["disposition"] = refFormValues.healthCareStatus
		}

		if (isNotEmpty(refFormValues.insurer)) {
			filters["insurer_id"] = refFormValues.insurer
		}
		if (isNotEmpty(refFormValues.personIdentifier)) {
			filters["person_identifier"] = refFormValues.personIdentifier
		}
		filters["filter_record_ids"] = checkedIds
		api
			.downloadHospicomBatch(filters)
			.call()
			.then((response) => {
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "davka.001"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
					this.checkedRecordIds = []
				} else {
					WarningStore.open(response.statusText)
				}
			})
	}
}

var singleton = new HospicomReportStore()
export default singleton
