import React, {useState} from "react"
import {observer} from "mobx-react"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import {FormattedMessage, injectIntl} from "react-intl"
import CancelIcon from "@material-ui/icons/Clear"
import PatientPrescriptionForm from "./patientPrescriptionForm"
// import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
// import PatientPrescriptionContainerStore from "../../../stores/PatientPrescriptionContainerStore"
import PatientPrescriptionFormSave from "../patientPrescriptionForm/patientPrescriptionSaveButtons"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
// import Storage from "../../../../../global/storage/storage"
// import DataStore from "../../../stores/DataStore"
// import UIStore from "../../../stores/UIStore"

// @observer
// export default class PatientPrescriptionContainer extends React.Component {
function PatientPrescriptionContainer(props) {
	// constructor(props) {
	// 	super(props)

	// 	this.state = {
	// 		forms: {},
	// 		maxId: 0
	// 	}

	// 	this.formRefs = {}
	// 	this.saveButtonsRef = null
	// 	this.canceledSessionLoad = false
	// }
	const [maxFormKey, setMaxFormKey] = useState(Math.max(...Object.keys(props.forms)))

	const addForm = () => {
		const firstForm = props.forms[Math.min(...Object.keys(props.forms))]
		props.forms[+maxFormKey + 1] = props.options.createFormFunction()
		props.forms[+maxFormKey + 1].$("diagnosis").value = firstForm.$("diagnosis").value

		RegisterCacheStore.diagnosisRegister
		if (isNotEmpty(firstForm.$("diagnosis").value)) {
			let diagnosis = RegisterCacheStore.diagnosisRegister.find((diag) => diag.code == firstForm.$("diagnosis").value)
			if (isSafe(diagnosis)) {
				if (diagnosis.code_ext == "U99.01") {
					props.forms[+maxFormKey + 1].$("prescriptionReason").value = "Ink. II.st."
					props.forms[+maxFormKey + 1].$("showDetails").value = true
				} else if (diagnosis.code_ext == "U99.02") {
					props.forms[+maxFormKey + 1].$("prescriptionReason").value = "Ink. III.st. trvalá"
					props.forms[+maxFormKey + 1].$("showDetails").value = true
				} else if (diagnosis.code_ext == "U99.03") {
					props.forms[+maxFormKey + 1].$("prescriptionReason").value = "Ink. III.st. trvalá, nezvratná"
					props.forms[+maxFormKey + 1].$("showDetails").value = true
				}
			}
		}

		props.forms[+maxFormKey + 1].$("substitutingPersonnelExpertiseId").value = firstForm.$(
			"substitutingPersonnelExpertiseId"
		).value
		props.forms[+maxFormKey + 1].$("substitutingDoctor").value = firstForm.$("substitutingDoctor").value
		props.forms[+maxFormKey + 1].$("substitutingExpertiseId").value = firstForm.$("substitutingExpertiseId").value
		props.forms[+maxFormKey + 1].$("substitutingDoctorOUPZS").value = firstForm.$("substitutingDoctorOUPZS").value

		if (!props.forms[+maxFormKey + 1].$("showDetails").value) {
			props.forms[+maxFormKey + 1].$("showDetails").value =
				isNotEmpty(firstForm.$("substitutingDoctor").value) ||
				isNotEmpty(firstForm.$("substitutingExpertiseId").value) ||
				isNotEmpty(firstForm.$("substitutingDoctorOUPZS").value)
		}

		if (firstForm.$("insertDoctorToNextForm").value == true) {
			props.forms[+maxFormKey + 1].$("doctorDate").value = firstForm.$("doctorDate").value
			props.forms[+maxFormKey + 1].$("recommendationDateValidity").value = firstForm.$(
				"recommendationDateValidity"
			).value
			props.forms[+maxFormKey + 1].$("doctorOUPZS").value = firstForm.$("doctorOUPZS").value
			props.forms[+maxFormKey + 1].$("sendingDoctor").value = firstForm.$("sendingDoctor").value
			props.forms[+maxFormKey + 1].$("sendingDoctorPersonnelExpertiseId").value = firstForm.$(
				"sendingDoctorPersonnelExpertiseId"
			).value
			props.forms[+maxFormKey + 1].$("sendingDoctorExpertiseId").value = firstForm.$("sendingDoctorExpertiseId").value
			props.forms[+maxFormKey + 1].$("showDetails").value = true
		}

		setMaxFormKey(+maxFormKey + 1)
	}

	const checkFormValidation = (form) => {
		if (form.$("repeatType").value == "0") {
			if (form.$("maxRepeats").value < 2) {
				return false
			}
		}
		if (form.$("quantization").value === "FIX" && form.$("quantizationRadio").value == "first") {
			if (+form.$("morning").value + +form.$("lunch").value + +form.$("evening").value + +form.$("sleep").value <= 0) {
				return false
			}
		} else if (form.$("quantization").value === "FIX" && form.$("quantizationRadio").value == "second") {
			if (
				+form.$("dosage").value + +form.$("everyHour").value <= 0 ||
				+form.$("dosage").value <= 0 ||
				+form.$("everyHour").value <= 0
			) {
				return false
			}
		}
		if (form.$("quantization").value !== "FIX" && form.$("quantization").value != "REC") {
			if (form.$("quantization").value == "VAR") {
				if (htmlToPlain(form.$("dosage").value).length < 3) {
					return false
				}
			} else {
				if (htmlToPlain(form.$("dosage").value).length == 0) {
					return false
				}
			}
		}
		if (
			isNotEmpty(form.$("sendingDoctor").value) ||
			isNotEmpty(form.$("doctorOUPZS").value) ||
			isNotEmpty(form.$("doctorDate").value)
		) {
			if (
				!(
					isNotEmpty(form.$("sendingDoctor").value) &&
					isNotEmpty(form.$("doctorOUPZS").value) &&
					isNotEmpty(form.$("doctorDate").value)
				)
			) {
				return false
			}
		}
		if (isNotEmpty(form.$("substitutingDoctor").value) || isNotEmpty(form.$("substitutingDoctorOUPZS").value)) {
			if (!(isNotEmpty(form.$("substitutingDoctor").value) && isNotEmpty(form.$("substitutingDoctorOUPZS").value))) {
				return false
			}
		}
		if (form.$("pacientPays").value) {
			if (isEmpty(form.$("pacientPaysReason").value)) {
				return false
			}
		}
		if (form.$("replacementProhibited").value) {
			let probChips = form.$("prohibitedChipsForm").value
			if (isEmpty(probChips) || (isNotEmpty(probChips) && (probChips == "[]" || probChips == "{}"))) {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.label.prohibitedDrugRequired"}))
				return false
			}
		}
		if (form.$("forcedSave").value) {
			if (isNotSafe(form.$("forcedSaveReason").value) || isEmpty(form.$("forcedSaveReason").value)) {
				return false
			}
		}
		if (isNotEmpty(form.$("days").value) && +form.$("days").value < 1) {
			return false
		}
		return true
	}

	const renderMultiplePrescriptions = () => {
		const prescriptionForms = []
		if (isSafe(props.forms)) {
			Object.keys(props.forms).forEach((formKey, idx) => {
				prescriptionForms.push(
					<PatientPrescriptionForm
						key={idx}
						keyVal={idx}
						formKey={formKey}
						form={props.forms[formKey]}
						options={props.options}
					/>
				)
				if (Object.keys(props.forms).length > 1) {
					prescriptionForms.push(
						<div className="xs-cancel-btn">
							<XsButton
								className="xs-danger xs-outline pullLeft"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									const handler = () => {
										if (isSafe(PatientPrescriptionStore.drugPrices)) {
											PatientPrescriptionStore.drugPrices.delete(formKey)
										}
										delete props.forms[formKey]
										setMaxFormKey(maxFormKey + 1)
									}
									GlobalStore.openConfirmationDialog("patientCardAction", {
										onConfirm: handler,
										text: "Common.label.cancelForm"
									})
								}}
								icon={<CancelIcon />}
							/>
						</div>
					)
				}
			})
		}
		return prescriptionForms
	}

	return (
		<div id="patientPrescriptionFormContainer">
			<PatientPrescriptionFormSave
				top
				options={props.options}
				form={props.forms}
				formValidation={(form, index) => checkFormValidation(form, index)}
				addForm={() => {
					addForm()
				}}
			/>
			{renderMultiplePrescriptions()}
			<PatientPrescriptionFormSave
				options={props.options}
				form={props.forms}
				formValidation={(form, index) => checkFormValidation(form, index)}
				addForm={() => {
					addForm()
				}}
			/>
		</div>
	)
}

export default injectIntl(observer(PatientPrescriptionContainer))
