"use strict"
import api from "../actions/api"
import moment from "moment"
import {observable, action} from "mobx"
import {getUserCompanyInfo, exportToExcel, getSelectedOrgUnitID} from "../../../global/helpers/actions"
import settings from "../../../global/config/settings"
import WarningStore from "../../../global/store/WarningStore"
import UIStore from "./UIStore"

class DraftReportStore {
	@observable draftList = []
	@observable isOpen = false
	@observable changedFilters = false

	currFilters = {}
	formRef = null

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			// this.currFilters.orgUnit != formValues.orgUnit ||
			this.currFilters.personIdentifier != formValues.personIdentifier
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action loadDraftReport(form, errorText) {
		this.currFilters = form.values()

		let filters = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				},
				{
					associated_column: "org_unit_id",
					values: [
						{
							id_value: getSelectedOrgUnitID()
						}
					]
				}
			]
		}

		UIStore.isFormSaving = true
		let refFormValues = form.values()
		if (isNotEmpty(refFormValues.datefrom)) {
			filters["time_from"] = moment(refFormValues.datefrom)
				.startOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		if (isNotEmpty(refFormValues.dateto)) {
			filters["time_to"] = moment(refFormValues.dateto)
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		// if (isNotEmpty(refFormValues.orgUnit)) {
		// 	filters.filters.push({
		// 		associated_column: "org_unit_id",
		// 		values: [
		// 			{
		// 				id_value: refFormValues.orgUnit
		// 			}
		// 		]
		// 	})
		// }

		if (isNotEmpty(refFormValues.personIdentifier)) {
			filters.filters.push({
				associated_column: "person_identifier",
				values: [
					{
						id_value: refFormValues.personIdentifier
					}
				]
			})
		}

		//org_unit_id
		//person_identifier
		api
			.loadDraftReport(filters)
			.call()
			.then((response) => {
				this.draftList = response.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action exportToXls(dialogForm, filterForm) {
		let req = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				}
			],
			uri: api.loadDraftReport().getPath()
		}

		if (isNotEmpty(dialogForm.values().exportType) && dialogForm.values().exportType != "all") {
			let refFormValues = filterForm.values()
			if (isNotEmpty(refFormValues.datefrom)) {
				req["time_from"] = moment(refFormValues.datefrom)
					.startOf("day")
					.format(settings.DB_DATETIME_FORMAT)
			}

			if (isNotEmpty(refFormValues.dateto)) {
				req["time_to"] = moment(refFormValues.dateto)
					.endOf("day")
					.format(settings.DB_DATETIME_FORMAT)
			}

			if (isNotEmpty(refFormValues.productFilter)) {
				req.filters.push({
					associated_column: "product_id",
					values: [
						{
							id_value: refFormValues.productFilter
						}
					]
				})
			}

			if (isNotEmpty(refFormValues.registrator)) {
				req.filters.push({
					associated_column: "registrator",
					values: [
						{
							id_value: refFormValues.registrator
						}
					]
				})
			}
		}

		exportToExcel(req, () => {
			this.close(), dialogForm.reset()
		})
	}
}

var singleton = new DraftReportStore()
export default singleton
