"use strict"

// default imports for all farms
import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
// import UI controls here
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import {Grid, InputAdornment} from "@material-ui/core"
// definitions of form fields
import {FormattedMessage, injectIntl} from "react-intl"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import PatientAnamnesisStore from "../../../stores/PatientAnamnesisStore"
import RouterStore from "../../../../../global/store/RouterStore"
import SaveIcon from "@material-ui/icons/Done"
import PrintIcon from "@material-ui/icons/Print"
import CancelIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import {handleInsertHelpersIntoTextArea, getUserCompanyInfo} from "../../../../../global/helpers/actions"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsSearchDropDownAction from "../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import TemplateForm from "../patientRecordForm/templateForm"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientOperationStore from "../../../stores/PatientOperationStore"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import DeliveriesForm from "./deliveriesForm"

import api from "../../../actions/api"

function PatientOperationForm(props) {
	const [disableLifeThreat, setDisableLifeThreat] = useState({})

	let dropdownRecordTemplates =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["OP"]) &&
		isSafe(DataStore.templatesAll["OP"].records) &&
		DataStore.templatesAll["OP"].records.length > 0
			? DataStore.templatesAll["OP"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r)
						}
					})
			: []

	useEffect(() => {
		const handler = function operation(e) {
			if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "operation") {
				//ulozit a zavriet request Alt + U
				if (e.altKey && e.keyCode == 85) {
					e.preventDefault()
					props.form.onSubmit(e)
				}
			}
		}

		document.body.addEventListener("keydown", handler)

		return function cleanUp() {
			document.body.removeEventListener("keydown", handler)
		}
	}, [])

	const handleDeliveryChange = (form, name, life_threatening, index) => {
		let values = form.get("value")
		let stateData = disableLifeThreat
		if (isSafe(name)) {
			values.col1NameExt = name
		}
		if (values.col4 == false) {
			values.col4 = life_threatening
		}
		stateData[index] = life_threatening
		setDisableLifeThreat(stateData)

		form.set(values)
	}

	const handleDiagnosisClear = (row, index) => {
		let data = row.value
		if (isSafe(data)) {
			data.col1NameExt = ""
			data.col4 = false
			row.set("value", data)
		}

		let stateData = disableLifeThreat
		stateData[index] = false
		setDisableLifeThreat(stateData)
	}

	const checkDisabled = (index) => {
		return disableLifeThreat[index]
	}

	return (
		<section className="xs-patient-card-form xs-column">
			<div className="xs-patient-form-actions xs-top">
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.save" />}
					type="submit"
					onClick={(e) => {
						props.form.$("printOperation").value = false
						props.form.onSubmit(e)
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
					tabindex={10001}
					onClick={async (e) => {
						props.form.$("printOperation").value = true
						props.form.onSubmit(e)
					}}
					icon={<PrintIcon />}
				/>
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						props.form.$("printOperation").value = false
						props.options.onClose("OPERATION", false, true)
					}}
					icon={<CancelIcon />}
				/>
			</div>
			<div className="xs-patient-form xs-row">
				<div className="xs-patient-form-left xs-flex">
					<Grid container>
						<Grid item xs={12}>
							<XsChipForm
								ref={(chipForm) => (PatientOperationStore.diagnosisChipsForm = chipForm)}
								field={props.form.$("diagnosisChipsForm")}
								label={
									props.intl.formatMessage({id: "Patient.form.patientrecord.diagnosis"}) +
									'<span class="xs-required">*</span>' // eslint-disable-line
								}
								configuration={{
									header: {
										chipsRenderer: (key, dataRow, clickHandler) => (
											<XsChip
												key={key}
												label={isSafe(dataRow["col1NameExt"]) ? dataRow["col1NameExt"]["value"] : ""}
												onClick={(e) => clickHandler(false, e, key)}
											/>
										),
										addButtonRenderer: (clickHandler) => (
											<XsIconButton
												className="xs-success xs-outline"
												icon={<AddIcon />}
												onClick={clickHandler}
												id={"diagnosis"}
											/>
										)
									},
									columns: [
										{
											name: "col1",
											label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
											width: 3,
											validationRule: "required|string",
											renderer: (field, index, fieldKey, onChangeHandler) => (
												<XsAutocompleteLocal
													field={field}
													clearText
													tabindex={1}
													minLengthForSearch="3"
													autoFocus={true}
													data={RegisterCacheStore.diagnosisRegister}
													inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
													saveValue={(obj) => obj.code}
													specialDataFilter={(data) => {
														if (isSafe(index) && +index > 0) {
															return data
														} else {
															return data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
														}
													}}
													postAction={(value) => {
														handleDeliveryChange(
															onChangeHandler(fieldKey),
															value.code_ext + "-" + value.name_ext,
															value.life_threatening,
															index
														)
														PatientOperationStore.setDeliveryDiagnosis(value.code, onChangeHandler(fieldKey))
													}}
													onClear={() => {
														handleDiagnosisClear(onChangeHandler(fieldKey), index)
													}}
													modalConfig={(textValue, clickHandler) => {
														return {
															columnDefs: {
																code_ext: {
																	title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
																	type: "string",
																	dbName: "code_ext",
																	design: {
																		width: "150px"
																	},
																	filter: {
																		gridWidth: 3,
																		defaultValue: "",
																		renderElement: "input"
																	}
																},
																name_ext: {
																	title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
																	type: "string",
																	dbName: "search_column",
																	filter: {
																		gridWidth: 9,
																		defaultValue: textValue,
																		renderElement: "input"
																	}
																}
															},
															options: {
																showCursor: true,
																onRowClick: (dataRow) => {
																	clickHandler(dataRow)
																},
																paging: {
																	rowsPerPage: 100,
																	rowsPerPageOptions: [5, 10, 25, 50, 100]
																}
															}
														}
													}}
												/>
											)
										},
										{
											name: "col1NameExt",
											renderer: () => {},
											width: 0
										},
										{
											name: "col2",
											label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
											width: 2,
											validationRule: "required|string",
											renderer: (field) => (
												<XsSearchSelect
													field={field}
													items={
														isSafe(GlobalStore.CL["diagnoseTypes"]) && field.path.split(".")[1] != 0
															? GlobalStore.CL["diagnoseTypes"].filter((i) => i.code !== "PRIMARY")
															: GlobalStore.CL["diagnoseTypes"]
													}
													required
													disabled={true}
												/>
											),
											defaultValue: (col, idx) => {
												if (isSafe(idx) && +idx > 0) {
													return "SECONDARY"
												} else {
													return "PRIMARY"
												}
											}
										},
										{
											name: "col3",
											label: <FormattedMessage id="Patient.form.patientrecord.clarification" />,
											width: 3,
											validationRule: "string",
											renderer: (field) => <XsInput field={field} />
										},
										{
											name: "col4",
											label: <FormattedMessage id="Patient.form.patientrecord.lifethreatening" />,
											width: 2,
											validationRule: "boolean",
											type: "checkbox",
											renderer: (field, index) => <XsCheckbox field={field} disabled={checkDisabled(index)} />
										}
									],
									options: {
										disableFirstRowDelete: true,
										isFormMode: true,
										isModalMode: false,
										hideButtons: true,
										startOpen: true,
										onFormOpen: () => {},
										onFormClose: () => {}
										// onFormSave: (values) => {
										// 	PatientRecordStore.diagnosisChips = values
										// }
									}
									// data: PatientRecordStore.diagnosisChips
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} className="mt-2">
						<Grid item xs={2}>
							<XsDateTimePicker field={props.form.$("examdate")} showTimeSelect={true} />
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect
								field={props.form.$("operating_theater")}
								items={PatientOperationStore.operatingTheaterData}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect
								field={props.form.$("surgery_schedule_type")}
								items={GlobalStore.CL["surgeryScheduleType"]}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect field={props.form.$("surgery_type")} items={GlobalStore.CL["surgeryType"]} />
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect field={props.form.$("anesthesia_type")} items={GlobalStore.CL["anesthesiaType"]} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={3}>
							<XsAutocomplete
								field={props.form.$("operator")}
								minLengthForSearch="3"
								api={api.loadPersonnelAndProvider}
								clearText
								filterValue="personnel_expertise_id"
								addSpecialFilter={() => {
									return [
										{
											associated_column: "company_id",
											predicate: "=",
											values: [
												{
													id_value: getUserCompanyInfo().id
												}
											]
										}
									]
								}}
								inputRenderer={(obj, menuItem) =>
									(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
										? "<b style=color:#00A787>"
										: "") +
									(obj.doctor_code ? obj.doctor_code + " / " : "") +
									obj.full_name +
									" / " +
									(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
									obj.cl_expertise_name_ext +
									(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
									(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
								}
								saveValue={(obj) => obj.personnel_expertise_id}
								postAction={(obj) => {
									props.form.$("operator_cl_expertise_id").value = obj.cl_expertise_id
									props.form.$("operator_personnel_id").value = obj.personnel_id
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsAutocomplete
								field={props.form.$("anesthesiologist")}
								minLengthForSearch="3"
								clearText
								api={api.loadPersonnelAndProvider}
								filterValue="personnel_expertise_id"
								addSpecialFilter={() => {
									return [
										{
											associated_column: "has_contract",
											predicate: "=",
											values: [
												{
													id_value: true
												}
											]
										}
									]
								}}
								inputRenderer={(obj, menuItem) =>
									(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
										? "<b style=color:#00A787>"
										: "") +
									(obj.doctor_code ? obj.doctor_code + " / " : "") +
									obj.full_name +
									" / " +
									(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
									obj.cl_expertise_name_ext +
									(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
									(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
								}
								saveValue={(obj) => obj.personnel_expertise_id}
								postAction={(obj) => {
									props.form.$("anesthesiologist_cl_expertise_id").value = obj.cl_expertise_id
									props.form.$("anesthesiologist_personnel_id").value = obj.personnel_id
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsAutocomplete
								field={props.form.$("instrumentalist")}
								minLengthForSearch="3"
								api={api.loadPersonnelAndProvider}
								clearText
								filterValue="personnel_id"
								addSpecialFilter={() => {
									return [
										{
											associated_column: "company_id",
											predicate: "=",
											values: [
												{
													id_value: getUserCompanyInfo().id
												}
											]
										},
										{
											associated_column: "category_id",
											predicate: "=",
											values: [
												{
													id_value: "D"
												}
											]
										}
									]
								}}
								inputRenderer={(obj, menuItem) =>
									(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
										? "<b style=color:#00A787>"
										: "") +
									(obj.doctor_code ? obj.doctor_code + " / " : "") +
									obj.full_name +
									" / " +
									(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
									obj.cl_expertise_name_ext +
									(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
									(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
								}
								saveValue={(obj) => obj.personnel_id}
								postAction={(obj) => {
									props.form.$("instrumentalist_cl_expertise_id").value = obj.cl_expertise_id
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={2}>
							<XsDateTimePicker field={props.form.$("operationStartDate")} showTimeSelect={true} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={props.form.$("operationEndDate")} showTimeSelect={true} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={props.form.$("operationRoomEntry")} showTimeSelect={true} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={props.form.$("operationRoomLeave")} showTimeSelect={true} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item>Doba prípravy:</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={2}>
							<XsInput
								field={props.form.$("surgeon_preparation_duration")}
								InputProps={{
									endAdornment: (
										<InputAdornment className="xs-endUnit" position="end">
											min
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput
								field={props.form.$("instrumentalist_preparation_duration")}
								InputProps={{
									endAdornment: (
										<InputAdornment className="xs-endUnit" position="end">
											min
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput
								field={props.form.$("anesthesiologist_preparation_duration")}
								InputProps={{
									endAdornment: (
										<InputAdornment className="xs-endUnit" position="end">
											min
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput
								field={props.form.$("anesthesia_duration")}
								InputProps={{
									endAdornment: (
										<InputAdornment className="xs-endUnit" position="end">
											min
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput
								field={props.form.$("mechanical_ventilation_duration")}
								InputProps={{
									endAdornment: (
										<InputAdornment className="xs-endUnit" position="end">
											min
										</InputAdornment>
									)
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} className="mt-3">
						<Grid item xs={3}>
							<XsSearchSelect
								field={props.form.$("surgery_perop_complications")}
								items={PatientOperationStore.complicationPeData}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsSearchSelect
								field={props.form.$("surgery_associated_complications")}
								items={PatientOperationStore.complicationAsData}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsSearchSelect
								field={props.form.$("surgery_procedure")}
								items={GlobalStore.CL["surgeryProcedure"]}
								getOptionLabel={(row) => `${row.code_ext} - ${row.name_ext}`}
								required
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsInput field={props.form.$("note")} multiple rows={4} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsTextArea
								field={props.form.$("anamnesis")}
								rows={10}
								rowsId={"operationAnamnesis"}
								maxLength={5000}
								showNoLabel={true}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={dropdownRecordTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													if (
														isNotEmpty(props.form.$("anamnesis").value) &&
														PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis
													) {
														handleInsertHelpersIntoTextArea(
															props.form,
															"anamnesis",
															PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis.cursor,
															data.itemData
														)
													} else {
														props.form.$("anamnesis").value += data.itemData
													}
													delete PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (
										data &&
										(isNotSafe(PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis) ||
											PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis.cursor != data.cursor)
									) {
										PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
						<TemplateForm type="OP" />
					</Grid>
				</div>
				<div className="xs-patient-form-right">
					{/* tu budu vykony */}
					<DeliveriesForm id={props.id} form={props.form} chipFormField={props.form.$("deliveriesChipsForm")} />
					{/* {PatientRecordStore.masterRecord && (
						<div>
							<div className={props.classes.title}>
								<FormattedMessage id="Patient.form.patientrequest.parentrecord" />
							</div>
							<Chip
								label={`${moment(PatientRecordStore.masterRecord.created_at).format(
									props.intl.formatMessage({id: "Application.moment.datetimeformat"})
								)} ${PatientRecordStore.masterRecord.name}`}
								onDelete={() => (PatientRecordStore.masterRecord = null)}
								color="primary"
								classes={{
									root: props.classes.chipStyle,
									label: props.classes.chipLabel
								}}
							/>
						</div>
					)}
					<TemplateForm type="AMB" specialLoadType={["AMB", "OF", "Recomm", "DC", "IEXAM"]} specialName="RECORD" />
					<DeliveriesForm id={props.id} form={props.form} chipFormField={props.form.$("deliveriesChipsForm")} /> */}
				</div>
			</div>
			<div className="xs-patient-form-actions">
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.save" />}
					type="submit"
					onClick={(e) => {
						logger("props.form", props.form.values())
						props.form.$("printOperation").value = false
						props.form.onSubmit(e)
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
					tabindex={10001}
					onClick={async (e) => {
						props.form.$("printOperation").value = true
						props.form.onSubmit(e)
					}}
					icon={<PrintIcon />}
				/>
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						props.form.$("printOperation").value = false
						props.options.onClose("OPERATION", false, true)
					}}
					icon={<CancelIcon />}
				/>
			</div>
		</section>
	)
}

export default injectIntl(observer(PatientOperationForm))
