import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"

import {Grid} from "@material-ui/core"

import "./printTemplatesStyle.less"

import fields from "./requestExaminationRadiofarmakomFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsSimpleTextArea from "../../../../../../global/ui/xsTextArea/xsSimpleTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"
import {getSelectedOrgUnitID, getSelectedOrgUnit} from "../../../../../../global/helpers/actions"
import TemplateForm from "../../patientRecordForm/templateForm"
import DataStore from "../../../../stores/DataStore"
import UIStore from "../../../../stores/UIStore"

import api from "../../../../actions/api"

@injectIntl
@observer
export default class RequestExaminationRiadiofarmakom extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	componentDidMount() {
		const orgunit = getSelectedOrgUnit()

		if (isNotEmpty(this.props.values) && isEmpty(this.props.values.sendingDocotrCode) && isSafe(orgunit)) {
			this.form.$("sendingDocotrCode").set("value", isSafe(orgunit.doctor_code) ? orgunit.doctor_code : "")
			this.form.$("orgunitCode").set("value", isSafe(orgunit.provider_code) ? orgunit.provider_code : "")
		}

		if (isNotEmpty(this.props.values) && isEmpty(this.props.values.clinicalDiagnosis)) {
			UIStore.isFormSaving = true
			api
				.loadPatientOverview(
					getSelectedOrgUnitID(),
					DataStore.patientDTO.get("patientID"),
					null,
					moment()
						.startOf("day")
						.utc()
						.format(),
					moment()
						.endOf("day")
						.utc()
						.format()
				)
				.call()
				.then((res) => {
					if (isSafe(res.rows)) {
						let dekurz = res.rows.find((i) => i.type_code == "DEK")

						if (isSafe(dekurz) && isNotEmpty(dekurz.diagnosis_code_ext)) {
							// logger("dekurz.diagnosis_code_ext", dekurz.diagnosis_code_ext)
							this.form.$("clinicalDiagnosis").set("value", dekurz.diagnosis_code_ext)
						}
					}
					UIStore.isFormSaving = false
				})
		}
	}

	render() {
		return (
			<React.Fragment>
				<Grid container spacing={8}>
					<Grid item xs={6}>
						<XsInput field={this.form.$("name")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("identifier")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={9}>
						<XsInput field={this.form.$("address")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("phone")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={3}>
						<XsInput field={this.form.$("insurerCode")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("idDrg")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("weight")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("height")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={3}>
						<XsInput field={this.form.$("sendingMedicalDevice")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("orgunitCode")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("sendingDocotrCode")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("sendingDoctorPhone")} />
					</Grid>
					{/* <Grid item xs={12}>
                        <XsSimpleTextArea field={this.form.$("complicationsDuringOperation")} rows={3} />
                    </Grid> */}
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={9}>
						<XsAutocompleteLocal
							field={this.form.$("clinicalDiagnosis")}
							minLengthForSearch="3"
							filterValue="code_ext"
							clearText
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => {
								return obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code_ext}
							postAction={(obj) => {
								this.form.$("diagnosisNameExt").value = obj.name_ext
								this.form.$("diagnosisCodeExt").value = obj.code_ext
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("typeOfExaminationRequired")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("question")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("additionalClinicalData")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("resultMethod")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("drugTreatment")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("radioterapy")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("operationDelivery")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("previousDiagnostic")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("datetime")} showTimeSelect={false} />
					</Grid>
					<Grid item xs={4}>
						<XsDateTimePicker
							field={this.form.$("orderDate")}
							showTimeSelect
							onSelect={(val) => {
								if (isSafe(val)) {
									this.form.$("date").value = moment(val).format("DD.MM.YYYY")
									this.form.$("hour").value = moment(val).format("HH:mm")
								} else {
									this.form.$("date").value = ""
									this.form.$("hour").value = ""
								}
							}}
						/>
					</Grid>
				</Grid>
				<TemplateForm type={this.templateName} />
			</React.Fragment>
		)
	}
}
