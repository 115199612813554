import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./requestCTIMEAFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
// import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsSimpleTextArea from "../../../../../../global/ui/xsTextArea/xsSimpleTextArea"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import api from "../../../../actions/api"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import {getSelectedOrgUnitID, getUserDoctorInfo} from "../../../../../../global/helpers/actions"

import DataStore from "../../../../stores/DataStore"
import UIStore from "../../../../stores/UIStore"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class RequestCTTemplateIMEA extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.state = {
			doctorOUPZSItems: []
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	componentDidMount() {
		const doctorInfo = getUserDoctorInfo()

		if (isNotEmpty(this.props.values) && isEmpty(this.props.values.sendingDoctor) && isSafe(doctorInfo)) {
			this.form.$("sendingDoctor").set("value", isSafe(doctorInfo.expertise) ? doctorInfo.expertise.id : "")
			this.form.$("doctorCode").set("value", doctorInfo.code)
			this.form.$("doctorName").set("value", doctorInfo.name)
		}

		if (isNotEmpty(this.props.values) && isEmpty(this.props.values.clinicalDiagnosis)) {
			UIStore.isFormSaving = true
			api
				.loadPatientOverview(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID"))
				.call()
				.then((res) => {
					if (isSafe(res.rows)) {
						let dekurz = res.rows.find((i) => i.type_code == "DEK" && moment(i.created_at).isSame(moment(), "day"))

						if (isSafe(dekurz)) {
							if (isNotEmpty(dekurz.diagnosis_code_ext)) {
								this.form.$("clinicalDiagnosis").set("value", dekurz.diagnosis_code_ext)
								this.form.$("diagnosisCodeExt").set("value", dekurz.diagnosis_code_ext)
								this.form.$("diagnosisNameExt").set("value", dekurz.diagnosis_name_ext)
							}

							if (isSafe(dekurz.data) && dekurz.data) {
								const objNalez = dekurz.data.find((x) => x.type._id == "AMB")
								const examinate = dekurz.data.find((x) => x.type._id == "IEXAM")

								if (isSafe(objNalez)) {
									this.form.$("epikriza").set("value", objNalez.content)

									let rtgUsg = objNalez.content

									if (isSafe(examinate) && isNotEmpty(examinate.content)) {
										rtgUsg += examinate.content
									}

									this.form.$("rtgUsg").set("value", rtgUsg)
								}
							}
						}

						let anamnesis = res.rows.find((i) => i.type_code == "ANA")

						if (isSafe(anamnesis) && isSafe(anamnesis.data) && anamnesis.data) {
							const personAna = anamnesis.data.find((x) => x.type._id == "ANA")
							this.form.$("anamnesis").set("value", personAna.content)
						}
						UIStore.isFormSaving = false
					}
				})
		}
	}

	render() {
		return (
			<React.Fragment>
				<Grid className="pa-4" container direction="column" align="top">
					<Grid container spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("name")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("identifier")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("insurerCode")} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={9}>
							<XsInput field={this.form.$("address")} />
						</Grid>
					</Grid>

					<Grid container spacing={8}>
						<Grid item xs={9}>
							<XsAutocompleteLocal
								field={this.form.$("clinicalDiagnosis")}
								minLengthForSearch="3"
								filterValue="code_ext"
								clearText
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code_ext}
								postAction={(obj) => {
									this.form.$("diagnosisNameExt").value = obj.name_ext
									this.form.$("diagnosisCodeExt").value = obj.code_ext
								}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={8}>
						<Grid item xs={9}>
							<XsAutocomplete
								field={this.form.$("sendingDoctor")}
								minLengthForSearch="3"
								api={api.loadPersonnel}
								clearText
								filterValue="personnel_expertise_id"
								inputRenderer={(obj, menuItem) =>
									(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
										? "<b style=color:#00A787>"
										: "") +
									(obj.identifier ? obj.identifier + " / " : "") +
									obj.full_name +
									" / " +
									(obj.expertise_code ? obj.expertise_code + " " : "") +
									obj.expertise +
									(isNotEmpty(obj.preferred_provider_code) ? " / " + obj.preferred_provider_code : "") +
									(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
										? "</b>"
										: "")
								}
								saveValue={(obj) => obj.personnel_expertise_id}
								postAction={(obj) => {
									this.form.$("doctorCode").value = obj.identifier
									this.form.$("doctorName").value = obj.full_name
									// props.form.$("operator_cl_expertise_id").value = obj.cl_expertise_id
									// props.form.$("operator_personnel_id").value = obj.personnel_id
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("orgunitcontact")} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={5}>
							<XsInput field={this.form.$("orgunitcode")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("examinationDatetime")}
								showTimeSelect={true}
								onChange={(value) => {
									if (isSafe(value)) {
										this.form.$("examinationDate").value = value.format("DD.MM.YYYY")
										this.form.$("examinationTime").value = value.format("HH:mm")
									} else {
										this.form.$("examinationDate").value = ""
										this.form.$("examinationTime").value = ""
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsTextArea field={this.form.$("anamnesis")} rows={3} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsTextArea field={this.form.$("alergyAnamnesis")} rows={3} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsTextArea field={this.form.$("epikriza")} rows={3} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsTextArea field={this.form.$("rtgUsg")} rows={3} />
						</Grid>
					</Grid>
					<Grid container spacing={8} className="pt-4 pb-3">
						<Grid item xs={12}>
							Bol už pacient na <b>CT vyšetrení?</b>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ctOrgunitName")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("ctExaminationDate")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("ctExaminationEnd")} rows={2} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsSimpleTextArea field={this.form.$("questionCTExamination")} rows={3} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsSimpleTextArea field={this.form.$("questionCTExaminationAnswer")} rows={3} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}
