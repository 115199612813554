export const NAME = "auth"

export const KEY_AUTH_TOKEN = "auth-token"
export const USER_DATA = "user-data"
export const USER_SETTINGS = "user-settings"
export const LAST_PATIENTS = "last-patients"
export const RESOURCES = "doctor-resources"
export const LAST_PATIENTS_COUNT = 5
export const LANGUAGE = "language"
export const DZPDATE = "check-dovera-date"
export const TEXTAREASIZES = "textareasizes"

export const API_ERROR = `${NAME}/API_ERROR`
export const CLEAR_API_ERROR = `${NAME}/CLEAR_API_ERROR`
