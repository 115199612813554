import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import {
	getUserPersonnelID,
	getSelectedOrgUnitID,
	getSelectedOrgUnit,
	getUser
} from "../../../../../global/helpers/actions"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientBloodTypeDialogStore from "../../../stores/PatientBloodTypeDialogStore"
// import WarningStore from "../../../../../global/store/WarningStore"
import config from "../../../../../global/config/settings"
import api from "../../../actions/api"
import {GWApiCall} from "../../../../../global/helpers/api"

@injectIntl
@observer
export default class PatientBloodTypeDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				let request = {
					_id: isNotEmpty(PatientBloodTypeDialogStore.recordId) ? PatientBloodTypeDialogStore.recordId : "",
					_ref: false,
					_type: "EHR.Data.BloodType",
					// implantation_date: isNotEmpty(form.$("implantation_date").value) ? form.$("implantation_date").value : null,
					type: {
						_id: form.$("bloodType").value,
						_type: "EHR.CL.Patient.BloodType",
						_ref: true
					},
					active: true,
					recorded_events: [
						{
							_ref: false,
							_type: "EHR.Data.Record.Event",
							active: true,
							recorded_at: form.$("dateOfExam").value, //moment().utc()
							type: {
								_id: "WRT",
								_type: "EHR.CL.Record.EventType"
							}
						}
					],
					specific_rels: [
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: DataStore.patientDTO.get("patientID"),
								_type: "EHR.Data.Entity.Patient"
							},
							type: "pat"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: getUserPersonnelID(),
								_type: "EHR.Data.Entity.Personnel"
							},
							type: "phy"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: getSelectedOrgUnitID(),
								_type: "Entity.Data.OrgUnit"
							},
							type: "ou"
						}
					]
				}

				api
					.saveRecord(request, DataStore.patientDTO.get("patientID"))
					.call()
					.then((res) => {
						PatientBloodTypeDialogStore.recordId = undefined
						api
							.loadBloodType(DataStore.patientDTO.get("patientID"))
							.call()
							.then((response) => {
								DataStore.patientDTO.set("bloodType", response.records[0])
								PatientBloodTypeDialogStore.close()
							})

						const selectedOrgUnit = getSelectedOrgUnit()
						const user = getUser()
						const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
						const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
						const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
						const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
						const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

						const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
							? DataStore.patientDTO.get("patient").validInfo.client
							: null
						const JRUZIDObj = isSafe(patientClientInfo.identifiers)
							? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
							: null

						GWApiCall({
							method: "POST",
							uri: `${config.GW_BASE_URL}/${
								res.ixs_signer_data.operation === "invalidate" ? "InvalidateEHRExtract" : "WriteEHRExtract"
							}`,
							body: JSON.stringify({
								// ClassName:
								// 	res.ixs_signer_data.operation === "invalidate"
								// 		? "ZrusZapisZPacientskehoSumaru_v2"
								// 		: "ZapisPacientskehoSumaruZdravotneProblemy_v2",
								ClassName: res.ixs_signer_data.eh_class,
								ExtId: res.ixs_signer_data.ext_id,
								ArchetypeId: res.ixs_signer_data.archetype_id,
								EHRExtract: res.ixs_signer_data.payload.text,
								PreviousExtId: res.ixs_signer_data.previous_ext_id,
								TimeCommitted: res.actual_time,
								RcIdOID: res.ixs_signer_data.rcid_oid,
								AmbeePatientId: DataStore.patientDTO.get("patientID"),
								PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
								PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
								AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
								AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
								AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
								AmbeePersonnelId: getUserPersonnelID(),
								OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
								PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
								PersonnelExpertiseJRUZVersion: specVer,
								PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
								RecordSignerData: isSafe(res.record_signer_data)
									? res.record_signer_data.map((x) => {
											return {
												Ref: x._ref,
												Type: x._type,
												RecordClass: x.record_class,
												RecordExternalId: x.record_external_id,
												RecordId: x.record_id
											}
									  })
									: null
							})
						})
							.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
							.then((data) => {
								api.setSent(res.ixs_signer_data.ext_id).call()
								if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
									GlobalStore.checkEHGWError(res.ErrorCode, res.ErrorMessage, res.IsEhealthException)
									// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
								}
							})
							.catch()

						form.reset()
					})
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		GlobalStore.refreshCodeLists(["bloodType"])

		this.form = new MobxReactForm(
			{
				fields: {
					bloodType: {
						label: <FormattedMessage id="Patient.sidebar.bloodType" />,
						rules: "required"
					},
					dateOfExam: {
						label: <FormattedMessage id="Common.label.dateOfExam" />,
						rules: "required",
						type: "date",
						value: moment()
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	componentDidMount() {
		PatientBloodTypeDialogStore.form = this.form
	}

	deleteBloodType = () => {
		api
			.deleteRecord(PatientBloodTypeDialogStore.recordId)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					if (isSafe(res.ixs_signer_data)) {
						const selectedOrgUnit = getSelectedOrgUnit()
						const user = getUser()
						const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
						const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
						const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
						const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
						const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

						const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
							? DataStore.patientDTO.get("patient").validInfo.client
							: null
						const JRUZIDObj = isSafe(patientClientInfo.identifiers)
							? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
							: null

						GWApiCall({
							method: "POST",
							uri: `${config.GW_BASE_URL}/CancelEHRExtract`,
							body: JSON.stringify({
								ClassName: "ZrusZapisZPacientskehoSumaru_v2",
								ExtId: res.ixs_signer_data.ext_id,
								PreviousExtId: res.ixs_signer_data.previous_ext_id,
								ArchetypeId: res.ixs_signer_data.archetype_id,
								RcIdOID: res.ixs_signer_data.rcid_oid,
								AmbeePatientId: DataStore.patientDTO.get("patientID"),
								PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
								PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
								AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
								AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
								AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
								AmbeePersonnelId: getUserPersonnelID(),
								OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
								PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
								PersonnelExpertiseJRUZVersion: specVer,
								PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
								RecordSignerData: isSafe(res.record_signer_data)
									? res.record_signer_data.map((x) => {
											return {
												Ref: x._ref,
												Type: x._type,
												RecordClass: x.record_class,
												RecordExternalId: x.record_external_id,
												RecordId: x.record_id
											}
									  })
									: null
							})
						})
							.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
							.then((data) => {
								api.setSent(res.ixs_signer_data.ext_id).call()
								if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
									GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
									// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
								}
							})
							.catch()
					}
					PatientBloodTypeDialogStore.recordId = undefined
					api
						.loadBloodType(DataStore.patientDTO.get("patientID"))
						.call()
						.then((response) => {
							DataStore.patientDTO.set("bloodType", response.records[0])
							PatientBloodTypeDialogStore.close()
						})
				}

				this.form.reset()
			})
	}

	render() {
		return (
			<Dialog
				id="xsPatientBloodTypeDialog"
				open={PatientBloodTypeDialogStore.isOpen}
				onClose={() => {
					PatientBloodTypeDialogStore.close()
					this.form.reset()
				}}
				className="xs-base-dialog dialog-patientbloodtype"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-450"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PatientBloodTypeDialogStore.close()
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-tint fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.sidebar.bloodType" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Patient.sidebar.bloodType.subtitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<Grid item container spacing={8}>
								<Grid item xs={12}>
									<SearchSelect field={this.form.$("bloodType")} items={GlobalStore.CL["bloodType"]} />
								</Grid>
								<Grid item xs={12}>
									<XsDateTimePicker field={this.form.$("dateOfExam")} showTimeSelect={false} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-success mr-3"
						type="submit"
						onClick={this.form.onSubmit}
						text={<FormattedMessage id="Patient.sidebar.bloodType.save" />}
						icon={<i className="fal fa-plus fa-lg" />}
					/>
					{isSafe(PatientBloodTypeDialogStore.recordId) && (
						<XsButton
							className="xs-danger xs-outline"
							type="submit"
							onClick={() => {
								GlobalStore.openConfirmationDialog("xsDeleteBloodType")
							}}
							text={<FormattedMessage id="Patient.sidebar.bloodType.delete" />}
							icon={<i className="fal fa-trash-alt fa-lg" />}
						/>
					)}
				</DialogActions>
				<XsConfirmationDialog
					type="danger"
					name="xsDeleteBloodType"
					text={
						<span className="xs-greyDefault fontSize13">
							<FormattedMessage id="Delete.bloodType.text" />
						</span>
					}
					subtext={<span className="xs-bold fontSize15">{PatientBloodTypeDialogStore.nameOfDeleteBloodType}</span>}
					headerConfig={{
						text: "Delete.bloodType.header.text",
						subtext: "Delete.bloodType.header.subtext",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.bloodType.delete"
					}}
					onConfirmation={() => this.deleteBloodType()}
				/>
			</Dialog>
		)
	}
}
