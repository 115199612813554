import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Checkbox, FormControlLabel} from "@material-ui/core"

import fields from "./invoiceSettingsDialogFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import InvoiceSettingsStore from "../../../stores/InvoiceSettingsStore"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"

import GlobalStore from "../../../../../global/store/GlobalStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

import "./invoiceSettings.less"

@injectIntl
@observer
export default class InvoiceSettingsDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (
					isEmpty(form.$("invoice").value) ||
					isEmpty(form.$("insurer").value) ||
					isEmpty(form.$("orgunit").value) ||
					isEmpty(form.$("partner").value)
				) {
					GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
					form.invalidate()
				}
			},
			onSuccess(form) {
				if (isSafe(InvoiceSettingsStore.insurerConfigId)) {
					InvoiceSettingsStore.updateInvoice(form)
				} else {
					InvoiceSettingsStore.addInvoice(form)
				}
			},
			onError() {
				// UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		InvoiceSettingsStore.invoiceFormRef = this.form
	}

	form

	rows = () => {
		let renderRows = []

		if (isSafe(InvoiceSettingsStore.invoicesJSON)) {
			InvoiceSettingsStore.invoicesJSON.forEach((value, idx) => {
				//eslint-disable-line
				let invoiceArr = InvoiceSettingsStore.invoicesJSON.get(idx)
				if (isSafe(invoiceArr)) {
					renderRows.push(
						invoiceArr.map((invoice, index) => {
							return (
								<Grid key={`${idx}_${index}`} container spacing={8} alignItems="center">
									<Grid item onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										{invoice.invoice}
									</Grid>
									<Grid item style={{width: "125px"}}>
										{InvoiceSettingsStore.insurerJSON[invoice.insurer]}
									</Grid>
									<Grid item style={{width: "150px"}} onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										{InvoiceSettingsStore.customerJSON[invoice.partner]}
									</Grid>
									<Grid item style={{width: "150px"}} onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										{InvoiceSettingsStore.orgunitJSON[invoice.orgunit]}
									</Grid>
									<Grid item onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										<FormControlLabel control={<Checkbox disabled={true} checked={invoice.capitation} />} label="KAP" />
									</Grid>
									<Grid item onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										<FormControlLabel control={<Checkbox disabled={true} checked={invoice.bezd} />} label="BEZD" />
									</Grid>
									<Grid item onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										<FormControlLabel control={<Checkbox disabled={true} checked={invoice.cudz} />} label="CUDZ" />
									</Grid>
									<Grid item onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										<FormControlLabel control={<Checkbox disabled={true} checked={invoice.ine} />} label="INE" />
									</Grid>
									<Grid item onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										<FormControlLabel control={<Checkbox disabled={true} checked={invoice.eu} />} label="eu" />
									</Grid>
									<Grid item onClick={() => InvoiceSettingsStore.selectInvoiceItem(invoice)}>
										<FormControlLabel control={<Checkbox disabled={true} checked={invoice.vzp} />} label="VZP" />
									</Grid>
									<Grid item>
										<XsIconButton
											className={"xs-danger xs-outline"}
											icon={<i className="fal fa-trash-alt fa-lg" />}
											onClick={() => InvoiceSettingsStore.removeInvoice(invoice.invoice, invoice.orgunits_config_id)}
										/>
									</Grid>
								</Grid>
							)
						})
					)
				}
			})
		}

		return renderRows
	}

	addBtnDisabled = () => {
		const formValues = this.form.values()

		let dis = true

		if (
			formValues.eu ||
			formValues.cudz ||
			formValues.vzp ||
			formValues.ine ||
			formValues.bezd ||
			formValues.capitation
		) {
			dis = false
		}

		return dis
	}

	render() {
		return (
			<Dialog
				id="xsInvoiceSettingsDialog"
				open={InvoiceSettingsStore.isOpenInvoiceSettingDialog}
				onClose={() => InvoiceSettingsStore.closeInvoiceSettings()}
				disableBackdropClick={true}
				classes={{
					paper: "xs-paper-dialog xs-width-paper-1050"
				}}
				// maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-cog fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.settingInvoiceAndBatch" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="pa-0 hidden xs-column">
					<Grid container justify="center" className="pt-2">
						<Grid item style={{width: "200px"}}>
							<XsSearchSelect
								field={this.form.$("invoice")}
								items={InvoiceSettingsStore.invoiceArr}
								required
								onChange={(val) => InvoiceSettingsStore.changeInvoice(val)}
							/>
						</Grid>
					</Grid>
					<div className="pr-4 pl-4">
						<Grid container alignItems="center" justify="space-between" spacing={8}>
							<Grid item style={{width: "125px"}}>
								<XsSearchSelect
									required
									disabled={InvoiceSettingsStore.disabledFields}
									field={this.form.$("insurer")}
									items={RegistersCacheStore.insurersRegister}
									onChange={() => InvoiceSettingsStore.validation()}
								/>
							</Grid>
							<Grid item style={{width: "150px"}}>
								<XsSearchSelect
									field={this.form.$("partner")}
									disabled={InvoiceSettingsStore.disabledFields}
									items={InvoiceSettingsStore.customers}
									onChange={() => InvoiceSettingsStore.validation()}
									required
								/>
							</Grid>
							<Grid item style={{width: "150px"}}>
								<XsSearchSelect
									field={this.form.$("orgunit")}
									// disabled={InvoiceSettingsStore.disabledFields}
									items={getUserPersonnelOrgUnits()}
									onChange={() => InvoiceSettingsStore.validation()}
									required
								/>
							</Grid>
							<Grid item>
								<XsCheckbox field={this.form.$("capitation")} disabled={InvoiceSettingsStore.disabledCap} />
							</Grid>
							<Grid item>
								<XsCheckbox field={this.form.$("bezd")} disabled={InvoiceSettingsStore.disabledBezd} />
							</Grid>
							<Grid item>
								<XsCheckbox field={this.form.$("cudz")} disabled={InvoiceSettingsStore.disabledCudz} />
							</Grid>
							<Grid item>
								<XsCheckbox field={this.form.$("ine")} disabled={InvoiceSettingsStore.disabledIne} />
							</Grid>
							<Grid item>
								<XsCheckbox field={this.form.$("eu")} disabled={InvoiceSettingsStore.disabledEu} />
							</Grid>
							<Grid item>
								<XsCheckbox field={this.form.$("vzp")} disabled={InvoiceSettingsStore.disabledVzp} />
							</Grid>
							<Grid item>
								<XsIconButton
									className="xs-success xs-outline"
									disabled={this.addBtnDisabled()}
									icon={
										isSafe(InvoiceSettingsStore.insurerConfigId) ? (
											<i className="fal fa-save fa-lg" />
										) : (
											<i className="fal fa-plus fa-lg" />
										)
									}
									onClick={this.form.onSubmit}
								/>
							</Grid>
						</Grid>
					</div>
					<div className="bgWhite pr-4 pl-4 overflowY invoiceList">
						<Grid container direction="column" spacing={8}>
							<Grid item xs={12}>
								{this.rows()}
							</Grid>
						</Grid>
					</div>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container alignItems="center" justify="space-between">
						<Grid item xs={4}>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times fa-lg" />}
								text={<FormattedMessage id="Common.label.closeAndDiscardChanges" />}
								onClick={() => InvoiceSettingsStore.closeInvoiceSettings()}
							/>
						</Grid>
						<Grid item xs={8} container spacing={8} justify="flex-end">
							<Grid item>
								<XsButton
									className={"xs-success xs-outline"}
									icon={<i className="fal fa-save fa-lg" />}
									text={<FormattedMessage id="Common.label.save" />}
									onClick={() => InvoiceSettingsStore.saveInvoices(false)}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className={"xs-success"}
									icon={<i className="fal fa-save fa-lg" />}
									text={<FormattedMessage id="Common.label.saveAndClose" />}
									onClick={() => InvoiceSettingsStore.saveInvoices(true)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
