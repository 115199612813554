"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../../../global/ui/globalUISchemeBindings"

// import UI controls here
import XsInput from "../../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import SearchSelect from "../../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import api from "../../../../../actions/api"
import AddIcon from "@material-ui/icons/Add"

// definitions of form fields
import fields from "./providedServicesFormFields"

import {FormattedMessage, injectIntl} from "react-intl"
import UIStore from "../../../../../stores/UIStore"
import GlobalStore from "../../../../../../../global/store/GlobalStore"
import WarningStore from "../../../../../../../global/store/WarningStore"
import CodeListStore from "../../../../../stores/CodeListStore"

import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import TrashIcon from "@material-ui/icons/DeleteForever"
import XsButton from "../../../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../../../global/ui/xsLoading/xsLoading"
import XsAutocomplete from "../../../../../../../global/ui/xsInput/xsAutocomplete"
import XsChipForm from "../../../../../../../global/ui/xsChipForm/xsChipForm"
import XsIconButton from "../../../../../../../global/ui/xsButton/xsIconButton"
import XsColorPicker from "../../../../../../../global/ui/xsColorPicker/xsColorPicker"
import XsConfirmationDialog from "../../../../../../../global/ui/xsDialog/xsConfirmationDialog"
import {ixsIsObject} from "../../../../../../../global/helpers/functions"
import XsCheckbox from "../../../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsTabsIcon from "../../../../../../../global/ui/xsTabs/xsTabsIcon"
import AddCodelistForm from "../../addCodelistForm/addCodelistForm"
import PriceListTableEx from "./priceListTableEx"

// import XsAutocompleteLocal from "../../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegistersCacheStore from "../../../../../../../global/store/RegistersCacheStore"

import "./providedServices.less"

@observer
export class ProvidedServicesForm extends React.Component {
	constructor(props) {
		super(props)
		//Reset
		CodeListStore.providedServiceLoaded = false
		CodeListStore.providedServiceLoading = false

		//Load služieb
		CodeListStore.loadCLServices()

		//Load templates
		CodeListStore.loadTemplates()

		CodeListStore.warningMessage = props.intl.formatMessage({id: "Common.warning.message.emptyform"})

		const hooks = {
			onSubmit(form) {
				if (
					CodeListStore.deliveryChipsForm &&
					CodeListStore.deliveryChipsForm.form &&
					!CodeListStore.deliveryChipsForm.form.isValid
				) {
					form.invalidate()
				} else {
					UIStore.isFormSaving = true
				}
			},
			onSuccess(form) {
				CodeListStore.saveProvidedServicesForm(form)
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		GlobalStore.refreshCodeLists(["genders", "summaryType", "recordMeasures"])
	}

	componentWillUnmount() {
		CodeListStore.resetProvidedServicesForm()
	}

	checkUniqueSummaryType = (field, summaryType, fieldKey) => {
		if (CodeListStore.templateChipsForm) {
			const templatesData = CodeListStore.templateChipsForm.getData()
			let unique = true
			Object.keys(templatesData).forEach((index) => {
				if (templatesData[index] && index != fieldKey && templatesData[index].summaryType === summaryType) {
					unique = false
					return
				}
			})
			if (!unique) {
				WarningStore.open(this.props.intl.formatMessage({id: "Admin.codelist.providedservices.warningUniqueTemplate"}))
				field.set("value", null)
			}
		}
	}

	initLoad = () => {
		if (!CodeListStore.providedServiceLoaded) {
			api
				.loadProvidedService(CodeListStore.selectedProvidedServiceID)
				.call()
				.then((ps) => {
					const fieldsObj = fields.load().fields
					Object.keys(fieldsObj).forEach((key) => {
						if (ps[key]) {
							let newValue = ixsIsObject(ps[key]) ? ps[key]._id : ps[key]
							if (typeof newValue !== "boolean") {
								newValue = isNaN(newValue) ? newValue : parseInt(newValue)
							}
							this.form.$(key).set("value", newValue)
						}
					})

					//Visual
					this.form.$("back_color").set("value", ps.visual.back_color)
					this.form.$("fore_color").set("value", ps.visual.fore_color)
					this.form.$("duration").set("value", ps.duration / 60)
					this.form.$("service").set("value", ps.service._id)
					this.form.$("id").set("value", CodeListStore.selectedProvidedServiceID)

					//Gender
					const gender = ps.relateds.find((r) => r.rel_class_name === "Entity.CL.Gender" && r.active)
					if (gender) {
						this.form.$("gender").set("value", gender.rel_object_id)
					}

					//Insurer
					const insurer = ps.relateds.find((r) => r.rel_class_name === "EHR.Data.Entity.Insurer" && r.active)
					if (insurer) {
						this.form.$("insurer").set("value", insurer.rel_object_id)
					}

					//Insurer Type
					const insurerType = ps.relateds.find((r) => r.rel_class_name === "EHR.CL.Patient.InsuranceType" && r.active)
					if (insurerType) {
						this.form.$("insurerType").set("value", insurerType.rel_object_id)
					}

					//Deliveries
					const items = ps.relateds
						.filter((r) => r.rel_class_name === "EHR.CL.Bill.Delivery" && r.active)
						.sort((a, b) => a.item_order - b.item_order)
						.map((r) => {
							let relatedDiagnosis = ps.relateds.find(
								(diag) => diag.rel_class_name == "EHR.CL.Record.Diagnosis" && diag.item_order == r.item_order
							)
							let relatedDeliveryItem = ps.relateds.find(
								(di) => di.rel_class_name == "EHR.CL.Bill.DeliveryItem" && di.item_order == r.item_order
							)
							return {
								delivery: r.rel_object_id,
								deliveryQuantity: r.quantity,
								deliveryItem: isSafe(relatedDeliveryItem) ? relatedDeliveryItem.rel_object_id : "",
								deliveryDiagnosis: isSafe(relatedDiagnosis) ? relatedDiagnosis.rel_object_id : ""
							}
						})

					CodeListStore.set("deliveryChips", items)
					if (isSafe(CodeListStore.deliveryChipsForm)) {
						CodeListStore.deliveryChipsForm.bindData(items)
					}

					//Templates
					const itemsTemplates = ps.relateds
						.filter((r) => r.rel_class_name === "EHR.Data.Template.Summary" && r.active)
						.sort((a, b) => a.item_order - b.item_order)
						.map((r) => {
							const template = CodeListStore.templates.find((t) => t._id === r.rel_object_id)
							return {
								summaryType: template ? template.type : null,
								templateType: r.rel_object_id
							}
						})

					CodeListStore.set("templateChips", itemsTemplates)
					if (isSafe(CodeListStore.templateChipsForm)) {
						CodeListStore.templateChipsForm.bindData(itemsTemplates)
						CodeListStore.templateChips = CodeListStore.templateChipsForm.form.values()
					}

					//Clinical Values
					const cvItems = ps.relateds
						.filter((r) => r.rel_class_name === "EHR.CL.Record.Measure" && r.active)
						.sort((a, b) => a.item_order - b.item_order)
						.map((r) => {
							return {
								cvMeasure: r.rel_object_id
							}
						})

					CodeListStore.set("clinicalValuesChips", cvItems)
					if (isSafe(CodeListStore.clinicalValuesChipsForm)) {
						CodeListStore.clinicalValuesChipsForm.bindData(cvItems)
						CodeListStore.clinicalValuesChips = CodeListStore.clinicalValuesChipsForm.form.values()
					}

					CodeListStore.set("providedServiceLoaded", true)
					CodeListStore.set("providedServiceLoading", false)
				})
		}
	}

	handleNewServiceSave = (response) => {
		if (response && response._id) {
			CodeListStore.pushCLSerivce(response)
			this.form.$("service").set("value", response._id)
		}
	}

	render() {
		const selectPSID = CodeListStore.selectedProvidedServiceID
		let services = CodeListStore.clservices
		let genders = GlobalStore.CL["genders"]
		let summaryType = GlobalStore.CL["summaryType"]

		let isNew = true

		if (selectPSID && !CodeListStore.providedServiceLoaded && CodeListStore.templates) {
			CodeListStore.set("providedServiceLoading", true)
			this.initLoad()
			isNew = false
		}

		if (services && !CodeListStore.providedServiceLoading) {
			return (
				<React.Fragment>
					<Paper elevation={0} className="xs-provided-services-form">
						{UIStore.isFormSaving && <XsLoading overlay={true} />}
						<Grid container direction="row">
							<Grid item xs={12}>
								<Grid container direction="row" spacing={8}>
									<Grid item xs={2}>
										<XsInput field={this.form.$("item_order")} />
									</Grid>
									<Grid item xs={5}>
										<SearchSelect field={this.form.$("service")} items={services} disabled={!isNew} />
									</Grid>
									<Grid item xs={1}>
										<AddCodelistForm
											apicall={"/service/cl/services"}
											type={"Service.Data.Service"}
											method={"PUT"}
											afterSave={this.handleNewServiceSave}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsInput field={this.form.$("duration")} type="number" min={1} />
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox field={this.form.$("default")} />
									</Grid>
								</Grid>
								<Grid container direction="row" spacing={8}>
									<Grid item xs={1}>
										<XsDateTimePicker field={this.form.$("valid_from")} />
									</Grid>
									<Grid item xs={1}>
										<XsDateTimePicker field={this.form.$("valid_to")} />
									</Grid>
									<Grid item xs={1}>
										<XsInput type="number" field={this.form.$("age_from")} />
									</Grid>
									<Grid item xs={1}>
										<XsInput type="number" field={this.form.$("age_to")} />
									</Grid>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("gender")} items={genders} />
									</Grid>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("insurer")} items={RegistersCacheStore.insurersRegister} />
									</Grid>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("insurerType")} items={GlobalStore.CL["insuranceTypes"]} />
									</Grid>
									<Grid item xs={1}>
										<XsColorPicker field={this.form.$("back_color")} />
									</Grid>
									<Grid item xs={1}>
										<XsColorPicker field={this.form.$("fore_color")} />
									</Grid>
								</Grid>

								{selectPSID && (
									<React.Fragment>
										<Grid container direction="row">
											<Grid item xs={12}>
												<XsChipForm
													ref={(chipForm) => {
														CodeListStore.deliveryChipsForm = chipForm
													}}
													label={this.props.intl.formatMessage({id: "Patient.form.patientrecord.deliveries"})}
													configuration={{
														header: {
															chipsRenderer: () => null,
															addButtonRenderer: (clickHandler) => (
																<XsIconButton
																	className="xs-default xs-outline"
																	icon={<AddIcon />}
																	onClick={clickHandler}
																	id={"deliveryItemRow"}
																/>
															)
														},
														columns: [
															{
																name: "delivery",
																label: this.props.intl.formatMessage({id: "Common.label.delivery"}),
																width: 3,
																validationRule: "required",
																renderer: (field) => (
																	<XsAutocomplete
																		field={field}
																		minLengthForSearch="1"
																		api={api.loadDeliveries}
																		searchFilter={"code_ext"}
																		loadApi={api.loadSpecificDelivery}
																		inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
																		saveValue={(obj) => obj.code}
																		postAction={() => {
																			CodeListStore.deliveryChips = CodeListStore.deliveryChipsForm.form.values()
																		}}
																		modalConfig={(textValue, clickHandler) => {
																			return {
																				columnDefs: {
																					code_ext: {
																						title: (
																							<FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />
																						),
																						design: {
																							width: "10%"
																						},
																						type: "string",
																						dbName: "code_ext",
																						filter: {
																							gridWidth: 3,
																							defaultValue: "",
																							renderElement: "input"
																						}
																					},
																					name_ext: {
																						title: (
																							<FormattedMessage id="Patient.form.patientrecord.deliveryTable.name_ext" />
																						),
																						type: "string",
																						dbName: "search_column",
																						design: {
																							width: "90%"
																						},
																						filter: {
																							gridWidth: 3,
																							defaultValue: textValue,
																							renderElement: "input"
																						}
																					}
																				},
																				options: {
																					showCursor: true,
																					onRowClick: (dataRow) => {
																						clickHandler(dataRow)
																					}
																				}
																			}
																		}}
																	/>
																)
															},
															{
																name: "deliveryQuantity",
																label: this.props.intl.formatMessage({id: "Patient.form.patientrecord.count"}),
																width: 1,
																validationRule: "required|numeric|min:1",
																renderer: (field) => {
																	return (
																		<XsInput
																			field={field}
																			type="number"
																			min={1}
																			onBlur={() =>
																				(CodeListStore.deliveryChips = CodeListStore.deliveryChipsForm.form.values())
																			}
																		/>
																	)
																},
																defaultValue: 1
															},
															{
																name: "deliveryDiagnosis",
																label: this.props.intl.formatMessage({id: "Patient.form.patientrecord.diagnosis"}),
																width: 3,
																renderer: (field) => (
																	<XsAutocomplete
																		// XsAutocompleteLocal
																		field={field}
																		minLengthForSearch="3"
																		api={api.loadDiagnosisCl}
																		// data={RegisterCacheStore.diagnosisRegister}
																		inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
																		saveValue={(obj) => obj.code}
																		postAction={() => {
																			CodeListStore.deliveryChips = CodeListStore.deliveryChipsForm.form.values()
																		}}
																		onClear={() => {
																			if (
																				isSafe(CodeListStore.deliveryChipsForm) &&
																				isSafe(CodeListStore.deliveryChipsForm.form)
																			) {
																				CodeListStore.deliveryChips = CodeListStore.deliveryChipsForm.form.values()
																			}
																		}}
																	/>
																)
															},
															{
																name: "deliveryItem",
																label: this.props.intl.formatMessage({id: "Delivery.List.AddItem"}),
																width: 3,
																renderer: (field) => (
																	<XsAutocomplete
																		field={field}
																		minLengthForSearch="1"
																		api={api.loadDeliveryItems}
																		searchFilter={"code_ext"}
																		loadApi={api.loadSpecificDeliveryItem}
																		inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
																		saveValue={(obj) => obj.code}
																		postAction={() => {
																			CodeListStore.deliveryChips = CodeListStore.deliveryChipsForm.form.values()
																		}}
																		onClear={() => {
																			if (
																				isSafe(CodeListStore.deliveryChipsForm) &&
																				isSafe(CodeListStore.deliveryChipsForm.form)
																			) {
																				CodeListStore.deliveryChips = CodeListStore.deliveryChipsForm.form.values()
																			}
																		}}
																		modalConfig={(textValue, clickHandler) => {
																			return {
																				columnDefs: {
																					code_ext: {
																						title: (
																							<FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />
																						),
																						design: {
																							width: "10%"
																						},
																						type: "string",
																						dbName: "code_ext",
																						filter: {
																							gridWidth: 3,
																							defaultValue: "",
																							renderElement: "input"
																						}
																					},
																					name_ext: {
																						title: <FormattedMessage id="Common.label.name" />,
																						type: "string",
																						dbName: "search_column",
																						design: {
																							width: "90%"
																						},
																						filter: {
																							gridWidth: 3,
																							defaultValue: textValue,
																							renderElement: "input"
																						}
																					}
																				},
																				options: {
																					showCursor: true,
																					onRowClick: (dataRow) => {
																						clickHandler(dataRow)
																					}
																				}
																			}
																		}}
																	/>
																)
															}
														],
														options: {
															formOnly: true,
															isFormMode: true,
															isModalMode: false,
															hideButtons: true,
															onRemoveRow: () => {
																CodeListStore.deliveryChips = CodeListStore.deliveryChipsForm.form.values()
															},
															onFormSave: (values) => {
																CodeListStore.deliveryChips = values
															}
														},
														data: CodeListStore.deliveryChips
													}}
												/>
											</Grid>
										</Grid>
										<Grid container direction="row" className="mt-4 mb-4">
											<Grid item xs={12}>
												<XsChipForm
													ref={(chipForm) => {
														CodeListStore.templateChipsForm = chipForm
													}}
													label={this.props.intl.formatMessage({id: "Patient.form.template.templates"})}
													configuration={{
														header: {
															chipsRenderer: () => null,
															addButtonRenderer: (clickHandler) => (
																<XsIconButton
																	className="xs-default xs-outline"
																	icon={<AddIcon />}
																	onClick={clickHandler}
																	id={"templateItem"}
																/>
															)
														},
														columns: [
															{
																name: "summaryType",
																label: this.props.intl.formatMessage({id: "Common.label.type"}),
																width: 4,
																validationRule: "required",
																renderer: (field, index, fieldKey) => (
																	<SearchSelect
																		field={field}
																		items={summaryType}
																		onChange={(value) => {
																			this.checkUniqueSummaryType(field, value, fieldKey)
																			CodeListStore.templateChips = CodeListStore.templateChipsForm.form.values()
																		}}
																		tabindex="-1"
																	/>
																)
															},
															{
																name: "templateType",
																label: this.props.intl.formatMessage({id: "Patient.form.template.template"}),
																width: 4,
																validationRule: "required",
																renderer: (field, index, fieldKey) => {
																	let formValues = []
																	if (CodeListStore.templateChipsForm) {
																		formValues = CodeListStore.templateChipsForm.getData()
																	}

																	return (
																		<SearchSelect
																			field={field}
																			items={CodeListStore.getFilteredTemplates(
																				formValues[fieldKey] ? formValues[fieldKey].summaryType : null
																			)}
																			onChange={() => {
																				CodeListStore.templateChips = CodeListStore.templateChipsForm.form.values()
																			}}
																		/>
																	)
																}
															}
														],
														options: {
															formOnly: true,
															isFormMode: true,
															isModalMode: false,
															hideButtons: true,
															onRemoveRow: () => {
																CodeListStore.templateChips = CodeListStore.templateChipsForm.form.values()
															},
															onFormSave: (values) => {
																CodeListStore.templateChips = values
															}
														},
														data: CodeListStore.templateChips
													}}
												/>
											</Grid>
										</Grid>
										<Grid container direction="row" className="mt-4 mb-4">
											<Grid item xs={12}>
												<XsChipForm
													ref={(chipForm) => (CodeListStore.clinicalValuesChipsForm = chipForm)}
													label={this.props.intl.formatMessage({id: "Patient.form.patientrecord.clinicalvalues"})}
													configuration={{
														header: {
															chipsRenderer: () => null,
															addButtonRenderer: (clickHandler) => (
																<XsIconButton
																	className="xs-default xs-outline"
																	icon={<AddIcon />}
																	onClick={clickHandler}
																	id={"clinicalValueItem"}
																/>
															)
														},
														columns: [
															{
																name: "cvMeasure",
																label: <FormattedMessage id="Patient.form.patientrecord.measuretype" />,
																width: 3,
																validationRule: "required|string",
																renderer: (field) => (
																	<SearchSelect field={field} items={GlobalStore.CL["recordMeasures"]} required />
																)
															},
															{
																name: "cvMeasureName",
																renderer: () => {},
																width: 0
															}
														],
														options: {
															formOnly: true,
															isFormMode: true,
															isModalMode: false,
															hideButtons: true,
															onFormSave: (values) => {
																CodeListStore.clinicalValuesChips = values
															}
														},
														data: CodeListStore.clinicalValuesChips
													}}
												/>
											</Grid>
										</Grid>
									</React.Fragment>
								)}
							</Grid>
						</Grid>
						<XsConfirmationDialog
							name="xsDeleteProvidedService"
							title={<FormattedMessage id="Common.label.warning" />}
							text={<FormattedMessage id="Admin.codelist.providedservices.deleteMessage" />}
							onConfirmation={() => CodeListStore.deleteProvidedService(selectPSID)}
						/>
					</Paper>
					{selectPSID && (
						<Grid container direction="column" className=" mb-4 mt-4">
							<Grid item className="xs-fullWidth">
								<XsTabsIcon
									value="pricelist"
									horizontal={true}
									mainClass="xs-pricelist-tab"
									background="white"
									tabs={[
										{
											label: <FormattedMessage id="Admin.codelist.providedservices.pricelist" />,
											icon: <i className="fas fa-euro-sign fa-lg" />,
											value: "pricelist",
											content: (
												<Grid item xs={12}>
													<PriceListTableEx providedServiceID={CodeListStore.selectedProvidedServiceID} />
												</Grid>
											)
										}
									]}
								/>
							</Grid>
						</Grid>
					)}
					<Grid container direction="row">
						<Grid item xs={12}>
							<XsButton
								className="xs-success mr-3"
								text={<FormattedMessage id="Common.label.save" />}
								type="submit"
								onClick={(e) => {
									this.form.onSubmit(e)
								}}
								icon={<SaveIcon />}
							/>
							<XsButton
								className="xs-danger xs-outline mr-3"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									CodeListStore.set("showProvidedServicesForm", false)
									CodeListStore.resetProvidedServicesForm()
								}}
								icon={<CancelIcon />}
							/>
							{selectPSID && (
								<XsButton
									className="xs-danger xs-outline"
									text={<FormattedMessage id="Common.label.delete" />}
									onClick={() => {
										GlobalStore.openConfirmationDialog("xsDeleteProvidedService", {psID: selectPSID})
									}}
									icon={<TrashIcon />}
								/>
							)}
						</Grid>
					</Grid>
				</React.Fragment>
			)
		} else {
			return <XsLoading overlay={true} />
		}
	}
}

export default injectIntl(ProvidedServicesForm)
