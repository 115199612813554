import React, {Component} from "react"

import {
	Table,
	TableBody,
	TableCell,
	TablePagination,
	TableRow,
	Tooltip,
	Checkbox,
	TableHead,
	TableSortLabel
} from "@material-ui/core"

import "./xsTableServer.less"
import {ApiCall} from "../../helpers/api"
import {FormattedMessage, injectIntl} from "react-intl"
import XsLoading from "../xsLoading/xsLoading"
import TablePaginationActions from "./xsTablePaginationActions"
import {getFullDataFromOptimized} from "../../helpers/actions"

import TableStore from "../../../modules/ambulance/stores/TableStore"

@injectIntl
class XsTableServer extends Component {
	constructor(props) {
		super(props)

		const {options} = props.config

		this._isMounted = false
		this.componentDidFetch = false

		let page = 0

		if (isNotEmpty(options.name) && TableStore.tables.hasOwnProperty(options.name)) {
			page = TableStore.tables[options.name].page
		}

		this.state = {
			order: false, //sortDirection: [asc, desc, false]
			orderBy: null,
			page: page,
			rowsPerPage: isSafe(options.paging) && isSafe(options.paging.rowsPerPage) ? options.paging.rowsPerPage : 10,
			selectedRow: undefined,
			data: [],
			totalRows: 0,
			isFetching: false,
			checkboxValues: []
		}
		//EMA-9033 hack kvoli @injectIntl pretoze normalny ref vrati ref na injectIntl a nie na XsTableServer
		if (isSafe(props.setRef) && typeof props.setRef == "function") {
			props.setRef(this)
		}
	}

	fetchData = (uri, postObj, stateObj) => {
		delayedCallback(500, () => {
			this.setState({isFetching: true})
			new ApiCall(uri, "POST", postObj)
				.call()
				.then((response) => {
					if (this.componentDidFetch === false) {
						this.componentDidFetch = true
					}

					if (this._isMounted && isSafe(response) && isSafe(stateObj)) {
						if (isSafe(this.props.postAction) && typeof this.props.postAction === "function") {
							this.props.postAction(response)
						}

						this.setState({
							...stateObj,
							data: response.rows,
							totalRows: response.row_count_full,
							isFetching: false
						})

						if (isSafe(this.props.getData) && typeof this.props.getData === "function") {
							this.props.getData(response.rows)
						}
					} else {
						this.setState({
							isFetching: false
						})
					}
				})
				.catch(() => {
					if (this.componentDidFetch === false) {
						this.componentDidFetch = true
					}
					this.setState({
						isFetching: false
					})
				})
		})
	}

	refreshData = () => {
		const orderBy = this.state.orderBy
		let order = this.state.order

		switch (order) {
			case "asc":
				order = false
				break
			case false:
				order = "desc"
				break
			default:
				order = "asc"
		}

		const stateObj = {
			order,
			orderBy,
			filters: this.props.filterValue,
			orders: this.props.orderValue
		}

		this.fetchData(this.props.uri, this.getPostObj(stateObj), stateObj)
	}

	getLoadedData = () => {
		return this.state.data.map((row) => this.props.config.options.mapper(row))
	}

	getPostObj = (stateObj) => {
		const obj_page = isSafe(stateObj) && isSafe(stateObj.page) ? stateObj.page : this.state.page
		const obj_rowsPerPage =
			isSafe(stateObj) && isSafe(stateObj.rowsPerPage) ? stateObj.rowsPerPage : this.state.rowsPerPage

		const obj_order = isSafe(stateObj) && isSafe(stateObj.order) ? stateObj.order : this.state.order
		const obj_orderBy = isSafe(stateObj) && isSafe(stateObj.orderBy) ? stateObj.orderBy : this.state.orderBy

		let retObj = {
			row_offset: obj_page * obj_rowsPerPage + 1, // skip => v skutocnosti je to poradove cislo, od ktoreho zaznamu vrati rows
			row_count_show: obj_rowsPerPage // take
		}

		if (isSafe(this.props.config.options.row_count_full)) {
			retObj["row_count_full"] = this.props.config.options.row_count_full
		}

		if (isSafe(obj_orderBy) && typeof obj_order === "string") {
			retObj["orders"] = [
				{
					asc: obj_order === "asc",
					associated_column: this.props.config.columnDefs[obj_orderBy].dbName
				}
			]
		}

		// filter:
		if (isSafe(stateObj) && isSafe(stateObj.filters)) {
			retObj["filters"] = stateObj.filters.map((filter) => {
				return {
					associated_column: filter.associated_column,
					values: filter.values
				}
			})
		}

		// orders:
		if (isSafe(stateObj) && isSafe(stateObj.orders)) {
			retObj["orders"] = stateObj.orders.map((order) => {
				return {
					associated_column: order.associated_column,
					asc: order.asc
				}
			})
		}

		return retObj
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	UNSAFE_componentWillMount() {
		let stateObj = undefined
		if (isSafe(this.props.filterValue)) {
			stateObj = {["filters"]: this.props.filterValue}
		}

		if (isSafe(this.props.orderValue)) {
			if (isSafe(stateObj)) {
				stateObj["orders"] = this.props.orderValue
			} else {
				stateObj = {["orders"]: this.props.orderValue}
			}
		}

		this.fetchData(this.props.uri, this.getPostObj(stateObj), {})
		// TODO: MAROS - fixni asynchronous task lebo prebieha force-update
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const {options} = this.props.config
		let page = 0

		if (isNotEmpty(options.name) && TableStore.tables.hasOwnProperty(options.name)) {
			page = TableStore.tables[options.name].page
		}
		const stateObj = {page: page}
		stateObj["filters"] = newProps.filterValue
		stateObj["orders"] = newProps.orderValue

		this.fetchData(this.props.uri, this.getPostObj(stateObj), stateObj)
	}

	createSortHandler = (property) => (event) => {
		this.handleRequestSort(event, property)
	}

	handleRequestSort = (event, property) => {
		const {options} = this.props.config
		const orderBy = property
		let order = this.state.order

		switch (order) {
			case "asc":
				order = false
				break
			case false:
				order = "desc"
				break
			default:
				order = "asc"
		}

		const stateObj = {
			order,
			orderBy,
			filters: this.props.filterValue,
			orders: this.props.orderValue
		}

		if (
			isNotEmpty(options.name) &&
			TableStore.tables.hasOwnProperty(options.name) &&
			TableStore.tables[options.name].hasOwnProperty("index")
		) {
			delete TableStore.tables[options.name]["index"]
		}

		this.fetchData(this.props.uri, this.getPostObj(stateObj), stateObj)
	}

	handleChangePage = (event, page) => {
		const {options} = this.props.config

		const stateObj = {
			page,
			filters: this.props.filterValue,
			orders: this.props.orderValue
		}

		if (
			isNotEmpty(options.name) &&
			TableStore.tables.hasOwnProperty(options.name) &&
			TableStore.tables[options.name].hasOwnProperty("index")
		) {
			delete TableStore.tables[options.name]["index"]
		}

		this.fetchData(this.props.uri, this.getPostObj(stateObj), stateObj)
	}

	handleChangeRowsPerPage = (event) => {
		const {options} = this.props.config

		const stateObj = {
			rowsPerPage: event.target.value,
			filters: this.props.filterValue,
			orders: this.props.orderValue
		}

		if (
			isNotEmpty(options.name) &&
			TableStore.tables.hasOwnProperty(options.name) &&
			TableStore.tables[options.name].hasOwnProperty("index")
		) {
			delete TableStore.tables[options.name]["index"]
		}

		this.fetchData(this.props.uri, this.getPostObj(stateObj), stateObj)
	}

	handleChange = (name) => (event) => {
		if (this._isMounted) {
			this.setState({
				[name]: event.target.value
			})
		}
	}

	selectRow = (idx) => {
		if (this._isMounted) {
			this.setState({
				selectedRow: this.state.selectedRow === idx ? undefined : idx
			})
		}
	}

	tableHeaderRow
	tableHeaderJSX
	tableHeader() {
		const {columnDefs, options} = this.props.config
		const {order, orderBy} = this.state

		this.tableHeaderRow = []
		this.tableHeaderJSX = null

		if (!options.hideHeader && !options.renderHeaderAsFirstRow) {
			let columnName = isSafe(orderBy) ? orderBy : null
			let sortDirection = isSafe(order) ? order : null

			if (
				!isSafe(columnName) &&
				isSafe(options) &&
				isSafe(options.defaultSort) &&
				isSafe(options.defaultSort.columnName)
			) {
				columnName = options.defaultSort.columnName
				sortDirection = options.defaultSort.sortDirection === "desc" ? "desc" : "asc"
			}

			{
				options.checkboxes &&
					this.tableHeaderRow.push(
						<TableCell key="checkbox_header" style={{paddingRight: "0px", width: "48px", minWidth: "48px"}}></TableCell>
					)
			}

			// const defaultWidth = 100 / +Object.keys(columnDefs).length
			Object.keys(columnDefs).forEach((column, idx) => {
				const columnDef = columnDefs[column]

				const tooltip =
					isSafe(columnDef.design) && isSafe(columnDef.design.header) && isSafe(columnDef.design.header.tooltip)
						? columnDef.design.header.tooltip()
						: null
				const sortable = isSafe(columnDef.sortable) && columnDef.sortable
				const className =
					isSafe(columnDef.design) && isSafe(columnDef.design.header) && isSafe(columnDef.design.header.className)
						? columnDef.design.header.className
						: null
				const width = isSafe(columnDef.design) && isSafe(columnDef.design.width) ? columnDef.design.width : null //`${defaultWidth}%`

				if (isSafe(tooltip) && sortable) {
					this.tableHeaderRow.push(
						<Tooltip key={idx} title={tooltip} enterDelay={750} placement="bottom-start">
							<TableCell
								sortDirection={columnName === column ? sortDirection : false}
								key={column}
								onClick={this.createSortHandler(column)}
								className={className ? `${className} sortable` : "sortable"}
								width={width}
							>
								{(sortDirection === "asc" || sortDirection === "desc") && (
									<TableSortLabel active={columnName === column} direction={sortDirection}>
										{columnDef.title}
									</TableSortLabel>
								)}
								{!sortDirection && columnDef.title}
							</TableCell>
						</Tooltip>
					)
				} else if (isSafe(tooltip)) {
					this.tableHeaderRow.push(
						<Tooltip key={idx} title={tooltip} enterDelay={750} placement="bottom-start">
							<TableCell
								sortDirection={columnName === column ? sortDirection : false}
								key={column}
								className={className}
								width={width}
							>
								{columnDef.title}
							</TableCell>
						</Tooltip>
					)
				} else if (sortable) {
					this.tableHeaderRow.push(
						<TableCell
							sortDirection={columnName === column ? sortDirection : false}
							key={column}
							onClick={this.createSortHandler(column)}
							className={className ? `${className} sortable` : "sortable"}
							width={width}
						>
							{(sortDirection === "asc" || sortDirection === "desc") && (
								<TableSortLabel active={columnName === column} direction={sortDirection}>
									{columnDef.title}
								</TableSortLabel>
							)}
							{!sortDirection && columnDef.title}
						</TableCell>
					)
				} else {
					this.tableHeaderRow.push(
						<TableCell
							sortDirection={columnName === column ? sortDirection : false}
							key={column}
							className={className}
							width={width}
						>
							{columnDef.title}
						</TableCell>
					)
				}
			})

			this.tableHeaderJSX = (
				<TableHead>
					<TableRow>{this.tableHeaderRow}</TableRow>
				</TableHead>
			)
		}
	}
	tableBodyRows
	tableBody() {
		const {columnDefs, options} = this.props.config
		const {order, orderBy, page, rowsPerPage} = this.state
		let dataSource = isSafe(this.state.data) ? this.state.data : []

		this.tableBodyRows = []

		/// render headera do prveho row-u kvoli zarovnaniu stlpcov headera a body
		if (options.renderHeaderAsFirstRow) {
			let columnName = isSafe(orderBy) ? orderBy : null
			let sortDirection = isSafe(order) ? order : null

			if (
				!isSafe(columnName) &&
				isSafe(options) &&
				isSafe(options.defaultSort) &&
				isSafe(options.defaultSort.columnName)
			) {
				columnName = options.defaultSort.columnName
				sortDirection = options.defaultSort.sortDirection === "desc" ? "desc" : "asc"
			}

			this.tableBodyRows.push(
				<TableRow key={-1} className="headerAsFirstRow">
					{Object.keys(columnDefs).map((column, idx) => {
						const columnDef = columnDefs[column]

						const tooltip =
							isSafe(columnDef.design) && isSafe(columnDef.design.header) && isSafe(columnDef.design.header.tooltip)
								? columnDef.design.header.tooltip()
								: null
						const sortable = isSafe(columnDef.sortable) && columnDef.sortable
						const className =
							isSafe(columnDef.design) && isSafe(columnDef.design.header) && isSafe(columnDef.design.header.className)
								? columnDef.design.header.className
								: null
						const width = isSafe(columnDef.design) && isSafe(columnDef.design.width) ? columnDef.design.width : null //`${defaultWidth}%`

						if (isSafe(tooltip) && sortable) {
							return (
								<Tooltip key={idx} title={tooltip} enterDelay={750} placement="bottom-start">
									<TableCell
										sortDirection={columnName === column ? sortDirection : false}
										onClick={this.createSortHandler(column)}
										className={className ? `${className} sortable` : "sortable"}
										width={width}
									>
										{(sortDirection === "asc" || sortDirection === "desc") && (
											<TableSortLabel active={columnName === column} direction={sortDirection}>
												{columnDef.title}
											</TableSortLabel>
										)}
										{!sortDirection && columnDef.title}
									</TableCell>
								</Tooltip>
							)
						} else if (isSafe(tooltip)) {
							return (
								<Tooltip key={idx} title={tooltip} enterDelay={750} placement="bottom-start">
									<TableCell
										sortDirection={columnName === column ? sortDirection : false}
										key={column}
										className={className}
										width={width}
									>
										{columnDef.title}
									</TableCell>
								</Tooltip>
							)
						} else if (sortable) {
							return (
								<TableCell
									sortDirection={columnName === column ? sortDirection : false}
									key={column}
									onClick={this.createSortHandler(column)}
									className={className ? `${className} sortable` : "sortable"}
									width={width}
								>
									{(sortDirection === "asc" || sortDirection === "desc") && (
										<TableSortLabel active={columnName === column} direction={sortDirection}>
											{columnDef.title}
										</TableSortLabel>
									)}
									{!sortDirection && columnDef.title}
								</TableCell>
							)
						} else
							return (
								<TableCell
									sortDirection={columnName === column ? sortDirection : false}
									key={idx}
									className={className}
									width={width}
								>
									{columnDef.title}
								</TableCell>
							)
					})}
				</TableRow>
			)
		}

		if (dataSource.length > 0) {
			if (this.props.optimizedData) {
				dataSource = getFullDataFromOptimized(dataSource)
			}

			dataSource.forEach((row, idx) => {
				if (isSafe(options) && isSafe(options.mapper)) {
					row = options.mapper(row)
				}

				// const defaultWidth = 100 / +Object.keys(columnDefs).length

				this.tableBodyRows.push(
					<TableRow
						key={idx}
						hover={isSafe(options) && options.showCursor ? true : false}
						className={
							isSafe(options) &&
							isSafe(options.selectRow) &&
							options.selectRow &&
							isSafe(this.state.selectedRow) &&
							this.state.selectedRow === idx
								? "xs-selected-row"
								: ""
						}
					>
						{options.checkboxes && (
							<TableCell
								key={`${row[options.checkboxColumn]}`}
								style={{paddingRight: "0px", width: "48px", minWidth: "48px"}}
							>
								<Checkbox
									checked={this.state.checkboxValues.includes(row[options.checkboxColumn])}
									onChange={(e) => {
										let value = e.target.checked
										let checkboxValues = this.state.checkboxValues

										if (value) {
											checkboxValues.push(row[options.checkboxColumn])
											this.setState(
												{checkboxValues: checkboxValues /*, checkAll: this.data.length == checkboxValues.length*/},
												() => options.onClickCheckbox(this.state.checkboxValues)
											)
										} else {
											this.setState(
												{
													checkboxValues: this.state.checkboxValues.filter((val) => val != row[options.checkboxColumn])
													/*checkAll: false*/
												},
												() => options.onClickCheckbox(this.state.checkboxValues)
											)
										}
									}}
								/>
							</TableCell>
						)}
						{Object.keys(columnDefs).map((column, i) => {
							const columnDef = columnDefs[column]

							const CellTemplate =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.renderer)
									? columnDef.design.body.renderer
									: null
							let value =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.formatter)
									? columnDef.design.body.formatter(row[column])
									: row[column]
							const tooltip =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.tooltip)
									? columnDef.design.body.tooltip(row)
									: null
							let className =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.className)
									? typeof columnDef.design.body.className === "function"
										? columnDef.design.body.className(row)
										: columnDef.design.body.className
									: null
							const width = isSafe(columnDef.design) && isSafe(columnDef.design.width) ? columnDef.design.width : null //`${defaultWidth}%`

							if (
								isNotEmpty(options.name) &&
								TableStore.tables.hasOwnProperty(options.name) &&
								isNotEmpty(TableStore.tables[options.name].index) &&
								idx == TableStore.tables[options.name].index
							) {
								className += " lastSelectedRow"
							}

							const isRowClick =
								columnDef.type !== "action" &&
								isSafe(options) &&
								isSafe(options.onRowClick) &&
								typeof options.onRowClick === "function"
									? true
									: false
							const cursorStyle = isRowClick ? {cursor: "pointer"} : {}

							if (isSafe(tooltip)) {
								return (
									<Tooltip key={`${idx}_${i}`} title={tooltip} enterDelay={750} placement="bottom-start">
										<TableCell
											className={className}
											width={width}
											style={cursorStyle}
											onClick={() => (
												isRowClick
													? () => (
															options.onRowClick(row),
															isNotEmpty(options.name)
																? (TableStore.tables[options.name] = {
																		index: idx,
																		rowsPerPage: rowsPerPage,
																		page: page
																  })
																: null
													  )
													: null,
												columnDef.type !== "action" && isSafe(options) && isSafe(options.selectRow) && options.selectRow
													? this.selectRow(idx)
													: null
											)}
										>
											{isSafe(CellTemplate) ? <CellTemplate value={value} /> : value}
										</TableCell>
									</Tooltip>
								)
							} else {
								return (
									<TableCell
										className={className}
										width={width}
										style={cursorStyle}
										key={`${idx}_${i}`}
										onClick={() => (
											isRowClick
												? (options.onRowClick(row),
												  isNotEmpty(options.name)
														? (TableStore.tables[options.name] = {
																index: idx,
																rowsPerPage: rowsPerPage,
																page: page
														  })
														: null)
												: null,
											columnDef.type !== "action" && isSafe(options) && isSafe(options.selectRow) && options.selectRow
												? this.selectRow(idx)
												: null
										)}
									>
										{isSafe(CellTemplate) ? <CellTemplate value={value} /> : value}
									</TableCell>
								)
							}
						})}
					</TableRow>
				)
			})
		} else if (this.componentDidFetch == false) {
			this.tableBodyRows.push(
				<TableRow key={0}>
					<TableCell className="nx-grid-loading">
						<XsLoading />
					</TableCell>
				</TableRow>
			)
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data">
						<i className="far fa-lg fa-empty-set mr-3"></i>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	tableFooterJSX
	tableFooter() {
		const {options} = this.props.config
		const {rowsPerPage, page} = this.state
		this.tableFooterJSX = null
		// if (rowsPerPage <= this.state.totalRows) {
		this.tableFooterJSX = (
			<div className="xs-grid-footer">
				<TablePagination
					component="div"
					className="xs-table-pagination"
					count={this.state.totalRows}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={
						isSafe(options) && isSafe(options.paging) && isSafe(options.paging.rowsPerPageOptions)
							? options.paging.rowsPerPageOptions
							: [5, 10, 25, 50]
					}
					page={page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
					labelRowsPerPage={<FormattedMessage id="Table.Pagination.RowPerPage" />}
					labelDisplayedRows={(pager) =>
						`${pager.from}-${pager.to} ${this.props.intl.formatMessage({id: "Table.Pagination.Of"})} ${pager.count}`
					}
				/>
			</div>
		)
		if (isSafe(this.props.onRenderFooterInModal) && typeof this.props.onRenderFooterInModal == "function") {
			this.props.onRenderFooterInModal(this.tableFooterJSX)
		}
		// }
	}

	render() {
		this.tableHeader()

		this.tableBody()

		this.tableFooter()

		return (
			<div className="xs-server-grid ">
				<div className="xs-grid">
					{this.props.config.options.pagerOnTop === true && this.tableFooterJSX}
					{isSafe(this.tableHeaderJSX) && <Table className="xs-grid-header">{this.tableHeaderJSX}</Table>}
					<div className="xs-grid-body">
						<Table>
							<TableBody>{this.tableBodyRows}</TableBody>
						</Table>
						{this.componentDidFetch == true && this.state.isFetching == true && (
							<div className="xs-grid-body-loading">
								<XsLoading />
							</div>
						)}
					</div>
					{this.props.config.options.pagerOnBottom !== false &&
						isNotSafe(this.props.onRenderFooterInModal) &&
						this.tableFooterJSX}
				</div>
			</div>
		)
	}
}

export default XsTableServer
