import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import moment from "moment"
import classnames from "classnames"
import renderHTML from "react-render-html"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Checkbox} from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"

import NotificationCenterStore from "./NotificationCenterStore"

@observer
export default class NotificationDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const notifications = NotificationCenterStore.onlyNew
			? NotificationCenterStore.receiveNotify.filter((x) => !x.read)
			: NotificationCenterStore.receiveNotify

		return (
			<Dialog
				id="xsNotificationDialog"
				open={NotificationCenterStore.isOpenNotification}
				onClose={() => NotificationCenterStore.closeNotification()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-bell fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.warning" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="pa-4">
					<Grid container direction="column">
						{isSafe(NotificationCenterStore.selectedNotify) &&
						isNotEmpty(NotificationCenterStore.selectedNotify.message) ? (
							<React.Fragment>
								<Grid
									item
									container
									className="xs-bold pointer"
									onClick={() => (NotificationCenterStore.selectedNotify = null)}
								>
									<Grid item className="pr-2">
										<i className="fas fa-arrow-left"></i>
									</Grid>
									<Grid item>{moment(NotificationCenterStore.selectedNotify.time).format("DD.MM.YYYY HH:mm:ss")}</Grid>
								</Grid>
								<Grid item className="pt-6">
									{isNotEmpty(NotificationCenterStore.selectedNotify.message)
										? renderHTML(NotificationCenterStore.selectedNotify.message)
										: ""}
								</Grid>
							</React.Fragment>
						) : (
							<React.Fragment>
								{isSafe(notifications) && notifications.length > 0 ? (
									<React.Fragment>
										<Grid container>
											<Grid item xs={1} style={{marginLeft: "16px", marginBottom: "10px"}}>
												<Checkbox
													checked={NotificationCenterStore.selectedAll}
													style={{height: "18px"}}
													disabled={notifications.filter((x) => !x.read).length == 0}
													onChange={(e) => {
														if (e.target.checked) {
															NotificationCenterStore.selectedAll = true
															NotificationCenterStore.selectedIds = notifications
																.filter((x) => !x.read)
																.map((x) => x.id)
														} else {
															NotificationCenterStore.selectedAll = false
															NotificationCenterStore.selectedIds = []
														}
													}}
												/>
											</Grid>
											<Grid item xs={10}>
												<FormattedMessage id="Common.label.markUnread" />
											</Grid>
										</Grid>
										{notifications
											.sort(
												(a, b) => moment(b.time).format("YYYYMMDDHHmmss") - moment(a.time).format("YYYYMMDDDHHmmss")
											)
											.map((not, idx) => (
												<Grid
													item
													container
													key={idx}
													className={classnames("pa-3 borderRadius-1 borderGreyLight pointer mt-1", {
														bgWhite: !not.read,
														bgSnowWhite: not.read
													})}
												>
													<Grid item container xs={1}>
														<Checkbox
															checked={NotificationCenterStore.selectedIds.includes(not.id)}
															style={{height: "18px"}}
															disabled={not.read}
															onChange={(e) => {
																if (e.target.checked) {
																	NotificationCenterStore.selectedIds.push(not.id)
																	if (
																		NotificationCenterStore.selectedIds.length ==
																		notifications.filter((x) => !x.read).length
																	) {
																		NotificationCenterStore.selectedAll = true
																	}
																} else {
																	NotificationCenterStore.selectedIds = NotificationCenterStore.selectedIds.filter(
																		(x) => x != not.id
																	)
																	if (
																		NotificationCenterStore.selectedIds.length !=
																		notifications.filter((x) => !x.read).length
																	) {
																		NotificationCenterStore.selectedAll = false
																	}
																}
															}}
														/>
													</Grid>
													<Grid
														item
														container
														xs={11}
														onClick={() => {
															NotificationCenterStore.selectedNotify = not
															if (!not.read) {
																NotificationCenterStore.updateIndexDB(not)
															}
														}}
													>
														<Grid item xs={3}>
															{moment(not.time).format("DD.MM.YYYY HH:mm.ss")}
														</Grid>
														<Grid item xs={8} className="cutText">
															{isNotEmpty(not.message) ? renderHTML(not.message) : ""}
														</Grid>
														<Grid item xs={1} className="greenDark textCenter">
															{!not.read ? "nová" : ""}
														</Grid>
													</Grid>
												</Grid>
											))}
									</React.Fragment>
								) : (
									<Grid item container justify="center" className="pa-3 borderRadius-1 bgWhite borderGreyLight mt-1">
										<Grid item className="xs-bold">
											Nenašli sa žiadne upozornenia
										</Grid>
									</Grid>
								)}
							</React.Fragment>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							{(isEmpty(NotificationCenterStore.selectedNotify) ||
								isEmpty(NotificationCenterStore.selectedNotify.message)) && (
								<XsButton
									className={classnames("xs-outline", {
										"xs-success pointer": NotificationCenterStore.selectedIds.length > 0,
										"xs-default": NotificationCenterStore.selectedIds.length == 0
									})}
									disabled={NotificationCenterStore.selectedIds.length == 0}
									icon={<i className="fal fa-check fa-lg" />}
									text={<FormattedMessage id="Common.labal.markAsRead" />}
									onClick={() => NotificationCenterStore.markAsRead()}
								/>
							)}
						</Grid>
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times fa-lg" />}
								text={<FormattedMessage id="Common.form.close" />}
								onClick={() => NotificationCenterStore.closeNotification()}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
