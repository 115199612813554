import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Grid, Paper} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
// import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsTabs from "../../../../../global/ui/xsTabs/xsTabs"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import AgreementPartForm from "../agreementDetail/agreementPartForm"
import AgreementPartDepartmentsDialog from "../agreementDetail/agreementPartDepartmentsDialog"
import PricePerCapitationGrid from "./pricePerCapitationGrid"
import PriceLimitPerHCTypeGrid from "./priceLimitPerHCTypeGrid"
import PricePerPointGrid from "./pricePerPointGrid"
import PricePerDeliveryGrid from "./pricePerDeliveryGrid"
import PricePerCapitationAdditionalGrid from "./pricePerCapitationAdditionalGrid"
import PricePerAttributeItemGrid from "./pricePerAttributeItemGrid"
import PointPerDeliveryGrid from "./pointPerDeliveryGrid"

import WarningStore from "../../../../../global/store/WarningStore"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import UIStore from "../../../stores/UIStore"
import RouterStore from "../../../../../global/store/RouterStore"

@injectIntl
@observer
export default class AgreementAttributeList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.mainMenuTab = "agreements"

		AgreementAttributeStore.loadAttrType()

		GlobalStore.refreshCodeLists(["insuranceTypes"])

		if (RouterStore.location.pathname == "/part") {
			if (isNotEmpty(this.props.id)) {
				AgreementAttributeStore.loadPart(this.props.id)
			} else {
				AgreementAttributeStore.isNewPart = true
			}
		}

		if (isSafe(AgreementAttributeStore.healthTypes) && AgreementAttributeStore.healthTypes.length === 0) {
			AgreementAttributeStore.loadHealthType()
		}

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					selectedAttr: {
						label: <FormattedMessage id="Agreement.Container.Detail.Attribute.SelectAttribute" />,
						value: "EHR.Data.Contract.SK.Attribute:PoPD",
						rules: "required"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	render() {
		let renderAttrTable = undefined
		switch (AgreementAttributeStore.typeFilter) {
			case "EHR.Data.Contract.SK.Attribute:PoPD":
				renderAttrTable = <PointPerDeliveryGrid isEditAttributeGrid={AgreementAttributeStore.isEditAttributeGrid} />
				break
			case "EHR.Data.Contract.SK.Attribute:PPC":
				renderAttrTable = <PricePerCapitationGrid isEditAttributeGrid={AgreementAttributeStore.isEditAttributeGrid} />
				break
			case "EHR.Data.Contract.SK.Attribute:PPCa":
				renderAttrTable = (
					<PricePerCapitationAdditionalGrid isEditAttributeGrid={AgreementAttributeStore.isEditAttributeGrid} />
				)
				break
			case "EHR.Data.Contract.SK.Attribute:PLZS":
				renderAttrTable = <PriceLimitPerHCTypeGrid isEditAttributeGrid={AgreementAttributeStore.isEditAttributeGrid} />
				break
			case "EHR.Data.Contract.SK.Attribute:PPP":
				renderAttrTable = <PricePerPointGrid isEditAttributeGrid={AgreementAttributeStore.isEditAttributeGrid} />
				break
			case "EHR.Data.Contract.SK.Attribute:PPD":
				renderAttrTable = <PricePerDeliveryGrid isEditAttributeGrid={AgreementAttributeStore.isEditAttributeGrid} />
				break
			case "EHR.Data.Contract.SK.Attribute:PPDI":
				renderAttrTable = (
					<PricePerAttributeItemGrid isEditAttributeGrid={AgreementAttributeStore.isEditAttributeGrid} />
				)
				break
		}

		return (
			<Grid container direction="column" className="xs-attribute-container">
				<Grid item xs={12}>
					<Paper className="xs-header" square={true} elevation={1}>
						<div className="xs-title">
							<FormattedMessage id="Agreement.Container.Detail.PartSubMat" />
						</div>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Grid container direction="column" className="xs-attribute-list">
						<Grid item xs={12}>
							<AgreementPartForm />
						</Grid>
						<Grid item xs={12}>
							{!AgreementAttributeStore.isNewPart && (
								<XsTabs
									value="attr"
									horizontal={true}
									mainClass="xs-agreement-tab"
									tabs={[
										{
											label: (
												<span className="xs-attr-tab">
													{this.props.intl.formatMessage({id: "Agreement.Container.Detail.Attribute.ListAttributes"})}
												</span>
											),
											value: "attr",
											content: (
												<Grid container direction="column">
													<Grid item xs={12} className="xs-tab-header-action">
														<Grid container direction="row" alignItems="center" justify="space-between">
															<Grid item xs={4}>
																<XsSearchSelect
																	field={this.form.$("selectedAttr")}
																	// disabled={InvoiceSettingsStore.disabledFields}
																	items={AgreementAttributeStore.agreementAttributeTypes}
																	onChange={(item) => {
																		AgreementAttributeStore.chooseAttributeType(item)
																		AgreementAttributeStore.isEditAttributeGrid = false
																		AgreementAttributeStore.updatedAttribute = {}
																		AgreementAttributeStore.loadPart(AgreementAttributeStore.partId)
																	}}
																	required
																/>
															</Grid>
															<Grid item xs={4}>
																<Grid container direction="row" justify="flex-end">
																	{!AgreementAttributeStore.isEditAttributeGrid && (
																		<Grid item>
																			<XsButton
																				className="xs-default xs-outline"
																				text={<FormattedMessage id="Common.label.edit" />}
																				onClick={() => {
																					AgreementAttributeStore.isEditAttributeGrid = true
																				}}
																			/>
																		</Grid>
																	)}
																	{AgreementAttributeStore.isEditAttributeGrid && (
																		<Grid item>
																			<XsButton
																				className="xs-danger xs-outline"
																				text={<FormattedMessage id="Common.label.cancel" />}
																				onClick={() => {
																					AgreementAttributeStore.isEditAttributeGrid = false
																					AgreementAttributeStore.updatedAttribute = {}
																					AgreementAttributeStore.loadPart(AgreementAttributeStore.partId)
																				}}
																			/>
																		</Grid>
																	)}
																	{AgreementAttributeStore.isEditAttributeGrid && (
																		<Grid item>
																			<XsButton
																				className="xs-success ml-3"
																				text={<FormattedMessage id="Common.label.save" />}
																				onClick={() => {
																					if (AgreementAttributeStore.isValidGrid()) {
																						if (
																							AgreementAttributeStore.typeFilter ===
																							"EHR.Data.Contract.SK.Attribute:PoPD" /* || AgreementAttributeStore.typeFilter === "EHR.Data.Contract.SK.Attribute:PPCa"*/
																						) {
																							if (!AgreementAttributeStore.hasDuplicityDeliveryPoint()) {
																								AgreementAttributeStore.isEditAttributeGrid = false
																								AgreementAttributeStore.updateAttributes()
																							} else {
																								WarningStore.open(
																									`${this.props.intl.formatMessage({
																										id: "Agreement.Container.Detail.Attribute.duplicityDelivery"
																									})}`
																								)
																							}
																						} else if (
																							AgreementAttributeStore.typeFilter ===
																							"EHR.Data.Contract.SK.Attribute:PPC" /* || AgreementAttributeStore.typeFilter === "EHR.Data.Contract.SK.Attribute:PPCa"*/
																						) {
																							if (!AgreementAttributeStore.hasIntersect()) {
																								AgreementAttributeStore.isEditAttributeGrid = false
																								AgreementAttributeStore.updateAttributes()
																							} else {
																								WarningStore.open(
																									`${this.props.intl.formatMessage({
																										id: "Patient.form.patientPrescription.intersect"
																									})}`
																								)
																							}
																						} else if (
																							AgreementAttributeStore.typeFilter ===
																							"EHR.Data.Contract.SK.Attribute:PPP"
																						) {
																							if (!AgreementAttributeStore.hasDuplicityDeliveries()) {
																								AgreementAttributeStore.isEditAttributeGrid = false
																								AgreementAttributeStore.updateAttributes()
																							} else {
																								WarningStore.open(
																									`${this.props.intl.formatMessage({
																										id: "Agreement.Container.Detail.Attribute.duplicityDelivery"
																									})}`
																								)
																							}
																						} else if (
																							AgreementAttributeStore.typeFilter ===
																							"EHR.Data.Contract.SK.Attribute:PPD"
																						) {
																							if (!AgreementAttributeStore.hasDuplicityDelivery()) {
																								AgreementAttributeStore.isEditAttributeGrid = false
																								AgreementAttributeStore.updateAttributes()
																							} else {
																								WarningStore.open(
																									`${this.props.intl.formatMessage({
																										id:
																											"Agreement.Container.Detail.Attribute.pricePerDelivery.duplicityDelivery"
																									})}`
																								)
																							}
																						} else {
																							AgreementAttributeStore.isEditAttributeGrid = false
																							AgreementAttributeStore.updateAttributes()
																						}
																					} else {
																						WarningStore.open(
																							`${this.props.intl.formatMessage({
																								id: "Patient.form.patientPrescription.invalidForm"
																							})}`
																						)
																					}
																				}}
																			/>
																		</Grid>
																	)}
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12}>
														{renderAttrTable}
													</Grid>
												</Grid>
											)
										}
									]}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
				<AgreementPartDepartmentsDialog />
				<XsConfirmationDialog
					name="xsDeleteAgreementAttribute"
					text={<FormattedMessage id="Agreement.Part.RemovePart.Message" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x"></i>
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg"></i>,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg"></i>,
						text: "Common.btn.agreementAttribute.delete"
					}}
					onConfirmation={() => AgreementAttributeStore.removeAttribute()}
				/>
			</Grid>
		)
	}
}
