//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import moment from "moment"

import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Clear"

import {getHour, getMinute, getDate, getMonth, getYear} from "../../../../global/ui/xsDateTimePicker/helpers"

import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"
// import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"

import EditEventDialogStore from "../../stores/EditEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"
import WarningStore from "../../../../global/store/WarningStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import RouterStore from "../../../../global/store/RouterStore"
import UIStore from "../../stores/UIStore"
import settings from "../../../../global/config/settings"
import {XsDateTimePickerDirect} from "../../../../global/ui/xsDateTimePickerDirect/xsDateTimePickerDirect"

import EditEventSlots from "./editEventSlots"
import EditEventOrders from "./editEventOrders"

@injectIntl
@observer
export default class EditEventDialogContainer extends React.Component {
	constructor(props) {
		super(props)

		EditEventDialogStore.changeServiceTxt = props.intl.formatMessage({id: "Common.label.setOfficeHour"})

		this.state = {
			isSaving: false
		}
	}

	// enableSave = () => {
	// 	this.setState({isSaving: false})
	// }

	onChangeInputChange = (e) => {
		EditEventDialogStore.inputDateTimeIsValid = e.target.value
	}

	onBlurInputChange = (e) => {
		if (
			moment(e.target.value, "DD.MM.YYYY HH:mm", true).isValid() &&
			moment(e.target.value, "DD.MM.YYYY HH:mm").isSameOrAfter(moment(), "DD.MM.YYYY HH:mm")
		) {
			// EditEventDialogStore.selectedDateTime = moment(e.target.value, "DD.MM.YYYY HH:mm")
			EditEventDialogStore.selectedDateTime = moment(e.target.value, "DD.MM.YYYY HH:mm").set(
				"minute",
				CalendarStore.getRoundMinutes(moment(e.target.value, "DD.MM.YYYY HH:mm"))
			)
			EditEventDialogStore.inputDateTimeIsValid = CalendarStore.getRoundTime(moment(e.target.value, "DD.MM.YYYY HH:mm"))
			EditEventDialogStore.setSlots()
		} else {
			EditEventDialogStore.inputDateTimeIsValid = CalendarStore.getRoundTime(EditEventDialogStore.selectedDateTime) //EditEventDialogStore.selectedDateTime.format("DD.MM.YYYY HH:mm")
		}
	}

	onHandleClickSlot = (selectedDateTime) => {
		EditEventDialogStore.selectedDateTime = moment(selectedDateTime)
		EditEventDialogStore.inputDateTimeIsValid = moment(selectedDateTime).format("DD.MM.YYYY HH:mm")
		CalendarStore.selectedSlotDateTime = selectedDateTime

		CalendarStore.loadPatientsOrder(
			moment(selectedDateTime)
				.startOf("day")
				.utc()
				.format(),
			moment(selectedDateTime)
				.endOf("day")
				.utc()
				.format()
		)
	}

	// handleDateTimePickerChange = (selectedDateTime) => {
	// 	let date = getDate(selectedDateTime)
	// 	let month = getMonth(selectedDateTime)
	// 	let year = getYear(selectedDateTime)
	// 	let hour = getHour(selectedDateTime)
	// 	let minute = getMinute(selectedDateTime)

	// 	const datetime = moment().set({date, month, year, hour, minute})

	// 	EditEventDialogStore.selectedDateTime = datetime
	// 	EditEventDialogStore.setSlots()
	// 	EditEventDialogStore.inputDateTimeIsValid = datetime.format("DD.MM.YYYY HH:mm")

	// 	CalendarStore.loadPatientsOrder(
	// 		moment(datetime)
	// 			.startOf("day")
	// 			.utc()
	// 			.format(),
	// 		moment(datetime)
	// 			.endOf("day")
	// 			.utc()
	// 			.format()
	// 	)
	// }

	handleDayClick = (selectedDateTime) => {
		let date = getDate(selectedDateTime)
		let month = getMonth(selectedDateTime)
		let year = getYear(selectedDateTime)
		let hour = getHour(moment())
		let minute = getMinute(selectedDateTime)

		let currDate = getDate(moment())
		let currMonth = getMonth(moment())
		let currYear = getYear(moment())

		let datetime = null

		if (currDate != date || currMonth != month || currYear != year) {
			let hour =
				isSafe(GlobalStore.openingHour) && isSafe(GlobalStore.openingHour[selectedDateTime.day()])
					? GlobalStore.openingHour[selectedDateTime.day()].from
					: 0
			datetime = moment().set({date, month, year, hour, minute})
		} else {
			minute = CalendarStore.getRoundTime(moment())
			datetime = moment().set({date, month, year, hour, minute})
		}

		EditEventDialogStore.selectedDateTime = datetime
		delayedCallback(500, () => EditEventDialogStore.setSlots())
		EditEventDialogStore.inputDateTimeIsValid = datetime.format("DD.MM.YYYY HH:mm")

		CalendarStore.loadPatientsOrder(
			moment(datetime)
				.startOf("day")
				.utc()
				.format(),
			moment(datetime)
				.endOf("day")
				.utc()
				.format()
		)
	}

	handleTimeClick = (selectedDateTime, hourList) => {
		let date = getDate(EditEventDialogStore.selectedDateTime)
		let month = getMonth(selectedDateTime)
		let year = getYear(selectedDateTime)
		let hour = hourList ? getHour(selectedDateTime) : getHour(EditEventDialogStore.selectedDateTime)
		let minute = hourList ? getMinute(EditEventDialogStore.selectedDateTime) : getMinute(selectedDateTime)

		const datetime = moment().set({date, month, year, hour, minute})

		EditEventDialogStore.selectedDateTime = datetime
		delayedCallback(500, () => EditEventDialogStore.setSlots())
		EditEventDialogStore.inputDateTimeIsValid = datetime.format("DD.MM.YYYY HH:mm")

		CalendarStore.loadPatientsOrder(
			moment(datetime)
				.startOf("day")
				.utc()
				.format(),
			moment(datetime)
				.endOf("day")
				.utc()
				.format()
		)
	}

	getOpenHours = () => {
		let from = 0
		let to = 23
		let hours = []
		for (let i = from; i <= to; i++) {
			hours.push(i)
		}

		return hours
	}

	render() {
		const isMainPage = RouterStore.location.pathname === settings.HOMEPAGE

		const {client, status, interval_from} = EditEventDialogStore.event

		let last_name,
			first_name,
			person_age,
			client_text,
			identifier,
			insurer_short_name,
			status_id,
			client_id,
			contact = ""

		if (isSafe(client)) {
			identifier = isNotEmpty(client.identifier) ? `, ${client.identifier}` : ""
			last_name = isNotEmpty(client.last_name) ? client.last_name : ""
			first_name = isNotEmpty(client.first_name) ? client.first_name : ""
			person_age = isNotEmpty(client.person_age) ? client.person_age : ""
			client_text = isNotEmpty(client.client_text) ? client.client_text : ""
			insurer_short_name = isNotEmpty(client.insurer_short_name) ? `(${client.insurer_short_name})` : ""
			client_id = isNotEmpty(client.client_id) ? client.client_id : ""
			contact = isNotEmpty(client.phone) ? `, ${client.phone}` : ""
		}

		if (isSafe(status)) {
			status_id = isNotEmpty(status._id) ? status._id : ""
		}

		const title = isEmpty(last_name)
			? client_text
			: `${last_name} ${first_name} ${
					isNotEmpty(person_age)
						? `, ${person_age} ${this.props.intl.formatMessage({id: "Common.label.yearsShortcut"})}`
						: ""
			  }${identifier} ${insurer_short_name}${contact}`

		return (
			<Dialog
				id="xsEditEventDialog"
				open={EditEventDialogStore.isOpen}
				onClose={() => EditEventDialogStore.close()}
				onEscapeKeyDown={() => EditEventDialogStore.close()}
				disableBackdropClick={true}
				scroll="body"
				aria-labelledby="scroll-dialog-title"
				PaperProps={{
					className:
						(EditEventDialogStore.step === 1 && status_id === "F") || EditEventDialogStore.step === 2
							? "xs-dialog-container xs-dialog-finished"
							: "xs-dialog-container"
				}}
			>
				<DialogContent className="xs-dialog-body">
					{EditEventDialogStore.step === 1 && status_id !== "F" && (
						<div className="xs-dialog-content xs-second-step">
							<Grid container direction="column" className="xs-left" justify="space-between">
								<Grid item container direction="column" justify="space-between" className="xs-flex">
									<Grid item container className="xs-relative pt-4" justify="center">
										<Grid item>
											<i className="fal fa-calendar-plus fa-3x greyDark" />
										</Grid>
										<XsIconButton
											className="xs-close-btn"
											icon={<DeleteIcon onClick={() => EditEventDialogStore.close()} />}
										/>
									</Grid>
									<Grid item container spacing={8} alignItems="center" justify="center" className="xs-bold">
										<Grid item>{title}</Grid>
									</Grid>
									{isMainPage && (
										<React.Fragment>
											<Grid
												item
												container
												justify="center"
												alignItems="center"
												spacing={8}
												className="pointer xs-blueInfo fontSize13"
												onClick={() => {
													if (isNotEmpty(client_id)) {
														if (UIStore.calendarTimePeriod === "resources") {
															EditEventDialogStore.routeAndShowPatientDetail()
														} else {
															EditEventDialogStore.showPatientDetail()
														}
													} else {
														EditEventDialogStore.createNewPatient()
													}
												}}
											>
												<Grid item>
													<i className="fal fa-search" />
												</Grid>
												<Grid item>
													{isNotEmpty(client_id) ? (
														<FormattedMessage id="Calendar.editEvent.dialog.patientCard" />
													) : (
														<FormattedMessage id="Calendar.editEvent.dialog.createPatient" />
													)}
												</Grid>
											</Grid>
										</React.Fragment>
									)}
									<Grid item container spacing={8} className="pl-4 pr-4">
										<Grid item xs={8}>
											<SearchSelect
												items={EditEventDialogStore.serviceData.map((resource) => {
													return {label: resource.name_ext, value: resource.code}
												})}
												onChange={(obj) =>
													GlobalStore.openConfirmationDialog("xsChangeEventService", {serviceObj: obj})
												}
												isValid={
													isSafe(EditEventDialogStore.serviceObj) && isSafe(EditEventDialogStore.serviceObj.value)
												}
												value={EditEventDialogStore.serviceObj}
												disabled={status_id == "F" ? true : false}
												hideLabel={true}
												required
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												className="xs-textfield-white"
												value={EditEventDialogStore.inputDateTimeIsValid}
												onChange={(e) => this.onChangeInputChange(e)}
												onBlur={(e) => this.onBlurInputChange(e)}
											/>
										</Grid>
									</Grid>
									<Grid item className="xs-datetime-picker">
										<XsDateTimePickerDirect
											onMonthChange={(sDateTime) => CalendarStore.handleEventsByDay(sDateTime)}
											onYearChange={(sDateTime) => CalendarStore.handleEventsByDay(sDateTime)}
											onDayClick={(sDateTime) => this.handleDayClick(sDateTime)}
											onTimeClick={(sDateTime, hourList) => this.handleTimeClick(sDateTime, hourList)}
											highlightDates={CalendarStore.eventsByDay}
											selected={EditEventDialogStore.selectedDateTime}
											showTimeSelect={true}
											minDate={moment()}
											inline={true}
										/>
									</Grid>
									<Grid item className="pr-4 pl-4">
										<TextField
											className="xs-textfield-white"
											placeholder={this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.note.placeholder"})}
											value={EditEventDialogStore.note}
											onChange={(e) => (EditEventDialogStore.note = e.target.value)}
										/>
									</Grid>
									<Grid item container justify="space-between" className="pr-4 pl-4 pb-2">
										<Grid item>
											<XsButton
												className="xs-danger xs-outline"
												text={
													<FormattedMessage id="Calendar.addEvent.dialog.deleteEvent" defaultMessage="Delete event" />
												}
												icon={<i className="fal fa-trash-alt fa-lg" />}
												onClick={() => (EditEventDialogStore.step = 2)}
											/>
										</Grid>
										<Grid item>
											<Grid container spacing={8}>
												<Grid item>
													<XsButton
														className="xs-default xs-outline mr-3"
														text={
															<FormattedMessage
																id="Calendar.addEvent.dialog.completeEvent"
																defaultMessage="Complete event"
															/>
														}
														icon={<i className="fal fa-calendar-check fa-lg" />}
														onClick={() => {
															if (
																moment(interval_from).diff(
																	moment()
																		.set("hour", 23)
																		.set("minute", 59)
																		.set("second", 59)
																) < 0
															) {
																EditEventDialogStore.completeEvent()
															} else
																WarningStore.open(
																	this.props.intl.formatMessage({id: "Calendar.editEvent.completeEvent.Message"})
																)
														}}
													/>
												</Grid>
												<Grid item>
													<XsButton
														className="xs-success"
														text={<FormattedMessage id="Common.label.save" />}
														icon={<i className="fal fa-save fa-lg" />}
														onClick={() => {
															if (
																moment().isAfter(
																	moment(EditEventDialogStore.selectedDateTime)
																		.set("second", 0)
																		.utc()
																)
															) {
																WarningStore.open(
																	`${this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.eventInPast"})}`
																)
															} else if (
																isEmpty(EditEventDialogStore.serviceObj) ||
																isEmpty(EditEventDialogStore.serviceObj.value)
															) {
																WarningStore.open(
																	`${this.props.intl.formatMessage({
																		id: "Common.label.createService"
																	})}`
																)
															} else {
																EditEventDialogStore.editEvent()
															}
														}}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item container alignItems="center" className="xs-footer bgGreyLight greyDefault pl-4 pr-4">
									<Grid item className={EditEventDialogStore.isWarningMessage ? "xs-title xs-warning" : "xs-title"}>
										{EditEventDialogStore.isWarningMessage ? (
											<span className="xs-war">
												<i className="fas fa-exclamation-triangle" /> <FormattedMessage id="Common.error.warning" />{" "}
											</span>
										) : (
											""
										)}
										<FormattedMessage id={EditEventDialogStore.headerMessage} />
									</Grid>
								</Grid>
							</Grid>
							<div className="xs-right">
								<EditEventOrders />
								<EditEventSlots />
							</div>
						</div>
					)}
					{EditEventDialogStore.step === 1 && status_id === "F" && (
						<div className="xs-dialog-content xs-second-step">
							<Grid container direction="column" justify="space-between" className="xs-left hidden">
								<Grid container direction="column">
									<Grid item container className="xs-relative pt-4" justify="center">
										<Grid item>
											<i className="fal fa-calendar-plus fa-3x greyDark" />
										</Grid>
										<XsIconButton
											className="xs-close-btn"
											icon={<DeleteIcon onClick={() => EditEventDialogStore.close()} />}
										/>
									</Grid>
									<Grid item container justify="center" className="xs-bold mt-2 mb-1">
										<Grid item>
											{last_name +
												" " +
												first_name +
												`${
													isNotEmpty(person_age)
														? `, ${person_age} ${this.props.intl.formatMessage({
																id: "Common.label.years"
														  })}`
														: ""
												}` +
												" " +
												insurer_short_name}
										</Grid>
									</Grid>
									<Grid
										item
										container
										justify="center"
										alignItems="center"
										spacing={8}
										className="pointer xs-blueInfo fontSize13"
										onClick={() => {
											if (isNotEmpty(client_id)) {
												if (UIStore.calendarTimePeriod === "resources") {
													EditEventDialogStore.routeAndShowPatientDetail()
												} else {
													EditEventDialogStore.showPatientDetail()
												}
											} else {
												EditEventDialogStore.createNewPatient()
											}
										}}
									>
										<Grid item>
											<i className="fal fa-search" />
										</Grid>
										<Grid item>
											{isNotEmpty(client_id) ? (
												<FormattedMessage id="Calendar.editEvent.dialog.patientCard" />
											) : (
												<FormattedMessage id="Calendar.editEvent.dialog.createPatient" />
											)}
										</Grid>
									</Grid>
									<Grid item container spacing={8} className="pl-4 pr-4 mt-1">
										<Grid item xs={8}>
											<SearchSelect
												items={EditEventDialogStore.serviceData.map((resource) => {
													return {label: resource.name_ext, value: resource.code}
												})}
												onChange={(obj) =>
													GlobalStore.openConfirmationDialog("xsChangeEventService", {serviceObj: obj})
												}
												isValid={
													isSafe(EditEventDialogStore.serviceObj) && isSafe(EditEventDialogStore.serviceObj.value)
												}
												value={EditEventDialogStore.serviceObj}
												disabled={status_id == "F" ? true : false}
												hideLabel={true}
												required
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												disabled
												className="xs-textfield-white xs-disabled"
												value={EditEventDialogStore.inputDateTimeIsValid}
												onChange={(e) => this.onChangeInputChange(e)}
												onBlur={(e) => this.onBlurInputChange(e)}
											/>
										</Grid>
									</Grid>
									<Grid item className="pl-4 pr-4 mb-1 mt-1">
										<TextField
											disabled
											className="xs-textfield-white xs-disabled"
											placeholder={this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.note.placeholder"})}
											value={EditEventDialogStore.note}
											onChange={(e) => (EditEventDialogStore.note = e.target.value)}
										/>
									</Grid>
								</Grid>
								<Grid item container direction="column">
									<Grid container item className="pl-4 pr-4 pb-2 pt-2" justify="flex-end">
										<Grid item>
											<XsButton
												className="xs-danger xs-outline"
												text={
													<FormattedMessage id="Calendar.addEvent.dialog.deleteEvent" defaultMessage="Delete event" />
												}
												icon={<i className="fal fa-trash-alt fa-lg" />}
												onClick={() => GlobalStore.openConfirmationDialog("xsDeleteEvent")}
											/>
										</Grid>
									</Grid>
									<Grid item container className="xs-footer bgGreyLight greyDefault pl-4 pr-4" alignItems="center">
										<Grid item>
											<i className="fas fa-check-circle fa-lg" />
										</Grid>
										<Grid item className="pl-1">
											<FormattedMessage id="Calendar.addEvent.dialog.completedEvent" defaultMessage="Completed" />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					)}
					{EditEventDialogStore.step === 2 && (
						<div className="xs-dialog-content xs-second-step">
							<Grid container direction="column" justify="space-between" className="xs-left">
								<Grid item>
									<Grid item container xs={12} className="xs-relative pt-4" justify="center">
										<Grid item>
											<i className="fal fa-calendar-exclamation fa-3x greyDark" />
										</Grid>
										<XsIconButton
											className="xs-close-btn"
											icon={<DeleteIcon onClick={() => EditEventDialogStore.close()} />}
										/>
									</Grid>
									<Grid item container className="xs-bold mt-2 mb-1" justify="center">
										<Grid item>{last_name + " " + first_name + " " + insurer_short_name}</Grid>
									</Grid>
									<Grid item container justify="center" className="xs-bold mt-1 mb-1">
										<Grid item>
											{moment(EditEventDialogStore.selectedDateTime)
												.locale("sk-SK")
												.format("DD.MM.YYYY hh:mm")}
										</Grid>
									</Grid>
									<Grid item container justify="center" className="mt-3">
										<Grid item>
											<FormattedMessage
												id="Calendar.editEvent.delete.confirmMessage"
												defaultMessage="Do you really want to delete calendar event?"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item container direction="column">
									<Grid item container justify="space-between" className="pr-4 pl-4">
										<Grid item>
											<XsButton
												className="xs-default xs-outline"
												text={<FormattedMessage id="Common.label.keep" defaultMessage="Keep" />}
												onClick={() => (EditEventDialogStore.step = 1)}
											/>
										</Grid>
										<Grid item>
											<XsButton
												className="xs-danger xs-outline"
												text={<FormattedMessage id="Common.label.remove" defaultMessage="Remove" />}
												icon={<i className="fal fa-trash-alt fa-lg" />}
												onClick={() => EditEventDialogStore.deleteEvent()}
											/>
										</Grid>
									</Grid>
									<Grid item container className="xs-footer bgGreyLight greyDefault pr-4 pl-4 mt-2" alignItems="center">
										<Grid item>{EditEventDialogStore.event.services[0].service_name_ext}</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					)}
				</DialogContent>
				<XsConfirmationDialog
					name="xsChangeEventService"
					text={<FormattedMessage id="Calendar.editEvent.changeService" />}
					type="info"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-info-circle fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.change"
					}}
					confirmBtn={{
						icon: <i className="fal fa-info-circle fa-lg" />,
						text: "Common.btn.editEvent.changeService"
					}}
					onConfirmation={() => EditEventDialogStore.handleChangeResource()}
				/>
				<XsConfirmationDialog
					name="xsDeleteEvent"
					text={<FormattedMessage id="Common.label.removeFinishedEvent" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.label.keep"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.remove"
					}}
					onConfirmation={() => EditEventDialogStore.deleteEvent()}
				/>
			</Dialog>
		)
	}
}
