import api from "../../../modules/ambulance/actions/api"
import {getUserCompanyInfo, getSelectedOrgUnitID, printReport} from "../../../global/helpers/actions"
import {getFilters} from "../../../global/helpers/api"
import UIStore from "./UIStore"
import PrintTemplatesStore from "./PrintTemplatesStore"
import moment from "moment"
import {observable, action} from "mobx"
// import actionCard from "../actions/patientCard"
import DataStore from "../stores/DataStore"
import ProblemsStore from "./ProblemsStore"
import GlobalStore from "../../../global/store/GlobalStore"
import settings from "../../../global/config/settings"
import renderHTML from "react-render-html"

class PatientOperationStore {
	operatingTheaterData = []
	complicationAsData = []
	complicationPeData = []

	diagnosisChipsForm

	deliveriesAddItemChips = []
	deliveriesAddItemChipsForm = {}
	deliveriesChipsForm = null
	@observable serviceData = []
	@observable providedServicesSearchValue = null
	@observable defaultSendingType = null

	/* -------------- DELIVERIES FUNCTIONS------------------------ */

	@action setDeliveryDiagnosis(value, form) {
		if (
			isSafe(form) &&
			isSafe(form.get("value")) &&
			form.get("value").col2 === "PRIMARY" &&
			isSafe(this.deliveriesChipsForm)
		) {
			let data = this.deliveriesChipsForm.getData()
			if (Object.keys(data).length) {
				Object.keys(data).forEach((i) => {
					if (isEmpty(data[i].col2)) {
						data[i].col2 = value
					}
				})
				this.deliveriesChipsForm.bindData(data)
			}
		}
	}

	@action async addProvidedServices(form, service) {
		UIStore.isFormSaving = true
		api
			.loadProvidedService(service.value)
			.call()
			.then(async (res) => {
				if (isSafe(res.relateds) && Array.isArray(res.relateds) && res.relateds.length > 0) {
					// Pridanie diagnoz
					let diagnosisChipsForm = JSON.parse(form.$("diagnosisChipsForm").value)
					if (this.readonlyform == false) {
						let diagnosis = res.relateds.filter((row) => row.rel_class_name == "EHR.CL.Record.Diagnosis")
						if (isSafe(diagnosis) && diagnosis.length) {
							if (isSafe(diagnosisChipsForm["0"]) && isNotEmpty(diagnosisChipsForm["0"].col1)) {
								let ids = diagnosis.map((i) => i.rel_object_id)
								if (isSafe(ids) && ids.length) {
									let uniqueIds = Array.from(new Set(ids))
									let tmp = uniqueIds.map((i) => {
										return {
											col1: i,
											col1NameExt: "",
											col2: "SECONDARY",
											col3: "",
											col4: ""
										}
									})
									diagnosisChipsForm = diagnosisChipsForm.concat(tmp)
								}
							} else {
								let ids = diagnosis.map((i) => i.rel_object_id)
								if (isSafe(ids) && ids.length) {
									let uniqueIds = Array.from(new Set(ids))
									diagnosisChipsForm = uniqueIds.map((i, idx) => {
										return {
											col1: i,
											col1NameExt: "",
											col2: idx == 0 ? "PRIMARY" : "SECONDARY",
											col3: "",
											col4: ""
										}
									})
								}
							}
						}
					}

					let newMainDiagnosis =
						isSafe(diagnosisChipsForm) && isSafe(diagnosisChipsForm["0"]) ? diagnosisChipsForm["0"].col1 : ""

					let hasDiagnosis = isEmpty(newMainDiagnosis) ? false : true
					let setDispensary = false
					let diagnosisForm = JSON.parse(form.$("diagnosisChipsForm").value)
					if (
						isSafe(ProblemsStore.problemsDTO) &&
						Array.isArray(ProblemsStore.problemsDTO) &&
						ProblemsStore.problemsDTO.length &&
						isSafe(diagnosisForm)
					) {
						let diagnosisCodes = Object.keys(diagnosisForm).map((key) => diagnosisForm[key].col1)
						if (
							ProblemsStore.problemsDTO.some(
								(row) =>
									isSafe(row.problem_type) &&
									row.problem_type.code == "DISPENSARY" &&
									diagnosisCodes.includes(row.diagnosis)
							)
						) {
							setDispensary = true
							form.$("hasDispensaryDiagnosis").value = true
							form.$("dispensarisation").value = true
						}
					}
					//Pridanie vykonov
					let deliveriesChipsForm = JSON.parse(form.$("deliveriesChipsForm").value)
					let relatedDeliveryItems = isNotEmpty(form.$("deliveriesFormAddItems").value)
						? JSON.parse(form.$("deliveriesFormAddItems").value)
						: {}
					const deliveriesChipsFormLength = deliveriesChipsForm.length

					let newDeliveries = res.relateds
						.filter((row) => row.rel_class_name == "EHR.CL.Bill.Delivery")
						.map((data, index) => {
							let relatedDiagnosis = res.relateds.find(
								(diag) => diag.rel_class_name == "EHR.CL.Record.Diagnosis" && data.item_order == diag.item_order
							)
							let relatedDeliveryItem = res.relateds.find(
								(diag) => diag.rel_class_name == "EHR.CL.Bill.DeliveryItem" && data.item_order == diag.item_order
							)
							if (isSafe(relatedDeliveryItem)) {
								let relatedDeliveryItemPrice = null
								if (GlobalStore.CL["deliveryItem"]) {
									relatedDeliveryItemPrice = GlobalStore.CL["deliveryItem"].find(
										(item) => item._id == relatedDeliveryItem.rel_object_id
									).price_in_currency
								}

								let relatedDeliveryId = GlobalStore.CL["deliveryItem"].find(
									(item) => item._id == relatedDeliveryItem.rel_object_id
								)._id

								relatedDeliveryItems["" + (index + deliveriesChipsFormLength)] = [
									{
										product: relatedDeliveryId,
										quantity: 1,
										type: "adicntr",
										typeNameExt: "Zmluvná pripočitateľná položka",
										unit: relatedDeliveryItemPrice
									}
								]
							}

							return {
								col1: data.rel_object_id,
								col2: isSafe(relatedDiagnosis) ? relatedDiagnosis.rel_object_id : newMainDiagnosis,
								col3: isNotEmpty(data.quantity) ? data.quantity : "",
								colPaymentType: "Z",
								col4: "",
								col5: "",
								col6: "",
								delivery_extension: "",
								col7: moment().format(process.env.DB_DATETIME_FORMAT),
								colSendingType: setDispensary ? "D" : "",
								colDateOfRequest: ""
							}
						})

					// Load specialnych cien ktore si lekari zmenili a teda niesu v ciselniku
					for (let index = deliveriesChipsFormLength; index < Object.keys(relatedDeliveryItems).length; index++) {
						const key = Object.keys(relatedDeliveryItems)[index]
						if (isSafe(relatedDeliveryItems[key]) && isSafe(relatedDeliveryItems[key][0])) {
							let item = relatedDeliveryItems[key][0]
							if (isNotEmpty(item.product)) {
								let price = await api
									.getDeliveryItemPrice(item.product, {
										org_unit_id: getSelectedOrgUnitID(),
										insurer_id: DataStore.patientDTO.get("insurerId"),
										date: moment().format("YYYY-MM-DD")
									})
									.call()
								if (isSafe(price)) {
									relatedDeliveryItems[key][0].unit = price.price_in_currency
								}
							}
						}
					}

					deliveriesChipsForm = deliveriesChipsForm.concat(newDeliveries)
					if (!this.readonlyform) {
						form.$("diagnosisChipsForm").set(JSON.stringify(diagnosisChipsForm))
					}
					form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChipsForm)
					await this.loadChipText(form, () => {})
					let deliveryData = JSON.parse(form.$("deliveriesChipsForm").value)
					for (let index = 0; index < deliveryData.length; index++) {
						let row = deliveryData[index]
						if (row.col1CodeExt == "H0006") {
							let overviewData = await api
								.loadPatientOverview(
									getSelectedOrgUnitID(),
									DataStore.patientDTO.get("patientID"),
									null,
									moment(form.$("examdate").value)
										.startOf("day")
										.format(settings.DB_DATETIME_FORMAT),
									moment(form.$("examdate").value)
										.endOf("day")
										.format(settings.DB_DATETIME_FORMAT)
								)
								.call()
							let scoring = overviewData.rows.reverse().find((row) => row.type_id == "EHR.Data.Scoring")
							if (isSafe(scoring)) {
								relatedDeliveryItems[index] = [
									{
										type: "adigen",
										typeNameExt: "Iná pripočítateľná položka",
										product: "SKOR0" + scoring.value,
										quantity: 1,
										unitEndText: "",
										unit: 0
									}
								]
							}
						}
					}

					form.$("deliveriesFormAddItems").value = JSON.stringify(relatedDeliveryItems)
					this.deliveriesAddItemChips = relatedDeliveryItems

					if (isSafe(form.$("recordId").value) && isNotEmpty(form.$("recordId").value) && this.readonlyform) {
						this.deliveriesChipsForm.props.configuration.options.onFormSave(deliveriesChipsForm)
					}

					let data = []
					if (!hasDiagnosis) {
						let diagnosis = isSafe(JSON.parse(form.$("diagnosisChipsForm").value))
							? JSON.parse(form.$("diagnosisChipsForm").value)[0]
							: null

						if (isSafe(diagnosis) && isNotEmpty(diagnosis.col1)) {
							let filter = getFilters([`diagnosis=${diagnosis.col1}`])
							let response = await api.loadDiagnosisMeasure(filter).call()
							if (
								isSafe(response) &&
								isSafe(response.rows) &&
								isSafe(response.rows[0]) &&
								isSafe(response.rows[0].items) &&
								response.rows[0].items.length
							) {
								let items = response.rows[0].items
								items.forEach((val) => {
									data.push({
										col1: val.measure._id,
										col1NameExt: val.measure.name_ext,
										col2: "",
										col3: isSafe(val.measure.default_unit) ? val.measure.default_unit._id : "",
										note: "",
										date: ""
									})
								})
							}
						}
					}

					UIStore.isFormSaving = false
				} else {
					UIStore.isFormSaving = false
				}
			})
	}

	@action insertSendingTypeToDelivieries(form, sendingTypeObj) {
		UIStore.isFormSaving = true
		let deliveriesChipsForm = JSON.parse(form.$("deliveriesChipsForm").value)
		const setSenderToAll = () => {
			deliveriesChipsForm.forEach((row) => {
				row.colSendingType = sendingTypeObj.value
			})
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChipsForm)
			this.deliveriesChipsForm.bindData(deliveriesChipsForm)
		}

		if (deliveriesChipsForm.every((row) => isEmpty(row.colSendingType))) {
			setSenderToAll()
		} else {
			GlobalStore.openConfirmationDialog("xsRewriteSendingType", {rewriteCallback: setSenderToAll})
		}
		UIStore.isFormSaving = false
	}

	@action checkDeliverysForConstraints(saveCallback) {
		let data = this.deliveriesChipsForm.getData()
		if (Object.keys(data).length > 0 || data.length > 0) {
			let req = {
				patient_id: DataStore.patientDTO.get("patientID"),
				insurer_id: DataStore.patientDTO.get("insurerId"),
				provider_id: getSelectedOrgUnitID(),
				deliveries: []
			}

			req.deliveries = Object.keys(data).map((objKey) => {
				let delivery = data[objKey]
				return {
					item_id: delivery.col1,
					delivery_date_time: delivery.col7
				}
			})

			api
				.getDeliveryConflicts(req)
				.call()
				.then((response) => {
					if (response.entries.length) {
						let periodicityError = ""
						let combinationError = ""
						response.entries.forEach((entry) => {
							if (isSafe(entry.constraints) && Array.isArray(entry.constraints) && entry.constraints.length > 0) {
								entry.constraints.forEach((constrain) => {
									if (constrain._type == "EHR.CL.Bill.SK.DeliveryPeriod") {
										let periodicityObj = GlobalStore.CL.medicationRepeats.find(
											(per) => per._id == constrain.allowed_period_unit._id
										)
										periodicityError += `${entry.item.code_ext}-${entry.item.name_ext} ${
											constrain.allowed_amount_in_period
										} za ${constrain.allowed_period_amount} ${periodicityObj.name_ext.toLowerCase()} <br/>`
									} else if (constrain._type == "EHR.CL.Bill.SK.DeliveryNoCombination") {
										if (isSafe(constrain.no_item)) {
											combinationError += `${constrain.no_item.code_ext}-${constrain.no_item.name_ext} <br/>`
										}
									}
								})
							}
						})
						if (isNotEmpty(periodicityError) || isNotEmpty(combinationError)) {
							let text = ""
							if (isNotEmpty(periodicityError)) {
								text += "<b>Obmedzená periodicita vykázania:</b> <br />" + periodicityError
							}
							if (isNotEmpty(combinationError)) {
								text += "<b>Zakázaná kombinácia:</b> <br />" + combinationError
							}
							GlobalStore.openConfirmationDialog("xsDeliveryConflictsDialog", {
								dialogText: renderHTML(text),
								confirmAction: saveCallback
							})
						}
					} else {
						saveCallback()
					}
					//obmedzena periodicita vykazania,
					//zakazana kombinacia
				})
		} else {
			saveCallback()
		}
	}

	@action checkDeliverysAndSave(form, values, id) {
		//Zkontroluje ci Vykony obsahuju vykon H0006 ak ano k tomuto vykonu musi byt pripocitatelna polozka SKOR s nejakymi 2 cislami napriklad SKOR12 ak tam je ulozi vykony inak sa otovi xsConfirmationDialog v ktorom sa pyta ci aj tak chces ulozit
		let isFormValid = this.validateDeliveryChips()
		if (isFormValid) {
			// this.handleSaveDeliveries(form, values, id)
		} else {
			UIStore.isFormSaving = false
			GlobalStore.openConfirmationDialog("xsDeliveriesValidationFailed", {
				deliveriesValidatinFailedCallback: () => {
					this.handleSaveDeliveries(form, values, id)
				}
			})
		}
	}

	@action async handleSaveDeliveries(values, opId) {
		let deliveriesRequest = []
		if (isSafe(this.deliveriesChipsForm)) {
			const dchips = this.deliveriesChipsForm.getData()
			let idx = 0
			//dchips.map((delivery, idx) => {
			Object.keys(dchips).forEach((key) => {
				let delivery = dchips[key]
				let deliveryRow = {
					_ref: false,
					_type: "EHR.Data.Record.SK.Delivery",
					item: {
						_id: delivery.col1,
						_type: "EHR.CL.Bill.SK.Delivery"
					},
					count: delivery.col3,
					type: "dlv",
					payer_type: isNotEmpty(delivery.colPaymentType) ? delivery.colPaymentType : null,
					insuree_type: isNotEmpty(delivery.col4) ? delivery.col4 : null,
					refund_type: isNotEmpty(delivery.col5) ? delivery.col5 : null,
					delivery_extension: isNotEmpty(delivery.delivery_extension) ? delivery.delivery_extension : null,
					movement: delivery.col6,
					delivery_date_time: moment(delivery.col7).format(process.env.DB_DATETIME_FORMAT),
					sender_type: isNotEmpty(delivery.colSendingType) ? delivery.colSendingType : null,
					request_date: isNotEmpty(delivery.colDateOfRequest)
						? moment.isMoment(delivery.colDateOfRequest)
							? delivery.colDateOfRequest.format(settings.DB_DATE_FORMAT)
							: moment(delivery.colDateOfRequest).format(settings.DB_DATE_FORMAT)
						: null
				}

				if (
					isNotEmpty(delivery.col2) ||
					(isSafe(this.diagnosisChipsForm) &&
						isSafe(this.diagnosisChipsForm.getData()) &&
						isSafe(this.diagnosisChipsForm.getData()["0"]) &&
						isNotEmpty(this.diagnosisChipsForm.getData()["0"].col1))
				) {
					deliveryRow["diagnosis"] = {
						_id: isNotEmpty(delivery.col2)
							? delivery.col2
							: isSafe(this.diagnosisChipsForm) &&
							  isSafe(this.diagnosisChipsForm.getData()) &&
							  isSafe(this.diagnosisChipsForm.getData()["0"]) &&
							  this.diagnosisChipsForm.getData()["0"].col2 === "PRIMARY"
							? this.diagnosisChipsForm.getData()["0"].col1
							: "",
						_type: "EHR.CL.Record.Diagnosis"
					}
				}

				deliveriesRequest.push(deliveryRow)

				if (isSafe(this.deliveriesAddItemChips[idx])) {
					deliveriesRequest[idx].sub_items = this.deliveriesAddItemChips[idx].map((chip) => {
						let product = {}
						let description = null
						let delivery_item = null
						let _type = "EHR.Data.Record.SK.Delivery.Product"
						if (chip.type == "adidrug" || chip.type == "adidev" || chip.type == "adicntr") {
							product["_id"] = chip.product
							if (chip.type == "adidrug") {
								product["_type"] = "EHR.CL.Medication.SK.Drug"
							} else if (chip.type == "adidev") {
								product["_type"] = "EHR.CL.Medication.SK.MedicalDevice"
							} else if (chip.type == "adicntr") {
								product = null
								delivery_item = {
									_id: chip.product,
									_type: "EHR.CL.Bill.SK.DeliveryItem"
								}
								_type = "EHR.Data.Record.Delivery.CustomItem"
							}
						} else {
							product = null
							description = chip.product
						}
						return {
							_ref: false,
							type: "adi",
							sub_type: chip.type,
							quantity: chip.quantity,
							unit_price: chip.unit,
							product,
							delivery_item,
							description,
							_type
						}
					})
				}
				idx++
			})
			await api.saveDeliveries(deliveriesRequest, opId).call()
		}
	}

	/* -------------- /DELIVERIES FUNCTIONS------------------------ */

	@action async loadOperationData() {
		try {
			const req = {
				filters: [
					{
						associated_column: "company_id",
						values: [
							{
								id_value: getUserCompanyInfo()._id
							}
						]
					}
				]
			}

			const data = await api.getOperatingTheater(req).call()

			this.operatingTheaterData = isSafe(data) && isSafe(data.rows) ? data.rows : []
		} catch (error) {
			this.operatingTheaterData = []
		}

		try {
			const data = await api.getSurgeryComplication().call()

			if (isSafe(data) && isSafe(data.rows)) {
				this.complicationAsData = data.rows.filter((x) => x.scope == "As" || x.scope == "*")
				this.complicationPeData = data.rows.filter((x) => x.scope == "Pe" || x.scope == "*")
			}
		} catch (error) {
			this.complicationAsData = []
			this.complicationPeData = []
		}
	}

	@action async loadOperation(form, recordId) {
		const operation = await api.loadRecord(recordId, "L3").call()
		// Ak neexistuje ziadna anamneza pre pacienta BE vrati prazdny response takze awiat tam vlozi header typu Response a vtedy sa nic nema loadovat
		if (!(operation instanceof Response)) {
			this.insertFormData(form, operation)
		}
	}

	@action async insertFormData(form, operation) {
		let recordId = form.$("recordId").value
		form.reset()

		if (isNotEmpty(recordId)) {
			form.$("recordId").value = recordId
		}

		if (isSafe(operation.recorded_events) && operation.recorded_events.length > 0) {
			const opExamDate = operation.recorded_events.find((x) => x.type == "WRT")
			const opStartDate = operation.recorded_events.find((x) => x.type == "SRGFROM")
			const opEndDate = operation.recorded_events.find((x) => x.type == "SRGTO")
			const opRoomEntry = operation.recorded_events.find((x) => x.type == "OROOMENTER")
			const opRoomLeave = operation.recorded_events.find((x) => x.type == "OROOMLEAVE")

			form.$("examdate").set("value", isNotEmpty(opExamDate) ? moment(opExamDate.recorded_at) : "")
			form.$("operationStartDate").set("value", isNotEmpty(opStartDate) ? moment(opStartDate.recorded_at) : "")
			form.$("operationEndDate").set("value", isNotEmpty(opEndDate) ? moment(opEndDate.recorded_at) : "")
			form.$("operationRoomEntry").set("value", isNotEmpty(opRoomEntry) ? moment(opRoomEntry.recorded_at) : "")
			form.$("operationRoomLeave").set("value", isNotEmpty(opRoomLeave) ? moment(opRoomLeave.recorded_at) : "")
		}
		// form.$("diagnosisChipsForm").set("value", "")
		form
			.$("operating_theater")
			.set("value", isNotEmpty(operation.operating_theater) ? operation.operating_theater._id : "")
		form
			.$("mechanical_ventilation_duration")
			.set(
				"value",
				isNotEmpty(operation.mechanical_ventilation_duration) ? operation.mechanical_ventilation_duration : ""
			)

		form
			.$("anesthesia_duration")
			.set("value", isNotEmpty(operation.anesthesia_duration) ? operation.anesthesia_duration : "")
		form
			.$("surgeon_preparation_duration")
			.set("value", isNotEmpty(operation.surgeon_preparation_duration) ? operation.surgeon_preparation_duration : "")
		form
			.$("instrumentalist_preparation_duration")
			.set(
				"value",
				isNotEmpty(operation.instrumentalist_preparation_duration) ? operation.instrumentalist_preparation_duration : ""
			)
		form
			.$("anesthesiologist_preparation_duration")
			.set(
				"value",
				isNotEmpty(operation.anesthesiologist_preparation_duration)
					? operation.anesthesiologist_preparation_duration
					: ""
			)
		form.$("anesthesia_type").set("value", isNotEmpty(operation.anesthesia_type) ? operation.anesthesia_type._id : "")
		form.$("surgery_type").set("value", isNotEmpty(operation.surgery_type) ? operation.surgery_type._id : "")
		form
			.$("surgery_schedule_type")
			.set("value", isNotEmpty(operation.surgery_schedule_type) ? operation.surgery_schedule_type._id : "")

		if (isSafe(operation.summaries) && operation.summaries.length > 0) {
			const summaries = operation.summaries.find((i) => i.type._id === "OP")

			form.$("anamnesis").set("value", isSafe(summaries) ? htmlStyleToPlain(summaries.content) : "")
		}

		let operator_cl_expertise_id = null
		let anesthesiolog_cl_expertise_id = null

		if (isSafe(operation.general_items) && operation.general_items.length > 0) {
			let tmpData = operation.general_items.filter((data) => data._type == "EHR.Data.Record.Diagnosis")
			tmpData = tmpData.sort((a, b) => (a.diagnosis_type._id === "PRIMARY" ? -1 : a.item_order - b.item_order))
			const diagnosis = tmpData.map((i) => {
				return {
					col1: i.item._id,
					col1NameExt: i.item.code_ext + "-" + i.item.name_ext,
					col2: i.diagnosis_type.code,
					col3: i.description,
					col4: isSafe(i.life_threatening) ? i.life_threatening : false
				}
			})

			if (diagnosis.length > 0) {
				form.$("diagnosisChipsForm").value = JSON.stringify(diagnosis)
			} else {
				form.$("diagnosisChipsForm").value = JSON.stringify([
					{col1: "", col1NameExt: "", col2: "PRIMARY", col3: "", col4: false}
				])
			}

			const operator = operation.general_items.find((data) => data.type == "phyexp")
			const anesthesiolog = operation.general_items.find((data) => data.type == "anesthphyexp")

			if (isSafe(operator) && isSafe(operator.item)) {
				operator_cl_expertise_id = operator.item._id
			}
			if (isSafe(anesthesiolog) && isSafe(anesthesiolog.item)) {
				anesthesiolog_cl_expertise_id = anesthesiolog.item._id
			}
		}

		let operator_personel_id = null
		let anesthesiolog_personel_id = null

		if (isSafe(operation.specific_rels) && operation.specific_rels.length > 0) {
			const op = operation.specific_rels.find((x) => x.type == "srgphy")
			const instr = operation.specific_rels.find((x) => x.type == "srginstr")
			const anest = operation.specific_rels.find((x) => x.type == "srganesth")

			operator_personel_id = isNotEmpty(op) ? op.specific_rel._id : null
			anesthesiolog_personel_id = isNotEmpty(anest) ? anest.specific_rel._id : null

			form.$("instrumentalist").set("value", isNotEmpty(instr) ? instr.specific_rel._id : "")
		}

		if (isSafe(operator_cl_expertise_id) && isSafe(operator_personel_id)) {
			let filters = getFilters([`expertise_id=${operator_cl_expertise_id}`, `personnel_id=${operator_personel_id}`])

			let response = await api.loadPersonnel(filters).call()

			if (isSafe(response) && isSafe(response.rows) && response.rows.length) {
				form.$("operator").set("value", response.rows[0].personnel_expertise_id)
				form.$("operator_cl_expertise_id").set("value", operator_cl_expertise_id)
				form.$("operator_personnel_id").set("value", operator_personel_id)
			}
		}

		if (isSafe(anesthesiolog_cl_expertise_id) && isSafe(anesthesiolog_personel_id)) {
			let filters = getFilters([
				`expertise_id=${anesthesiolog_cl_expertise_id}`,
				`personnel_id=${anesthesiolog_personel_id}`
			])

			let response = await api.loadPersonnel(filters).call()

			if (isSafe(response) && isSafe(response.rows) && response.rows.length) {
				form.$("anesthesiologist").set("value", response.rows[0].personnel_expertise_id)
				form.$("anesthesiologist_cl_expertise_id").set("value", anesthesiolog_cl_expertise_id)
				form.$("anesthesiologist_personnel_id").set("value", anesthesiolog_personel_id)
			}
		}

		if (isSafe(operation._notes) && operation._notes.length > 0) {
			const note = operation._notes.find((x) => x.type == "General" && x.active)

			if (isSafe(note)) {
				form.$("note").set("value", note.value)
				form.$("noteId").set("value", note._id)
			}
		}

		form.$("surgery_procedure").set("value", isSafe(operation.surgery_procedure) ? operation.surgery_procedure._id : "")

		if (isSafe(operation.surgery_complications) && operation.surgery_complications.length > 0) {
			const pe = operation.surgery_complications.find((x) => x.scope == "Pe")
			const as = operation.surgery_complications.find((x) => x.scope == "As")

			form.$("surgery_perop_complications").set("value", isSafe(pe) ? pe.type._id : "")
			form.$("surgery_associated_complications").set("value", isSafe(as) ? as.type._id : "")
		}

		let deliveriesChips = {}
		let deliveriesAddItemChips = {}
		if (isSafe(operation.general_items) && operation.general_items.length) {
			let tmpDiagnosis = operation.general_items.filter(
				(i) => isSafe(i.item) && i.item._type === "EHR.CL.Bill.SK.Delivery"
			)
			deliveriesChips = tmpDiagnosis.map((data, idx) => {
				if (isSafe(data.sub_items) && data.sub_items.length) {
					data.sub_items.sort((a, b) => {
						return a.sub_item_order - b.sub_item_order
					})
					this.deliveriesAddItemChips[idx] = data.sub_items.map((x) => {
						let productId = ""
						if (x.sub_type == "adidrug" || x.sub_type == "adidev") {
							if (isSafe(x.product)) {
								productId = x.product._id
							}
						} else if (x.sub_type == "adicntr") {
							if (isSafe(x.delivery_item)) {
								productId = x.delivery_item._id
							}
						} else {
							productId = x.description
						}

						return {
							type: x.sub_type,
							typeNameExt:
								isSafe(GlobalStore.CL["generalSubItemType"]) &&
								isSafe(GlobalStore.CL["generalSubItemType"].find((i) => i._id == x.sub_type))
									? GlobalStore.CL["generalSubItemType"].find((i) => i._id == x.sub_type).name_ext
									: "",
							product: productId,
							quantity: x.quantity,
							unit: x.unit_price,
							unitEndText: isSafe(x.product) ? x.product.basic_statement_unit : ""
						}
					})
				} else {
					deliveriesAddItemChips[idx] = []
				}

				let col7Data = null
				if (isSafe(data.delivery_date_time)) {
					col7Data = moment(data.delivery_date_time).format(process.env.DB_DATETIME_FORMAT)
				} else {
					null
				}

				return {
					col1: data.item._id,
					col2: isSafe(data.diagnosis) && isNotEmpty(data.diagnosis._id) ? data.diagnosis._id : "",
					col3: +data.count,
					colPaymentType: isSafe(data.payer_type) ? data.payer_type : "",
					col4: isSafe(data.insuree_type) ? data.insuree_type : "",
					col5: isSafe(data.refund_type) ? data.refund_type : "",
					col6: isSafe(data.movement) ? data.movement : "",
					delivery_extension: isSafe(data.delivery_extension) ? data.delivery_extension._id : "",
					col7: isSafe(col7Data) ? col7Data : "",
					colSendingType: isSafe(data.sender_type) ? data.sender_type : "",
					colDateOfRequest: isSafe(data.request_date) ? data.request_date : ""
				}
			})
		}

		if (isSafe(deliveriesChips)) {
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
			await this.loadChipText(form)
		}
	}

	@action async loadChipText(form, callback) {
		if (isNotEmpty(form.$("deliveriesChipsForm").value)) {
			let data = JSON.parse(form.$("deliveriesChipsForm").value)
			let dataArr = []
			let idsArr = []

			Object.keys(data).forEach((field) => {
				dataArr[+field] = data[field]
			})
			dataArr.forEach((item) => {
				idsArr.push(item.col1)
			})

			if (idsArr.length) {
				let filters = {filters: [{associated_column: "code", predicate: "=", values: []}]}
				idsArr.forEach((i) => {
					filters.filters[0].values.push({id_value: i})
				})
				let response = await api.loadSpecificDelivery(filters).call()
				dataArr.forEach((i) => {
					let dataRow = response.rows.find((row) => row._id == i.col1)
					if (isSafe(dataRow)) {
						i.col1NameExt = dataRow.code_ext + "-" + dataRow.name_ext
						i.col1CodeExt = dataRow.code_ext
					}
				})
				// this.deliveriesChipsForm.bindData(dataArr)
				form.$("deliveriesChipsForm").set(JSON.stringify(dataArr))
				this.deliveriesLoadedFlag = true
				if (isSafe(this.deliveriesChipsForm)) {
					this.deliveriesChipsForm.forceUpdate()
				}
				if (isSafe(callback) && typeof callback === "function") {
					callback()
				}
			}
		}
	}

	@action printOperation(recordId) {
		UIStore.isFormSaving = true

		api
			.loadRecord(recordId, "L4")
			.call()
			.then((op) => {
				let printValues = PrintTemplatesStore.getCommonValues()

				if (isSafe(op.recorded_events) && op.recorded_events.length > 0) {
					const opExamDate = op.recorded_events.find((x) => x.type == "WRT")
					const opStartDate = op.recorded_events.find((x) => x.type == "SRGFROM")
					const opEndDate = op.recorded_events.find((x) => x.type == "SRGTO")

					printValues["examDate"] = isSafe(opExamDate) ? moment(opExamDate.recorded_at).format("DD.MM.YYYY") : ""
					printValues["opFrom"] = isSafe(opStartDate) ? moment(opStartDate.recorded_at).format("DD.MM.YYYY HH:mm") : ""
					printValues["opTo"] = isSafe(opEndDate) ? moment(opEndDate.recorded_at).format("DD.MM.YYYY HH:mm") : ""
				}

				printValues["surgeryType"] =
					isSafe(op.surgery_type) && isNotEmpty(op.surgery_type.name_ext) ? op.surgery_type.name_ext : ""

				if (isSafe(op.general_items) && op.general_items.length > 0) {
					let tmpData = op.general_items.filter((data) => data._type == "EHR.Data.Record.Diagnosis")
					tmpData = tmpData.sort((a, b) => (a.diagnosis_type._id === "PRIMARY" ? -1 : a.item_order - b.item_order))

					let diagnosis = tmpData.map((i) => i.item.code_ext + "-" + i.item.name_ext).join(", ")

					printValues["diagnosis"] = diagnosis
				}

				if (isSafe(op.summaries) && op.summaries.length > 0) {
					const summaries = op.summaries.find((i) => i.type._id === "OP")

					printValues["finding"] = isSafe(summaries) ? htmlStyleToPlain(summaries.content) : ""
				}

				if (isSafe(op.specific_rels) && op.specific_rels.length > 0) {
					const oper = op.specific_rels.find((x) => x.type == "srgphy")
					const instr = op.specific_rels.find((x) => x.type == "srginstr")
					const anest = op.specific_rels.find((x) => x.type == "srganesth")

					printValues["operator"] =
						isSafe(oper) && isSafe(oper.specific_rel) && isSafe(oper.specific_rel.entity)
							? oper.specific_rel.entity.full_name
							: ""
					printValues["instrumentalist"] =
						isSafe(instr) && isSafe(instr.specific_rel) && isSafe(instr.specific_rel.entity)
							? instr.specific_rel.entity.full_name
							: ""
					printValues["anesthesiologist"] =
						isSafe(anest) && isSafe(anest.specific_rel) && isSafe(anest.specific_rel.entity)
							? anest.specific_rel.entity.full_name
							: ""
				}

				printValues["surgery_procedure"] = isSafe(op.surgery_procedure) ? op.surgery_procedure.name_ext : ""

				printReport("operation", printValues)

				UIStore.isFormSaving = false
			})
	}

	@action async loadDefaultDelivaries(form) {
		let response = await api
			.loadPatientOverview(
				getSelectedOrgUnitID(),
				DataStore.patientDTO.get("patientID"),
				[],
				moment()
					.startOf("day")
					.utc()
					.format(),
				moment()
					.endOf("day")
					.utc()
					.format(),
				null,
				null,
				null,
				"?level_flag=CDL2"
			)
			.call()
		let data = response.rows
		if (isSafe(data)) {
			const nzs = data.find((row) => row.type_code == "NZS")

			if (isSafe(nzs) && isSafe(nzs.deliveries) && nzs.deliveries.length && isSafe(nzs.deliveries[0])) {
				const delivery = {
					col1: nzs.deliveries[0].item._id,
					col2: "",
					col3: 1,
					colPaymentType: "Z",
					col4: "",
					col5: "",
					col6: isSafe(data.movement) ? data.movement : "",
					delivery_extension: isSafe(data.delivery_extension) ? data.delivery_extension._id : "",
					col7: moment(),
					colSendingType: "",
					colDateOfRequest: ""
				}

				form.$("deliveriesChipsForm").value = JSON.stringify([delivery])

				await this.loadChipText(form)
			}
		}
	}
}

var singleton = new PatientOperationStore()
export default singleton
