import React from "react"
import config from "../config/settings"
import {onApiError} from "./functions"
import {getUser, getSelectedOrgUnit, getUserPersonnelID} from "./actions"
import {injectIntl, FormattedMessage} from "react-intl"
import RouterStore from "../store/RouterStore"
import ErrorStore from "../store/ErrorStore"
import UIStore from "../../modules/ambulance/stores/UIStore"
import GlobalStore from "../store/GlobalStore"

export function ApiCall(options, method, body, hideError = false) {
	if (options && typeof options === "object") {
		this.path = options.path
		this.method = options.method || "GET"
		this.body = options.body
		this.headers = options.headers
		this.json = options.json
		this.customURL = options.customURL
		this.transform = options.transform === undefined ? true : options.transform
		this.includeToken = options.includeToken === undefined ? true : options.includeToken
	} else {
		this.path = options
		this.method = method || "GET"
		this.body = body
		this.transform = true
		this.includeToken = true
		this.hideError = hideError
	}
}

ApiCall.prototype.getURL = function() {
	return `${config.API_BASE_URL}${this.path}`
}
ApiCall.prototype.call = function(getStatusCode, requestId, getIXSRequestId, timeout) {
	if (isSafe(requestId)) {
		if (isSafe(this.headers)) {
			this.headers = Object.assign(this.headers, {"IXS-REQUESTID": requestId})
		} else {
			this.headers = {"IXS-REQUESTID": requestId}
		}
	}
	return api(
		this.path,
		{
			method: this.method,
			body: this.body,
			headers: this.headers,
			json: this.json === false ? undefined : !!this.body,
			transform: this.transform || true,
			includeToken: this.includeToken
		},
		this.transform,
		this.customURL,
		getStatusCode,
		getIXSRequestId,
		timeout,
		this.hideError
	)
}
ApiCall.prototype.getPath = function() {
	return this.path
}

function ApiError(status, statusText, response, additionalInfoForError, hideError = false) {
	if (isSafe(response) && isSafe(response.errors) && response.errors.length > 0 && response.errors[0].code == -1207) {
		RouterStore.push("/login")
	} else {
		UIStore.isFormSaving = false
		if (!hideError) {
			ErrorStore.status = status
			ErrorStore.statusText = statusText
			ErrorStore.response = response
			ErrorStore.additionalInfo = additionalInfoForError
			ErrorStore.open()
		}
	}
}

const createDefaultHeaders = (includeToken) => {
	const user = getUser()
	if (includeToken && user) {
		return {
			Authorization: `IXS ${user.ixstoken || ""}`
		}
	} else {
		return {}
	}
}

const adjustOptions = (options) => {
	if (!options) {
		options = {}
	}

	options.credentials = "include"

	if (!options.headers) {
		options.headers = {}
	}

	// if (typeof options.headers.then === "function") {
	//   options.headers = options.headers;
	// }

	const headers = createDefaultHeaders(options.includeToken)
	if (options.json === true) {
		if (typeof options.body === "object") {
			options.body = JSON.stringify(options.body)
		}
		headers["Content-Type"] = "application/json"
	}

	Object.assign(options.headers, headers)

	return options
}

const checkStatus = (response, additionalInfoForError, hideError = false) => {
	if (response.status >= 200 && response.status < 300) {
		return response
	} else {
		response
			.json()
			.catch(() => {
				throw new ApiError(response.status, response.statusText, null, additionalInfoForError, hideError)
			})
			.then((json) => {
				onApiError(response.status, response.statusText, json, additionalInfoForError, hideError)
				throw new ApiError(response.status, response.statusText, json, additionalInfoForError, hideError)
			})
	}
}

const parseJSON = (response, getIXSRequestId) => {
	let contentType = response.headers.get("Content-Type")
	contentType = contentType && contentType.split(";")[0]
	if (typeof getIXSRequestId === "function") {
		getIXSRequestId(response.headers.get("ixs-requestid"))
	}

	if (!contentType || contentType === "application/json") {
		return response.json().catch(() => response)
	} else {
		return response
	}
}

const handleApiCatch = (response, additionalInfoForError, hideError = false) => {
	if (response == "TypeError: Failed to fetch") {
		throw new ApiError(
			"",
			"",
			{errors: [{description: <FormattedMessage id="Api.error" />}]},
			additionalInfoForError,
			hideError
		)
	} else if (response == "Error: Client timeout") {
		throw new ApiError(
			"",
			"",
			{errors: [{description: <FormattedMessage id="Api.client.timeout" />}]},
			additionalInfoForError,
			hideError
		)
	} else {
		throw new ApiError(
			"",
			"",
			{errors: [{description: <FormattedMessage id="Api.unknown.error" />}]},
			additionalInfoForError,
			hideError
		)
	}
}

const timeoutPromise = (ms, promise) => {
	return new Promise((resolve, reject) => {
		let timeoutId = setTimeout(() => {
			timeoutId = undefined
			reject(new Error("Client timeout"))
		}, ms)
		promise.then(
			(res) => {
				if (timeoutId) {
					clearTimeout(timeoutId)
					resolve(res)
				}
			},
			(err) => {
				if (timeoutId) {
					clearTimeout(timeoutId)
					reject(err)
				}
			}
		)
	})
}

const api = (
	path,
	options,
	transform = true,
	customURL = null,
	getStatusCode,
	getIXSRequestId,
	timeout,
	hideError = false
) => {
	const fetchPath = customURL || config.API_BASE_URL + path
	const fetchPathWithTimestamp = `${fetchPath}${fetchPath.includes("?") ? "&" : "?"}nctsmp=${Date.now()}`
	const additionalInfoForError = {path, options}

	return timeoutPromise(timeout || config.API_CLIENT_TIMEOUT, fetch(fetchPathWithTimestamp, adjustOptions(options)))
		.then((response) => {
			if (config.DEBUG) {
				const contentLength = response.headers.get("CONTENT-LENGTH")
				if (contentLength && contentLength > config.API_LENGTH_THRESHOLD) {
					new ApiError(
						"",
						"",
						{errors: [{description: <FormattedMessage id="Api.response.length" values={{uri: response.url}} />}]},
						additionalInfoForError,
						false
					)
				}
			}
			return transform
				? (typeof getStatusCode === "function" ? getStatusCode(response.status) : null,
				  checkStatus(response, additionalInfoForError, hideError))
				: (typeof getStatusCode === "function" ? getStatusCode(response.status) : null, response)
		})
		.then((response) => (transform ? parseJSON(response, getIXSRequestId) : response))
		.catch((response) => {
			handleApiCatch(response, additionalInfoForError, hideError)
		})
}

export const getFilters = (expressions, extras) => {
	const operators = ["="]
	const filters = expressions.map((expr) => {
		if (operators.some((o) => expr.includes(o))) {
			const operator = operators.find((o) => expr.includes(o))
			const exprSplit = expr.split(operator)
			const column = exprSplit[0]
			const values = exprSplit[1]
			const valuesArr = values.split(";")

			const valuesJSON = valuesArr.map((val) => {
				return {id_value: val}
			})

			return {
				associated_column: column,
				predicate: operator,
				values: valuesJSON
			}
		}
	})

	return Object.assign({}, {filters: filters}, extras)
}

export default injectIntl(api)

export const GWApiCall = ({method, uri, body, authToken}) => {
	if (config.GW_BASE_URL) {
		// ak je EHGW zastarala, zablokuju sa volania
		if (GlobalStore.isEHGWOutdated) {
			return Promise.reject()
		}

		let request = {method: method}
		let headers = {}

		if (method === "POST") {
			request["mode"] = "cors"
			request["body"] = body
			headers["Accept"] = "application/json"
			headers["Content-Type"] = "application/json"
		}

		if (authToken) {
			headers["Authorization"] = authToken
		}

		request["headers"] = headers

		// najskor sa zavola registerZpr (ak nie je), a az potom to co sa malo zavolat
		if (
			(GlobalStore.ZprRegisteredInEhealthState == 0 || GlobalStore.ZprRegisteredInEhealthState == 2) &&
			uri.indexOf("RegisterZpr") == -1
		) {
			GlobalStore.ZprRegisteredInEhealthState = 3

			const user = getUser()
			const selectedOrgUnit = getSelectedOrgUnit()
			const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
			const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
			const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
			const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
			const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

			return new Promise((resolve) => {
				fetch(`${config.GW_BASE_URL}/RegisterZpr`, {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						Force: true,
						AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
						AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
						AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
						AmbeePersonnelId: getUserPersonnelID(),
						OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
						PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
						PersonnelExpertiseJRUZVersion: specVer,
						PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
						// backup pre stare EHGW:
						OUPZS: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
						Specialization: isSafe(spec) ? spec : null,
						SpecializationVersion: specVer
					})
				})
					.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
					.then((data) => {
						GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
						GlobalStore.ZprRegisteredInEhealthState = isEmpty(data.ErrorMessage) && isNotEmpty(data.Id) ? 1 : 2
						resolve(fetch(uri, request))
					})
					.catch(() => {
						GlobalStore.ZprRegisteredInEhealthState = 2
						resolve(fetch(uri, request))
					})
			})
		} else {
			return fetch(uri, request)
		}
	} else {
		return Promise.reject()
	}
}
