import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid} from "@material-ui/core"

import "./printTemplatesStyle.less"

import fields from "./medicalExaminationDriverCardTemplateFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"

import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

@injectIntl
@observer
export default class MedicalExaminationDriverCardTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("name")} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("birthdate")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("own")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("opinion")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>
						{/* <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.FormLabel6" /> */}
						Menovaný bol posudzovaný s nasledovným výsledkom:
					</div>

					<Grid container direction="column" align="left">
						<Grid item xs={12}>
							<XsInput field={this.form.$("result1_cat")} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("result2_cat")} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("result3_cat")} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
