"use strict"

import api from "../actions/api"
import config from "../../../global/config/settings"
import {
	getUserPersonnelID,
	printReport,
	getUserDoctorInfo,
	getSelectedOrgUnitID,
	printMultiReport,
	getSelectedOrgUnit,
	getUser
} from "../../../global/helpers/actions"
import actionCard from "../actions/patientCard"
import settings from "../../../global/config/settings"
import WarningStore from "../../../global/store/WarningStore"
import UIStore from "../stores/UIStore"
import DataStore from "../stores/DataStore"
import GlobalStore from "../../../global/store/GlobalStore"
import PatientRecordStore from "../stores/PatientRecordStore"
import {observable, action} from "mobx"
import moment from "moment"

class PatientRequestStore {
	@observable diagnosis = []
	@observable expertise = []
	@observable provider = []
	@observable selectedEHR = []
	@observable represcribeID = null
	@observable represcribeLoaded = false
	@observable recordLoaded = false
	@observable todayRecordsChecked = false
	paAmbDefaultID = null

	//FLAGS
	@observable represcribeFlag = false

	textAreaCursors = {}

	requestRef = null
	ids = []
	@observable validationSnackbar = null

	@action clearData(name) {
		this[name] = {}
	}

	@action reset() {
		this.diagnosis = []
		this.expertise = []
		this.provider = []
		this.selectedEHR = []
		this.represcribeID = null
		this.represcribeLoaded = false
		this.recordLoaded = false
		this.todayRecordsChecked = false
		this.textAreaCursors = {}
		this.profActivitySet = false
	}

	@action setter(name, value) {
		this[name] = value
	}

	@action getTypeData(names) {
		return [
			{
				_id: "EHR.Data.Specialist.Request",
				name_ext: names[0]
			},
			{
				_id: "EHR.Data.Radiology.Request",
				name_ext: names[1]
			}
		]
	}

	@action addToSelectedEHR(record) {
		this.selectedEHR.push(record)
	}

	@action addSelectedEHR(warningText) {
		let showWarning = false
		this.selectedEHR.push(
			...DataStore.checkedEHR.filter((ce) => {
				if (PatientRecordStore.record) {
					if (ce.record_id === PatientRecordStore.record.record_id) {
						showWarning = true
					}
					return ce.type_code === "DEK" && ce.record_id !== PatientRecordStore.record.record_id
				}
				return ce.type_code === "DEK"
			})
		)
		showWarning ? WarningStore.open(warningText) : null
		DataStore.clearCheckedEHR()
	}

	@action removeSelectedEHR(index) {
		this.selectedEHR.splice(index, 1)
	}

	@action isSelectedEHR(selected) {
		if (selected) {
			return Boolean(this.selectedEHR.find((ehr) => ehr.record_id === selected.record_id))
		}
	}

	@action clearSelectedEHR() {
		this.selectedEHR = []
	}

	// @action represcribe(id = null) {
	// 	this.represcribeID = id
	// 	this.represcribeLoaded = false
	// }

	@action represcribe(requests) {
		this.ids = requests.map((i) => {
			return i.record_id
		})

		if (isSafe(this.requestRef)) {
			let ids = this.ids.slice()
			this.requestRef.clearAllReqeust(() => {
				this.requestRef.addForm(requests.length - 1, ids)
			})
			this.ids = []
		}
		DataStore.clearCheckedEHR()
	}

	@action represcribeIdsToForms() {
		if (isSafe(this.ids) && this.ids.length) {
			let ids = this.ids.slice()
			this.requestRef.clearAllReqeust(() => {
				this.requestRef.addForm(ids.length - 1, ids)
			})
		}
		this.ids = []
	}

	@action isGoingToReprescribe() {
		if (isSafe(this.ids) && this.ids.length) {
			return true
		} else {
			return false
		}
	}

	@action saveForm(data, patientDTO, masterRecord, translations, clearForms, onSuccess, draftOnly = false) {
		const patientID = patientDTO.validInfo.client._id
		const providerID = getSelectedOrgUnitID()
		const personnelID = getUserPersonnelID()

		let saveRequests = []

		if (isSafe(data) && data.length > 0) {
			data.forEach((item) => {
				const items = item.values()

				let saveRequest = {
					_ref: false,
					_type: items.request_type,
					active: true,
					version: {
						_type: "EHR.CL.Record.Version",
						_id: draftOnly ? "draft" : "final"
					},
					priority: {
						_ref: false,
						_type: "EHR.CL.Record.Priority",
						_id: items.priority
					},
					general_items: [],
					recorded_events: [
						{
							_ref: false,
							_type: "EHR.Data.Record.Event",
							recorded_at: moment().format(config.DB_DATETIME_FORMAT),
							type: "REQ"
						}
					],
					specific_rels: [
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: patientID,
								_type: "Entity.Data.Client"
							},
							type: "pat"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: providerID,
								_type: "Entity.Data.OrgUnit"
							},
							type: "ou"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: personnelID,
								_type: "EHR.Data.Entity.Personnel"
							},
							type: "phy"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: personnelID,
								_type: "EHR.Data.Entity.Personnel"
							},
							type: "sndphy"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: providerID,
								_type: "Entity.Data.OrgUnit"
							},
							type: "sndou"
						}
					],
					summaries: [
						{
							_ref: false,
							_type: "EHR.Data.Record.Summary",
							active: true,
							content: items.request,
							content_type: "text/plain",
							type: "REQ"
						}
					]
				}

				if (isNotEmpty(items.recordId)) {
					saveRequest["_id"] = items.recordId
				}

				if (isNotEmpty(items.diagnosis)) {
					saveRequest.general_items.push({
						_ref: false,
						_type: "EHR.Data.Record.Diagnosis",
						item: {
							_id: items.diagnosis,
							_type: "EHR.CL.Record.Diagnosis"
						},
						item_order: 1,
						type: "dgn",
						description: items.clarification,
						diagnosis_type: items.diagnosis_type
					})
				}

				if (isNotEmpty(items.expertise_code)) {
					saveRequest.general_items.push({
						_ref: false,
						_type: "EHR.Data.Record.Expertise",
						item: {
							_id: items.expertise_code,
							_type: "EHR.CL.Expertise"
						},
						type: "recvouexp"
					})
				}

				//Poznámka pre pacienta
				if (items.patientnote) {
					saveRequest.summaries.push({
						_ref: false,
						_type: "EHR.Data.Record.Summary",
						active: true,
						content: items.patientnote,
						content_type: "text/plain",
						type: "INFO"
					})
				}

				if (items.request_type === "EHR.Data.Specialist.Request" && isNotEmpty(items.professional_activity)) {
					saveRequest.general_items.push({
						_ref: false,
						_type: "EHR.Data.Record.ProfessionalActivity",
						item: {
							_id: items.professional_activity,
							_type: "EHR.CL.ProfessionalActivity"
						},
						type: "profact"
					})
				}

				if (isNotEmpty(items.diagnosis_secondary)) {
					saveRequest.general_items.push({
						_ref: false,
						_type: "EHR.Data.Record.Diagnosis",
						item: {
							_id: items.diagnosis_secondary,
							_type: "EHR.CL.Record.Diagnosis"
						},
						type: "dgn",
						item_order: 2,
						// description: items.clarification,
						diagnosis_type: items.diagnosis_type_secondary
					})
				}

				//Žiadanka na zobrazovacie vyšetrenie
				if (items.request_type === "EHR.Data.Radiology.Request") {
					saveRequest["sedation_needed"] = items.sedation_needed
					saveRequest["modality"] = items.modality
					saveRequest["organ_to_exam"] = items.organ_to_exam
				}

				//Oddelenie
				// if (items.provider_code) {
				//   saveRequest.specific_rels.push({
				//     "_ref": false,
				//     "_type": "EHR.Data.Record.SpecificRel",
				//     "specific_rel": {
				//       "_id": items.provider_code,
				//       "_type": "EHR.Data.Entity.Provider"
				//     },
				//     "type": "recou"
				//   });
				// }

				//Nadradený dekurz
				if (masterRecord) {
					saveRequest["master_records"] = [
						{
							_ref: false,
							_type: "EHR.Data.Record.RecordRelationship",
							master: masterRecord.record_id,
							type: "SND"
						}
					]
				}

				//Naviazané dekurzy
				if (this.selectedEHR && this.selectedEHR.length > 0) {
					saveRequest["enslaved_records"] = []

					this.selectedEHR.map((selected) => {
						saveRequest["enslaved_records"].push({
							_ref: false,
							_type: "EHR.Data.Record.RecordRelationship",
							slave: selected.record_id,
							type: "ASSOC"
						})
					})
				}

				saveRequests.push(saveRequest)
			})
		}

		if (draftOnly) {
			return saveRequests
		}

		api
			.saveRecord(saveRequests, patientID)
			.call()
			.then((response) => {
				// if (response._id) {
				// data.reset();
				if (isSafe(response) && response.length > 0) {
					response.forEach((item) => {
						if (
							isSafe(item) &&
							isSafe(item.ixs_signer_data) &&
							isSafe(item.ixs_signer_data.payload) &&
							isSafe(item.ixs_signer_data.payload.text)
						) {
							const selectedOrgUnit = getSelectedOrgUnit()
							const user = getUser()
							const OUPZS = isSafe(selectedOrgUnit)
								? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID")
								: null
							const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
							const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
							const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
							const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

							const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
								? DataStore.patientDTO.get("patient").validInfo.client
								: null
							const JRUZIDObj = isSafe(patientClientInfo.identifiers)
								? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
								: null

							fetch(`${settings.GW_BASE_URL}/WriteEHRExtract`, {
								method: "POST",
								headers: {
									Accept: "application/json",
									"Content-Type": "application/json"
								},
								body: JSON.stringify({
									ClassName: "ZapisZaznamOVysetreni_v5",
									ExtId: item.record_external_id,
									EHRExtract: item.ixs_signer_data.payload.text,
									TimeCommitted: item.actual_time,
									AmbeePatientId: DataStore.patientDTO.get("patientID"),
									PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
									PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
									AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
									AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
									AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
									AmbeePersonnelId: getUserPersonnelID(),
									OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
									PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
									PersonnelExpertiseJRUZVersion: specVer,
									PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
									RecordSignerData: isSafe(item.record_signer_data)
										? item.record_signer_data.map((x) => {
												return {
													Ref: x._ref,
													Type: x._type,
													RecordClass: x.record_class,
													RecordExternalId: x.record_external_id,
													RecordId: x.record_id
												}
										  })
										: null
								})
							})
								.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
								.then((res) => {
									if (isSafe(res)) {
										let responseWithData = response.find((row) => isSafe(row) && isSafe(row.record_ids))
										if (isSafe(responseWithData) && isSafe(responseWithData.record_ids)) {
											responseWithData.record_ids.forEach((id) => {
												if (isNotEmpty(id)) {
													api.setSent(id).call()
												}
											})
										}
									}
									if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
										if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
											WarningStore.open("<b>Vytlačte papierový záznam!</b></br>" + res.ErrorMessage)
										}
									}
								})
								.catch(() => {})
						}
					})
				}

				PatientRecordStore.record = null
				if (onSuccess) {
					onSuccess(response)
				}
				this.dataLoaded = this.dataState = false
				GlobalStore.setNotificationMessage("Patient.form.patientrequest.savesuccess")
				UIStore.isFormSaving = false
				if (data && data.length > 0 && data[0].$("print").value) {
					let multipleRq = []
					let printQueue = []
					Object.keys(response).forEach((obj) => {
						const requestID = response[obj]._id
						if (!printQueue.includes(requestID)) {
							multipleRq.push(this.printRequest(requestID, patientDTO, translations, false))
							printQueue.push(requestID)
						}
					})
					Promise.all(multipleRq).then((r) => {
						printMultiReport(r)
					})
				}
				actionCard.loadPatientOverview(providerID, patientID, null, null, true).then((overview) => {
					DataStore.setPatientEHR(overview)

					let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
					if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
						DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
					}
				})
				UIStore.patientCardTab = ""
				this.clearSelectedEHR()
				clearForms()
				window.scrollTo(0, 0)
			})
	}

	@action printRequest(recordID, pacInfo, translations, directPrint = true) {
		return api
			.loadRecord(recordID, "L4")
			.call()
			.then((recordData) => {
				const doctorInfo = getUserDoctorInfo()
				let providerCode = ""
				const providerOrgUnit = recordData.specific_rels.find((sr) => sr.type === "ou")
				if (providerOrgUnit && providerOrgUnit.specific_rel && providerOrgUnit.specific_rel.identifiers) {
					const providerIdentifier = providerOrgUnit.specific_rel.identifiers.find((i) => i.type === "PROVIDERCODE")
					providerCode = providerIdentifier.value
				}

				const sedation_needed = recordData.sedation_needed ? "Áno" : "Nie"

				let printValues = {
					name: `${pacInfo.last_name.toUpperCase()} ${pacInfo.first_name}`,
					dg: "",
					identifier: pacInfo.identifier,
					address:
						pacInfo.validInfo && pacInfo.validInfo.address
							? `${pacInfo.validInfo.address.city} ${pacInfo.validInfo.address.street} ${pacInfo.validInfo.address.house_number}`
							: "",
					doctorinfo: doctorInfo ? `${doctorInfo.name} ${doctorInfo.code ? doctorInfo.code : ""}` : "",
					modality: recordData.modality
						? `<tr><td><span class="bold">Modalita:</span> ${recordData.modality}</td></tr>`
						: "",
					organ_to_exam: recordData.organ_to_exam
						? `<tr><td><span class="bold">Vyšetrovaný orgán:</span> ${recordData.organ_to_exam}</td></tr>`
						: "",
					sedation_needed: `<tr><td><span class="bold">Potreba sedácie:</span> ${sedation_needed}</td></tr>`
				}

				//ProviderCode to doctorinfo
				if (providerCode) {
					printValues.doctorinfo += ` / ${providerCode}`
				}
				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					printValues[
						"insurer"
					] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
				}

				//Dg
				//Primary
				const primaryDg = recordData.general_items.find(
					(dg) => dg._type === "EHR.Data.Record.Diagnosis" && dg.diagnosis_type && dg.diagnosis_type._id === "PRIMARY"
				)
				if (primaryDg) {
					printValues.dg += `${primaryDg.item.code_ext} - ${primaryDg.item.name_ext}`
				}

				//Secondary and others
				recordData.general_items
					.filter(
						(dg) => dg._type === "EHR.Data.Record.Diagnosis" && dg.diagnosis_type && dg.diagnosis_type._id !== "PRIMARY"
					)
					.map((dg) => {
						printValues.dg += `, ${dg.item.code_ext} - ${dg.item.name_ext}`
					})

				//Odbornosť
				if (recordData.general_items.length > 0) {
					const ex = recordData.general_items.find(
						(gi) => gi._type === "EHR.Data.Record.Expertise" && gi.type === "recvouexp"
					)
					if (ex) {
						printValues["expertise"] = `${ex.item.code_ext} - <span class="bold">${ex.item.name_ext}</span>`
					}
					const pa = recordData.general_items.find(
						(gi) => gi._type === "EHR.Data.Record.ProfessionalActivity" && gi.type === "profact"
					)

					if (pa) {
						printValues["pa"] = `<p>${pa.item.name_ext}</p>`
					}
				}

				//Nález + Info pre pacienta
				if (recordData.summaries.length > 0) {
					const sumReq = recordData.summaries.find((s) => s.type._id === "REQ")
					const sumInfo = recordData.summaries.find((s) => s.type._id === "INFO")
					if (sumReq) {
						printValues["request"] = sumReq.content
					}
					if (sumInfo) {
						printValues[
							"patientnote"
						] = `<tr><td colspan="3"><span class="bold">Informácia pre pacienta</span> ${sumInfo.content}</td></tr>`
					}
				}

				//Čiarový kód
				if (recordData.identifiers) {
					const barcode = recordData.identifiers.find((i) => i.type === "NCZIRECIDENT")
					if (barcode) {
						printValues["barcode"] = barcode.value
						printValues["barcode_text"] = `<p class="barcode-text">${barcode.value.substr(
							0,
							15
						)}<span class="bold">${barcode.value.substr(15, 6)}</span></p>`
					}
				}

				//Priorita (Urgentnosť)
				printValues["priority"] = recordData.priority ? recordData.priority.name_ext : ""

				//Dátum a čas
				if (recordData.recorded_events.length > 0) {
					const ev = recordData.recorded_events.find((re) => re.type === "REQ")
					if (ev) {
						printValues["created"] = moment(ev.recorded_at).format(translations.dateFormat)
					}
				}
				const templateName = recordData._type === "EHR.Data.Specialist.Request" ? "request" : "request_radiology"
				if (directPrint) {
					printReport(templateName, printValues)
				}
				return {template: templateName, values: printValues}
			})
	}

	@action async loadDefaultDiagnosis(form) {
		const providerId = getSelectedOrgUnitID()
		const patientId = DataStore.patientDTO.get("patientID")
		const filter = {
			filters: [
				{
					associated_column: "provider_id",
					values: [
						{
							id_value: providerId
						}
					]
				},
				{
					associated_column: "patient_id",
					values: [
						{
							id_value: patientId
						}
					]
				},
				{
					associated_column: "type_id",
					values: [
						{
							id_value: "EHR.Data.Ambulance"
						}
					]
				}
			],
			time_from: moment
				.utc()
				.startOf("day")
				.format(),
			time_to: moment
				.utc()
				.endOf("day")
				.format()
		}

		const response = await api.loadEHROverview(filter).call()
		if (isSafe(response) && isSafe(response.rows) && isSafe(response.rows[0]) && isSafe(response.rows[0].diagnosis)) {
			form.$("diagnosis").value = response.rows[0].diagnosis
		}
	}

	@action async loadFormData(form, id, draft) {
		let recordData = await api.loadRecord(id, "L4").call()
		if (recordData.general_items) {
			const dg = recordData.general_items.find(
				(gi) => gi._type === "EHR.Data.Record.Diagnosis" && gi.diagnosis_type._id === "PRIMARY"
			)
			const dgSecondary = recordData.general_items.find(
				(gi) => gi._type === "EHR.Data.Record.Diagnosis" && gi.diagnosis_type._id === "SECONDARY"
			)
			const ex = recordData.general_items.find(
				(gi) => gi._type === "EHR.Data.Record.Expertise" && gi.type === "recvouexp"
			)
			const pa = recordData.general_items.find(
				(gi) => gi._type === "EHR.Data.Record.ProfessionalActivity" && gi.type === "profact"
			)

			// const orgunit = recordData.specific_rels.find(sr => sr.specific_rel._type === "EHR.Data.Entity.Provider");
			const record = recordData.master_records.find((mr) => mr.type === "SND")
			const sumReq = recordData.summaries.find((s) => s.type._id === "REQ")
			const sumInfo = recordData.summaries.find((s) => s.type._id === "INFO")
			if (sumReq) {
				form.$("request").value = sumReq.content
			}
			if (sumInfo) {
				form.$("patientnote").value = sumInfo.content
			}
			form.$("request_type").value = recordData._type
			form.$("sedation_needed").value = recordData.sedation_needed
			form.$("modality").value = recordData.modality
			form.$("organ_to_exam").value = recordData.organ_to_exam
			form.$("priority").value = recordData.priority._id

			if (isSafe(ex)) {
				form.$("expertise").value = ex.item._id
			}
			if (isSafe(dg)) {
				form.$("clarification").value = dg.description
				form.$("diagnosis").value = dg.item._id
			}
			if (isSafe(pa) && isSafe(pa.item)) {
				form.$("professional_activity").value = pa.item._id
			}
			if (isSafe(dgSecondary)) {
				form.$("diagnosis_secondary").value = dgSecondary.item._id
				form.$("diagnosis_type_secondary").value = "SECONDARY"
				form.$("showSecondaryDiagnosis").value = true
			}
			if (record) {
				this.setter("record", record.master)
			}
		}

		if (draft) {
			form.$("recordId").value = recordData._id
		}
		// PatientRequestStore.represcribeFlag = true
	}

	//Ak máme načítaný CL professionalActivity, tak defaultne naplníme z číselníka code_ext 2 hodnotu (mala by to byť ambulancia)
	setProfessionalActivity = (form) => {
		if (form) {
			if (GlobalStore.CL["professionalActivity"]) {
				const ambPA = GlobalStore.CL["professionalActivity"].find((pa) => pa.code_ext === "2")
				if (ambPA) {
					this.paAmbDefaultID = ambPA._id
					form.$("professional_activity").set("value", this.paAmbDefaultID)
				}
			}
		}
	}
}

var singleton = new PatientRequestStore()
export default singleton
